//получение отчетов
export const REPORTS_GET_ACTION = {
    //получение отчетов
    GET_REPORT : "REPORT | GET_REPORT",
    //получение параметров отчета
    GET_PARAMS : "REPORT | GET_PARAMS",
    //утоновить активноую строку
    SET_ACTIVE_ROW : "REPORT | SET_ACTIVE_ROW",

};
//сохранение вводимых параметров
export const REPORT_PARAMS = {
    //сохранение данных из mySelect
    REPORT_MULTIPLE_PARAMS_SAVE : "REPORT | INPUTS | REPORT_MULTIPLE_PARAMS_SAVE",

};

export const REPORT_STATE = {
    GET_STATE : "REPORT | GET_STATE",
    GET_UID : "REPORT | GET_UID",
}


export const SEARCH_PREVIEW_ACTIONS = {
    // сброс предпросмотра
    RESET : "SEARCH | PREVIEW | RESET",
    // сбор и сохранение данных для нового выбранного документа
    CREATE_BY_NEW_OPEN_DOC : "SEARCH | PREVIEW | CREATE_BY_NEW_OPEN_DOC",
    // сбор данных для отображения в дополнительных разделах
    COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL : "SEARCH | ADDITIONAL_SECTIONS | COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL",
    // выбор активного (показанного) файла для предпросмотра
    SELECT_ACTIVE_FILE : "SEARCH | PREVIEW | SELECT_ACTIVE_FILE",
    // выбрать активный файл предпросмотра в карточке документа
    SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS : "SEARCH | CARDS | SELECT_ACTIVE_PREVIEW_FILE",
    // выбрать активную для просмотра карточку документа
    SELECT_ACTIVE_DOC_CARDS : "SEARCH | CARDS | SELECT_ACTIVE_DOC_CARDS",
    // открытие или закрытие дополнительных разделов
    TOGGLE_ADDITIONAL_SECTIONS_OPEN : "SEARCH | ADDITIONAL_SECTIONS | TOGGLE_ADDITIONAL_SECTIONS_OPEN",
    // создание новой карточки документов
    CREATE_DOC_CARDS : "SEARCH | CARDS | CREATE_DOC_CARDS",
};

// временные данные во избежании повторных запросов к серверу
export const SEARCH_TEMPORARY_DATA =  {
    // сохранить произвольные данные
    SAVE : "SEARCH | TEMPORARY_DATA | SAVE",
    // стереть все данные
    CLEAR : "SEARCH | TEMPORARY_DATA | CLEAR",
}

// глобальное состояние для компонента DocumentApp
export const SEARCH_ROOT_STATE_ACTIONS = {
    // изменить режим отображения
    CHANGE_VIEW_MODE : "SEARCH | ROOT_STATE | CHANGE_VIEW_MODE",
};