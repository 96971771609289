import React from "react";
import {connect} from "react-redux";
import TreePackageFolderDownload from "./TreePackagesFolderDownload";
import {history, store} from "../../../index";
import {setLoaderModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {
    handlerSearchPackagesPath,
    handlerTreePackage
} from "../../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {
    breadcrumbsAdd,
    PackageAddNodesTree,
    SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {setViewModePreviewPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    handlerIsSearch,
    updateInputsFieldPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {
    deleteRowFromChecked_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {PACKAGES_TABLE_ACTIONS} from "../../../store/packages/packageActionsList";


class TreePackagesDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            curRef: null
        }

        this.setRef = this.setRef.bind(this);
        this.selectNodeSearch = this.selectNodeSearch.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.state !== prevState)
            if (this.state.curRef?.current) {
                this.state.curRef.current.focus();
            }
    }

    setRef = (lRef) => {
        //проверка от зацикливания
        if (lRef)
            if (this.state.curRef != lRef)
                this.setState({curRef: lRef})
    }

    selectNodeSearch(item) {
        this.props.setViewModePreviewPackages({
            ViewMode : "two_table_download"
        });

        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "TreePackagesFolderSearch51" }
        }));

        this.props.handlerSearchPackagesPath({
            TableType : "download",
            Id:item.Id,
            Next : {
                action : ()=>{
                    this.props.handlerTreePackage({
                        TableType : "download",
                        IsDownload : true,
                        Next: {
                            action : () => {
                                if(this.props.viewModePreview === "welcome") {
                                    this.props.setViewModePreviewPackages({
                                        ViewMode : "none",
                                    });
                                }

                                // this.props.setModalData({});
                                //Новый прелоадер
                                this.props.setLoaderModalData({keyDeleted: "TreePackagesFolderSearch64"});


                                let query = this.props.router.location.query;
                                let queryLink = "";

                                queryLink = Routing.CreateRef({
                                    oldQuery : query,
                                    newQuery : {dwp : item.Id},
                                    excludedKeys : ["p2"]
                                });

                                let link = `${this.props.router.location.pathname}${queryLink}`;
                                history.push(link);
                            },
                            params: {}
                        }
                    });
                },
                params : {}
            }
        })
        //TODO aa чтобы не закрывалась модалка, иначе в модалке выходит из div
        if (this.props.backFocus)
            this.props.backFocus();
    }

    render() {
        let data = (this.props.viewModeDownload === RootComponentsStateViewModeTypes.PackagesApp.Tree_Search_Download)
            ? this.props.tree_search
            : this.props.tree_render?.Children;
        // let data = this.props.tree_render;
        let depth = 0;

        if(!data) return null;

        return(
            <ul className="menu-nav">
                {
                    data.map((item, index) => {
                        if (this.props.viewModeDownload === RootComponentsStateViewModeTypes.PackagesApp.Tree_Search_Download) {
                            let activeStyle = {};
                            let activeItemStyleText = "";
                            let activeItemStyleIcon = "icon-color-grey";
                            if (this.props.searchActiveNode && item.Id === this.props.searchActiveNode.Node.Id) {
                                activeStyle = {"border": "2px var(--elem-bg) solid"};
                                activeStyle.background = "var(--elem-bg)";
                                activeStyle.transition = "background-color 0.3s";
                                activeStyle.minHeight = "45px";
                                activeStyle.borderRadius = "25px";

                                activeItemStyleText = "text-color-primary-dark";
                                activeItemStyleIcon = "icon-color-primary-dark";
                            }
                            activeStyle.marginTop = "3px";
                            activeStyle.marginBottom = "3px";
                            let folderIcon = item.ContainsChilds ? "icon-on1" : "icon-off1";

                            return (
                                <li className={"menu-link-p menu-item "} key={item.Id}>
                                     <span className="menu-link menu-toggle" style={activeStyle}
                                           onClick={() => {
                                               store.dispatch({type: PACKAGES_TABLE_ACTIONS.SEARCH_TOGGLE_DOWNLOAD_SEARCH, payload: false});
                                               this.selectNodeSearch(item)
                                           }}
                                     >
                                         <span className="svg-icon menu-icon">
                                            <i className={folderIcon + " " + activeItemStyleIcon}/>
                                         </span>
                                        <span className={`menu-text ${activeItemStyleText}`}>{item.Name}
                                        </span>
                                         {/*<i className={`arrow_m icon-Arrows_1`} />*/}
                                    </span>
                                    <div className="menu-submenu menu-submenu-p">

                                    </div>
                                </li>
                            );
                        } else return (
                            <TreePackageFolderDownload
                                key={item.TreeId}
                                data={item}
                                setRef={(Item) => {
                                    this.setRef(Item)
                                }}
                                depth={depth+1}/>
                        );
                    })
                }
            </ul>
        );
    }
}


const  mapStateToProps = state => {
    return {
        tree_render : state.packages.treeDownload.render, // данные в обычном режиме
        tree_search : state.packages.treeSearchDownload.folders, // данные при поиске
        tree_isSearch : state.packages.treeDownload.isSearch,
        searchActiveNode : state.packages.treeDownload.activeNode,
        router : store.getState().router,
        checkedFields : state.packages.mainTable.checkedFields,
        viewModeDownload : state.packages.rootState.viewModeDownload,
    }
}

const  mapDispatchToProps = {
    handlerSearchPackagesPath,
    handlerTreePackage,
    setLoaderModalData,
    PackageAddNodesTree,
    SelectActivePackageNode,
    breadcrumbsAdd,
    setViewModePreviewPackages,
    updateInputsFieldPackage,
    handlerIsSearch,
    deleteRowFromChecked_packagesDocumentRight

}

export default connect(mapStateToProps, mapDispatchToProps)(TreePackagesDownload);
