import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import moment from 'moment';
import {SelectActivePackageDocument} from "../../../../store/packages/actionCreators/package_TreeActionCreator";
import {TableTypesEnum} from "../../../../tools/StaticTypes";



class VersionSectionPackagesMini extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen : false
        };

        this.toggle = this.toggle.bind(this);
        this.selectVersion = this.selectVersion.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }

    // выбрать другую версию документа
    selectVersion(index) {
        let activeDocument;
        if(this.props.tableRole === TableTypesEnum.Main) activeDocument = this.props.activeDocument;
        else if(this.props.tableRole === TableTypesEnum.Additional) activeDocument = this.props.activeDocumentAdditional;
        else if(this.props.tableRole === TableTypesEnum.Download) activeDocument = this.props.activeDocumentDownload;
        else if(this.props.tableRole === TableTypesEnum.DocumentsRight) activeDocument = this.props.activeDocumentRight;

        this.props.SelectActivePackageDocument({
            VersionIndex : index,
            TableType : this.props.tableRole,
            Id : this.props.tableRole === TableTypesEnum.DocumentsRight ? activeDocument.Info.Id : activeDocument.Node.Id,
            IsDownload : this.props.tableRole === TableTypesEnum.Download,
            ActiveDoc: activeDocument
        });
    }


    render() {
        let activeDocument;
        if(this.props.tableRole === TableTypesEnum.Main) activeDocument = this.props.activeDocument;
        else if(this.props.tableRole === TableTypesEnum.Additional) activeDocument = this.props.activeDocumentAdditional;
        else if(this.props.tableRole === TableTypesEnum.Download) activeDocument = this.props.activeDocumentDownload;
        else if(this.props.tableRole === TableTypesEnum.DocumentsRight) activeDocument = this.props.activeDocumentRight;


        if(!activeDocument) return null;
        let isReady = activeDocument.additionalSections.isReady;
        let sectionData = activeDocument.additionalSections.versionSection.versions.Records;
        let activeVersionIndex = activeDocument.additionalSections.versionSection.activeVersionIndex;

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Версии документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">
                        <table className="table table-bordered table-hover table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-11">Дата</th>
                                <th className="col-lg-1">Номер</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.map((item, index)=>{
                                    let timeMoment = new moment(item.VersionTime);

                                    let trClass = "";
                                    if(index === activeVersionIndex) {
                                        trClass = "tr-active";
                                    }

                                    return(
                                        <tr className={trClass} key={item.Version} onClick={()=> {this.selectVersion(index)}}>
                                            <td title={timeMoment.format("LTS")}>
                                               <time>{timeMoment.format("L")}</time>
                                            </td>
                                            <td title={item.VersionComment}>
                                                <span className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                    {item.Version}
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,
        activeDocumentRight : state.packages.mainTable.activeDocument,
    }
}

const  mapDispatchToProps = {
    SelectActivePackageDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionSectionPackagesMini);
