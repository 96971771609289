import React from "react";
import {connect} from "react-redux";
import {setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";
import LeftMenuReports from "../Reports/LeftMenuReports";
import InputsReport from "../Reports/InputsReport";
import {ActionQueue} from "../../store/rootReducer";
import {getReports} from "../../store/reports/actionCreators/report_LeftMenu";
import {store} from "../../index";
import MobileMenu from "../static/MobileMenu";
import Select from "react-select";


const selectStyles = {
    container: () => ({
        display: "none",
    }),
}

class ReportsApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            SearchText: "",//первое поле
            AttributesText: "",//поиск по атрибутам
            FullText: "",//поиск по полнотексту
            IsSearchInAttachments: false, //искать в тексте прикрепленных файлов
            IsSearchInVersions: false, // искать в версиях
            IsSearchInMarkedForDelete: false // искать в помеченных на удаление
        };

    }

    componentDidMount() {
        this.props.setStateLeftAsideMenu({
            isClose: true
        });

        this.props.getReports({Next : {
            action : () => {
                store.dispatch({type : "finish"});
            },
            params : {}
        }});
    }


    render() {
        let isReady=this.props.isReady

        let asideMobileStyle = "";
        if(!this.props.asideMenuIsClose) {
            asideMobileStyle = "aside-on";
        }
        let mod = this.props.theme.modString;
// содержание страницы
        return (
            <div>
                <div className="header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu">

                    <MobileMenu rootName={"ReportsApp"}/>

                    <div className="d-flex flex-column flex-root">
                        <div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>
                            <LeftMenu rootName="ReportsApp"/>

                            {/*{*/}
                            {/*    <div className="aside-secondary d-flex-none flex-row-fluid" style={{}}>*/}
                            {/*        <div className="aside-workspace scroll scroll-push my-2">*/}
                            {/*            <div className="tab-content">*/}
                            {/*                <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active">*/}

                            {/*                    <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">*/}
                            {/*                        <div className="aside-menu min-h-lg-800px">*/}


                            {/*                            {<LeftMenuReports/>}*/}


                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper">

                            <div className="content d-flex flex-column flex-column-fluid">
                                <TopMenu rootName="ReportsApp"/>
                            </div>


                            <div className="d-flex flex-column-fluid px-4">
                                {/* TODO TP костыль для анимации левого меню start */}
                                <Select options={[{label: 1, value: 1}]} styles= {selectStyles} />
                                {/* end */}
                                <div className="container-new">
                                    <div className="row pt-4">
                                        <div className="col-lg-12" style={{padding: "0"}}>
                                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
                                                 style={{height: "350px",backgroundImage: `url('./images/reports${mod}.svg')`}}>
                                                <div className="card-body d-flex" style={{padding: "4rem 3.25rem"}}>
                                                    <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                                        <div className="flex-grow-1">
                                                            <span className="text-welcome font-weight-bolder font-size-h1">Отчеты</span>
                                                            <p className="text-welcome font-weight-bold mt-3">С помощью раздела для формирования отчетов<br/>
                                                                вы можете настроить экспорт статистической информации
                                                            </p>
                                                        </div>
                                                        {/*<Link to={"/help"} className="btn btn-light font-weight-bolder text-success btn-lg"*/}
                                                        {/*      style={{borderRadius: "0.8em"}}>*/}
                                                        {/*    Справка*/}
                                                        {/*</Link>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row col-xl-7">
                                            <div className="col-xl-7 pl-8">
                                                {<LeftMenuReports/>}
                                            </div>
                                        </div>

                                        {
                                            isReady &&
                                            <div className="col-xl-5">
                                                {<InputsReport/>}
                                            </div>
                                        }





                                    </div>
                                </div>
                            </div>








                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


const  mapStateToProps = state => {
    return {
        rootState : state.report.rootState,
        isReady : state.report.leftMenu.isReady,
        asideMenuIsClose : state.globalState.app.asideMenuIsClose,
        theme: state.globalState.theme,
    }
}

const  mapDispatchToProps = {
    setStateLeftAsideMenu,
    ActionQueue,
    getReports
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsApp);
