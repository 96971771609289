import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default class Page extends Component {
    static propTypes = {
        pageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        pageNumber: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool,
        activeClass: PropTypes.string,
        activeLinkClass: PropTypes.string,
        itemClass: PropTypes.string,
        linkClass: PropTypes.string,
        disabledClass: PropTypes.string,
        href: PropTypes.string
    };

    static defaultProps = {
        activeClass: "active",
        disabledClass: "disabled",
        itemClass: undefined,
        linkClass: undefined,
        activeLinkCLass: undefined,
        isActive: false,
        isDisabled: false,
        href: "#"
    };

    handleClick(e) {
        const { isDisabled, pageNumber } = this.props;
        e.preventDefault();
        if (isDisabled) {
            return;
        }
        this.props.onClick(pageNumber);
    }

    render() {
        let {
            pageText,
            activeClass,
            itemClass,
            linkClass,
            activeLinkClass,
            disabledClass,
            isActive,
            isDisabled,
            href,
            ariaLabel
        } = this.props;

        const css = cx(itemClass, {
            [activeClass]: isActive,
            [disabledClass]: isDisabled
        });

        const linkCss = cx(linkClass, {
            [activeLinkClass]: isActive,
            //TODO Задача № 20409 Блокировать кнопки переключения страниц при крайнем положении текущей страницы
            [disabledClass]: isDisabled
        });


            if(pageText == "prev<") {
                return (
                <li className={css} onClick={this.handleClick.bind(this)}>
                    <a className={linkCss} href={href} aria-label={ariaLabel}>
                        <i className="svg-icon svg-icon-sm svg-icon icon-Previous_page"/>
                    </a>
                </li>);
            }
            else if(pageText == "prev<<"){
                return (
                    <li className={css} onClick={this.handleClick.bind(this)}>
                        <a className={linkCss} onClick={this.handleClick.bind(this)} href={href} aria-label={ariaLabel}>
                            <i className="svg-icon svg-icon-sm svg-icon icon-First_page"/>
                        </a>
                    </li>);
            }
            else if(pageText == "next>"){
                return (
                    <li className={css} onClick={this.handleClick.bind(this)}>
                        <a className={linkCss} href={href} aria-label={ariaLabel}>
                            <i className="svg-icon svg-icon-sm svg-icon icon-Next_page"/>
                        </a>
                    </li>);
            }
            else if(pageText == "next>>"){
                return (
                    <li className={css} onClick={this.handleClick.bind(this)}>
                        <a className={linkCss} href={href} aria-label={ariaLabel}>
                            <i className="svg-icon svg-icon-sm svg-icon icon-Last_page"/>
                        </a>
                    </li>);
            }
            else {
                return (
                <li className={css} onClick={this.handleClick.bind(this)}>
                    <a className={linkCss} href={href} aria-label={ariaLabel}>
                        {pageText}
                    </a>
                </li>);
            }
    }
}
