import React from "react";
import {connect} from "react-redux";
import {combineMainTableAttributesSearch} from "../../store/search/actionCreators/search_table";
import {__} from "../../tools/HelpFunctions";
import {apiRequest} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {Sorting} from "../../tools/Filters";
import {store} from "../../index";
import {
    handlerIsSearch,
    handlerSearchPackages,
    searchByParamsPackages,
    updateInputsFieldPackage, updateInputsFieldSortingPackage
} from "../../store/packages/actionCreators/package_InputActionCreator";
import {
    getDocumentsForTreePackages,
    PackageAddNodesTree,
    SelectActivePackageNode
} from "../../store/packages/actionCreators/package_TreeActionCreator";
import {treeSearchPackage} from "../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {
    setLoaderModalData,
    setModalData,
    setUserModalShow
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ModalTypes, PackagesViewFilters} from "../../tools/StaticTypes";
import {
    changeSortDirection,
    changeViewFilter,
    PackagesPaginationMovePage, setViewModePreviewPackages
} from "../../store/packages/actionCreators/package_MainTableActionCreator";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import FiltersString from "./filtersByTypes/FiltersString";
import FiltersDate from "./filtersByTypes/FiltersDate";
import Select from "react-select";

class InputSearchPackage extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover: false,
            accordionShow1: false,
            accordionShow2: false,
            accordionShow3: true,
            accordionShow4: false,
            arrowDopDate: false,

            LoadTypes: [],
            TypeIds: [],
            limitationTooltipShow: false,

            clearHoverDate: false,
            clearHoverNumber: false,
            clearHoverName: false,
            clearHoverTypeName: false,
            clearHoverModifyDate: false,

            PackageName: "",

            currentFilter: null,
            extFilter: false,
            extSearch: false,

            initNode: null,
            typeOptions: [],
            curType: ""
        };
        this.limitation = React.createRef();

        this.RegDateAsStringRef = React.createRef();
        this.RegNumberRef = React.createRef();
        this.NameRef = React.createRef();
        this.TypeNameRef = React.createRef();
        this.ModifyDateRef = React.createRef();


        this.search = this.search.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.accordion1 = this.accordion1.bind(this);
        this.accordion2 = this.accordion2.bind(this);
        this.accordion3 = this.accordion3.bind(this);
        this.accordion4 = this.accordion4.bind(this);
        this.loadTypes = this.loadTypes.bind(this);
        this.docTypesSelectOnChange = this.docTypesSelectOnChange.bind(this);
        this.dopDate = this.dopDate.bind(this);
        this.clearInput = this.clearInput.bind(this);

        this.getCurrentSearchInputs = this.getCurrentSearchInputs.bind(this);
        this.getAppliedParams = this.getAppliedParams.bind(this)
        this.sortByParam = this.sortByParam.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
    }

    async componentDidMount() {
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: false,
                gif: "packages",
                key: "InputSearchPackage111",
            }
        })
        let fillingFieldsCount = 0;
        let searchInputs = this.getCurrentSearchInputs();
        if (searchInputs) fillingFieldsCount = Object.values(searchInputs.AppliedParams).filter(Boolean).length;
        if (fillingFieldsCount > 0) {
            this.setState({extSearch: true})
        }

        await this.loadTypes()
        let activeNode = this.props.tableRole === "main"
            ? this.props.activeNode
            : this.props.tableRole === "additional"
                ? this.props.activeNodeAdditional
                : this.props.activeNodeDownload

        await store.dispatch(getDocumentsForTreePackages({
            Id: activeNode?.Node.Id,
            PageOffset: 0,
            PageSize: 512,
            TableType: this.props.tableRole,
            ForTypes: true,
            Next: {
                action: () => {
                    this.props.setLoaderModalData({keyDeleted: "InputSearchPackage121"})
                }, params: {}
            }
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let initNode = this.props.tableRole === "main"
            ? this.props.initNode
            : this.props.tableRole === "additional"
                ? this.props.initNodeAdditional
                : this.props.initNodeDownload


        let options = []
        if (this.state.LoadTypes) {
            this.state.LoadTypes.forEach(type => {
                if (initNode?.find(node => type.Id === node.Type.Id))
                    options.push({value: type.Name, label: type.Name})
            })
            if (JSON.stringify(options) !== JSON.stringify(this.state.typeOptions)) {
                this.setState({typeOptions: options})
            }
        }
    }

    getCurrentSearchInputs() {
        let searchInputs;

        if (this.props.tableRole === "main") {
            const {searchInputsMain} = this.props;
            if (searchInputsMain) {
                searchInputs = searchInputsMain
            }
        } else if (this.props.tableRole === "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional) {
                searchInputs = searchInputsAdditional
            }

        } else if (this.props.tableRole === "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload) {
                searchInputs = searchInputsDownload
            }

        }
        return searchInputs;
    }


    // componentDidMount() {
    //TODO задача по разделению фильтров пакетов
    // let {searchInputs} = this.props;
    // let searchInputs;
    //
    // if (this.props.tableRole == "main")
    // {
    //     const {searchInputsMain} = this.props;
    //     if (searchInputsMain)
    //     {
    //         searchInputs = searchInputsMain
    //     }
    // }
    // else if (this.props.tableRole == "additional") {
    //     const {searchInputsAdditional} = this.props;
    //     if (searchInputsAdditional)
    //     {
    //         searchInputs = searchInputsAdditional
    //     }
    //
    // } else if (this.props.tableRole == "download") {
    //     const {searchInputsDownload} = this.props;
    //     if (searchInputsDownload)
    //     {
    //         searchInputs = searchInputsDownload
    //     }
    //
    // }
    // }
    dopDate() {
        this.setState({
            arrowDopDate: !this.state.arrowDopDate
        });
    }

    accordion1() {

        this.setState({
            accordionShow1: !this.state.accordionShow1
        });

        this.loadTypes()
    }


    accordion2() {

        this.setState({
            accordionShow2: !this.state.accordionShow2
        });
    }

    accordion3() {

        this.setState({
            accordionShow3: !this.state.accordionShow3
        });
    }

    accordion4() {

        this.setState({
            accordionShow4: !this.state.accordionShow4
        });
    }

    areFieldsEmpty(searchInputs) {
        return Object.values(searchInputs?.RegDateAsString).every(elem => !elem)
            && Object.values(searchInputs?.RegNumber).every(elem => !elem)
            && Object.values(searchInputs?.Name).every(elem => !elem)
            && Object.values(searchInputs?.TypeName).every(elem => !elem)
            && Object.values(searchInputs?.ModifyDate).every(elem => !elem)
    }

    getAppliedParams(searchInputs) {
        const result = {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false,}
        const {RegNumber, Name, TypeName, ModifyDate, RegDateAsString} = searchInputs
        if (Object.values(RegNumber).some(item => item)) {
            result.RegNumber = true
        }
        if (Object.values(Name).some(item => item)) {
            result.Name = true
        }
        if (Object.values(TypeName).some(item => item)) {
            result.TypeName = true
        }
        if (Object.values(ModifyDate).some(item => item)) {
            result.ModifyDate = true
        }
        if (Object.values(RegDateAsString).some(item => item)) {
            result.RegDateAsString = true
        }
        return result
    }

    inputHandler(event = null, ref = null, item = null) {
        let searchInputs = this.getCurrentSearchInputs()
        let value = event ? event.target.value : ""
        if (item == null) {
            let nameInput = event ? event.target.name : ref.current.name;
            const {RegNumber, Name, TypeName, ModifyDate, RegDateAsString, AppliedParams} = searchInputs
            if (nameInput === "Number") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber: {ApproximatelyEqual: value, BeginningFrom: '', EndingOn: '', Equal: ''},
                    Name,
                    TypeName,
                    ModifyDate,
                    RegDateAsString,
                    AppliedParams,
                });
            } else if (nameInput === "Name") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name: {ApproximatelyEqual: value, BeginningFrom: '', EndingOn: '', Equal: ''},
                    TypeName,
                    ModifyDate,
                    RegDateAsString,
                    AppliedParams,
                });
            } else if (nameInput === "TypeName") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName: {In: ""},
                    ModifyDate,
                    RegDateAsString,
                    AppliedParams,
                });
            } else if (nameInput === "ModifyDate") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName,
                    ModifyDate: {ApproximatelyEqual: value, OnDate: '', DateStart: '', DateEnd: ''},
                    RegDateAsString,
                    AppliedParams,
                }, () => {
                    // if (value === "" || value === null) this.search({});
                });
            } else if (nameInput === "Date") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName,
                    ModifyDate,
                    RegDateAsString: {ApproximatelyEqual: value, OnDate: '', DateStart: '', DateEnd: ''},
                    AppliedParams,
                }, () => {
                    // if (value === "" || value === null) this.search({});
                });
            }
        } else {
            let ActiveAttributes = __.deepCopy(this.state.ActiveAttributes);
            let index = ActiveAttributes.findIndex(attr => attr.uid === item.uid);
            ActiveAttributes[index].inputValue = value;
            this.setState({ActiveAttributes});
        }
    }


    async search({FieldSorting = "", Direction = 0, isClear = null, lFlagReset = null, isClose = null, applyButton = false}) {


        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data: {
                content: "Загрузка документов...",
                disableButton: true,
                fullBackground: false,
                gif: "packages",
                key: "inputSearchPackage285"
            }
        }));

        if (!(store.getState().packages.rootState.viewModePreview.includes("two_table")
            || store.getState().packages.rootState.viewModePreview === "right_document_table"))
            this.props.setViewModePreviewPackages({
                ViewMode: "none",
            });


        //TODO aa 24.03.2023 Если находишься на 3 странице и фильтруешь, и после фильтра только две страницы, то пагинация показывает 76 из 49, что ошибочно
        await this.props.PackagesPaginationMovePage({
            FieldSorting,
            Direction,
            PageNumber: 1,
            TableType: this.props.tableRole
        });

        if (applyButton) {
            if (this.areFieldsEmpty(this.getCurrentSearchInputs())) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Нужно заполнить хотя бы один атрибут`}
                });
                this.props.setLoaderModalData({keyDeleted: "InputSearchPackage306"});
                return null
            }
            if (this.props.tableRole !== "main") {
                this.props.setUserModalShow({
                    isShow: false
                });
            }
        }


        let searchInputs = this.getCurrentSearchInputs()
        let lActiveNode;

        if (this.props.tableRole === "main")
            lActiveNode = this.props.activeNode.Node.Id
        else if (this.props.tableRole === "additional")
            lActiveNode = this.props.activeNodeAdditional.Node.Id
        else if (this.props.tableRole === "download")
            lActiveNode = this.props.activeNodeDownload.Node.Id;

        if (lFlagReset) {
            this.setState({curType: ""})

            this.props.updateInputsFieldSortingPackage({TableRole: this.props.tableRole})
            await this.props.updateInputsFieldPackage({
                TableRole: this.props.tableRole,
                RegDateAsString: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                Name: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                RegNumber: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                TypeName: {In: ''},
                ModifyDate: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                AppliedParams: {
                    RegDateAsString: false,
                    Name: false,
                    RegNumber: false,
                    TypeName: false,
                    ModifyDate: false,
                },
                // IsSearch: false
            });

            //TODO нужно, чтобы сбрасывал

            this.props.PackageAddNodesTree({
                Id: lActiveNode,
                TableType: this.props.tableRole
            });
            this.props.handlerIsSearch({TableType: this.props.tableRole});
            // this.props.updateInputsFieldPackage({TableRole: this.props.tableRole});

            await this.props.PackagesPaginationMovePage({
                PageNumber: 1,
                TableType: this.props.tableRole
            });

            this.props.changeViewFilter({
                Type: "packages",
                TableType: this.props.tableRole,
                lStoreValue: PackagesViewFilters.All
            });

            // this.props.setModalData({});
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "InputSearchPackage345"});
            return;
        }

        const appliedParams = this.getAppliedParams(searchInputs)

        // this.props.updateInputsFieldSortingPackage({TableRole: this.props.tableRole, SortingParam: FieldSorting, Direction})
        this.props.updateInputsFieldPackage({
            TableRole: this.props.tableRole,
            //TODO aa 28.03.2023 ! НЕ УБИРАТЬ иначе ломается подсчет количества доступных страниц после фильтра
            IsSearch: true,
            RegDateAsString: searchInputs.RegDateAsString ?? { ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: '' },
            RegNumber: searchInputs.RegNumber ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            Name: searchInputs.Name ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            TypeName: searchInputs.TypeName ?? {In: ''},

            //TODO Задача № 20069 3. Формат введения - дата. В документах данное поле имеет текстовый формат ввода, т.к. используется фильтрация формата 05.2022 для фильтрации документов за май 2022, данная практика используется по аналогии со старым архивом
            // ModifyDate:  __.reformatDate(this.state.ModifyDate),
            ModifyDate: searchInputs.ModifyDate ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            AppliedParams: appliedParams,
            Next: {
                action: () => {
                    if (isClear) {
                        this.props.PackageAddNodesTree({
                            Id: lActiveNode,
                            Next: {
                                action: () => {
                                    // this.props.handlerIsSearch({
                                    //     TableType: "main",
                                    //     Next: {
                                    //         action: () => {
                                                // this.props.setModalData({});
                                                //Новый прелоадер
                                                this.props.setLoaderModalData({keyDeleted: "InputSearchPackage378"});
                                    //         },
                                    //         params: {}
                                    //     }
                                    // })
                                },
                                params: {}
                            }
                        })
                    } else {
                        if (
                            this.areFieldsEmpty(searchInputs) && this.props.tableRole !== "main" && isClose
                        ) {
                            // this.search(false, true);
                            this.props.changeViewFilter({
                                Type: "packages",
                                TableType: this.props.tableRole,
                                lStoreValue: PackagesViewFilters.OnlyDocuments
                            });
                        } else {
                            if (this.state.PackageName.length > 0) {

                                this.props.treeSearchPackage({
                                    IdPackage: lActiveNode,
                                    Input: this.state.PackageName,
                                    IsFilters: true
                                })
                                // this.props.setModalData({});
                                //Новый прелоадер
                                this.props.setLoaderModalData({keyDeleted: "InputSearchPackage406"});
                            } else {
                                this.props.PackageAddNodesTree({
                                    // FieldSorting,
                                    // Direction,
                                    Id: lActiveNode,
                                    Next: {
                                        action: () => {
                                            this.props.searchByParamsPackages({
                                                TableType: this.props.tableRole,
                                                Next: {
                                                    action: () => {
                                                        this.props.handlerSearchPackages({
                                                            TableType: this.props.tableRole,
                                                            Next: {
                                                                action: () => {
                                                                    if (!Direction)
                                                                        this.props.SelectActivePackageNode({
                                                                            Id: lActiveNode,
                                                                            TableType: this.props.tableRole,
                                                                        })
                                                                    this.props.setLoaderModalData({keyDeleted: "InputSearchPackage421"});
                                                                },
                                                                params: {}
                                                            }
                                                        })
                                                    },
                                                    params: {}
                                                }
                                            })
                                        },
                                        params: {}
                                    }
                                })
                            }
                        }
                    }
                },
                params: {}
            }
        });
        //TODO - закрытие модалки по кнопке "Найти"
        if (isClose) {
            //TODO - отжатие кнопки Не показывать пакеты
            if (this.props.tableRole !== "main") {
                let lFlagSearch = this.props.tableRole === "additional"


                //TODO Задача № 20456 БАГ. Включаются пакеты, при запуске фильтра последовательно - enter и Применить

                //TODO в handlerSearchPackages меняется признак поиска в true, но несмотря на await из стора он пока не виден.
                // lFlagSearch = this.props.tableRole == "additional" ? (await store.getState().packages.treeAdditional.isSearch) : (await store.getState().packages.treeDownload.isSearch);

                if (!lFlagSearch)
                    this.props.changeViewFilter({
                        Type: "packages",
                        TableType: this.props.tableRole,
                        lStoreValue: PackagesViewFilters.OnlyDocuments
                    });
            }


            this.props.setUserModalShow({isShow: false});
            // Если нет значений
            if (this.areFieldsEmpty(searchInputs)) {
                this.props.changeViewFilter({
                    Type: "packages",
                    TableType: this.props.tableRole,
                    lStoreValue: PackagesViewFilters.OnlyDocuments
                });

                this.props.handlerIsSearch({TableType: this.props.tableRole});
                // this.props.setModalData({});
                //Новый прелоадер
                this.props.setLoaderModalData({keyDeleted: "InputSearchPackage474"});
            }

        }
    }


    async loadTypes() {
            let request_types = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE, {
                filter: {IdFilial: store.getState().globalState.filial.Active.Id},
                sorting: new Sorting().add({Direction: 1, PropertyName: "Name"}).get()
            });

            await request_types.execute((data) => {
                this.setState({LoadTypes: data.Records});
            }, (data) => {
                console.log("Ошибка загрузки списка типов документов: ", data)
            });
    }

    docTypesSelectOnChange(item) {
        let tempArr = new Set();
        for (let i = 0; i < item.length; i++) {
            //this.setState({TypeIds : [item[i].value]});
            tempArr.add(item[i].value);

        }
        tempArr.delete(null);
        this.setState({TypeIds: Array.from(tempArr)}, () => {

        });


    }

    async clearInput(event, ref) {
        // if (ref ==="TypeName") {
        //     let searchInputs = this.getCurrentSearchInputs()
        //     const {RegNumber, Name, ModifyDate, RegDateAsString, AppliedParams} = searchInputs
        //     this.props.updateInputsFieldPackage({
        //         TableRole: this.props.tableRole,
        //         RegNumber,
        //         Name,
        //         TypeName: {In: ""},
        //         ModifyDate,
        //         RegDateAsString,
        //         AppliedParams,
        //     });
        // }
        await this.inputHandler(null, ref)
        ref.current.value = ""
        this.search({})
    }

    setExtSearch(ev) {
        const value = ev.target.checked
        this.setState({extSearch: value})
    }

    setExtFilters(ev, item, input) {
        let current
        switch (item) {
            case "Date":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Дата",
                    IdAttributeType: DataTypesEnum.DataTime,
                    value: input
                }
                break
            case "Number":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Номер",
                    IdAttributeType: DataTypesEnum.String,
                    value: input
                }
                break
            case "Name":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Наименование",
                    IdAttributeType: DataTypesEnum.String,
                    value: input
                }
                break
            case "TypeName":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Тип",
                    IdAttributeType: DataTypesEnum.String,
                    value: input
                }
                break
            case "ModifyDate":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Последнее изменение",
                    IdAttributeType: DataTypesEnum.DataTime,
                    value: input
                }
                break
            default:
                break
        }

        this.setState({currentFilter: current, extFilter: true});
    }

    getFilterCount(field) {
        let fillingFieldsCount = 0;
        if (!field) return fillingFieldsCount
        if (typeof field === "object") {
            fillingFieldsCount = Object.values(field).filter(Boolean).length;
        }
        return fillingFieldsCount
    }

    getConfigLine(ref, input) {
        let style = {
            color: 'var(--originaltwo)',
            backgroundColor:'var(--elem-bg)',
            borderColor: '#ffffff',
            cursor:"pointer",
        }
        return (
            <div className="d-flex mt-4">
                <div className="col-lg-6">
                    <a href="#"
                       className={`btn btn-outline-secondary font-weight-bold ${ref === "TypeName" && "invisible"}`}
                       style={style}
                       onClick={(ev) => {
                           if (ref !== "TypeName") this.setExtFilters(ev, ref.current.name, input)
                       }}
                    >
                        Настроить
                    </a>
                </div>

                <div className="col-lg-7"
                     style={{
                         display: 'flex',
                         alignItems: 'center',
                         alignContent: 'center',
                         flexWrap: 'wrap',
                         justifyContent: 'center',
                         flexDirection: 'row'
                     }}>
                    <p style={{marginBottom: 0}}>
                        Применено: {this.getFilterCount(input)}
                    </p>
                </div>
            </div>
        )
    }

    async sortByParam({FieldSorting = "", Direction = 0}) {
            this.props.setLoaderModalData({
                data: {
                    content: "Загрузка...",
                    disableButton: true,
                    fullBackground: true,
                    gif: "packages",
                    key: "ThMainTable6963"
                }
            });

            this.props.updateInputsFieldSortingPackage({TableRole: this.props.tableRole, SortingParam: FieldSorting, Direction})

            this.props.changeSortDirection({dir: Direction, sortField: FieldSorting})

            await this.props.PackagesPaginationMovePage({
                PageNumber: 1,
                TableType: this.props.tableRole
            });

            let searchInputs = this.getCurrentSearchInputs()

            const appliedParams = this.getAppliedParams(searchInputs)
            let lActiveNode;

            if (this.props.tableRole === "main")
                lActiveNode = this.props.activeNode.Node.Id
            else if (this.props.tableRole === "additional")
                lActiveNode = this.props.activeNodeAdditional.Node.Id
            else if (this.props.tableRole === "download")
                lActiveNode = this.props.activeNodeDownload.Node.Id;

            this.props.updateInputsFieldPackage({
                TableRole: this.props.tableRole,
                // IsSearch: true,
                RegDateAsString: searchInputs.RegDateAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                RegNumber: searchInputs.RegNumber ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                Name: searchInputs.Name ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                TypeName: searchInputs.TypeName ?? {In: ''},
                ModifyDate: searchInputs.ModifyDate ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                AppliedParams: appliedParams,
                IsSearch:searchInputs.IsSearch,
                Next: {
                    action: () => {
                        this.props.PackageAddNodesTree({
                            FieldSorting,
                            Direction,
                            Id: lActiveNode,
                            TableType: this.props.tableRole,
                            Next: {
                                action: () => {
                                    this.props.searchByParamsPackages({
                                        TableType: this.props.tableRole,
                                        FieldSorting,
                                        Direction,
                                        Next: {
                                            action: () => {
                                                this.props.handlerSearchPackages({
                                                    TableType: this.props.tableRole,
                                                    Next: {
                                                        action: () => {
                                                            this.props.SelectActivePackageNode({
                                                                Id: lActiveNode,
                                                                TableType: this.props.tableRole,
                                                            })
                                                            this.props.setLoaderModalData({keyDeleted: "ThMainTablePackage123"});
                                                        },
                                                        params: {}
                                                    }
                                                })
                                            },
                                            params: {}
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
    }


    changeHandler(value) {
        const searchInputs = this.getCurrentSearchInputs()
        const {RegNumber, Name, ModifyDate, RegDateAsString, AppliedParams} = searchInputs

        let typeValue = []
        for (const type of value) {
            typeValue.push(type.value)
        }
        this.props.updateInputsFieldPackage({
            TableRole: this.props.tableRole,
            RegNumber,
            Name,
            TypeName: {In: typeValue.length ? typeValue : ""},
            ModifyDate,
            RegDateAsString,
            AppliedParams,
        })
        // if (value.value)
            this.setState({curType: value.value})
        // else this.setState({curType: ""})
    }

    render() {
        const {currentFilter} = this.state;

        let searchInputs = this.getCurrentSearchInputs()

        // let typesOptions = [];
        // typesOptions.push({
        //     value: null,
        //     label: "Не выбрано",
        //     isDisabled: true
        // });
        // for (let i = 0; i < this.state.LoadTypes.length; i++) {
        //     typesOptions.push({
        //         value: this.state.LoadTypes[i].Id,
        //         label: this.state.LoadTypes[i].Name,
        //         isDisabled: false
        //     });
        // }


        let accordionShow3 = "";
        let accordionShowArrow3 = "";
        if (this.state.accordionShow3) {
            accordionShow3 = "show"
            accordionShowArrow3 = "active"
        }
        // let accordionShow4 = "";
        // let accordionShowArrow4 = "";
        // if (this.state.accordionShow4) {
        //     accordionShow4 = "show"
        //     accordionShowArrow4 = "active"
        // }


        let searchIconNumber = "icon-number_search ";
        if (this.state.clearHoverNumber) { // для отображения красного крестика
            searchIconNumber = "icon-Delete icon-color-red";
        }

        let searchIconName = "icon-name_search ";
        if (this.state.clearHoverName) { // для отображения красного крестика
            searchIconName = "icon-Delete icon-color-red";
        }
        let searchIconTypeName = "icon-Search";
        if (this.state.clearHoverTypeName) { // для отображения красного крестика
            searchIconTypeName = "icon-Delete icon-color-red";
        }

        let searchIconModifyDate = "icon-calendar_search";
        if (this.state.clearHoverModifyDate) { // для отображения красного крестика
            searchIconModifyDate = "icon-Delete icon-color-red";
        }

        let searchIconDate = "icon-calendar_search";
        if (this.state.clearHoverDate) { // для отображения красного крестика
            searchIconDate = "icon-Delete icon-color-red";
        }

        // let searchIconRegPeriodStart = "icon-up_calendar";
        // if (this.state.clearHoverRegPeriodStart) { // для отображения красного крестика
        //     searchIconRegPeriodStart = "icon-Delete icon-color-red";
        // }
        //
        // let searchIconRegPeriodFinish = "icon-down_calendar";
        // if (this.state.clearHoverRegPeriodFinish) { // для отображения красного крестика
        //     searchIconRegPeriodFinish = "icon-Delete icon-color-red";
        // }

        let lCaptionVisible = true;
        if (this.props.captionUnvisible)
            lCaptionVisible = false;

        let sortedParams;

        if (this.props.tableRole == "main") {
            const {searchInputsMain, sortedParamsMain} = this.props;

            searchInputs = searchInputsMain
            sortedParams = sortedParamsMain

        } else if (this.props.tableRole == "additional") {
            const {searchInputsAdditional, sortedParamsAdditional} = this.props;

            searchInputs = searchInputsAdditional
            sortedParams = sortedParamsAdditional

        } else if (this.props.tableRole == "download") {
            const {searchInputsDownload, sortedParamsDownload} = this.props;

            searchInputs = searchInputsDownload
            sortedParams = sortedParamsDownload
        }


        const selectStyles = {
            valueContainer: (base) => ({
                ...base,
                padding: "0 1.3rem"
            }),
            singleValue: (base) => ({
                ...base,
                fontSize: "1.08rem",
                color: "#3F4254"
            }),
            container: (base) => ({
                ...base,
                width: "100px",
                flexGrow: 1
            }),
            control: (base) => ({
                ...base,
                border: "1px solid #E4E6EF",
                borderRadius: "0 4px 4px 0"
            }),

            menuList: (base) => ({
                ...base,
                width: "100%",
                "::-webkit-scrollbar": {
                    width: "5px",
                    height: "5px",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                },

                "::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    /*background-color: rgb(239 137 137 / 20%);*/
                    backgroundColor: "var(--background-colorBS)",
                },

                "::-webkit-scrollbar-track": {
                    borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.35)",
                }
            }),
            menu: (base) => ({
                ...base,
                width: "220px",
            }),
            placeholder: (base) => ({
                ...base,
                color: "#a9a9a9d1",
                fontSize: "1.08rem",
            })
        }

        return (
            <div>
                {/*<form className="form mb-4">*/}
                {/*TODO добавить событие на форму*/}
                {
                    lCaptionVisible &&
                    <div className="d-flex">
                        <div className="p-2 p-lg-2 my-lg-2">

                            <h2 className="text-color-primary font-weight-bolder">Пакеты</h2>
                            {/*

                            {
                                this.state.limitationTooltipShow &&
                                <MyTooltip target={this.limitation}
                                           title={"Внимание!"}
                                           moreInfo={true}
                                           text={"Результаты поиска ограничены <span style='color:red'>2000</span> документами. Если искомого документа нет среди найденных, попробуйте уточнить условия поиска"}
                                           show={this.state.limitationTooltipShow}
                                           placement={"right"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-circle btn-sm"
                                  ref={this.limitation}
                                  onMouseEnter={()=>{this.setState({limitationTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({limitationTooltipShow : false})}}>
                             <i className="svg-icon icon-Info"/>
                         </span>
                         */}


                        </div>
                    </div>
                }

                <div className="accordion accordion-light accordion-toggle-arrow">
                    <div className="card">
                        <div className="card-header">
                            <div className={"card-title " + accordionShowArrow3} onClick={this.accordion3}>
                                Фильтры по документам текущего пакета
                            </div>
                        </div>

                        <div className="beautiful-scroll-5 py-2 pr-4"
                             style={{maxHeight: "75vh", overflowX: "hidden", marginRight: "-5px"}}>
                            <div className="min-h-lg-700px">
                                <div className={"collapse " + accordionShow3}>
                                    <div className="row">
                                        <label className="col-9 col-form-label text-color-primary">
                                            Расширенные фильтры
                                        </label>
                                        <div className="col-3 d-flex align-items-center">
                                            <span className="switch switch-sm">
                                                <label>
                                                    <input type="checkbox"
                                                           name="extSearch"
                                                           checked={this.state.extSearch}
                                                           onChange={(e) => {
                                                               this.setExtSearch(e)
                                                           }}
                                                    />
                                                    <span/>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        !this.state.extFilter ?
                                            <div className="card-body">
                                                <div>
                                                    <div className="row">
                                                        <div className={!lCaptionVisible ? 'col-lg-9' : 'col-lg-12'}
                                                             onClick={() => this.search({
                                                                 applyButton: true,
                                                                 isClose: true
                                                             })}>
                                                        <span className="btn btn-primary btn-lg btn-block my-3">
                                                            Применить
                                                        </span>
                                                        </div>
                                                        {
                                                            !lCaptionVisible &&
                                                            <div className="col-lg-3">
                                                            <span
                                                                className="btn btn-outline-primary btn-icon btn-lg btn-block my-3"
                                                                title={"Очистить текущий фильтр"}
                                                                onClick={() => this.search({lFlagReset: true})}
                                                            >
                                                                <i id="disabled"
                                                                   className="svg-icon icon-Table_edit_8 fsic4-i"/>
                                                            </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        // this.props.tableRole !== "download" &&
                                                        <div className="input-group my-2">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                                    onMouseEnter={() => {
                                                                        this.setState({clearHoverDate: true})
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        this.setState({clearHoverDate: false})
                                                                    }}
                                                                    onClick={(event) => this.clearInput(event, this.RegDateAsStringRef)}>
                                                                    <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconDate}`}
                                                                       id="Date"/>
                                                                </span>
                                                            </div>

                                                            <input type="text"
                                                                   className="form-control form-control-lg h-40px br-right-5"
                                                                   placeholder="Дата"
                                                                   ref={this.RegDateAsStringRef}
                                                                   value={searchInputs.RegDateAsString?.ApproximatelyEqual ?? ""}
                                                                   onChange={(event) => {
                                                                       this.inputHandler(event)
                                                                   }}
                                                                   onKeyDown={(event) => {
                                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                           this.search({})
                                                                       }
                                                                   }}
                                                                   name="Date"
                                                            />
                                                            <div className="input-group-prepend">
                                                                <span onClick={(event) => {
                                                                    this.sortByParam({FieldSorting: "RegDate", Direction: 2})
                                                                }}
                                                                      className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                      role="button"
                                                                >
                                                                    <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.RegDate === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                                </span>
                                                                <span onClick={(event) => {
                                                                    this.sortByParam({FieldSorting: "RegDate", Direction: 1})
                                                                }}
                                                                      className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                      role="button"
                                                                >
                                                                    <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.RegDate === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                                </span>
                                                            </div>
                                                            {
                                                                this.state.extSearch && this.getConfigLine(this.RegDateAsStringRef, searchInputs.RegDateAsString)
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="input-group my-4">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverNumber: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverNumber: false})
                                                            }}
                                                            onClick={(event) => this.clearInput(event, this.RegNumberRef)}>
                                                            <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconNumber}
                                                               id="Number"/>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                           className="form-control form-control-lg h-40px br-right-5"
                                                           placeholder="Номер"
                                                           ref={this.RegNumberRef}
                                                           onChange={(event) => {
                                                               this.inputHandler(event)
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                   this.search({})
                                                               }
                                                           }}
                                                           value={searchInputs.RegNumber?.ApproximatelyEqual ?? ""}
                                                           name="Number"
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "RegNumber", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.RegNumber === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "RegNumber", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.RegNumber === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                    </div>
                                                    {
                                                        this.state.extSearch && this.getConfigLine(this.RegNumberRef, searchInputs.RegNumber)
                                                    }
                                                </div>
                                                <div className="input-group my-4">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverName: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverName: false})
                                                            }}
                                                            onClick={(event) => this.clearInput(event, this.NameRef)}>
                                                            <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconName}
                                                               id="Name"/>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                           className="form-control form-control-lg h-40px br-right-5"
                                                           placeholder="Наименование"
                                                           ref={this.NameRef}
                                                           onChange={(event) => {
                                                               this.inputHandler(event)
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                   this.search({})
                                                               }
                                                           }}
                                                           value={searchInputs.Name?.ApproximatelyEqual ?? ""}
                                                           name="Name"
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "Name", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.Name === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "Name", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.Name === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                    </div>
                                                    {
                                                        this.state.extSearch && this.getConfigLine(this.NameRef, searchInputs.Name)
                                                    }
                                                </div>
                                                <div className="input-group my-4" >
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverTypeName: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverTypeName: false})
                                                            }}
                                                            onClick={() => {
                                                                this.setState({curType: ""})
                                                                let searchInputs = this.getCurrentSearchInputs()
                                                                const {RegNumber, Name, ModifyDate, RegDateAsString, AppliedParams} = searchInputs
                                                                this.props.updateInputsFieldPackage({
                                                                    TableRole: this.props.tableRole,
                                                                    RegNumber,
                                                                    Name,
                                                                    TypeName: {In: ""},
                                                                    ModifyDate,
                                                                    RegDateAsString,
                                                                    AppliedParams,
                                                                });
                                                                this.search({})
                                                            }}>
                                                            <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconTypeName}`}
                                                               id="TypeName"/>
                                                        </span>
                                                    </div>
                                                    {/*<input type="text"*/}
                                                    {/*       className="form-control form-control-lg h-40px br-right-5"*/}
                                                    {/*       placeholder="Тип"*/}
                                                    {/*       onChange={(event) => {*/}
                                                    {/*           this.inputHandler(event)*/}
                                                    {/*       }}*/}
                                                    {/*       ref={this.TypeNameRef}*/}
                                                    {/*       onKeyDown={(event) => {*/}
                                                    {/*           if (event.code === "Enter" || event.code === "NumpadEnter") {*/}
                                                    {/*               this.search({})*/}
                                                    {/*           }*/}
                                                    {/*       }}*/}
                                                    {/*       value={searchInputs.TypeName?.ApproximatelyEqual ?? ""}*/}
                                                    {/*       name="TypeName"*/}
                                                    {/*/>*/}
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        styles={selectStyles}
                                                        value={this.state.curType}
                                                        options={this.state.typeOptions}
                                                        isDisabled={this.state.typeOptions.length === 0}
                                                        isMulti
                                                        placeholder={"Тип"}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => "Отсутствуют типы для фильтрации"}
                                                        onChange={(value) => {this.changeHandler(value)}}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: 'var(--hover-primary)',
                                                                primary25: 'var(--light-primary)',
                                                                primary50: 'var(--hover-primary)',
                                                                primary: 'var(--originaltwo)',
                                                            },
                                                        })}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "TypeName", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.TypeName === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "TypeName", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.TypeName === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                    </div>
                                                    {
                                                        this.state.extSearch && this.getConfigLine("TypeName", searchInputs.TypeName)
                                                    }
                                                </div>
                                                {
                                                    this.props.tableRole !== "download" &&
                                                    <div className="input-group my-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                                onMouseEnter={() => {
                                                                    this.setState({clearHoverModifyDate: true})
                                                                }}
                                                                onMouseLeave={() => {
                                                                    this.setState({clearHoverModifyDate: false})
                                                                }}
                                                                onClick={(event) => this.clearInput(event, this.ModifyDateRef)}>
                                                                <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconModifyDate}`}
                                                                   id="ModifyDate"/>
                                                            </span>
                                                        </div>

                                                        <input type="text"
                                                               ref={this.ModifyDateRef}
                                                               className="form-control form-control-lg h-40px br-right-5"
                                                               placeholder="Последнее изменение"
                                                               value={searchInputs.ModifyDate?.ApproximatelyEqual ?? ""}
                                                               onChange={(event) => {
                                                                   this.inputHandler(event)
                                                               }}
                                                               onKeyDown={(event) => {
                                                                   if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                       this.search({})
                                                                   }
                                                               }}
                                                               name="ModifyDate"
                                                        />
                                                        <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "ModifyDate", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.ModifyDate === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                            <span onClick={(event) => {
                                                                this.sortByParam({FieldSorting: "ModifyDate", Direction: 1})
                                                            }}
                                                                  className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                  role="button"
                                                            >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.ModifyDate === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        </div>
                                                        {
                                                            this.state.extSearch &&
                                                            this.getConfigLine(this.ModifyDateRef, searchInputs.ModifyDate)
                                                        }
                                                    </div>
                                                }

                                            </div>
                                            :
                                            currentFilter &&
                                            <>
                                                {currentFilter.IdAttributeType === DataTypesEnum.String &&
                                                    <FiltersString
                                                        onApply={() => this.setState({extFilter: false})}
                                                        filterInfo={currentFilter} packageSearch/>}
                                                {currentFilter.IdAttributeType === DataTypesEnum.DataTime &&
                                                    <FiltersDate
                                                        onApply={() => this.setState({extFilter: false})}
                                                        filterInfo={currentFilter} packageSearch/>}
                                            </>
                                    }
                                </div>
                            </div>
                        </div>


                        {/*    <div className={"card-title "+ accordionShowArrow4} onClick={this.accordion4}>*/}
                        {/*        Фильтр по пакетам*/}
                        {/*    </div>*/}


                        {/*    <div className={"collapse "+ accordionShow4} >*/}
                        {/*        <div className="card-body">*/}
                        {/*            <div>*/}

                        {/*            <div className="input-icon h-40px my-4">*/}
                        {/*                <input type="text" className="form-control form-control-lg h-40px"  placeholder="Фильтр по наименованию"*/}
                        {/*                       onChange={(event) => {*/}
                        {/*                           this.inputHandler(event)*/}
                        {/*                       }}*/}
                        {/*                       value={this.state.PackageName}*/}
                        {/*                       name="PackageName"*/}
                        {/*                />*/}
                        {/*                <span className="svg-icon svg-icon-lg"*/}
                        {/*                      onMouseEnter={()=>{this.setState({clearHoverNumber : true})}}*/}
                        {/*                      onMouseLeave={()=>{this.setState({clearHoverNumber : false})}}*/}
                        {/*                      onClick={(event)=>this.clearInput(event)}*/}
                        {/*                >*/}
                        {/*                 <i className={"svg-icon svg-icon-lg icon-color-primary "+searchIconNumber} id="Number"/>*/}
                        {/*             </span>*/}
                        {/*            </div>*/}


                        {/*        </div>*/}


                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        activeNodeDownload: state.packages.treeDownload.activeNode,

        changeViewModeSearch: state.document.rootState.viewModeSearch,

        initNode: state.packages.tree.lastLoad.documentsForTypes,
        initNodeAdditional: state.packages.treeAdditional.lastLoad.documentsForTypes,
        initNodeDownload: state.packages.treeDownload.lastLoad.documentsForTypes,

        searchInputs: state.packages.search.searchInputs,
        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,

        sortedParams: state.packages.search.SortedParams,
        sortedParamsMain: state.packages.tree.SortedParams,
        sortedParamsAdditional: state.packages.treeAdditional.SortedParams,
        sortedParamsDownload: state.packages.treeDownload.SortedParams,
    }
}

const mapDispatchToProps = {
    changeViewFilter,
    changeDocumentViewMode,
    changeSortDirection,
    combineMainTableAttributesSearch,
    getDocumentsForTreePackages,
    handlerSearchPackages,
    handlerIsSearch,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    searchByParamsPackages,
    SelectActivePackageNode,
    setModalData,
    setLoaderModalData,
    setUserModalShow,
    setViewModePreviewPackages,
    treeSearchPackage,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage
}

export default connect(mapStateToProps, mapDispatchToProps)(InputSearchPackage);
