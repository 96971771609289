
import produce from "immer";

import {REPORT_PARAMS, REPORT_STATE, REPORTS_GET_ACTION} from "./reportActionsList";


const leftMenu = {
    collection : {
        reports : [],
        totalInServer : 0,
    },
    params : [],
    isReady:false,
    reportGuid:"",
    state:2,
    activeRow:{
        Id:0,
    },
};

const inputValue={
    multipleValue:[]
}

const initialState = {
    leftMenu,
    inputValue,
};

export const reportReducer = (state = initialState, action) => {
    return produce(state, newState => {

        //получение документов из поиска
        if(action.type === REPORTS_GET_ACTION.GET_REPORT) {
            newState.leftMenu.collection.reports = action.payload.Records;
            newState.leftMenu.collection.totalInServer = action.payload.TotalRecordsCount;

        }//получение параметров отчета
         else if(action.type === REPORTS_GET_ACTION.GET_PARAMS){
           newState.leftMenu.params = action.payload;
           newState.leftMenu.isReady = true;

        }
        else if(action.type === REPORTS_GET_ACTION.SET_ACTIVE_ROW){
            newState.leftMenu.activeRow.Id = action.payload;

        }
         //сохраняем параметры mySelect
        else if(action.type === REPORT_PARAMS.REPORT_MULTIPLE_PARAMS_SAVE) {
            newState.leftMenu.params = action.payload;
        }
        else if(action.type === REPORT_STATE.GET_UID){
            newState.leftMenu.reportGuid = action.payload;
        }
        else if(action.type === REPORT_STATE.GET_STATE){
            newState.leftMenu.state = action.payload.State;
        }
        //собираем таблицу
        // else if(action.type === SEARCH_TABLE_ACTIONS.COMBINE_ATTRIBUTE){
        //     newState.mainTable.header = action.payload.Headers;
        //     newState.mainTable.body = action.payload.Body;
        // }//записываем выбранный документ
        // else if(action.type === SEARCH_TABLE_ACTIONS.SELECT_ACTIVE_ROW) {
        //     newState.mainTable.activeRow = action.payload;
        // }
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.RESET) {
        //     newState.preview = preview;
        // }
        // else if(action.type === SEARCH_TABLE_ACTIONS.SET_PAGINATION_PAGE_NUMBER) {
        //     newState.mainTable.paginationPageNumber = action.payload;
        // }
        //
        // // temporaryData
        // else if(action.type === SEARCH_TEMPORARY_DATA.SAVE) {
        //     newState.temporaryData[action.payload.name] = action.payload.data;
        // }
        // else if(action.type === SEARCH_TEMPORARY_DATA.CLEAR) {
        //     newState.temporaryData = {};
        // }
        // // preview
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.CREATE_BY_NEW_OPEN_DOC) {
        //     newState.preview.files = action.payload;
        //     newState.preview.activeFileIndex = 0;
        //     newState.preview.isReady = true;
        // }
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL) {
        //     newState.additionalSections.state.attributesSection.attributes = action.payload.attributesSection;
        //     newState.additionalSections.state.contentSection.content = action.payload.contentSection;
        //     newState.additionalSections.state.filesSection.files = action.payload.filesSection;
        //     newState.additionalSections.state.versionSection.versions = action.payload.versionSection;
        //     newState.additionalSections.state.documentPackagesSection.packages = action.payload.documentPackagesSection;
        //     newState.additionalSections.state.versionSection.activeVersionIndex = action.payload.activeVersionIndex;
        //
        //     newState.additionalSections.isReady = true;
        // }
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_FILE) {
        //     newState.preview.activeFileIndex = action.payload;
        // }
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS) {
        //     let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.CardId);
        //     newState.cards.collection[cardIndex].preview.activeFileIndex = action.payload.ActiveFileIndex;
        // }
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_DOC_CARDS) {
        //     newState.cards.activeCardId = action.payload;
        // }
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.TOGGLE_ADDITIONAL_SECTIONS_OPEN) {
        //     newState.additionalSections.state[action.payload.name].isOpen = action.payload.isOpen;
        // }
        // else if(action.type === SEARCH_TABLE_ACTIONS.ADD_ROW_TO_CHECKED) {
        //     newState.mainTable.checkedFields = action.payload;
        // }
        // else if(action.type === SEARCH_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED) {
        //     newState.mainTable.checkedFields = action.payload;
        // }
        // else if(action.type === SEARCH_TABLE_ACTIONS.SET_PAGINATION_ON_PAGE_SIZE) {
        //     newState.mainTable.paginationPageSize = action.payload;
        // }
        // else if(action.type === SEARCH_PREVIEW_ACTIONS.CREATE_DOC_CARDS) {
        //     newState.cards.collection.push(action.payload);
        //     newState.cards.isReady = true;
        // }
        //
        // // rootState
        // else if(action.type === SEARCH_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE) {
        //     newState.rootState.viewMode = action.payload;
        // }
    });
}

