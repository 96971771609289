import React from "react";
import {connect} from "react-redux";
import {
    PackagesPaginationMovePage,
    setPaginationPageSizeInPackages
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import PaginationSelect from "../../PaginationSelect";
import {store} from "../../../index";
import {
    PackageAddNodesTree,
    SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {
    handlerSearchPackages,
    searchByParamsPackages
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {ActionQueue} from "../../../store/rootReducer";

class PaginationCounterPackages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.change = this.change.bind(this);
        this.getCurrentSearchInputs = this.getCurrentSearchInputs.bind(this);
    }

    getCurrentSearchInputs() {
        let searchInputs;

        if (this.props.tableRole === "main") {
            const {searchInputsMain} = this.props;
            if (searchInputsMain) {
                searchInputs = searchInputsMain
            }
        } else if (this.props.tableRole === "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional) {
                searchInputs = searchInputsAdditional
            }

        } else if (this.props.tableRole === "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload) {
                searchInputs = searchInputsDownload
            }

        }

        return searchInputs;
    }

    change(value) {
        const newSize = value;
        const searchInputs = this.getCurrentSearchInputs();

        let lActiveNode;

        if (this.props.tableRole === "main")
            lActiveNode = this.props.activeNode.Node.Id
        else if (this.props.tableRole === "additional")
            lActiveNode = this.props.activeNodeAdditional.Node.Id
        else if (this.props.tableRole === "download")
            lActiveNode = this.props.activeNodeDownload.Node.Id;


        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "PaginationCounterPackages35" }
        }));



        if (Object.values(searchInputs.AppliedParams).some(Boolean)){
            this.props.ActionQueue({
                List : [
                    {action : this.props.PackageAddNodesTree, params : {Id: lActiveNode}, name : "PackageAddNodesTree"},
                    {action : this.props.setPaginationPageSizeInPackages, params : {
                            Count: newSize, TableType: this.props.tableRole
                        }, name : "setPaginationPageSizeInPackages"},
                    {action : this.props.PackagesPaginationMovePage, params : {TableType: this.props.tableRole}, name : "PackagesPaginationMovePage"},
                    {action : this.props.searchByParamsPackages, params : {TableType: this.props.tableRole}, name : "searchByParamsPackages"},
                    {action : this.props.handlerSearchPackages, params : {TableType: this.props.tableRole}, name : "handlerSearchPackages"},
                    {action : this.props.SelectActivePackageNode, params : {
                            Id: lActiveNode, TableType: this.props.tableRole
                        }, name : "SelectActivePackageNode"},
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "InputSearchPackage421"}, name : "setLoaderModalData"},
                ],
                debug : false,
            });
        } else {
            this.props.ActionQueue({
                List : [
                    {action : this.props.setPaginationPageSizeInPackages, params : {
                            Count: newSize, TableType: this.props.tableRole
                        }, name : "setPaginationPageSizeInPackages"},
                    {action : this.props.PackagesPaginationMovePage, params : {TableType: this.props.tableRole}, name : "PackagesPaginationMovePage"},
                    {action : () => {
                            this.props.setPageInBreadcrumbs();
                            this.props.setLoaderModalData({keyDeleted: "PaginationCounterPackages50"})
                        }, params : {}, name : "setLoaderModalData"},

                ],
                debug : false,
            });

        }
    }

    render() {

        let paginationPageSize = this.props.tableRole === "main"
            ?  this.props.paginationPageSize
            :  this.props.tableRole === "additional"
                ? this.props.paginationPageSizeAdditional
                : this.props.paginationPageSizeDownload;


        let {activePage, totalItemsCount, isViewModeNone} = this.props;
        let from = ((activePage - 1) * paginationPageSize) + 1;
        let to = ((Number.parseInt((activePage - 1) ) * paginationPageSize)) + Number.parseInt(paginationPageSize);

        if(to > totalItemsCount) to = totalItemsCount;

        let infoText = "...";
        if(totalItemsCount) {
            if(totalItemsCount <= 0) {
                infoText = (<span className="text-muted">Документов не обнаружено.</span>);
            }
            else {
                infoText = (<span className="text-muted">{isViewModeNone ? "Показано" : ""} {from} - {to} из {totalItemsCount}</span>);
            }
        }


        return (
            <PaginationSelect activePage={activePage}
                              infoText={infoText}
                              paginationPageSize={paginationPageSize}
                              change={this.change}
                              setPageInBreadcrumbs={this.props.setPageInBreadcrumbs}
            />
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        activeNodeDownload: state.packages.treeDownload.activeNode,

        paginationPageSize : state.packages.tree.paginationPageSize,
        paginationPageSizeAdditional : state.packages.treeAdditional.paginationPageSize,
        paginationPageSizeDownload : state.packages.treeDownload.paginationPageSize,

        searchInputs: state.packages.search.searchInputs,
        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    handlerSearchPackages,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    searchByParamsPackages,
    SelectActivePackageNode,
    setLoaderModalData,
    setModalData,
    setPaginationPageSizeInPackages,

}

export default connect(mapStateToProps,mapDispatchToProps)(PaginationCounterPackages);
