import React from "react";
import {connect} from "react-redux";
import {ActionQueue} from "../../store/rootReducer";
import {AUTH_login} from "../../store/globalState/actionCreators/globalState_AuthActionCreator";
import {store} from "../../index";
import Modal from "../overPage/modal/Modal";

class AuthForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userName : "",
            password : "",
            typeInput: "password",
        }

        this.formHandler   = this.formHandler.bind(this);
        this.userNameRef = React.createRef(); // для фокуса на input userName
    }

    componentDidMount() {
        this.userNameRef.current.focus(); // фокус на input userName

    }

    formHandler(event) {
        this.props.changeLoadingAuth(true);
        this.props.AUTH_login({
            userName : this.state.userName,
            password : this.state.password,

            Next : {
                action : ()=> {
                    this.props.changeLoadingAuth(false, false);
                    this.props.success(); // вызываем функцию загрузки данных после успешной авторизации
                },
                params : {}
            },
            Error : {
                action : ()=>{
                    this.props.changeLoadingAuth(false, true);
                },
                params : {}
            }
        });
        event.preventDefault();
    }


    render() {
        let mod = store.getState().globalState.theme.modString

        let inputErrorStyle = {};
        let errorText = null;
        if(this.props.isAuthError) {
            inputErrorStyle = {
                borderColor : "#f44336"
            };
            errorText = (
                <div style={{textAlign : "center", paddingBottom : "10px"}}>
                    <span style={{color : "#f44336"}}>Ошибка авторизации: Неверный логин или пароль</span>
                </div>
            );
        }

        let typeInput = this.state.typeInput;
        return (
            <div className="d-flex flex-column flex-root" style={{height : "100vh"}}>
                <Modal/>
                <div className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
                    <div className="login-aside order-2 order-lg-1 d-flex col-lg-6 flex-row-auto position-relative overflow-hidden">
                        <div className="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35">
                            <div className="d-flex flex-column-fluid flex-column flex-center">
                                <div className="login-form login-signup col-lg-7">
                                    <div className="text-center pb-8">
                                        <span>
                                            <img alt="Logo" src="/images/logo/auth.png" className="max-h-70px pb-8"/>
                                        </span>
                                        <h2 className="font-weight-bolder text-dark font-size-h1">Авторизация</h2>
                                        <p className="font-weight-bold">
                                            Для работы c документами необходимо войти в систему <b>Электронный Архив</b>
                                        </p>
                                    </div>

                                    <form className="form fv-plugins-bootstrap fv-plugins-framework align-center" onSubmit={this.formHandler}>
                                        <div className="form-group fv-plugins-icon-container">
                                            {errorText}
                                            <input
                                                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                                type="text"
                                                style={inputErrorStyle}
                                                ref={this.userNameRef}
                                                value={this.state.userName}
                                                onChange={event => this.setState({ userName: event.target.value })}
                                                placeholder="Логин" name="userName" autoComplete="off"/>
                                        </div>
                                        <div className="form-group fv-plugins-icon-container input-icon input-icon-right">
                                            <input
                                                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                                type={typeInput}
                                                style={inputErrorStyle}
                                                value={this.state.password}
                                                onChange={event => this.setState({ password: event.target.value })}
                                                placeholder="Пароль" name="password"
                                                autoComplete="off"/>
                                            <span>
                                                {
                                                    this.state.typeInput === "password" ?
                                                    <span className="svg-icon svg-icon-lg" style={{right : "10px"}}
                                                          onClick= {()=>{this.setState({typeInput : "text"})}}
                                                    >
                                                        <i className="svg-icon svg-icon-lg icon-eye_closed"/>
                                                    </span>
                                                        :
                                                    <span className="svg-icon svg-icon-lg" style={{right : "10px"}}
                                                              onClick= {()=>{this.setState({typeInput : "password"})}}
                                                        >
                                                        <i className="svg-icon svg-icon-lg icon-eye_open"/>
                                                    </span>
                                                }
                                            </span>
                                        </div>
                                        <div className="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3">
                                            <button type="submit"
                                                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4">
                                                    Войти
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/*<div className="text-center">*/}
                            {/*    <button type="button" className="btn btn-light-success font-weight-bolder px-8 py-4 my-3 font-size-h6">*/}
                            {/*        <span> Заявка на регистрацию</span>*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                <div className="content order-1 col-lg-6 order-lg-2 d-flex flex-column w-100 pb-0" style={{padding: "0",  width: "100%"}}>
                    <div className="content-img d-flex flex-row-fluid bgi-size-cover bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                         style={{backgroundImage: `url('/images/auth${mod}.svg')`, backgroundSize: "contain", backgroundPosition: "right"}}/>
                </div>
                </div>
            </div>
        );
    }

}

const  mapStateToProps = state => {
    return {

    }
}

const  mapDispatchToProps = {
    AUTH_login,
    ActionQueue,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
