import React from "react";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
  combineMainTableAttributes,
  mainTableDownloadDocCollection
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {connect} from "react-redux";
import {
  deleteSectionsForTree,
  getSectionsForTree, saveSectionsForTree
} from "../../../store/documents/actionCreators/document_TreeActionCreator";
import {__} from "../../../tools/HelpFunctions";

import {ModalTypes} from "../../../tools/StaticTypes";
import {Routing} from "../../../tools/routing/Routing";
import {history} from "../../../index";

class ChangeDocumentSections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allSectionsDocument: [],
      filterSectionsDocument: [],
      sectionsState: []
    };

    this.hide = this.hide.bind(this);
    this.searchSectionDocument = this.searchSectionDocument.bind(this);
    this.sendSections = this.sendSections.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.clearAll = this.clearAll.bind(this);

  }

  async componentDidMount() {
    let idDoc = this.props.activeDocument.Version.IdDoc;
    const idDocType = this.props.activeDocument.Info.Type.Id;
    await this.props.getSectionsForTree({ idDoc: idDoc })
    await this.setState({
      allSectionsDocument: this.props.allGroups.filter(group => group.Type === 2).sort((a,b) => {return a.GroupOrder - b.GroupOrder}),
    })
    const copyPrevState = __.deepCopy(this.state.allSectionsDocument)
    let prevState = copyPrevState.map(item => {
      if (this.props.activeDocumentSection.find(serv => serv.IdDocGroup === item.Id)) {
        return {
          ...item,
          defaultActive : true,
          active: true
        }
      } else {
        return {
          ...item,
          defaultActive : false,
          active: false
        }
      }
    })

    // TODO № 21565
    prevState = prevState.filter(item => item.GroupTypeRefs.find(type => type.IdDocType === idDocType))

    this.setState({
      sectionsState: prevState
    })
  }

  componentWillUnmount() {
    this.props.getSectionsForTree({ isClear: true })
  }

  hide() {
    this.props.setModalData({});
  }

  searchSectionDocument(value){
    this.setState({
      filterSectionsDocument: this.state.sectionsState.filter(section => section.Name.toLowerCase().includes(value.toLowerCase()))
    })
  }

  handleSection(item, value){
    let copyState = __.deepCopy(this.state.sectionsState)
    copyState.find(copySection => copySection?.Id === item.Id).active = value
    this.setState({sectionsState: copyState})
  }

  selectAll(){
    let copyState = __.deepCopy(this.state.sectionsState)
    copyState.map(item => item.active = true)
    this.setState({sectionsState: copyState})
  }

  clearAll(){
    let copyState = __.deepCopy(this.state.sectionsState)
    copyState.map(item => item.active = false)
    this.setState({sectionsState: copyState})
  }

  async sendSections(){
      this.props.setModalData({
        name : ModalTypes.app.alert,
        data : {content : "Отправка...", disableButton : true, fullBackground : false, gif : "documents"}
      });
      let deleteCollection = this.state.sectionsState
        .filter(item => item.defaultActive === true)
        .filter(item => item.defaultActive !== item.active)
        .map(section => {
            return {
              idDoc: this.props.activeDocument.Info.Id,
              IdDocGroup: section.Id,
              Id: this.props.activeDocumentSection.find(item => item.IdDocGroup === section.Id).Id
            }
          });
      let saveCollection = this.state.sectionsState
        .filter(item => item.defaultActive === false)
        .filter(item => item.defaultActive !== item.active)
        .map(section => {
          return {
            idDoc: this.props.activeDocument.Info.Id,
            IdDocGroup: section.Id
          }
        });
      if(deleteCollection.length > 0){
        await this.props.deleteSectionsForTree({deleteSections: deleteCollection})
      }
      if(saveCollection.length > 0){
        await this.props.saveSectionsForTree({saveSections: saveCollection})
      }
      await this.props.mainTableDownloadDocCollection({});
      await this.props.combineMainTableAttributes({});
      let query = this.props.router.location.query;
      let queryLink = Routing.CreateRef({
        oldQuery : query,
        newQuery : {},
        excludedKeys : ["d"]
      });
      let link = `${this.props.router.location.pathname}${queryLink}`;
      await history.push(link);
      this.props.setModalData({
        name: ModalTypes.app.info,
        data: {type : "success", content : `Раздел документа успешно изменён!`}
      });
  }

  render() {
    let modalStyle = {
      display : "block",
      backgroundColor: "#061c3e66",
    };

    return(
      <div className="modal fade show" style={modalStyle} onClick={this.hide}>
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
          <div className="modal-content"
               onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
              <h5 className="modal-title text-success font-weight-bolder ">
                Изменение разделов документа
              </h5>
            </div>
            <div className="modal-body" style={{height: "30vw", padding: "0.8rem 1.75rem"}}>
              <div className="d-flex flex-wrap mb-7">
                <input type="text" className="form-control mb-3" placeholder="Поиск" onChange={e => this.searchSectionDocument(e.target.value)}/>
                <button className="btn btn-primary" type='button' style={{marginRight: "10px"}} onClick={this.selectAll}>Выбрать все</button>
                <button className="btn btn-secondary" type='button' onClick={this.clearAll}>Снять все</button>
              </div>
              {
                this.state.filterSectionsDocument.length > 0 ? this.state.filterSectionsDocument.map((item) =>
                    <div className="row">
                      <label className="checkbox checkbox-lg checkbox-inline mr-2" >
                      <input type={"checkbox"} onChange={(e) => this.handleSection(item, e.target.checked)}
                             checked={item.active} style={{marginRight: "10px"}}/>
                        <span className={"row-checked"}> </span>
                        <p>{item.Name}</p>
                      </label>

                    </div>
                ) : this.state.sectionsState.map((item) =>
                    <div className="row">
                      <label className="checkbox checkbox-lg checkbox-inline mr-2 mb-3 d-flex align-items-center" >
                      <input type={"checkbox"} onChange={(e) => this.handleSection(item, e.target.checked)}
                             checked={item.active}/>
                        <span className={"row-checked"} style={{marginRight: 10}}> </span>{item.Name}
                      </label>


                    </div>
                )}
            </div>
              <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                <button className="btn btn-primary font-weight-bolder" onClick={this.sendSections}>Подтвердить</button>
                <button className="btn btn-light font-weight-bold" onClick={this.hide}>Отмена</button>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

const  mapStateToProps = state => {
  return {
    activeNode : state.document.tree.activeNode,
    activeDocument : state.document.mainTable.activeRow,
    activeDocumentSection: state.document.tree.activeSection,
    allGroups: state.document.tree.group,
    router : state.router,
  }
}

const  mapDispatchToProps = {
  setModalData,
  combineMainTableAttributes,
  mainTableDownloadDocCollection,
  getSectionsForTree,
  deleteSectionsForTree,
  saveSectionsForTree,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDocumentSections);
