const LanguageStrings = {
    Version: {
        // Client: "1.1.26", //16.03.2023
        // Client: "1.1.27", //17.03.2023
        // Client: "1.1.28", //20.03.2023 test
        // Client: "1.1.28", //21.03.2023
        // Client: "1.1.28,05", //22.03.2023
        // Client: "1.1.28,1", //22.03.2023
        // Client: "1.1.29", //22.03.2023
        // Client: "1.1.30", //23.03.2023
        // Client: "1.1.30,05", //24.03.2023
        // Client: "1.1.30,07", //24.03.2023
        // Client: "1.1.31", //27.03.2023
        //Client: "1.1.32", //28.03.2023
        // Client: "1.1.33", //30.03.2023
        // Client: "1.1.34", //31.03.2023
        // Client: "1.1.35", //03.04.2023
        // Client: "1.1.36", //04.04.2023
        //Client: "1.1.37", //07.04.2023
        // Client: "1.1.38", //11.04.2023
        // Client: "1.1.38,05", //12.04.2023
        // Client: "1.1.39",//13.04.2023
        // Client: "1.1.40", //13.04.2023
        // Client: "1.1.41", //18.04.2023
        // Client: "1.1.42", //19.04.2023
        // Client: "1.1.43", //21.04.2023
        // Client: "1.1.44", //25.04.2023
        // Client: "1.1.45", //25.04.2023
        // Client: "1.1.46", //28.04.2023
        // Client: "1.1.47", //02.05.2023
        // Client: "1.1.48", //03.05.2023
        // Client: "1.1.49", //05.05.2023
        // Client: "1.1.50", //12.05.2023
        // Client: "1.1.51", //15.05.2023
        // Client: "1.1.52", //22.05.2023
        // Client: "1.1.53", //26.05.2023
        // Client: "1.1.54", //05.06.2023
        // Client: "1.1.55", //08.06.2023
        // Client: "1.1.56", //20.06.2023
        // Client: "1.1.57", //20.06.2023
        // Client: "1.1.58", //29.06.2023
        // Client: "1.1.59", //11.07.2023
        // Client: "1.1.60", //20.07.2023
        // Client: "1.1.62", //03.08.2023
        // Client: "1.1.62.5", //08.08.2023
        // Client: "1.1.62.7", //08.08.2023
        // Client: "1.1.63", //09.08.2023
        // Client: "1.1.63.5", //14.08.2023
        // Client: "1.1.63.7", //15.08.2023
        Client: "1.1.63.8", //17.08.2023
    },
};

export default LanguageStrings;
