export const emptySearchFields = {
    FilialIds : [],
    SearchText : "",
    AttributesText : "",
    FullText : "",
    IsSearchInAttachments : false,
    IsSearchInVersions : false,
    IsSearchInMarkedForDelete : false,
    TypeIds : [],
    RegDateAsString : "",
    RegPeriodStart : "",
    RegPeriodFinish : "",
    Name : "",
    RegNumber : "",
    VersionNumber : "",
}