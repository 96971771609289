import React from "react";
import {connect} from "react-redux";
import {TableFilterHasValueEnum} from "../../../tools/StaticTypes";
import {changeDocumentSearchInputs} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    combineMainTableAttributes,
    mainTableSearchDoc
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {ActionQueue} from "../../../store/rootReducer";
import {
    changeDocumentSearchInputs_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {updateInputsFieldPackage} from "../../../store/packages/actionCreators/package_InputActionCreator";

// type StateType = {
//     HasValue: number;
//     LessOrEqual: number | null;
//     MoreOrEqual: number | null;
//     Equal: number | null;
// }
//
// type OwnPropsType = {
//     rootLink: RootLinkEnum
// }
//
// type PropsFromRedux = ConnectedProps<typeof connector>;
// type PropsType = OwnPropsType & PropsFromRedux;

class FiltersNumber extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            HasValue: TableFilterHasValueEnum.None,
            LessOrEqual: null,
            MoreOrEqual: null,
            Equal: null,

            onClearLessOrEqualHover: false,
            onClearMoreOrEqualHover: false,
            onClearEqualHover: false,
        };

        this.CheckConflictedFields = this.CheckConflictedFields.bind(this);
        this.ApplyFilter = this.ApplyFilter.bind(this);
    }

    componentDidMount() {
        const { filterInfo: { Value } } = this.props;
        if(Value) {
            this.setState({
                LessOrEqual: Value.LessOrEqual ?? null,
                MoreOrEqual: Value.MoreOrEqual ?? null,
                Equal: Value.Equal ?? null,
            });
        }
    }

    CheckConflictedFields(inputName) {
        if(inputName === "Equal") {
            if(this.state.Equal !== null) {
                if(this.state.LessOrEqual) {
                    this.setState({LessOrEqual: null});
                }
                if(this.state.MoreOrEqual) {
                    this.setState({MoreOrEqual: null});
                }
            }
        }
        else {
            if(this.state.Equal !== null) {
                if(this.state.LessOrEqual) {
                    this.setState({Equal: null});
                }
                if (this.state.MoreOrEqual) {
                    this.setState({Equal: null});
                }

            }
        }
    }

    async ApplyFilter(isClear = false) {
        if (this.props.searchRight) {
            this.props.changeDocumentSearchInputs_packagesDocumentRight({
                ...this.props.filterInfo,
                Value : {
                    LessOrEqual: isClear ? "" : this.state.LessOrEqual,
                    MoreOrEqual: isClear ? "" : this.state.MoreOrEqual,
                    Equal: isClear ? "" : this.state.Equal,
                }
            });
        } else if (this.props.packageSearch) {
            this.props.updateInputsFieldPackage({
                ...this.props.packageSearchInputs,
                RegNumber : {
                    LessOrEqual: isClear ? "" : this.state.LessOrEqual,
                    MoreOrEqual: isClear ? "" : this.state.MoreOrEqual,
                    Equal: isClear ? "" : this.state.Equal,
                }
            });
        } else {
        this.props.changeDocumentSearchInputs({
            ...this.props.filterInfo,
            Value : {
                LessOrEqual: isClear ? "" : this.state.LessOrEqual,
                MoreOrEqual: isClear ? "" : this.state.MoreOrEqual,
                Equal: isClear ? "" : this.state.Equal,
            }
        });
        }
        if(isClear) {
            this.setState({ LessOrEqual: null, MoreOrEqual: null, Equal: null});
            return
        }

        if (this.props.onApply) this.props.onApply()
    }

    render() {
        const { filterInfo } = this.props;
        const filterName = typeof filterInfo.Attribute === "string"
            ? filterInfo.Attribute
            : filterInfo.Attribute.AttributeName.Name

        return (
            <>
                <h5 className="text-dark my-3">Фильтр (<b>{filterName}</b>)</h5>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Равно</p>
                <div className="col-lg-12 input-group h-40px my-4">
                    <div className="input-group-prepend">
                        <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                              onMouseEnter={() => {this.setState({ onClearEqualHover : true})}}
                              onMouseLeave={() => {this.setState({ onClearEqualHover : false})}}
                              onClick={() => {if(this.state.onClearEqualHover) {
                                  this.setState({Equal: null, EqualString: ""})}}}>
                            <i className={`svg-icon svg-icon-lg ${this.state.onClearEqualHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-equals_16"}`}/>
                        </span>
                    </div>
                    <input type="number" className="form-control form-control-lg h-40px br-right-5"
                           name={"Equal"}
                           onKeyDown={(event)=>{
                               if(event.code === "Enter" || event.code === "NumpadEnter") {
                                   this.ApplyFilter();
                               }
                           }}
                           onChange={(event)=>{
                               this.setState({Equal: Number.parseInt(event.target.value.trim())}, () => {
                                   this.CheckConflictedFields("Equal");
                               });
                           }}
                           placeholder="Задайте значение" value={this.state.Equal ? this.state.Equal : ""}
                    />
                </div>
            </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Больше или равно</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                             <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                   onMouseEnter={() => {this.setState({ onClearMoreOrEqualHover : true})}}
                                   onMouseLeave={() => {this.setState({ onClearMoreOrEqualHover : false})}}
                                   onClick={() => {if(this.state.onClearMoreOrEqualHover) {
                                       this.setState({MoreOrEqual: null, MoreOrEqualString: ""})}}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearMoreOrEqualHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-lessOrEqual-1"}`}/>
                            </span>
                        </div>
                        <input type="number" className="form-control form-control-lg h-40px br-right-5"
                               name={"MoreOrEqual"}
                               onKeyDown={(event) => {
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event)=>{
                                   this.setState({MoreOrEqual: Number.parseInt(event.target.value.trim())}, () => {
                                       this.CheckConflictedFields("MoreOrEqual");
                                   });
                               }}
                               placeholder="Задайте значение" value={this.state.MoreOrEqual ? this.state.MoreOrEqual : ""}
                        />
                    </div>
                </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Меньше или равно</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  onMouseEnter={() => {this.setState({ onClearLessOrEqualHover : true})}}
                                  onMouseLeave={() => {this.setState({ onClearLessOrEqualHover : false})}}
                                  onClick={() => {if(this.state.onClearLessOrEqualHover) {
                                      this.setState({LessOrEqual: null, LessOrEqualString: ""})}}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearLessOrEqualHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-lessOrEqual"}`}/>
                            </span>
                        </div>
                        <input type="number" className="form-control form-control-lg h-40px br-right-5"
                               name={"LessOrEqual"}
                               onKeyDown={(event) => {
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event) => {
                                   this.setState({LessOrEqual: Number.parseInt(event.target.value.trim())}, ()=>{
                                       this.CheckConflictedFields("LessOrEqual");
                                   });
                               }}
                               placeholder="Задайте значение" value={this.state.LessOrEqual ? this.state.LessOrEqual : ""}
                        />
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-5" onClick={() => {this.ApplyFilter()}}>
                        <a href="#" className="btn btn-primary font-weight-bold">Применить</a>
                    </div>
                    <div className="col-lg-4">
                        <a onClick={() => {if (this.props.onApply) this.props.onApply()}}
                            href="#" className="btn btn-outline-secondary font-weight-bold">Отменить</a>
                    </div>
                    <div className="col-lg-2" onClick={() => {this.ApplyFilter(true)}}>
                        <span className="btn btn-icon btn-lg">
                            <i className="svg-icon icon-Table_edit_8 icon-color-primary text-success" />
                        </span>
                    </div>
                </div>


                {/*<p className="mb-3">Наличие значения</p>*/}
                {/*<div className="input-icon h-40px my-2">*/}
                {/*    <select name="test"  className="form-select" value={this.state.HasValue}*/}
                {/*            onChange={(event)=>{this.setState({HasValue: Number.parseInt(event.target.value)},*/}
                {/*                () => {this.onInputHandler()})}}>*/}
                {/*        <option value={0}>Не выбрано</option>*/}
                {/*        <option value={1}>Нет значения</option>*/}
                {/*        <option value={2}>Есть значение</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        packageSearchInputs: state.packages.search.searchInputs,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeDocumentSearchInputs,
    changeDocumentSearchInputs_packagesDocumentRight,
    changeDocumentViewMode,
    combineMainTableAttributes,
    mainTableSearchDoc,
    updateInputsFieldPackage,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(FiltersNumber);
