import {store} from "../../../index";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {REPORTS_GET_ACTION} from "../reportActionsList";

export const getReports = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            // let PageSize=store.getState().search.mainTable.paginationPageSize;
            // let PageNumber=store.getState().search.mainTable.paginationPageNumber;
            // let test = store.getState().search.mainTable.inputField.TypeIds;

            let ArmType = store.getState().globalState.settings.ArmType;


            let filter=new Filter(FiltersType.ENTITY)
            filter.add(
                new EntityFilters().setEntityName("ArmStoredReport")
                    .add({Name: "ArmType", Value: ArmType, Operator: 0})
                    .addAttribute({Name : "ReferenceProperty",Value:"IdReport"})
                    .addAttribute({Name : "RootEntityProperty",Value:"Id"})
                    .get()
            )
            filter.addAttribute({Name: "PagingFilter", Value: {PageSize : 25, PageOffset : 0}})


            filter= filter.get()
            let request = new apiRequest(apiUrl.REPORTS.STORED_REPORT, {
                filter,
                sorting : new Sorting()
                    .add({PropertyName: "Id", Direction: 1})
                    .get()
            });

            request.execute(function(data) {

                dispatch({type : REPORTS_GET_ACTION.GET_REPORT, payload : data});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения списка отчетов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения списка отчетов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const getReportParams = ({IdReport=null,Next = null, Error = null}) => {

    return async dispatch => {
        try {

            let filter=new Filter(FiltersType.ENTITY)
            filter.add(
                new EntityFilters().setEntityName("StoredReportParam")
                    .add({Name: "IdReport", Value: IdReport, Operator: 0})
                    .get()
            )


            filter= filter.get()
            let request = new apiRequest(apiUrl.REPORTS.STORED_REPORT_PARAMS, {
                filter,
                sorting : new Sorting()
                    .add({PropertyName: "ParamOrder", Direction: "1"})
                    .add({PropertyName: "Id", Direction: "1"})
                    .get()
            });

            request.execute(function(data) {

                let records = data.Records;
                let oldParams=store.getState().report?.leftMenu?.params;
                for(let i = 0; i < records.length; i++){
                    if(oldParams){
                        for (let j = 0; j < oldParams.length; j++) {
                            if(records[i].TypeCode == oldParams[j].TypeCode && records[i].Name == oldParams[j].Name){
                                // console.log("oldParams[j].TypeCode",oldParams[j].TypeCode)
                                // console.log("records[i].TypeCode",records[i].TypeCode)
                                // console.log("records[i].Name",records[i].Name)
                                // console.log("oldParams[j].Name",oldParams[j].Name)
                                records[i].value = oldParams[j]?.value;
                            }
                        }
                        // if(records[i].IsList){
                        //     records[i].value = oldParams[i]?.value;
                        // } else {
                        //     records[i].value = oldParams[i]?.value;
                        // }
                    }
                    else {
                        if(records[i].IsList){
                            records[i].value = [];
                        } else {
                            records[i].value = "";
                        }
                    }

                }
                dispatch({type : REPORTS_GET_ACTION.GET_PARAMS, payload : records});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения списка параметров (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения списка параметров (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// export const setActiveRow = ({IdReport=null,Next = null, Error = null}) => {
//
//     return async dispatch => {
//         try {
//
//                 dispatch({type : REPORTS_GET_ACTION.GET_PARAMS, payload : records});
//                 if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
//             },function(data) {
//                 if(data.status === 401) {
//                     dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
//                 }
//                 console.log("Ошибка получения списка параметров (post post): ", data);
//             });
//         } catch (exception) {
//             console.log("Ошибка получения списка параметров (catch) ", exception);
//
//             if(Error) {Error.action(Error.params);} // если ошибка колбек
//         }
//     }
// }

export const setActiveRow = ({IdReport=null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : REPORTS_GET_ACTION.SET_ACTIVE_ROW, payload : IdReport});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения страницы пагинации в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
