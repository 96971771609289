import React from "react";
import {connect} from "react-redux";
import Iframe from "react-iframe";
import AttributesSectionPackagesMini from "../additionalDataSection/packages/mini/AttributesSectionPackagesMini";
import ContentSectionPackagesMini from "../additionalDataSection/packages/mini/ContentSectionPackagesMini";
import VersionSectionPackagesMini from "../additionalDataSection/packages/mini/VersionSectionPackagesMini";
import DocumentPackagesSectionPackagesMini
    from "../additionalDataSection/packages/mini/DocumentPackagesSectionPackagesMini";
import {SelectActivePackageDocument} from "../../store/packages/actionCreators/package_TreeActionCreator";
import {API} from "../../tools/API_NEW/API";
import {history, store} from "../../index";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {HelpFunctions} from "../../tools/HelpFunctions";
import parse from "html-react-parser";
import {AdminLevelEnum, ModalTypes, TableTypesEnum} from "../../tools/StaticTypes";
import {setModalData, setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import sanitizeHtml from "sanitize-html";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";
import FilesSection from "../additionalDataSection/FilesSection";
import DepartmentsSection from "../additionalDataSection/DepartmentsSection";
import {
    collectDataForAdditionalSections_Normal
} from "../../store/documents/actionCreators/document_PreviewActionCreator";

class PackagesPreviewMini extends React.Component {
    constructor(props) {
        super(props);

        this.lFlagWithDepartments = false;
        let {globalSettings} = this.props;
        if (globalSettings?.WithDepartments === "1" && this.props.adminLevel > AdminLevelEnum.CommonUser)
            this.lFlagWithDepartments = true

        this.state = {
            closePreviewTooltipShow : false,
            goToDocumentsTooltipShow : false,
            addNewCardTooltipShow : false,
            DeleteDocumentFromPackagesTooltipShow : false,
            infoTooltipShow: false,
            changeVersionDocTooltipShow: false,
        };

        this.closePreviewRef = React.createRef();
        this.goToDocumentsRef = React.createRef();
        this.DeleteDocumentFromPackagesRef = React.createRef();
        this.addNewCardRef = React.createRef();
        this.infoRef = React.createRef();
        this.changeVersionDocRef = React.createRef();


        this.render_cardBody = this.render_cardBody.bind(this);
        this.closePreview = this.closePreview.bind(this);
        this.goToDocuments = this.goToDocuments.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.changeVersionDoc = this.changeVersionDoc.bind(this);
    }



    deleteDocument(){

        let activeDocument;
        let activeNode;
        if(this.props.tableRole === "main")  {
            activeDocument = this.props.activeDocument;
            activeNode = this.props.activeNode;
        }
        else if(this.props.tableRole === "additional") {
            activeDocument = this.props.activeDocumentAdditional;
            activeNode = this.props.activeNodeAdditional;
        }
        else if(this.props.tableRole === "download") {
            activeDocument = this.props.activeDocumentDownload;
            activeNode = this.props.activeNodeDownload;
        }


        this.props.setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type : "deleteDocument",
                activeDocument : activeDocument,
                activeNode : activeNode,
                tableRole : this.props.tableRole
            }
        });
    }
    closePreview() {
        this.setState({closePreviewTooltipShow: false})
        //aa 29.09.2022
        // if(this.props.tableRole === "download") {
        //     this.props.SelectActivePackageDocument({TableType : this.props.tableRole, IsDownload : true, IsVisibleModal: false});
        // }
        // else {
        //     this.props.SelectActivePackageDocument({TableType : this.props.tableRole, IsVisibleModal: false});
        // }
        let activeDocument;
        if (this.props.tableRole === "main")  activeDocument = this.props.activeDocument;
        else if(this.props.tableRole === "additional") activeDocument = this.props.activeDocumentAdditional;
        else if(this.props.tableRole === "download") activeDocument = this.props.activeDocumentDownload;

        if(this.props.tableRole === "download") {
            this.props.SelectActivePackageDocument({ Id: activeDocument.Node.Id, TableType : this.props.tableRole, IsDownload : true, IsVisibleModal: false});
        }
        else {
            this.props.SelectActivePackageDocument({Id: activeDocument.Node.Id, TableType : this.props.tableRole, IsVisibleModal: false});
        }

    }

    render_cardBody() {
        let activeDocument;
        if (this.props.tableRole === TableTypesEnum.Main)  activeDocument = this.props.activeDocument;
        else if(this.props.tableRole === TableTypesEnum.Additional) activeDocument = this.props.activeDocumentAdditional;
        else if(this.props.tableRole === TableTypesEnum.Download) activeDocument = this.props.activeDocumentDownload;

        let preview = activeDocument.preview;
        let body = <div className="card-body"/>;
        let activeLink = "";

        if (preview.activeView === "preview") {
            activeLink = preview.files[preview.activeFileIndex].previewLink;
            body = (
                <div className="card-body beautiful-scroll-10" style={{}}>
                    <img src={activeLink} alt="" style={{
                        boxShadow: "0 0 15px -5px grey",
                        width: "100%"}}/>
                </div>
            );
        }
        else {
            activeLink = preview.files[preview.activeFileIndex].originalLink;
            body = (
                <div className="card-body beautiful-scroll-10" style={{width : "100%", height : "100%", padding : "0px"}}>
                    <Iframe url={activeLink}
                            width="100%"
                            height="100%"
                            id="test"
                            display="initial"
                            position="relative"/>
                </div>
            );
        }

        return body;
    }

    async goToDocuments(isCard = false) {
        let activeDocument;
        if(this.props.tableRole === TableTypesEnum.Main)  activeDocument = this.props.activeDocument;
        else if(this.props.tableRole === TableTypesEnum.Additional) activeDocument = this.props.activeDocumentAdditional;
        else if(this.props.tableRole === TableTypesEnum.Download) activeDocument = this.props.activeDocumentDownload;

        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (this.props.verifyDocs)
        {
            if (this.props.verifyDocs[activeDocument.Node.Id]) {
                vFlagVerify = true;
                docRefInfo = this.props.verifyDocs[activeDocument.Node.Id][0];
            }
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: activeDocument.Node.Id,
                    IdFilial: this.props.activeFilialId
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: activeDocument.Node.Id,
                    data: docRefInfo
                }
            });
        }

        if (docRefInfo.errorCode) {
            if (docRefInfo.message) {
                if (docRefInfo.message.Message) {
                    store.dispatch(setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            content: docRefInfo.message.Message,
                            disableButton: false,
                            type: "fail"
                        }
                    }));
                }
            }
        }
        else {
            let link = `/documents`;
            let postfix = "";
            if (docRefInfo.IdDocGroup)
                postfix += `?g=${docRefInfo.IdDocGroup}`;
            if (docRefInfo.IdDocType)
                if (postfix != "")
                    postfix += `&t=${docRefInfo.IdDocType}`
                else
                    postfix += `?t=${docRefInfo.IdDocType}`;
            if (docRefInfo.IdDoc)
                if (postfix != "")
                    postfix += `&d=${docRefInfo.IdDoc}`;
                else
                    postfix += `?d=${docRefInfo.IdDoc}`;
            link += postfix;


            // let link = `/documents?g=${docRefInfo.IdDocGroup}&t=${docRefInfo.IdDocType}&d=${docRefInfo.IdDoc}`;


            //
            //
            // // let docRefInfo = await API.search().verifyDocClientLinkIds({
            // //     linkIds : {
            // //         IdDoc : activeDocument.Node.Id,
            // //         IdFilial : this.props.activeFilialId
            // //     }
            // // });
            //
            // let link = `/documents?g=${docRefInfo.IdDocGroup}&t=${docRefInfo.IdDocType}&d=${docRefInfo.IdDoc}`;

            if (isCard) {
                link += `&c=${docRefInfo.IdDoc}`;
            } else {
                // если из пакетов была открыта карточка,
                // а после выполнен переход в реестр нужно сохранить состояние левого меню
                this.props.setStateLeftAsideMenu({
                    isClose: false,
                    rootName: "DocumentApp"
                });
            }

            history.replace(link);
        }
    }

    changeVersionDoc() {
        this.props.setModalData({
            name: ModalTypes.packages.ChangeVersionDocInPackage,
            data: {
                TableType: this.props.tableRole,
                IsDownload: this.props.tableRole === TableTypesEnum.Download
            }
        });
    }


    render() {
        let activeDocument;
        let isVisibleModal;

        if(this.props.tableRole === TableTypesEnum.Main) {activeDocument = this.props.activeDocument; isVisibleModal = this.props.isVisibleModalDocument}
        else if(this.props.tableRole === TableTypesEnum.Additional) {activeDocument = this.props.activeDocumentAdditional; isVisibleModal = this.props.isVisibleModalDocumentAdditional}
        else if(this.props.tableRole === TableTypesEnum.Download) {activeDocument = this.props.activeDocumentDownload; isVisibleModal = this.props.isVisibleModalDocumentDownload;}

        if(!activeDocument) return null;
        if(!isVisibleModal) return null;

        let text = sanitizeHtml(activeDocument.Node.Name + (activeDocument.Node.ShortDescription ?? ""))
        const isAuto = activeDocument?.Node.IsAuto;

        const IdDoc = activeDocument.Node.Version.IdDoc
        return (
            <div className="modal modal-sticky modal-sticky-bottom-right modal-packege1 show" style={{zIndex : "999"}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="card card-custom" style={{border:"1px solid #ffffff"}}>
                            <div className="card-header align-items-center px-4 py-3 modal-packege2">
                                <div className="text-left flex-grow-1">
                                    <div className="text-dark-75 font-weight-bold">
                                        <span title={`${activeDocument.Node.Name} ${activeDocument.Node.ShortDescription ?? ""}`}>
                                            {HelpFunctions.cutLongString(parse(text), 20, true)}
                                        </span>
                                        {/*{*/}
                                        {/*    this.state.infoTooltipShow &&*/}
                                        {/*    <MyTooltip target={this.infoRef}*/}
                                        {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                                        {/*               show={this.state.infoTooltipShow}*/}
                                        {/*               placement={"left"} delay={500}*/}
                                        {/*    />*/}
                                        {/*}*/}
                                        {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                                        {/*      ref={this.infoRef}*/}
                                        {/*      onMouseEnter={()=>{this.setState({infoTooltipShow : true})}}*/}
                                        {/*      onMouseLeave={()=>{this.setState({infoTooltipShow : false})}}>*/}
                                        {/*    <i className="svg-icon icon-Info"/>*/}
                                        {/*</span>*/}
                                        {
                                            this.state.changeVersionDocTooltipShow &&
                                            <MyTooltip target={this.changeVersionDocRef}
                                                       text={"Изменить версию документа в пакете"}
                                                       show={this.state.changeVersionDocTooltipShow}
                                                       placement={"top"} delay={500}
                                            />
                                        }
                                        {
                                            (this.props?.adminLevel >= 1 && !isAuto) && this.props.tableRole !== TableTypesEnum.Download &&
                                            <span className="btn btn-icon btn-sm" ref={this.changeVersionDocRef}
                                                  onClick={()=>{this.changeVersionDoc()}}
                                                  onMouseEnter={()=>{this.setState({changeVersionDocTooltipShow : true})}}
                                                  onMouseLeave={()=>{this.setState({changeVersionDocTooltipShow : false})}}>
                                                <i className="svg-icon icon-Table_edit_1 icon-color-primary"/>
                                            </span>
                                        }
                                        {
                                            this.state.addNewCardTooltipShow &&
                                            <MyTooltip target={this.addNewCardRef}
                                                       text={"Карточка документа"}
                                                       show={this.state.addNewCardTooltipShow}
                                                       placement={"top"} delay={500}
                                            />
                                        }
                                        <span className="btn btn-icon btn-sm"
                                              ref={this.addNewCardRef}
                                              onClick={()=>{this.goToDocuments(true)}}
                                              onMouseEnter={()=>{this.setState({addNewCardTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({addNewCardTooltipShow : false})}}>
                                            <i className="svg-icon icon-Table_edit_3 icon-color-primary"/>
                                        </span>
                                        {
                                            this.state.goToDocumentsTooltipShow &&
                                            <MyTooltip target={this.goToDocumentsRef}
                                                       text={"Перейти в реестр"}
                                                       show={this.state.goToDocumentsTooltipShow}
                                                       placement={"top"} delay={500}
                                            />
                                        }
                                        <span className="btn btn-icon btn-sm"
                                              ref={this.goToDocumentsRef}
                                              onMouseEnter={()=>{this.setState({goToDocumentsTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({goToDocumentsTooltipShow : false})}}
                                              onClick={()=>{this.goToDocuments()}}>
                                            <i className="svg-icon icon-left_window icon-color-primary"/>
                                        </span>
                                        {
                                            this.state.DeleteDocumentFromPackagesTooltipShow &&
                                            <MyTooltip target={this.DeleteDocumentFromPackagesRef}
                                                       text={"Исключить документ из пакета"}
                                                       show={this.state.DeleteDocumentFromPackagesTooltipShow}
                                                       placement={"top"} delay={500}
                                            />
                                        }
                                        {
                                            (this.props?.adminLevel > AdminLevelEnum.CommonUser && this.props.tableRole !== TableTypesEnum.Download) &&
                                            <span className="btn btn-icon btn-sm"
                                                  ref={this.DeleteDocumentFromPackagesRef}
                                                  onMouseEnter={()=>{this.setState({DeleteDocumentFromPackagesTooltipShow : true})}}
                                                  onMouseLeave={()=>{this.setState({DeleteDocumentFromPackagesTooltipShow : false})}}
                                                  onClick={()=>{this.deleteDocument()}}>
                                            <i className="svg-icon icon-Doc_view_8 icon-color-red"/>
                                        </span>
                                        }
                                    </div>
                                </div>

                                {
                                    this.state.closePreviewTooltipShow &&
                                    <MyTooltip target={this.closePreviewRef}
                                               text={"Закрыть предпросмотр"}
                                               show={this.state.closePreviewTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <div className="text-right flex-grow-1">
                                    <span  onClick={this.closePreview}
                                           ref={this.closePreviewRef}
                                           onMouseEnter={()=>{this.setState({closePreviewTooltipShow : true})}}
                                           onMouseLeave={()=>{this.setState({closePreviewTooltipShow : false})}}
                                            className="btn btn-icon btn-sm">
                                        <i className="svg-icon icon-Delete icon-color-red"/>
                                    </span>
                                </div>
                            </div>
                            <div className="card-body modal-packege4">

                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="symbol symbol-50 symbol-lg-120 modal-packege5 modal-packege6">
                                            {this.render_cardBody()}
                                        </div>
                                    </div>
                                    <div className="col-lg-8" style={{minHeight:"40vh"}}>
                                        <div className={"accordion accordion-solid accordion-toggle-plus beautiful-scroll-10 no-scroll"}>
                                            <AttributesSectionPackagesMini tableRole={this.props.tableRole}/>
                                            <ContentSectionPackagesMini tableRole={this.props.tableRole}/>

                                            {/*<FilesSectionPackagesMini tableRole={this.props.tableRole}/>*/}
                                            <FilesSection
                                                activeDocument={activeDocument}
                                                typeSection = {"packages"}
                                                signatureNotEditable = {true}/>

                                            <VersionSectionPackagesMini tableRole={this.props.tableRole}/>
                                            <DocumentPackagesSectionPackagesMini tableRole={this.props.tableRole}/>
                                            {this.lFlagWithDepartments &&
                                                <DepartmentsSection
                                                    tableRole={this.props.tableRole}
                                                    depMini
                                                    IdDoc={IdDoc}
                                                    viewMode={"normal"}
                                                />}
                                            {/*//TODO аккордеон здесь*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {

        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,

        isVisibleModalDocument : state.packages.tree.IsVisibleModal,
        isVisibleModalDocumentAdditional : state.packages.treeAdditional.IsVisibleModal,
        isVisibleModalDocumentDownload : state.packages.treeDownload.IsVisibleModal,


        activeNode : state.packages.tree.activeNode,
        activeNodeAdditional : state.packages.treeAdditional.activeNode,
        activeNodeDownload : state.packages.treeDownload.activeNode,
        activeFilialId : state.globalState.filial.Active.Id,

        verifyDocs: state.document.verifyDocs,
        adminLevel : state.globalState.user.AdminLevel,
        globalSettings: state.globalState.settings.Content,
    }
}

const  mapDispatchToProps = {
    collectDataForAdditionalSections_Normal,
    SelectActivePackageDocument,
    setModalData,
    setStateLeftAsideMenu
}


export default connect(mapStateToProps, mapDispatchToProps)(PackagesPreviewMini);
