import React from "react";
import {connect} from "react-redux";
import {DataTypes, ModalTypes, RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {__} from "../../../tools/HelpFunctions";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history, store} from "../../../index";
import {Sorting} from "../../../tools/Filters";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";

const animatedComponents = makeAnimated();

const sbSelect = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },

        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            /*background-color: rgb(239 137 137 / 20%);*/
            backgroundColor: "var(--background-colorBS)",
        },

        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    })
}


class ChangeDocumentTypeModalOriginal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortingTypesName: [],
            targetDataType: '',
            updatedDocumentAttributes: {
                docAttributes: [],
                docHeader: {
                    Id: null,
                    IdFilial: null,
                    Name: null,
                    RegDate: null,
                    RegNumber: null,
                }
            },
            ActiveIdFilial : null,
            SelectedGroups: [],
            LoadGroups : [],
            ActiveType : null,

            countEmptyRequiredFields: [],
        };

        this.hide = this.hide.bind(this);
        this.setTargetDataType = this.setTargetDataType.bind(this);
        this.changeAttributeValue = this.changeAttributeValue.bind(this);
        this.postNewDocumentType = this.postNewDocumentType.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeRegNumber = this.changeRegNumber.bind(this);
        this.changeRegDate = this.changeRegDate.bind(this);

        this.checkRequired = this.checkRequired.bind(this);
    }


    // скрывать модалку
    hide() {

        this.props.setModalData({});
    }

    componentDidMount() {
        this.setState({
            sortingTypesName: this.props.allTypes
              .filter(group => group.TypeAttributes.length > 0)
              .map(group => ({
                  Attributes: group.TypeAttributes,
                  label: group.Name,
                  value: group.Id,
              })),
            updatedDocumentAttributes: {
                docAttributes: [],
                docHeader: {
                    Id: this.props.activeDocument.Info.Id,
                    IdFilial: this.props.activeDocument.Info.IdFilial,
                    Name: this.props.activeDocument.Info.Name,
                    RegDate: this.props.activeDocument.Info.RegDate,
                    RegNumber: this.props.activeDocument.Info.RegNumber,
                    Type: {
                        Id: null,
                        Name: null,
                    }
                }
            }
        })
    }

    async setTargetDataType(type) {
        let findType = this.props.allTypes.find(item => item.Id === type.value)
        let typeAttributes = []
        findType.TypeAttributes.forEach((atr) => {
            let attributeValue = this.props.activeDocument.Info.Attributes.find(doc => doc.AttributeName.Id === atr.AttributeName.Id)?.AttributeValue
            typeAttributes.push({
                AttributeName: {
                    Id: atr.AttributeName.Id,
                    IdAttributeType: atr.AttributeName.IdAttributeType,
                    Name: atr.AttributeName.Name,
                },
                AttributeValue: attributeValue,
                IdDoc: this.props.activeDocument.Info.Version.IdDoc
            })
        })
        let copyUpdatedDocumentAttributes = __.deepCopy(this.state.updatedDocumentAttributes)
        copyUpdatedDocumentAttributes.docAttributes = typeAttributes
        copyUpdatedDocumentAttributes.docHeader.Type = {
            Name: findType.Name,
            Id: findType.Id
        }
        await this.setState({updatedDocumentAttributes: copyUpdatedDocumentAttributes})
        await this.setState({targetDataType: findType})
        await this.setState({ActiveType: findType.Id})
        await this.checkRequired()


        ///////////////////////

        let request_groups = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE_GROUP, {
            filter : {IdFilial : store.getState().globalState.filial.Active.Id},
            sorting : new Sorting()
                .add({Direction: 1,PropertyName : "Name"})
                .add({Direction: 1,PropertyName : "GroupOrder"})
                .get()
        });
        request_groups.execute((data)=> {
            this.setState({
                LoadGroups: data.Records.filter(group => group.Type === 2).sort((a,b) => {return a.GroupOrder - b.GroupOrder}),
            });
        },(data)=>{console.log("Ошибка загрузки списка групп документов: ", data)});
    }

    changeName(value){
        let copyUpdatedDocumentAttributes = __.deepCopy(this.state.updatedDocumentAttributes)
        copyUpdatedDocumentAttributes.docHeader.Name = value
        this.setState({updatedDocumentAttributes: copyUpdatedDocumentAttributes})
    }

    changeRegNumber(value){
        let copyUpdatedDocumentAttributes = __.deepCopy(this.state.updatedDocumentAttributes)
        copyUpdatedDocumentAttributes.docHeader.RegNumber = value
        this.setState({updatedDocumentAttributes: copyUpdatedDocumentAttributes})
    }

    changeRegDate(value){

        let copyUpdatedDocumentAttributes = __.deepCopy(this.state.updatedDocumentAttributes)
        copyUpdatedDocumentAttributes.docHeader.RegDate = value+"T00:00:00"
        this.setState({updatedDocumentAttributes: copyUpdatedDocumentAttributes})
    }

    changeAttributeValue(id, value, isRequired, isBoolean, type) {
        if(isBoolean){
           if(value){
               value = "1";
           }else{
               value="0";
           }
        }
        this.setState({
            updatedDocumentAttributes: {
                ...this.state.updatedDocumentAttributes,
                docAttributes: this.state.updatedDocumentAttributes.docAttributes.map((item) => {
                    if(item.AttributeName.Id === id) {
                        return {
                            ...item,
                            AttributeValue: value ?? null
                        }
                    }
                    else return item;
                })
            }
        });
        if (isRequired){
            this.checkRequired()
        }
    }

    async checkRequired(){
        let requiredFields = await this.state.targetDataType.TypeAttributes.filter(item => item.IsRequired)
        let countEmptyRequiredFields = await requiredFields
          .map(item => !!this.state.updatedDocumentAttributes.docAttributes
            .find(doc => doc.AttributeName.Id === item.AttributeName.Id).AttributeValue ? null : true)
            .filter((item) => item)
        this.setState({countEmptyRequiredFields: countEmptyRequiredFields})
    }

    async postNewDocumentType(){
        try {
            // this.props.setModalData({
            //     name: ModalTypes.app.alert,
            //     data: {content: "Загрузка...", disableButton: true, fullBackground: false, gif: "documents"}
            // });
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "ChangeDocumentTypeModalOriginal219" }
            }));

            // todo tp преобразование даты для сервера
            const docAttributes = this.state.updatedDocumentAttributes.docAttributes.map(attr => {
                if (attr.AttributeName.IdAttributeType === 5) {
                    if (typeof attr.AttributeValue === 'string') {
                        if (attr.AttributeValue.includes('-')) attr.AttributeValue = __.dateToRussia(attr.AttributeValue)
                    }
                }
                return attr
            });
            this.setState({
                updateDocumentAttributes: {...this.state.updatedDocumentAttributes, docAttributes: docAttributes}
            });
            let request = await new apiRequest(apiUrl.DOCUMENTS.UPDATE_DOC_ATTRIBUTES, this.state.updatedDocumentAttributes)

            await request.execute()
            await this.props.mainTableDownloadDocCollection({});
            await this.props.combineMainTableAttributes({});
            //Новый прелоадер
            // await this.props.setModalData({});
            store.dispatch(setLoaderModalData({keyDeleted: "ChangeDocumentTypeModalOriginal240"}));
            let query = this.props.router.location.query;
            let queryLink = Routing.CreateRef({
                oldQuery: query,
                newQuery: {},
                excludedKeys: ["d"]
            });

            if(this.state.SelectedGroups.length > 0) {
                let collection = [];
                for (let i = 0; i < this.state.SelectedGroups.length; i++) {
                    collection.push(
                        {
                            Id: this.props.activeDocument.Info.Version.Id,
                            IdDoc: this.props.activeDocument.Info.Version.IdDoc,
                            IdDocGroup: this.state.SelectedGroups[i].value
                        }
                    )
                }
                let request = new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
                    collection: collection
                });
                await request.execute(function (data) {
                }, function (data) {
                    if (data.status === 401) {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: `Ошибка помещенния в реестр: <br/>${data}`}
                        });
                        return;
                    }
                    console.log("Ошибка получения дерева группы документов (post): ", data);
                });
            }
            let link = `${this.props.router.location.pathname}${queryLink}`;
            await history.push(link);

            this.props.changeDocumentViewMode({
                ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
            })

            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "success", content: `Тип документа успешно изменён!`}
            });
        } catch(e) {
            console.log(e)
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: e.responseJSON.Message}
            });
        }
    }


    render() {
        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };
        let searchIconEndDate = "icon-calendar_search "
        let mismatchAttributes;

        if(this.state.targetDataType){
            mismatchAttributes = this.state?.targetDataType?.TypeAttributes.map(item => item.AttributeName.Name)
        }

        // let date = this.state.updatedDocumentAttributes.docHeader.RegDate.split('T',0)
         let date = this.state.updatedDocumentAttributes.docHeader.RegDate
        if(date){
            date= this.state.updatedDocumentAttributes.docHeader.RegDate.split('T',1)[0]
        }

        let groupsOptions = [];
        groupsOptions.push({
            value :  null,
            label :  "Не выбрано",
            isDisabled : true,
            groupTypes: []
        });
        for(let i = 0; i < this.state.LoadGroups.length; i++) {
            groupsOptions.push({
                value :  this.state.LoadGroups[i].Id,
                label :  this.state.LoadGroups[i].Name,
                isDisabled : false,
                groupTypes: this.state.LoadGroups[i].GroupTypeRefs,
            });
        }

        // TODO TP №21638,  №21567
        groupsOptions = this.state.ActiveType
            ? groupsOptions.filter(item => item.groupTypes.find(type => type.IdDocType === this.state.ActiveType))
            : groupsOptions

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div className="modal-content"
                         onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Подтвердите перенос документа
                            </h5>
                        </div>
                        <div className="modal-body" style={{height: "30vw", padding:" 0.8rem 1.75rem"}}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <form className="form">
                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>
                                                    <span className="label label-inline label-light-warning font-weight-bolder">
                                                        Внимание!
                                                    </span>
                                                    Вы переносите документ:
                                                </label>
                                                <input
                                                  type="email"
                                                  className="form-control"
                                                  value={this.props.activeDocument.Info.Name}
                                                  disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Текущий тип документа: </label>
                                                <input
                                                  type="email"
                                                  className="form-control"
                                                  placeholder={this.props.activeDocument.Info.Type.Name}
                                                  disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Новый тип документа: </label>
                                                    <Select
                                                      components={animatedComponents}
                                                      onChange={(item) => {this.setTargetDataType(item)}}
                                                      options={this.state.sortingTypesName}
                                                      placeholder="Выберите тип"
                                                      title="Из папки"
                                                      theme={(theme) => ({
                                                          ...theme,
                                                          colors: {
                                                              ...theme.colors,
                                                              text: 'var(--hover-primary)',
                                                              primary25: 'var(--light-primary)',
                                                              primary50: 'var(--hover-primary)',
                                                              primary: 'var(--originaltwo)',
                                                          },
                                                      })}
                                                    />
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Раздел документа: </label>
                                                <Select
                                                    styles={sbSelect}
                                                    placeholder="Выберите раздел документа"
                                                    title="Выберите раздел документа"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isMulti
                                                    onChange={(value) => this.setState({SelectedGroups: value})}
                                                    options={this.state.ActiveType ? groupsOptions : []}
                                                    noOptionsMessage={() => "Нет доступных разделов"}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'var(--hover-primary)',
                                                            primary25: 'var(--light-primary)',
                                                            primary50: 'var(--hover-primary)',
                                                            primary: 'var(--originaltwo)',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>



                                        <div className="form-group">

                                                <label>Дата <span className="text-danger">*</span></label>
                                                <div className="input-icon h-40px my-2" >
                                                 <span className="svg-icon svg-icon-lg"
                                                       //TODO выяснить зачем эта функция
                                                       // onClick={(event)=>{this.clearInput(event)}}>
                                                       onClick={(event)=>{}}>
                                                     <i id="EndDate" className={`svg-icon svg-icon-lg icon-color-primary ${searchIconEndDate}`}/>
                                                 </span>
                                                    <input type="date" className="form-control form-control-lg h-40px"
                                                           min="1900-01-01"
                                                           max="3000-12-31"
                                                           placeholder="Дата 'ПО'"
                                                           title="Дата 'ПО'"
                                                           //value={this.state.updatedDocumentAttributes.docHeader.RegDate}
                                                           value={date}
                                                           onChange={(e) => {
                                                               this.changeRegDate(e.target.value)
                                                           }}
                                                           name="dateFinish"
                                                    />
                                                </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label>Номер <span className="text-danger">*</span></label>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Номер"
                                                           value={this.state.updatedDocumentAttributes.docHeader.RegNumber}
                                                           onChange={e => this.changeRegNumber(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Версия <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" value={this.props.activeDocument.Version.Version} disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Наименование <span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <textarea className="form-control"
                                                              onChange={e => this.changeName(e.target.value)}
                                                              value={this.state.updatedDocumentAttributes.docHeader.Name}
                                                              placeholder={'Введите наименование'}
                                                              rows="3"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="col-lg-4">
                                    <p>
                                        <span className="label label-inline label-light-success font-weight-bold">
                                            Текущие
                                        </span>
                                        Атрибуты реестра “{this.props.activeDocument.Info.Type.Name}”
                                    </p>
                                    <div className="table-responsive">
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Атрибут</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Значение</p></th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.75em"}}>
                                            {
                                                this.props.activeDocument.Info.Attributes.map((item)=> {
                                                    let isMismatch = false;
                                                    if(this.state.targetDataType)(
                                                      isMismatch = mismatchAttributes.find(attribute => attribute === item.AttributeName.Name)
                                                    )
                                                    return <tr key={__.createUniqueIdString(6)}style={this.state.targetDataType && !isMismatch ? {backgroundColor: '#e7310087'} : this.state.targetFolder ? {backgroundColor: '#56dd5680'} : null}>
                                                        <td>{item.AttributeName.Name}</td>
                                                        <td>{item.AttributeValue}</td>
                                                    </tr>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                { this.state.targetDataType && <div className="col-lg-4">
                                    <p>
                                        <span className="label label-inline label-light-primary font-weight-bold">
                                            Новые
                                        </span>
                                        Атрибуты реестра “{this.state.targetDataType.Name}”
                                    </p>
                                    <div className="table-responsive">
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Атрибут</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Значение</p></th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize : "0.75em"}}>
                                            {this.state.targetDataType.TypeAttributes.map((item) => {

                                                let targetValue = this.state.updatedDocumentAttributes.docAttributes.find((atr) => atr.AttributeName.Id === item.AttributeName.Id)?.AttributeValue

                                               if(item.AttributeName.IdAttributeType===DataTypes.Boolean){
                                                  if(targetValue == 1){
                                                      return(
                                                          <tr key={__.createUniqueIdString(6)} style={!targetValue && item.IsRequired ? {backgroundColor: '#edb647cc'} : targetValue ? {backgroundColor: '#56dd5680'} : null}>
                                                              <td style={item.IsRequired ? {fontWeight: '700'} : null}>{item.AttributeName.Name}</td>
                                                              <td>
                                                                  <input
                                                                      type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                      className="form-control"
                                                                      value={targetValue}
                                                                      checked
                                                                      onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.checked, item.IsRequired, true)} />
                                                              </td>
                                                          </tr>
                                                      );
                                                  }else{
                                                      return(
                                                          <tr style={!targetValue && item.IsRequired ? {backgroundColor: '#edb647cc'} : targetValue ? {backgroundColor: '#56dd5680'} : null}>
                                                              <td style={item.IsRequired ? {fontWeight: '700'} : null}>{item.AttributeName.Name}</td>
                                                              <td>
                                                                  <input
                                                                      type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                      className="form-control"
                                                                      value={targetValue}
                                                                      onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.checked, item.IsRequired, true)} />
                                                              </td>
                                                          </tr>
                                                      );
                                                  }
                                               }else{
                                                   return(
                                                       <tr style={!targetValue && item.IsRequired ? {backgroundColor: '#edb647cc'} : targetValue ? {backgroundColor: '#56dd5680'} : null}>
                                                           <td style={item.IsRequired ? {fontWeight: '700'} : null}>{item.AttributeName.Name}</td>
                                                           <td>
                                                               <input
                                                                   type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                   className="form-control"
                                                                   value={targetValue}
                                                                   onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired,false, item.AttributeName.IdAttributeType)} />
                                                           </td>
                                                       </tr>
                                                   );
                                               }
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                            <button type="button"
                                    disabled={!this.state.targetDataType || !(this.state.countEmptyRequiredFields.length === 0)}
                                    className="btn btn-primary font-weight-bolder"
                                    onClick={this.postNewDocumentType}
                            >
                                Перенести документ в новый реестр
                            </button>
                            <button
                              type="button"
                              className="btn btn-light font-weight-bold"
                              onClick={this.hide}
                            >
                                Отменить действие
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeNode : state.document.mainTable.activeNode,
        activeDocument : state.document.mainTable.activeRow,
        allTypes: state.document.tree.docs,
        router : state.router,
    }
}

const  mapDispatchToProps = {
    setModalData,
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    changeDocumentViewMode
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDocumentTypeModalOriginal);
