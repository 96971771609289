import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {HelpFunctions} from "../../../tools/HelpFunctions";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";

class DropFileComponent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            drag: false,
            deleteTooltipShow: false,
            chosenFileError: null
        }

        this.deleteFilesRef = React.createRef();

        this.dragActiveRender = this.dragActiveRender.bind(this);
        this.dragNotActiveRender = this.dragNotActiveRender.bind(this);
        this.dragStartHandler = this.dragStartHandler.bind(this);
        this.dragLeaveHandler = this.dragLeaveHandler.bind(this);
        this.dropEndHandler = this.dropEndHandler.bind(this);
    }

    selectFiles(event) {
        let files = event.target.files
        if (files.length === 0) return
        if (event.target.name === "original") {
            const file = files[0]?.name.toLowerCase().endsWith(".pdf") ? files[0] : null
            this.props.setFiles(file)
        }
        if (event.target.name === "preview") {
            const file = files[0]?.name.toLowerCase().endsWith(".png") ? files[0] : null
            this.props.setFiles(file)
        }
        else if (event.target.name === "other") {
            // TODO: MS: пока комментим, в будущем будем использовать с доработками.
            // TODO TP №21637
            if (this.props.value.length > 0) {
                const concatArr = Array.from([...this.props.value, ...files]);

                files = concatArr.filter(
                    (file, index, self) => index === self.findIndex((f) => f.name === file.name && f.size === file.size)
                );
                if (concatArr.length > files.length) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            type: "fail",
                            content: `Ошибка заполнения: <br/> Нельзя загрузить одинаковые файлы`
                        }
                    });
                    return;
                }
            }
            this.props.setFiles(files)
        }
    }

    dragStartHandler(e) {
        e.preventDefault();
        e.stopPropagation();

        if(!this.state.drag) {
            this.setState({drag: true});
        }
    }

    dragLeaveHandler(e){
        e.preventDefault();
        e.stopPropagation();


        if(this.state.drag && e.target.className !== "dragZone") {
            this.setState({drag: false});
        }
    }

    dropEndHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.state.drag) {
            this.setState({drag: false});
            let files = [...event.dataTransfer.files];
            for (let i = 0; i < files.length; i++) {
                files[i].id = createUniqueIdString(8);
                files[i].description = "";
                files[i].new_name = files[i].name.toString();
            }

            if (event.target.id === "dragZone-original") {
                const file = files.find(item => item.name.toLowerCase().endsWith(".pdf"))
                this.props.setFiles(file)
            } else if (event.target.id === "dragZone-preview") {
                const file = files.find(item => item.name.toLowerCase().endsWith(".png"))
                this.props.setFiles(file)
            } else if (event.target.id === "dragZone-other") {
                // TODO: MS: пока комментим, в будущем будем использовать с доработками.
                // TODO TP №21637
                if (this.props.value.length > 0) {
                    const concatArr = Array.from([...this.props.value, ...files]);
                    files = concatArr.filter(
                        (file, index, self) => index === self.findIndex((f) => f.name === file.name && f.size === file.size)
                    );
                    if (concatArr.length > files.length) {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {
                                type: "fail",
                                content: `Ошибка заполнения: <br/> Нельзя загрузить одинаковые файлы`
                            }
                        });
                        return;
                    }
                }
                this.props.setFiles(files)
            }
        }
    }

    dragActiveRender() {
        return (
            <div
                // style={{height: "150px"}}
                id={`dragZone-${this.props.name}`}
                onDragOver={(e) => {this.dragStartHandler(e)}}
                onDragLeave={(e) => {this.dragLeaveHandler(e)}}
                onDrop={(e) => {this.dropEndHandler(e)}}
                className={`${this.state.chosenFileError ? "btn-danger" : "btn-light"} w-auto d-flex flex-column align-items-start btn-lg p-4 mr-5 border-dashed`}
            >
                <span className={`symbol ${this.state.chosenFileError ? "symbol-danger" : ""} mr-3`}>
                    <span className="symbol-label font-size-h5 font-weight-bold">
                        <i className="svg-icon icon-color-primary icon-Doc_view_4" style={{fontSize: "2rem"}}/>
                    </span>
                </span>
                <p className="text-secondary font-weight-bold">Отпустите <b className="text-success">файлы</b></p>
                <input type="file" name={this.props.name}
                       accept={this.props.accept}
                       onChange={(e) => {
                           this.selectFiles(e)
                       }}
                       ref={this.props.elemRef}
                       style={{display: "none"}}
                />
                <span className="form-text text-muted mt-4">Максимальный размер файла - 250Мб</span>
            </div>
        );
    }

    // когда файл не наведен на область загрузки
    dragNotActiveRender() {
        const chosenFile = this.props.value instanceof File || this.props.value?.length > 0

        return (
            <div
                // todo tp на маленьком разрешении текст внутри выходит за рамки,
                //  если нужен такой стиль, возможно лучше перевести в какие-то другие единицы
                // style={{height: "150px"}}
                id={`dragZone-${this.props.name}`}
                onClick={() => {this.props.elemRef.current.click()}}
                onDragOver={(e) => {this.dragStartHandler(e)}}
                className={`${this.state.elemError ? "btn-danger" : "btn-light"} w-auto d-flex flex-column align-items-start btn-lg p-4 mr-5 border-dashed overflow-hidden`}
            >
                <span
                    className={`symbol ${this.state.elemError ? "symbol-danger" : ""} mr-3 d-flex align-items-center`}>
                    <span
                        className="symbol-label font-size-h5 font-weight-bold">
                        <i className="svg-icon icon-color-primary icon-Doc_view_4"
                           style={{fontSize: "2rem"}}/>
                    </span>
                    {chosenFile &&
                        <span ref={this.deleteFilesRef}
                              className="btn btn-icon btn-sm"
                              onMouseEnter={() => {
                                  this.setState({deleteTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({deleteTooltipShow: false})
                              }}
                              onClick={(event) => {
                                  event.stopPropagation();
                                  //TODO TP input file тоже нужно очищать
                                  if (this.elemRef?.current)
                                      this.elemRef.current.value = "";
                                  document.getElementById(`input-${this.props.name}`).value = '';
                                  if (this.props.value instanceof File)
                                      this.props.setFiles(null)
                                  else
                                      this.props.setFiles([])
                              }}>
                        <i style={{fontSize: '16px'}} className="svg-icon icon-Delete icon-color-primary-exit"/>
                    </span>
                    }
                </span>
                <span className="text-muted font-weight-bold font-size-base mr-1">{this.props.name === "other" ? "Файл(ы):" : "Файл:"}</span>
                <span
                    className={`${this.state.elemError ? "text-light" : (chosenFile ? "" : "text-dark-50")}  font-weight-bolder font-size-base mr-3 text-nowrap`}
                    title={this.props.value?.name}
                >
                    {
                        this.props.value?.name ? HelpFunctions.cutLongString(this.props.value?.name, 30, true)
                        : this.props.value?.length > 0 ? `Выбрано файлов: ${this.props.value.length} шт.`
                                :"Не выбрано"
                    }
                </span>

                <input type="file" name={this.props.name}
                       id={`input-${this.props.name}`}
                       accept={this.props.accept}
                       onChange={(e) => {
                           this.selectFiles(e)
                       }}
                       ref={this.props.elemRef}
                       multiple={this.props.multiple}
                       style={{display: "none"}}
                />
                <span className="form-text text-muted mt-4">Максимальный размер файла - 250Мб</span>
            </div>
        );
    }

    render() {
        return this.state.drag
            ? this.dragActiveRender()
            : this.dragNotActiveRender();
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {
    setModalData
}
export default connect(mapStateToProps, mapDispatchToProps)(DropFileComponent);
