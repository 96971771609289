import {__} from "../../../tools/HelpFunctions";
import {store} from "../../../index";
import {addToEndUrl, apiUrl} from "../../../tools/API/apiUrl";
import {apiRequest} from "../../../tools/API/apiRequest";
import {APP_ACTIONS, AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {SEARCH_PREVIEW_ACTIONS, SEARCH_TEMPORARY_DATA} from "../searchActionsList";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {DOCUMENT_PREVIEW_ACTIONS} from "../../documents/documentActionsList";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {API, baseUrl} from "../../../tools/API_NEW/API";
import {DocFilePart} from "../../../tools/API_NEW/ServerTypes";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setLoaderModalData} from "../../globalState/actionCreators/globalState_AppActionCreator";


export const resetDocumentPreviewSearch = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let typeFile = store.getState().search.preview.typeFile
            await dispatch({type : SEARCH_PREVIEW_ACTIONS.RESET, payload : {}});
            if(typeFile){
                await dispatch({type : SEARCH_PREVIEW_ACTIONS.TYPE_File, payload : typeFile});
            }
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сброса данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const createPreviewBySelectDocumentSearch = ({Document = null, VersionIndex = null, Node = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            const {search: {rootState: {activeTab}, searchState: {searchResults}}} = store.getState();
            const inputDocIndex = searchResults.findIndex(item => item.Id === activeTab.Id);
            if(Document == null) {
                Document = __.deepCopy(searchResults[inputDocIndex].mainTable.activeRow);
            }

            let VersionId = Document.info.IdVersion;
            let DocId = Document.info.Id;
            // если версии уже были загружены
            // при первой загрузки использовать VersionIndex нельзя
            if(VersionIndex != null) {
                VersionId = __.deepCopy(store.getState().search.additionalSections.state.versionSection.versions[VersionIndex].Id);
                DocId = __.deepCopy(store.getState().search.additionalSections.state.versionSection.versions[VersionIndex].IdDoc);

            }

            let files = [];

            // основной файл предпросмотра изображение
            // files.push({
            //     name : "Предпросмотр",
            //     link : addToEndUrl({
            //         url : apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
            //         collection : [
            //             DocId,
            //             VersionId
            //         ]
            //     }),
            //     type : "image",
            //     description : "",
            //     size : null
            // });

            // получаем файлы
            let request = new apiRequest(apiUrl.DOCUMENTS.GET_BY_VERSION, {
                idDoc: DocId,
                idVersion :  VersionId
            });

            request.execute(function(data) {
                    let loadFiles = data.Records;
                    if (loadFiles.length === 0) {
                        dispatch({
                            type: APP_ACTIONS.SET_MODAL_DATA, payload: {
                                name: ModalTypes.app.info,
                                data: {
                                    content: `Ошибка открытия: файл поврежден`, type: "fail"
                                }
                            }
                        });
                        dispatch({
                            type:APP_ACTIONS.SET_LOADER_MODAL_DATA, payload: {
                                keyDeleted: "search_PreviewActionCreator87"
                            }
                        })
                        return false
                    }
                    // сохраняем во временные файлы
                    dispatch({type: SEARCH_TEMPORARY_DATA.SAVE, payload: {
                            name : "SearchDocumentFiles",
                            data : loadFiles
                        }});

                    // id атрибутов
                    // 1 - содержание документа
                    // 2 - файл предпросмотра
                    // 3 - файл для печати
                    // 4 - прикрепленные файлы

                    // основной файл предпросмотра pdf
                    let previewPdf = loadFiles.find(file => file.AttributeName.Id === 2);
                    if(previewPdf) {
                        files.push({
                            id : previewPdf.Id,
                            name : previewPdf.Name,
                            originalLink: addToEndUrl({
                                url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                collection: [
                                    previewPdf.Id,
                                    VersionId,
                                    "false" // для просмотра (true - для загрузки)
                                ]
                            }),
                            previewLink: addToEndUrl({
                                url: apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                                collection: [
                                    DocId,
                                    VersionId
                                ]
                            }),
                            // link : addToEndUrl({
                            //     url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                            //     collection : [
                            //         previewPdf.Id,
                            //         VersionId,
                            //         "false" // для просмотра (true - для загрузки)
                            //     ]
                            // }),
                           // link : `${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${DocId}&part=1&isAttachment=false`,
                            type : "pdf",
                            description : "Оригинал",
                            size : previewPdf.Size
                        });
                    }



                    // прикрепленные файлы
                    let otherFiles = loadFiles.filter(file => file.AttributeName.Id === 4);
                    if(otherFiles) {
                        for(let i = 0; i < otherFiles.length; i++) {
                            if(otherFiles[i].Name.toLowerCase().indexOf(".pdf") > 0) {
                                files.push({
                                    id : otherFiles[i].Id,
                                    name : otherFiles[i].Name,
                                    previewLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=1&isAttachment=${false}`,
                                    originalLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=0&isAttachment=${false}`,
                                    // link : addToEndUrl({
                                    //     url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                    //     collection : [
                                    //         otherFiles[i].Id,
                                    //        // VersionId,
                                    //         DocId,
                                    //         "false" // false - для просмотра (true - для загрузки)
                                    //     ]
                                    // }),
                                    // type : "pdf",
                                    type: "image",
                                    description : otherFiles[i].Description,
                                    size : otherFiles[i].Size,
                                    isAttach: true,
                                });
                            }
                        }
                    }

                    dispatch({type : SEARCH_PREVIEW_ACTIONS.CREATE_BY_NEW_OPEN_DOC, payload : files});//TODO tut
                    if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
                },
                function(data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    if(data.status === 400) {
                        dispatch(setLoaderModalData({keyDeleted: "search_PreviewActionCreator175"}))
                        dispatch({type: APP_ACTIONS.SET_MODAL_DATA, payload: {
                                name : ModalTypes.app.info,
                                data : {content : data.responseJSON.Message, type : "fail"}
                            }})
                        return data.status

                    }
                    console.log("Ошибка получения файлов для предпросмотра (post): ", data);
                });
        } catch (exception) {
            console.log("Ошибка сбора данных для предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const selectViewActiveFileForPreview = ({View = null}) => {
    return async dispatch => {
        try {
            dispatch({type : SEARCH_PREVIEW_ACTIONS.SELECT_VIEW_FOR_ACTIVE_FILE, payload : View});
        } catch (exception) {
            console.log("Ошибка сброса данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setFilesContent = ({document = null}) => {
    return async dispatch => {
        try {
            if(document) {
                let content;
                if(document.isAttach) {
                    content = await API.documents().getCurrentFileText({id: document.id, part: DocFilePart.Text, isAttachment: false});
                } else {
                    content = await API.documents().getContentForCurrentFile({idDoc: document.Id, idVersion: document.Version.Id})
                }
                dispatch({type: SEARCH_PREVIEW_ACTIONS.SET_FILE_CONTENT, payload: content});

            }
        } catch (exception) {
            console.log("Ошибка сбора данных для предпросмотра (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}

// сбор данных для отображения в дополнительных разделах
// при обычном просмотре (при просмотре карточки документов другая функция: _Cards)
// VersionIndex -> активная версия
export const collectDataForAdditionalSectionsSearch_Normal = ({VersionIndex = null, Next = null, Error = null}) => {
    return async (dispatch) => {
        try {
            const {search: {searchState: {searchResults, searchInputsList}, rootState: {activeTab}}} = store.getState();
            let documentInfo = searchResults.find(item => item.Id === activeTab.Id)?.mainTable.activeRow;
            let Version = documentInfo.info;
            let VersionId = documentInfo.info.IdVersion;
            let DocId = documentInfo.info.Id;
            if(VersionIndex != null) {
                Version = store.getState().search.additionalSections.state.versionSection.versions[VersionIndex];
                VersionId = __.deepCopy(store.getState().search.additionalSections.state.versionSection.versions[VersionIndex].Id);
                DocId = __.deepCopy(store.getState().search.additionalSections.state.versionSection.versions[VersionIndex].IdDoc);
            }
            let result = {
                attributesSection : documentInfo.AllAttributes,
                contentSection : "",
                filesSection : [],
                versionSection : [],
                documentPackagesSection : [],
                activeVersionIndex : VersionIndex ? VersionIndex : 0,
            };

            let files = searchResults.find(item => item.Id === activeTab.Id)?.temporaryData.SearchDocumentFiles;

            files = files.filter(file => file.AttributeName.Id === 4 || file.AttributeName.Id === 3);
            if(files) {
                for(let i = 0; i < files.length; i++) {
                    result.filesSection.push({
                        id : files[i].Id,
                        name : files[i].Name,
                        link : addToEndUrl({
                            url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                            collection : [
                                files[i].Id,
                                VersionId,
                                "true" // false - для просмотра (true - для загрузки)
                            ]
                        }),
                        type : files[i].Name.toLowerCase().indexOf(".pdf") > 0 ? "pdf" : "image",
                        description : files[i].Description,
                        size : files[i].Size
                    });
                }
            }

            const inputsList = searchInputsList.find(item => item.Id === activeTab.Id);


            let request_content = new apiRequest(apiUrl.SEARCH.SEARCH_BY_PARAMS_IN_FULLTEXT, {
                idDoc :  DocId,
                idVersion : VersionId,//?????
                searchParams : {
                    IdFilial: inputsList?.IdFilial,
                    PagingFilter:
                        {
                            PageSize: 25
                        }, //по дефолту 25
                    SearchText: inputsList.SearchText,//первое поле
                    AttributesText: inputsList.AttributesText,//поиск по атрибутам
                    FullText: inputsList.FullText,//поиск по полнотексту
                    IsSearchInAttachments: inputsList.IsSearchInAttachments, //искать в тексте прикрепленных файлов
                    IsSearchInVersions: inputsList.IsSearchInVersions, // искать в версиях
                    IsSearchInMarkedForDelete: inputsList.IsSearchInMarkedForDelete, // искать в помеченных на удаление

                }
            });

            let request_versions = new apiRequest(apiUrl.DOCUMENTS.GET_ALL_VERSIONS, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocVersion")
                            .add({Name: "IdDoc", Value: documentInfo.info.Id})//TODO тут косяк idDoc
                            .get()
                    ).get(),
                sorting : null
            });

            let request_packages = new apiRequest(apiUrl.PACKAGE.LOAD, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("PackageDocRef")
                            .add({Name: "IdDoc", Value: DocId})
                            .addAttribute({Name : "ReferenceProperty", Value : "IdPackage"})
                            .addAttribute({Name : "RootEntityProperty", Value : "Id"})
                            .get()
                    ).add(new EntityFilters().setEntityName("DocPackage").get()).get(),
                sorting : null
            });

            let request_departments_refs = new apiRequest(apiUrl.DOCUMENTS.GET_DEPARTMENTS_REFS, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocDepartmentRef")
                            .add({Name: "IdDoc", Value: DocId})
                            .get()
                    ).get(),
                sorting : null
            });

            request_content.execute(function (data) {

                    result.contentSection = data[0].FullText === ""
                        ? "Содержание отсутствует"
                        : data[0].FullText;//TODO пределать на цикл когда будем выводить содержание разных файло

                    request_versions.execute(function (data) {
                        result.versionSection = data.Records.reverse();
                        request_departments_refs.execute(function (data) {
                            result.departmentsSection = data.Records.reverse();

                            request_packages.execute(function (data) {
                                result.documentPackagesSection = data.Records;
                                // TODO: выяснить что нахрен за if и для чего он нужен
                                    let request_attributes = new apiRequest(apiUrl.SEARCH.SEARCH_BY_PARAMS_IN_ATTRIBUTES,
                                        {
                                            idDoc:DocId,
                                            idVersion:VersionId,
                                            searchParams : {
                                                IdFilial: inputsList?.IdFilial,
                                                PagingFilter:
                                                    {
                                                        PageSize: 25
                                                    }, //по дефолту 25
                                                SearchText: inputsList?.SearchText,//первое поле
                                                AttributesText: inputsList?.AttributesText,//поиск по атрибутам
                                                FullText: inputsList?.FullText,//поиск по полнотексту
                                                IsSearchInAttachments: inputsList?.IsSearchInAttachments, //искать в тексте прикрепленных файлов
                                                IsSearchInVersions: inputsList?.IsSearchInVersions, // искать в версиях
                                                IsSearchInMarkedForDelete: inputsList?.IsSearchInMarkedForDelete, // искать в помеченных на удаление
                                            }
                                        });

                                    request_attributes.execute(function (data) {
                                        result.attributesSection = [];
                                        for (let i = 0; i < data.length; i++) {
                                            result.attributesSection.push({
                                                Name : "PropertyName",
                                                Value : data[i].AttributeValue !== "" ? data[i].AttributeValue : "-",
                                                IdAttributeName : data[i].AttributeName.Id,
                                                IdAttributeType : data[i].AttributeName.IdAttributeType,
                                                AttributeName : "SortingAttributeValue",
                                                ColumnName : data[i].AttributeName.Name,
                                                IsConstant : false
                                            });
                                        }
                                        dispatch({type : SEARCH_PREVIEW_ACTIONS.COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL, payload : result});
                                        if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
                                        },
                                        function (data) {
                                        if(data.status === 401) {
                                            dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                        }
                                        console.log("Ошибка получения атрибутов документа по версии (post): ", data);
                                    });
                                    dispatch({type : SEARCH_PREVIEW_ACTIONS.COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL, payload : result});
                                },
                                function (data) {
                                    if(data.status === 401) {
                                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                    }
                                    console.log("Ошибка получения пакетов документа (post): ", data);
                                });
                        },
                        function (data) {
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            console.log("Ошибка получения версий документа (post): ", data);
                        });
                },
                function (data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    console.log("Ошибка получения содержания документа (post): ", data);
                });
            },
            function (data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения пакетов документа (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка сбора данных для дополнительного раздела (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// выбор активного (показанного) файла для предпросмотра
// Index - номер файла в коллекции файлов предпросмотра
export const selectActiveFileForPreviewSearch = ({Type="image",Index = 0, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_FILE, payload : {Type,Index}});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сброса данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const selectActiveFileForPreviewSearch_Card = ({CardId, ActiveFileIndex, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS, payload : {
                    CardId,
                    ActiveFileIndex
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка выбора активной карточки документа (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}




// открытие или закрытие дополнительных разделов
// Name - название раздела
// Status - true/false открыт или закрыт раздел
export const toggleAdditionalSectionsOpenSearch = ({Name, Status = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(Status == null) {
                Status = !store.getState().search.additionalSections.state[Name].isOpen;

            }
            dispatch({type : SEARCH_PREVIEW_ACTIONS.TOGGLE_ADDITIONAL_SECTIONS_OPEN, payload : {
                    name : Name,
                    isOpen : Status,
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения isOpen дополнительных разделов (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}






///////////////карточка





// собираем данные для отображения в предпросмотре в режиме карточки документа
// CardId -> какую карточку обновляем (если Null то создаем новую)
// VersionIndex -> для уже загруженных версий, при смене версии для просмотра
// TODO: потом переписать эту функцию в нормальный вид
export const createDocumentCardsSearch = ({IdDoc, IdVersion,  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            // Node.parent.info.Id -> это IdDocGroup
            // Node.info.Id -> это PropertyName: "Type.Id"
            let IdFilial = store.getState().globalState.filial.Active.Id;
            // let IdDocGroup = TreeCombine.searchByTreeId({
            //     treeId : store.getState().document.tree.activeNode.parent
            // }).info.Id;
            // имя папки
            // let DocGroupName = TreeCombine.searchByTreeId({
            //     treeId : store.getState().document.tree.activeNode.parent
            // }).info.Name;
            // let TypeId = store.getState().document.tree.activeNode.info.Id;
            // // имя документа в папке
            // let TypeName = store.getState().document.tree.activeNode.info.Name;

            let card = {
                Id : createUniqueIdString(6),
                IdDoc : IdDoc,
                IdLastVersion : IdVersion,
                IdActiveVersion : IdVersion,
                IdFilial : IdFilial,
                // IdDocGroup : IdDocGroup,
                //TypeId : TypeId,
               // DocGroupName : DocGroupName,
                //TypeName : TypeName,
                name : "",
                number : "",
                preview : {
                    isReady : false,
                    files : [],
                    activeFileIndex : 0
                },
                additionalSections:  {
                    isReady : false,
                    attributesSection : {
                        isOpen : true,
                        attributes : []
                    },
                    documentPackagesSection : {
                        isOpen : false,
                        packages : []
                    },
                    versionSection : {
                        isOpen : true,
                        versions : [],
                        activeVersionIndex : 0,
                    },
                    contentSection : {
                        isOpen : false,
                        content : "Содержание отсутствует"
                    },
                    filesSection : {
                        isOpen : false,
                        files : []
                    }
                }
            };


            // __START__ файлы предпросмотра
            let files = [];
            // основной файл предпросмотра изображение
            files.push({
                name : "Предпросмотр",
                link : addToEndUrl({
                    url : apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                    collection : [
                        IdDoc,
                        IdVersion
                    ]
                }),
                type : "image",
                description : "",
                size : null
            });

            // получаем файлы
            let request_files = new apiRequest(apiUrl.DOCUMENTS.GET_BY_VERSION, {
                idDoc: IdDoc,
                idVersion :  IdVersion
            });

            request_files.execute(function(data) {
                    let loadFiles = data.Records;

                    // id атрибутов
                    // 1 - содержание документа
                    // 2 - файл предпросмотра
                    // 3 - файл для печати
                    // 4 - прикрепленные файлы

                    // основной файл предпросмотра pdf
                    let previewPdf = loadFiles.find(file => file.AttributeName.Id === 2);

                    if(previewPdf) {
                        files.push({
                            id : previewPdf.Id,
                            name : previewPdf.Name,
                            link : addToEndUrl({
                                url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                collection : [
                                    previewPdf.Id,
                                    IdVersion,
                                    "false" // для просмотра (true - для загрузки)
                                ]
                            }),
                            type : "pdf",
                            description : "Оригинал",
                            size : previewPdf.Size,
                            AttributeName : previewPdf.AttributeName,
                        });
                    }


                    // прикрепленные файлы
                    let otherFiles = loadFiles.filter(file => file.AttributeName.Id === 4);
                    if(otherFiles) {
                        for(let i = 0; i < otherFiles.length; i++) {
                            if(otherFiles[i].Name.toLowerCase().indexOf(".pdf") > 0) {
                                files.push({
                                    id : otherFiles[i].Id,
                                    name : otherFiles[i].Name,
                                    link : addToEndUrl({
                                        url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                        collection : [
                                            otherFiles[i].Id,
                                            IdVersion,
                                            "false" // false - для просмотра (true - для загрузки)
                                        ]
                                    }),
                                    type : "pdf",
                                    description : otherFiles[i].Description,
                                    size : otherFiles[i].Size,
                                    AttributeName : otherFiles[i].AttributeName,
                                });
                            }
                        }
                    }



                    card.preview.files = files;
                    card.preview.isReady = true;
                    // __END__ файлы предпросмотра

                    // __START__ файлы дополнительной секции
                    files = loadFiles.filter(file => file.AttributeName.Id === 4 || file.AttributeName.Id === 3);
                    if(files) {
                        for(let i = 0; i < files.length; i++) {
                            card.additionalSections.filesSection.files.push({
                                id : files[i].Id,
                                name : files[i].Name,
                                link : addToEndUrl({
                                    url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                    collection : [
                                        files[i].Id,
                                        IdVersion,
                                        "true" // false - для просмотра (true - для загрузки)
                                    ]
                                }),
                                type : files[i].Name.toLowerCase().indexOf(".pdf") > 0 ? "pdf" : "image",
                                description : files[i].Description,
                                size : files[i].Size,
                                AttributeName : files[i].AttributeName,
                            });
                        }
                    }
                    // __END__ файлы дополнительной секции

                    // __START__ доп секция content

                    let request_content = new apiRequest(apiUrl.SEARCH.SEARCH_BY_PARAMS_IN_FULLTEXT, {
                        idDoc :  IdDoc,
                        idVersion : IdVersion,//?????
                        searchParams : {
                            IdFilial: store.getState().search.mainTable.inputField.IdFilial,
                            PagingFilter:
                                {
                                    PageSize: 25
                                }, //по дефолту 25
                            SearchText: store.getState().search.mainTable.inputField.SearchText,//первое поле
                            AttributesText: store.getState().search.mainTable.inputField.AttributesText,//поиск по атрибутам
                            FullText: store.getState().search.mainTable.inputField.FullText,//поиск по полнотексту
                            IsSearchInAttachments: store.getState().search.mainTable.inputField.IsSearchInAttachments, //искать в тексте прикрепленных файлов
                            IsSearchInVersions: store.getState().search.mainTable.inputField.IsSearchInVersions, // искать в версиях
                            IsSearchInMarkedForDelete: store.getState().search.mainTable.inputField.IsSearchInMarkedForDelete, // искать в помеченных на удаление

                        }
                    });

                    request_content.execute(function(data){
                           card.additionalSections.contentSection.content = data[0].FullText === ""
                                ? "Содержание отсутствует"
                                : data[0].FullText;
                            // __END__ доп секция content

                            // __START__ доп секция versions
                            let request_versions = new apiRequest(apiUrl.DOCUMENTS.GET_ALL_VERSIONS, {
                                filter : new Filter(FiltersType.ENTITY)
                                    .add(
                                        new EntityFilters().setEntityName("DocVersion")
                                            .add({Name: "IdDoc", Value: IdDoc})
                                            .get()
                                    ).get(),
                                sorting : null
                            });

                            request_versions.execute(function (data) {
                                    card.additionalSections.versionSection.versions = data.Records.reverse();
                                    // __END__ доп секция versions

                                    // __START__ доп секция packages
                                    let request_packages = new apiRequest(apiUrl.PACKAGE.LOAD, {
                                        filter : new Filter(FiltersType.ENTITY)
                                            .add(
                                                new EntityFilters().setEntityName("PackageDocRef")
                                                    .add({Name: "IdDoc", Value: IdDoc})
                                                    .addAttribute({Name : "ReferenceProperty", Value : "IdPackage"})
                                                    .addAttribute({Name : "RootEntityProperty", Value : "Id"})
                                                    .get()
                                            ).add(new EntityFilters().setEntityName("DocPackage").get()).get(),
                                        sorting : null
                                    });

                                    request_packages.execute(function (data) {
                                            card.additionalSections.documentPackagesSection.packages = data.Records;
                                            // __END__ доп секция packages

                                            // __START__ под секция атрибуты //Todo///////////////////////

                                            let request_attributes = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                                                filter : new Filter(FiltersType.ENTITY)
                                                    //.addAttribute({Name: "IdDocGroup", Value: IdDocGroup})
                                                    .addAttribute({Name: "PagingFilter", Value: {PageSize : 1, PageOffset : 0}})
                                                    .add(
                                                        new EntityFilters().setEntityName("DocExtended")
                                                            //.add({Name: "Type.Id", Value: TypeId})
                                                            .add({Name: "IdFilial", Value: IdFilial})
                                                            .add({Name: "Id", Value: IdDoc})
                                                            .add({Name: "Version.Id", Value: IdVersion /*VersionId*/}).get()
                                                    ).get(),
                                                sorting : new Sorting()
                                                    .addAttribute({Name: "IdAttributeName", Value: "-1"})
                                                    .add({PropertyName: "RegDate", Direction: 2})
                                                    .add({PropertyName: "RegNumber", Direction: 1})
                                                    .add({PropertyName: "Id", Direction: 1})
                                                    .get()
                                            });

                                            request_attributes.execute(function(data) {

                                                    let loadDoc = data.Records[0];


                                                    card.additionalSections.attributesSection.attributes.push({
                                                        Name : "PropertyName",
                                                        Value : loadDoc.Name,
                                                        IdAttributeName : null,
                                                        AttributeName : "Name",
                                                        ColumnName : "Наименование",
                                                        IsConstant : true
                                                    });

                                                    card.additionalSections.attributesSection.attributes.push({
                                                        Name : "PropertyName",
                                                        Value : loadDoc.RegNumber,
                                                        IdAttributeName : null,
                                                        AttributeName : "RegNumber",
                                                        ColumnName : "Номер",
                                                        IsConstant : true
                                                    });

                                                    card.additionalSections.attributesSection.attributes.push({
                                                        Name : "PropertyName",
                                                        Value : loadDoc.RegDate,
                                                        IdAttributeName : null,
                                                        AttributeName : "RegDate",
                                                        ColumnName : "Дата",
                                                        IsConstant : true
                                                    });

                                                    card.additionalSections.attributesSection.attributes.push({
                                                        Name : "PropertyName",
                                                        Value : loadDoc.Version.ModifyTime,
                                                        IdAttributeName : null,
                                                        AttributeName : "Version.ModifyTime",
                                                        ColumnName : "Последнее изменение",
                                                        IsConstant : true
                                                    });

                                                    // for (let i = 0; i < loadDoc.Attributes.length; i++) {
                                                    //     card.additionalSections.attributesSection.attributes.push({
                                                    //         Name : "PropertyName",
                                                    //         Value : loadDoc.Attributes[i].AttributeValue !== "" ? loadDoc.Attributes[i].AttributeValue : "-",
                                                    //         IdAttributeName : loadDoc.Attributes[i].AttributeName.Id,
                                                    //         IdAttributeType : loadDoc.Attributes[i].AttributeName.IdAttributeType,
                                                    //         AttributeName : "SortingAttributeValue",
                                                    //         ColumnName : loadDoc.Attributes[i].AttributeName.Name,
                                                    //         IsConstant : false
                                                    //     });
                                                    // }
                                                    // __END__ под секция атрибуты

                                                    let request_attributes_highlight = new apiRequest(apiUrl.SEARCH.SEARCH_BY_PARAMS_IN_ATTRIBUTES,
                                                        {
                                                            idDoc:IdDoc,
                                                            idVersion:IdVersion,
                                                            searchParams : {
                                                                IdFilial: store.getState().search.mainTable.inputField.IdFilial,
                                                                PagingFilter:
                                                                    {
                                                                        PageSize: 25
                                                                    }, //по дефолту 25
                                                                SearchText: store.getState().search.mainTable.inputField.SearchText,//первое поле
                                                                AttributesText: store.getState().search.mainTable.inputField.AttributesText,//поиск по атрибутам
                                                                FullText: store.getState().search.mainTable.inputField.FullText,//поиск по полнотексту
                                                                IsSearchInAttachments: store.getState().search.mainTable.inputField.IsSearchInAttachments, //искать в тексте прикрепленных файлов
                                                                IsSearchInVersions: store.getState().search.mainTable.inputField.IsSearchInVersions, // искать в версиях
                                                                IsSearchInMarkedForDelete: store.getState().search.mainTable.inputField.IsSearchInMarkedForDelete, // искать в помеченных на удаление

                                                            }
                                                        });
                                                    request_attributes_highlight.execute(function (data_search) {

                                                        for (let i = 0; i < loadDoc.Attributes.length; i++) {


                                                            let check = false;

                                                            for(let j = 0; j < data_search.length; j++) {

                                                                if(loadDoc.Attributes[i].AttributeName.Id === data_search[j].AttributeName.Id) {
                                                                    card.additionalSections.attributesSection.attributes.push({
                                                                        Name : "PropertyName",
                                                                        Value : (data_search[i].AttributeValue === "" || data_search[i].AttributeValue === null) ? "-" : data_search[i].AttributeValue,
                                                                        IdAttributeName : data_search[i].AttributeName.Id,
                                                                        IdAttributeType : data_search[i].AttributeName.IdAttributeType,
                                                                        AttributeName : data_search[i].AttributeName,
                                                                        ColumnName : data_search[i].AttributeName.Name,
                                                                        IsConstant : false
                                                                    });
                                                                   check = true;
                                                                }
                                                            }


                                                            if(check) continue;

                                                            card.additionalSections.attributesSection.attributes.push({
                                                                Name : "PropertyName",
                                                                Value : (loadDoc.Attributes[i].AttributeValue === "" || loadDoc.Attributes[i].AttributeValue === null) ? "-" : loadDoc.Attributes[i].AttributeValue,
                                                                IdAttributeName : loadDoc.Attributes[i].AttributeName.Id,
                                                                IdAttributeType : loadDoc.Attributes[i].AttributeName.IdAttributeType,
                                                                AttributeName : "SortingAttributeValue",
                                                                ColumnName : loadDoc.Attributes[i].AttributeName.Name,
                                                                IsConstant : false
                                                            });
                                                        }


                                                        card.name = loadDoc.Name;
                                                        card.number = loadDoc.RegNumber;
                                                        card.IdVersion = loadDoc.Version.Id;
                                                        card.additionalSections.isReady = true;

                                                        dispatch({type : DOCUMENT_PREVIEW_ACTIONS.CREATE_DOC_CARDS, payload : card});
                                                        if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

                                                    }, function (data){if(data.status === 401) {
                                                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                                    }
                                                        console.log("Ошибка получения атрибутов документа по версии (post): ", data);
                                                    });



                                                },
                                                function (data) {
                                                    if(data.status === 401) {
                                                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                                    }
                                                    console.log("Ошибка получения атрибутов документа по версии (post): ", data);
                                                });
                                        },
                                        function(data){
                                            if(data.status === 401) {
                                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                            }
                                            console.log("Ошибка получения пакетов документа (post): ", data);
                                        });
                                },
                                function(data) {
                                    if(data.status === 401) {
                                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                    }
                                    console.log("Ошибка получения версий документа (post): ", data);
                                });
                        },
                        function(data){
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            console.log("Ошибка получения содержания документа (post): ", data);
                        });
                },
                function(data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    console.log("Ошибка получения файлов для предпросмотра (post): ", data);
                });
        } catch (exception) {
            console.log("Ошибка сбора данных для карточки документа (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
// выбираем активную карточку документа
// CardId - если = null, то сбрасываем активную карточку документа
export const selectActiveDocumentSearchCard = ({CardId = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_DOC_CARDS, payload : CardId});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка выбора активной карточки документа (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
