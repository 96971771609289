import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {applyMiddleware, compose, createStore} from "redux";
import {Provider} from "react-redux";
import reportWebVitals from './reportWebVitals';
import thunk from "redux-thunk";

import "bootstrap/dist/css/bootstrap.min.css";

import "./media/styles/font-icon/style.css";

import "./media/styles/css_dp/main.css";
import "./media/styles/css_dp/size.css";
import "./media/styles/css_dp/normalize.css";
// import "./media/styles/css_dp/color_original.css";
import "./media/styles/css_dp/nav.css";
import "./media/styles/css_dp/button.css";
import "./media/styles/css_dp/form.css";
import "./media/styles/css_dp/aside-menu.css";
import "./media/styles/css_dp/symbol.css";
import "./media/styles/css_dp/card.css";
import "./media/styles/css_dp/table.css";
import "./media/styles/css_dp/checkbox.css";
import "./media/styles/css_dp/bg_theme_original.css";
import "./media/styles/css_dp/modal.css";
import "./media/styles/css_dp/accordion.css";
import "./media/styles/css_dp/context_menu.css";
import "./media/styles/css_dp/element-color.css";
import "./media/styles/css_dp/menu-tree.css";
import "./media/styles/css_dp/beautifulScroll.css";

import 'react-calendar/dist/Calendar.css';

// import "./media/styles/pagination.module.css";
 import "./media/styles/css_dp/packet.css";

import "./media/styles/serverStyle.css";

import {ConnectedRouter, routerMiddleware} from 'connected-react-router';
import { createBrowserHistory } from 'history'

import {rootReducer} from "./store/rootReducer";
import {ScreenResolution} from "./tools/ScreenResolution";
// import { Route, Switch } from 'react-router'
import {enableAllPlugins} from "immer";
import moment from "moment";
import {Routing} from "./tools/routing/Routing";
import {RouterMiddleware} from "./store/middleware/routerMiddleware";

import {ColorThemeController} from "./tools/theme/ColorThemeController";
enableAllPlugins();

// установили локаль в русский язык для отображения
// времени в правильном формате
moment.locale("ru");



export const history = createBrowserHistory(); // для маршрутизации
let storeCreator;
if(window.__REDUX_DEVTOOLS_EXTENSION__) {
    storeCreator = createStore(
        rootReducer(history),
        compose(
            applyMiddleware(
                thunk,
                routerMiddleware(history), // для маршрутизации
                RouterMiddleware
            ),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true,traceLimit: 100,}), // TODO: Чтобы показать - комментим
        ));
}
else {
    storeCreator = createStore(
        rootReducer(history),
        compose(
            applyMiddleware(
                thunk,
                routerMiddleware(history), // для маршрутизации
                RouterMiddleware
            )
        ));
}
export const store = storeCreator;


export const appScreenResolution = new ScreenResolution(); // разрешение экрана
export const AppRouter = new Routing();

const theme = JSON.parse(localStorage.getItem('color_theme'));
export const ColorTheme = new ColorThemeController(theme ?? "");

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
)

// ReactDOM.render(
//     <Provider store={store}>
//         <ConnectedRouter history={history}>
//             <App/>
//         </ConnectedRouter>
//     </Provider>,
//     document.getElementById('root')
// )

// ReactDOM.render(
//     <Provider store={store}>
//         <ConnectedRouter history={history}>
//             <Switch>
//                 <Route exact path="/" render={() => (<App/>)} />
//                 <Route render={() => (<App/>)} />
//             </Switch>
//         </ConnectedRouter>
//     </Provider>,
//     document.getElementById('root')
// )


reportWebVitals();