import React from "react";
import {connect} from "react-redux";
import {ActionQueue} from "../../../store/rootReducer";
import {
    combineMainTableAttributes_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    resetMainTableForUpdate_packagesDocumentRight,
    setPaginationPageSize_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import PaginationSelect from "../../PaginationSelect";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";


class PaginationCounterPackagesDocumentsRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.change = this.change.bind(this);
    }

    change(value) {
        let newSize = value;

        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents"}
        // });

        //TODO ????? зачем лишний пледоадер, есть же потом в очереди
        // //Новый прелоадер
        // store.dispatch(setLoaderModalData({
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "PaginationCounterPackagesDocumentsRight37" }
        // }));

        this.props.ActionQueue({
            List : [
                //Новый прелоадер
                {action: this.props.setLoaderModalData, params : {
                                                            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key: "PaginationCounterPackagesDocumentsRight44"}},
                    name: "setLoaderModalData"},
                {action: this.props.resetMainTableForUpdate_packagesDocumentRight, params : {}, name: "resetMainTableForUpdate_packagesDocumentRight"},
                {action: this.props.setPaginationPageSize_packagesDocumentRight, params : { PaginationPageSize : newSize}, name: "setPaginationPageSize_packagesDocumentRight"},
                {action: this.props.mainTableDownloadDocCollection_packagesDocumentRight, params : {}, name: "mainTableDownloadDocCollection_packagesDocumentRight"},
                {action: this.props.combineMainTableAttributes_packagesDocumentRight, params: {}, name : "combineMainTableAttributes_packagesDocumentRight"},
                {action: this.props.setLoaderModalData, params: {keyDeleted: "PaginationCounterPackagesDocumentsRight44"}, name : "setLoaderModalData"},
            ],
            debug : true
        });
    }


    render() {

        let {activePage, paginationPageSize, totalItemsCount} = this.props;

        let from = ((activePage - 1) * paginationPageSize) + 1;
        let to = ((Number.parseInt((activePage - 1) ) * paginationPageSize)) + Number.parseInt(paginationPageSize);

        if(to > totalItemsCount) to = totalItemsCount;

        let infoText = "...";
        if(totalItemsCount) {
            if(totalItemsCount <= 0) {
                infoText = (<span className="text-muted">Документов не обнаружено.</span>);
            }
            else {
                infoText = (<span className="text-muted">{from} - {to} из {totalItemsCount}</span>);
            }
        }

        return (
            <PaginationSelect activePage={activePage}
                              infoText={infoText}
                              paginationPageSize={paginationPageSize}
                              change={this.change}
            />
        );
    }
}

const  mapStateToProps = state => {
    return {
        paginationPageSize : state.packages.mainTable.paginationPageSize
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    combineMainTableAttributes_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    resetMainTableForUpdate_packagesDocumentRight,
    setLoaderModalData,
    setModalData,
    setPaginationPageSize_packagesDocumentRight,
}

export default connect(mapStateToProps,mapDispatchToProps)(PaginationCounterPackagesDocumentsRight);
