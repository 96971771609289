import React from "react";
import {connect} from "react-redux";
import {Routing} from "../../../tools/routing/Routing";
import {history} from "../../../index";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";

class DocumentPackagesSectionPackages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen : false
        };

        this.toggle = this.toggle.bind(this);
    }


    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }


    render() {
        if(!this.props.activeDocument) return null;
        let isReady = this.props.activeDocument.additionalSections.isReady;
        let sectionData = this.props.activeDocument.additionalSections.documentPackagesSection.packages.Records;

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }



        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Пакеты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">

                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-11">Пакет</th>
                                <th className="col-lg-1">Перейти</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.map((item, index) => {

                                    let query = this.props.router.location.query;
                                    let queryLink = "";

                                    let idDoc = this.props.activeDocument.Node.Id;

                                    if(item.Type == 1) {
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : 2, dwp : item.Id, dwd : idDoc},
                                            excludedKeys : ["g", "t", "c", "d", "s", "r"]
                                        });
                                    }
                                    else if(item.Type == 2){
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : item.Id, d : idDoc},
                                            excludedKeys : ["g", "t", "c", "s", "r"]
                                        });
                                    }

                                    let link = `/packages${queryLink}`;

                                    return (
                                        <tr key={item.Id}>
                                            <td>
                                                {item.Name}
                                            </td>
                                            <td>
                                                <span onClick={()=>{
                                                    // this.props.setModalData({
                                                    //     name: ModalTypes.app.alert,
                                                    //     data: {
                                                    //         content: "Загрузка...",
                                                    //         disableButton: true,
                                                    //         fullBackground: true,
                                                    //         gif: "packages"
                                                    //     }
                                                    // });

                                                    //TODO бесконечный прелоадер при переходе в пакет
                                                    //Новый прелоадер
                                                    // store.dispatch(setLoaderModalData({
                                                    //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "DocumentPackagesSectionPackages105" }
                                                    // }));

                                                    history.push(link);
                                                    history.replace(link);
                                                }} className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                    <i className="svg-icon icon-Doc_view_6"/>
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        router : state.router,
    }
}

const  mapDispatchToProps = {
    setModalData
}



export default connect(mapStateToProps, mapDispatchToProps)(DocumentPackagesSectionPackages);
