import React from "react";
import {connect} from "react-redux";
import {
    breadcrumbsMoveSelect, PackageAddNodesTree,
    SelectActivePackageDocument, SelectActivePackageNode, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {Link} from "react-router-dom";
import {setViewModePreviewPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {updateInputsFieldPackage} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {history, store} from "../../../index";
import {TableTypesEnum} from "../../../tools/StaticTypes";
import {ActionQueue} from "../../../store/rootReducer";

class PackagesBreadcrumbs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.moveSelect = this.moveSelect.bind(this);
    }


    async moveSelect(NodeId) {
        if (this.props.tableRole === TableTypesEnum.Download) {
            this.props.updateInputsFieldPackage({
                TableRole: this.props.tableRole,
                RegDateAsString: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                Name: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                RegNumber: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                TypeName: {In: ''},
                ModifyDate: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                AppliedParams: {
                    RegDateAsString: false,
                    Name: false,
                    RegNumber: false,
                    TypeName: false,
                    ModifyDate: false,
                },
                // IsSearch: false
                Next: {
                    action: () => {
                        //Новый прелоадер
                        this.props.setLoaderModalData({
                            data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: true,
                                gif: "packages",
                                key: "PackagesBreadcrumbs49"
                            },
                            Next: {
                                action: () => {
                                    this.props.breadcrumbsMoveSelect({
                                        NodeId,
                                        TableType: this.props.tableRole,
                                        IsDownload: true,
                                        Next: {
                                            action: () => {
                                                this.props.SelectActivePackageDocument({
                                                    TableType: this.props.tableRole,
                                                    Next: {
                                                        action: async () => {
                                                            for (const crumb of this.props.breadcrumbsDownload) {
                                                                await this.props.PackageAddNodesTree({
                                                                    Id: crumb.NodeId,
                                                                    IsDownload: true
                                                                })
                                                                await this.props.SelectActivePackageNode({
                                                                    Id: crumb.NodeId,
                                                                    IsDownload: true,
                                                                });

                                                                if (!store.getState().packages.treeDownload.openedFolders.includes(crumb.NodeId))
                                                                    this.props.togglePackagesFolders({
                                                                        IdFolder: crumb.NodeId,
                                                                        TableType: this.props.tableRole,
                                                                    });
                                                                if (NodeId === crumb.NodeId) {
                                                                    break
                                                                }
                                                            }
                                                            // todo TP зачем в конце был прелоадер Загрузка? бесконечно крутится
                                                            //Новый прелоадер
                                                            this.props.setLoaderModalData({keyDeleted: "PackagesBreadcrumbs69"});
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            })
        } else {
            let breadcrumbs = this.props.tableRole === TableTypesEnum.Main
                ? this.props.breadcrumbs
                : this.props.breadcrumbsAdditional
            let openedFolders = this.props.tableRole === TableTypesEnum.Main
                ? this.props.openedFolders
                : this.props.openedFoldersAdditional

            this.props.updateInputsFieldPackage({
                TableRole: this.props.tableRole,
                RegDateAsString: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                Name: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                RegNumber: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                TypeName: {In: ''},
                ModifyDate: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                AppliedParams: {
                    RegDateAsString: false,
                    Name: false,
                    RegNumber: false,
                    TypeName: false,
                    ModifyDate: false,
                },
                // IsSearch: false
                Next: {
                    action: () => {
                        //Новый прелоадер
                        this.props.setLoaderModalData({
                            data: {content: "Загрузка...", disableButton: true, fullBackground: true, gif: "packages", key: "PackagesBreadcrumbs125"},
                            Next: {
                                action: () => {
                                    this.props.PackageAddNodesTree({
                                        Id: NodeId,
                                        TableType: this.props.tableRole,
                                        Next: {
                                            action: () => {
                                                this.props.breadcrumbsMoveSelect({
                                                    NodeId,
                                                    TableType: this.props.tableRole,
                                                    Next: {
                                                        action: () => {
                                                            if (this.props.viewModePreview == "none" || this.props.viewModePreview == "normal") {
                                                                this.props.setViewModePreviewPackages({
                                                                    ViewMode: "none",
                                                                    Next: {
                                                                        action: () => {
                                                                            this.props.SelectActivePackageDocument({
                                                                                TableType: this.props.tableRole,
                                                                                Next: {
                                                                                    action: async () => {
                                                                                        for (const crumb of this.props.breadcrumbs) {
                                                                                            await this.props.PackageAddNodesTree({
                                                                                                Id: crumb.NodeId,
                                                                                                TableType: this.props.tableRole,
                                                                                            })
                                                                                            await this.props.SelectActivePackageNode({
                                                                                                Id: crumb.NodeId,
                                                                                                TableType: this.props.tableRole,
                                                                                            });


                                                                                            if (!openedFolders.includes(crumb.NodeId))
                                                                                                this.props.togglePackagesFolders({
                                                                                                    IdFolder: crumb.NodeId,
                                                                                                    TableType: this.props.tableRole,
                                                                                                });

                                                                                            if (NodeId === crumb.NodeId) {
                                                                                                break
                                                                                            }
                                                                                        }
                                                                                        this.props.setLoaderModalData({keyDeleted: "PackagesBreadcrumbs139"});
                                                                                    }
                                                                                }
                                                                            });
                                                                        }
                                                                    }
                                                                });
                                                            } else {
                                                                this.props.setViewModePreviewPackages({
                                                                    ViewMode: this.props.viewModePreview,
                                                                    Next: {
                                                                        action: () => {
                                                                            this.props.SelectActivePackageDocument({
                                                                                TableType: this.props.tableRole,
                                                                                Next: {
                                                                                    action: async () => {

                                                                                        for (const crumb of breadcrumbs) {
                                                                                            await this.props.PackageAddNodesTree({
                                                                                                Id: crumb.NodeId,
                                                                                                TableType: this.props.tableRole,
                                                                                            })
                                                                                            await this.props.SelectActivePackageNode({
                                                                                                Id: crumb.NodeId,
                                                                                                TableType: this.props.tableRole,
                                                                                            });

                                                                                            if (!openedFolders.includes(crumb.NodeId))
                                                                                                this.props.togglePackagesFolders({
                                                                                                    IdFolder: crumb.NodeId,
                                                                                                    TableType: this.props.tableRole,
                                                                                                });

                                                                                            if (NodeId === crumb.NodeId) {
                                                                                                break
                                                                                            }
                                                                                        }
                                                                                        this.props.setLoaderModalData({keyDeleted: "PackagesBreadcrumbs155"});
                                                                                    }
                                                                                }
                                                                            });
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    })
                                }
                            }
                        });
                    }
                }
            })
        }
    }

    render() {
        let breadcrumbs;
        if(this.props.tableRole === "main") breadcrumbs = this.props.breadcrumbs;
        else if(this.props.tableRole === "additional") breadcrumbs = this.props.breadcrumbsAdditional;
        else if(this.props.tableRole === "download") breadcrumbs = this.props.breadcrumbsDownload;

        return (
            <div className="ml-8 beautiful-scroll-5" style={{height : "57px", overflowY:"auto"}}>
                {/*<div className="ml-8 beautiful-scroll-5" >*/}
                <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold mt-4 mb-1 p-0 d-flex align-items-baseline " style={{whiteSpace : "nowrap",width:"5000px"}}>
                    {/*<ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold mt-4 mb-1 p-0 d-flex align-items-baseline ">*/}
                    {
                        breadcrumbs.length > 0 &&
                        breadcrumbs.map((item, index)=>{


                            let query = this.props.router.location.query;
                            let queryLink = "";

                            if(this.props.tableRole === "main") {
                                queryLink = Routing.CreateRef({
                                    oldQuery : query,
                                    newQuery : {p : item.NodeId},
                                    excludedKeys : ["d"]
                                });
                            }
                            else if(this.props.tableRole === "additional") {
                                queryLink = Routing.CreateRef({
                                    oldQuery : query,
                                    newQuery : {p2 : item.NodeId},
                                    excludedKeys : ["d2"]
                                });
                            }
                            else if(this.props.tableRole === "download") {
                                queryLink = Routing.CreateRef({
                                    oldQuery : query,
                                    newQuery : {dwp : item.NodeId},
                                    excludedKeys : ["dwd", "p2", "d2", "dd2", "dt2", "dg2"]
                                });
                            }

                            let link = `${this.props.router.location.pathname}${queryLink}`;
                            return (
                                <Link to={link} key={item.NodeId} className="breadcrumb-item" onClick={() => {this.moveSelect(item.NodeId)}}>
                                        <span className={`text-color-primary ${item.isCurrent ? "font-weight-bolder" : ""}`} title={item.Name}>
                                            {item.isCurrent ? <u>{item.Name}</u> : item.Name}
                                        </span>
                                </Link>
                            );
                            // if(this.props.tableRole === "download"){
                            //     if(item.isCurrent) {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span className="text-color-primary font-weight-bolder" title={item.Name} ><u>{item.Name}</u></span>
                            //             </Link>
                            //         );
                            //     } else {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span className="text-color-primary" title={item.Name}>{item.Name}</span>
                            //             </Link>
                            //         );
                            //     }
                            // }else{
                            //     if(item.isCurrent) {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item text-muted" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span style={{color : "#204BA0"}} title={item.Name} >{HelpFunctions.cutLongString(item.Name, 8, true)}</span>
                            //             </Link>
                            //         );
                            //     } else {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item text-muted" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span className="text-muted" title={item.Name}>{HelpFunctions.cutLongString(item.Name, 8, true)}</span>
                            //             </Link>
                            //         );
                            //     }
                            // }

                        })
                    }
                </ul>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        breadcrumbs : state.packages.tree.breadcrumbs,
        breadcrumbsAdditional : state.packages.treeAdditional.breadcrumbs,
        breadcrumbsDownload : state.packages.treeDownload.breadcrumbs,
        openedFolders : state.packages.tree.openedFolders,
        openedFoldersAdditional : state.packages.treeAdditional.openedFolders,
        openedFoldersDownload : state.packages.treeDownload.openedFolders,
        router : state.router,
        viewModePreview : state.packages.rootState.viewModePreview,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    breadcrumbsMoveSelect,
    PackageAddNodesTree,
    togglePackagesFolders,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    setViewModePreviewPackages,
    setLoaderModalData,
    setModalData,
    updateInputsFieldPackage
}



export default connect(mapStateToProps, mapDispatchToProps)(PackagesBreadcrumbs);
