import React from "react";
import {connect} from "react-redux";
import {attributesSectionsTypes} from "../../tools/StaticTypes";
import {
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards, updateContentRedactorData
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../tools/HelpFunctions";
import parse from "html-react-parser";
import sanitizeHtml  from 'sanitize-html';
const { domToReact } = parse;



class ContentSection extends React.Component {
    constructor(props) {
        super(props);


        this.state = {

        };

        this.sectionName = attributesSectionsTypes.contentSection;


        this.toggle = this.toggle.bind(this);
        this.editHandler = this.editHandler.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpen({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }

    // обработка изменения атрибутов
    editHandler(event) {
       this.props.updateContentRedactorData({
           value : event.target.value
       });
    }

    render() {
        let sectionData;
        let isReady;
        let originalCard = null;
        let originalSectionData = null;
        if(this.props.viewMode === "normal") {
            sectionData = this.props.additionalSectionsData.state[this.sectionName];
            isReady = this.props.additionalSectionsData.isReady;
        }
        else if(this.props.viewMode === "card") {
            let card;
            if(this.props.redactorMode && this.props.cards.redactorData) {
                card = this.props.cards.redactorData;
                originalCard = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            } else {
                card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            }

            if(originalCard) {
                originalSectionData = __.deepCopy(originalCard.additionalSections[this.sectionName]);
            }

            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = this.props.cards.collection
                .find(card => card.Id === this.props.cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }

        let editIcon = null;
        if(originalSectionData) {
            if(originalSectionData.content != sectionData.content) {
                editIcon = (
                    <span className="btn btn-icon btn-sm" title="Внесены изменения"
                          style={{position: "absolute", top: "7px", left: "120px"}}>
                    <i className="svg-icon icon-Edit icon-color-danger"/>
                </span>
                );
            }
        }


        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        let sectionDataText = sanitizeHtml(sectionData.content, {
            allowedAttributes: {span: ["class", "className"]}
        });

        let content = this.props.redactorMode
            ? <textarea onChange={this.editHandler} className="form-control beautiful-scroll-10" rows={7} defaultValue={sectionData.content}/>
            : <pre className="content-text">{parse(sectionDataText)}</pre>;

        // TODO при режиме редактирования и переключения разных файлов из дропдауна в карточке не обновляется содержание

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Содержание
                        {editIcon}
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body content-section p-6">
                        {
                            isReady && content
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        additionalSectionsData : state.document.additionalSections,
        cards : state.document.cards,
    }
}

const  mapDispatchToProps = {
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
    updateContentRedactorData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSection);
