
import {store} from "../../index";
import {UserSettingsActionsTypes, UserSettingsNames} from "../StaticTypes";
import {
    setColorTheme,
    updateUserSettings
} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {darkBlue_theme, lightBlue_theme, red_theme, yellow_theme, azure_theme} from "./themes";

export class ColorThemeController {

    selectedTheme;

    constructor(selectedTheme) {
        this.selectedTheme = selectedTheme ?? {lThemeName: "lightBlue_theme", lThemeType: "light"};
        this.execute(true);
    }

    setTheme(theme) {
        if(JSON.stringify(this.selectedTheme) === JSON.stringify(theme)) return;
        this.selectedTheme = theme ?? {lThemeName: "lightBlue_theme", lThemeType: "light"};
        this.execute(false);
    }

    toggle(name) {
        switch (name) {
            case "darkBlue_theme":
                this.selectedTheme = {lThemeName: "darkBlue_theme", lThemeType: "light"}
                break;
            case "lightBlue_theme":
                this.selectedTheme = {lThemeName: "lightBlue_theme", lThemeType: "light"}
                break;
            case "red_theme":
                this.selectedTheme = {lThemeName: "red_theme", lThemeType: "light"}
                break;
            case "yellow_theme":
                this.selectedTheme = {lThemeName: "yellow_theme", lThemeType: "light"}
                break;
            case "azure_theme":
                this.selectedTheme = {lThemeName: "azure_theme", lThemeType: "light"}
                break;
            default :
                this.selectedTheme = {lThemeName: "lightBlue_theme", lThemeType: "light"}
                break;
        }

        store.dispatch(setColorTheme({Theme: this.selectedTheme}))
        this.execute(false);
    }

    async execute(reloadPage = false) {
        let icon='favicon.ico';
        let style = document.getElementById("color-theme");
        let theme
        switch (this.selectedTheme.lThemeName) {
            case "darkBlue_theme":
                style.innerHTML = "";
                style.appendChild(document.createTextNode(darkBlue_theme));
                theme = {lThemeName: "darkBlue_theme", lThemeType: "light"}
                icon = "favicon.svg"
                break;

            case "lightBlue_theme":
                style.innerHTML = "";
                style.appendChild(document.createTextNode(lightBlue_theme));
                theme = {lThemeName: "lightBlue_theme", lThemeType: "light"}
                icon = "favicon.svg"
                break;

            case "red_theme":
                style.innerHTML = "";
                style.appendChild(document.createTextNode(red_theme));
                theme = {lThemeName: "red_theme", lThemeType: "light"}
                icon = "favicon_red.svg"
                break;

            case "yellow_theme":
                style.innerHTML = "";
                style.appendChild(document.createTextNode(yellow_theme));
                theme = {lThemeName: "yellow_theme", lThemeType: "light"}
                icon = "favicon_one.svg"
                break;

            case "azure_theme":
                style.innerHTML = "";
                style.appendChild(document.createTextNode(azure_theme));
                theme = {lThemeName: "azure_theme", lThemeType: "light"}
                icon = "favicon_azure.svg"
                break;
            default :
                style.innerHTML = "";
                style.appendChild(document.createTextNode(lightBlue_theme));
                theme = {lThemeName: "lightBlue_theme", lThemeType: "light"}
                icon = "favicon.svg"
                break;
        }
        this.selectedTheme = theme
        store.dispatch(setColorTheme({Theme: theme}))

        let head = document.getElementsByTagName('head')[0];

// Найти и удалить старую иконку из тега HEAD
        let links = head.getElementsByTagName('link');
        for (let i = 0; i < links.length; i++) {
            let lnk = links[i];
            if (lnk.rel=='icon') {
                head.removeChild(lnk);
            }
        }

// Добавить новую иконку
        let link = document.createElement('link');
        link.setAttribute('href', `/${icon}`);
        // link.setAttribute('type','image/x-icon');
        link.setAttribute('rel','icon');
        head.appendChild(link);

        if(!reloadPage) {
            await store.dispatch(updateUserSettings({
                Name : UserSettingsNames.THEME_CONFIG,
                Action : UserSettingsActionsTypes.Update,
                Data : {
                    selectedTheme: {
                        lThemeName: this.selectedTheme.lThemeName,
                        lThemeType: "light"
                    }
                }
            }));
        }
    }
}
