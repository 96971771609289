export function createUniqueIdString(len = 6) { // рандомное id строка
    let _abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let abc = _abc + "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let rs = "";
    while (rs.length < len) {
        rs += abc[Math.floor(Math.random() * abc.length)];
    }
    return rs;
}

export function createUniqueIdNumber() { // рандомное id число
    return Math.round((Math.random() + Math.random()) * Math.random() * 1000000) ;
}