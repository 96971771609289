import React from "react";
import {connect} from "react-redux";
import {getReportParams, getReports, setActiveRow} from "../../store/reports/actionCreators/report_LeftMenu";
import {history} from "../../index";

class LeftMenuReports extends React.PureComponent  {

    constructor(props) {
        super(props);

        this.state = {

            isReady:false,
            activeRow: {},


        };

        this.selectReport = this.selectReport.bind(this);
    }

    // search(){
    //
    //     this.props.getReports({})
    //     this.setState({isReady:true});
    // }
    componentWillMount() {

        this.setState({activeRow: this.props.activeRow})

    }


    selectReport(event,item, link){
        this.setState({activeRow:item});
        let idReport = item.Id;
        this.props.getReportParams({IdReport: idReport});
        this.props.setActiveRow({IdReport: idReport});
        history.push(link);
    }

    render() {
// определяем активная строка или нет
//         let trClass = this.state.activeRow && this.state.activeRow.Version.IdDoc === this.state.data.Version.IdDoc
//             ? "tablez2 tr-active noneFocus"
//             : "tablez2 noneFocus";

        // let isReady =this.state.isReady;



        return (

                <div className="card card-custom container-solid" style={{borderRadius: "20px", border: "0.3em solid var(--light-primary)"}}>
                    <div className="card-header">

                        <div className="card-title">
                            <h4 className="card-label text-weight-bolder text-color-primary"
                                title="Отчеты">Отчеты</h4>
                        </div>



                    </div>

                    <div className="card-body beautiful-scroll-10 p-0">
                    {/*<button className="btn btn-primary btn-lg btn-block my-3" onClick={this.search}>Найти</button>*/}

                    <table  style={{display:"table"}}
                        className="table table-responsive table-striped table-hover table-vertical-center m-0">

                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Наименование</th>
                            <th scope="col">Описание</th>
                            <th scope="col">Шаблон</th>
                        </tr>
                        </thead>



                            {/*isReady ?*/}
                        <tbody className="table_font_size_85">
                                {

                                    this.props.reports.map((item, index) => {
                                        let link = `/report?r=${item.Id}`;
                                        let trClass = "tablez2 noneFocus";
                                        if(this.state?.activeRow?.Id == item.Id){
                                            trClass= "tablez2 tr-active noneFocus"
                                        }else{
                                            trClass= "tablez2 noneFocus"
                                        }
                                        return(
                                        <tr
                                            onClick={(event) => {
                                            this.selectReport(event, item, link)}}
                                            key={item.Id}
                                            className={trClass}>
                                            <th>
                                                <p className="change_table_text_2">
                                                {item.Name}
                                                </p>
                                            </th>
                                            <th>
                                                <p className="change_table_text_2">
                                                {item.Description}
                                                </p>
                                            </th>
                                            <th>
                                                <p className="change_table_text_2">
                                                {item.TemplateFileName}
                                                </p>
                                            </th>
                                        </tr>
                                        )
                                    })
                                }
                                </tbody>
                                {/*</tbody> : <tbody/>*/}


                    </table>

                    </div>

                    <div className="card-footer d-flex justify-content-between">
                    </div>


                </div>







        );
    }
}

const  mapStateToProps = state => {
    return {
        reports : state.report.leftMenu.collection.reports,
        activeRow: state.report.leftMenu.activeRow,
    }
}

const  mapDispatchToProps = {
    getReports,
    getReportParams,
    setActiveRow
}

export default connect(mapStateToProps,mapDispatchToProps)(LeftMenuReports);
