
import {SEARCH_ROOT_STATE_ACTIONS} from "../searchActionsList";
import {store} from "../../../index";

// изменяем отображение
export const changeSearchViewMode = ({ViewMode ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: SEARCH_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE, payload: ViewMode});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// изменяем отображение
export const searchToggleShowAsideMenu = ({isOpen = null}) => {
    return async dispatch => {
        try {
            if (isOpen === null) {
                isOpen = store.getState().search.rootState.isOpenAsideMenu;
            }
            await dispatch({type: SEARCH_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU, payload: !isOpen});
        } catch (exception) {
            console.log("Ошибка изменения видимости меню (catch): ", exception);
        }
    }
}