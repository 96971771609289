import {TreeTypes} from "../../tools/TreeCombine";
import produce from "immer";
import {
    DOCUMENT_PREVIEW_ACTIONS,
    DOCUMENT_ROOT_STATE_ACTIONS, DOCUMENT_SEARCH_ACTIONS, DOCUMENT_TEMPORARY_DATA, DOCUMENT_VERIFY,
    MAIN_TABLE_ACTIONS,
    TREE_ACTIONS
} from "./documentActionsList";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {__} from "../../tools/HelpFunctions";

const tree = {
    // проверка на загрузку данных
    // для предотвращения повторной загрузки
    // при смене корневого активного компонента
    isLoaded : false,
    // необходим для переключение между
    // обычным деревом и деревом с результатом поиска
    isSearch : false,
    // полученные типы документов
    docs : [],
    // полученные группы (папки) документов
    group : [],
    structure: [],
    // открытые разделы документов дерева
    openedFolders : [],
    // готовое для отображения дерево
    render : {
        parent : null,
        type : TreeTypes.ROOT,
        info : {},
        children : []
    },
    // готовое для отображения дерево во время поиска
    search : {
        parent : null,
        type : TreeTypes.ROOT,
        info : {},
        children : []
    },

    // текст поиска в дереве документов
    searchText : "",
    // выбранный узел дерева
    activeNode : null,
    // идет ли загрузка данных
    isLoading: false,
};

// поиск в документах
const search = {
    searchInputs : [],
    isSearch : false,
};

// информация о таблице документов
const mainTable = {
    sortDirection : false,
    sortField: '',
    // загруженные для отображения документы
    collection : {
        docs : [],
        recordsOffset:0,
        totalInServer : 0,
    },
    // собранное тело таблицы
    body : null,
    // собранные заголовки таблицы
    header : null,
    // отмеченные документы в таблице
    // использовать как Set : методы -> add, has, delete, size
    checkedFields : [],
    // номер страницы в пагинации
    paginationPageNumber : 1,
    // кол-во документов отображаемых в таблице
    paginationPageSize : 25,
    // кол-во кнопок отображаемых при пагинации (с цифрами)
    paginationButtonsCount : 5,

    // существующие для таблицы настройки
    settings : {
        // размер таблицы от размера столбцов
        width : "100%",
        // режим отображения текста
        textViewMode : {
            header : "oneLine", // oneLine or multiLine
            body : "oneLine" // oneLine or multiLine
        },
        // скрытые столбцы таблицы
        hideColumns : [],
    },
    // примененные к таблице фильтры
    filters : [],
    // выбранная в таблице строка (документ)
    activeRow : null,

    closeOpenedTabs : null,
};

// окно предпросмотра документа
const preview = {
    // готов ли компонент к первичному отображению
    isReady : false,
    // файлы предпросмотра (link, name, size, type)
    files : [],
    // выбранный файл предпросмотра
    activeFileIndex : 0,
    //оригинал или предпросморт
    typeFile: "image",
    // фиксация размера предпросмотра
    fixingPreview: "normal",
    // выбор вида отображения активного файла (оригинал / предпросмотр)
    activeView: "preview"
};

// секции с дополнительными данными о документе
const additionalSections = {
    test:"",
    // готов ли компонент к первичному отображению
    isReady : false,

    // данные для каждой отдельной секции
    state : {
        // раздел всех атрибутов документа
        attributesSection: {
            // открыт\закрыт
            isOpen: true,
            // коллекция всех атрибутов
            attributes : [],
        },
        // раздел с пакетами в которых содержится документ
        documentPackagesSection: {
            // открыт\закрыт
            isOpen: false,
            // коллекция содержащих пакетов
            packages: [],
        },
        // раздел с версиями документа
        versionSection: {
            // открыт\закрыт
            isOpen: true,
            // коллекция версий документов
            versions : [],
            // выбранная для предпросмотра версия (index в коллекции versions)
            activeVersionIndex : 0,
        },

        // newFeature departmentsRef
        departmentsSection: {
            // открыт\закрыт
            isOpen: true,
            departmentsData: []
        },
        // раздел с содержимым документа
        contentSection: {
            // открыт\закрыт
            isOpen: false,
            // содержание документа
            content : "Содержание отсутствует",
        },
        // раздел с файлами прикрепленными к документу
        filesSection: {
            // открыт\закрыт
            isOpen: false,
            // коллекция прикрепленных к документу файлов
            files : [],
        }
    }
};

// карточки документов
const cards = {
    // готов ли компонент к отображению
    isReady : false,
    // активные карточки документов
    collection : [],
    // карточка документа, которая отображается в данный момент
    activeCardId : null,
    // данные собранные для создания новой версии документа
    redactorData : null,
    // собранные после изменения данные
    redactorDataCollected : {
        isUpdatedInAll : false,
    }
};

// состояние всего корневого компонента
const rootState = {
    // режим вида отображения компонента
    // какие компоненты в каком виде будут отображены
    viewMode : RootComponentsStateViewModeTypes.DocumentsApp.None,
    // открыто/закрыто asideMenu
    isOpenAsideMenu: true,
};

// временные данные
// нужны для предотвращения
// повторных запросов к серверу
const temporaryData = {
};

// верифицированные документы
// нужны для предотвращения
// повторных запросов к серверу
const verifyDocs = {
};

const treeSearchQuery = ""

const initialState = {
    tree,
    treeSearchQuery,
    mainTable,
    rootState,
    preview,
    additionalSections,
    cards,
    search,
    temporaryData,
    verifyDocs
};

export const documentReducer = (state = initialState, action) => {
    return produce(state, newState => {

        // tree
        if(action.type === TREE_ACTIONS.GET_DOCS) {
            newState.tree.docs = action.payload;
        }
        else if(action.type === TREE_ACTIONS.GET_GROUP) {
            newState.tree.group = action.payload;
        }
        else if(action.type === TREE_ACTIONS.GET_STRUCTURE) {
            newState.tree.structure = action.payload;
        }
        else if(action.type === TREE_ACTIONS.GET_SECTIONS) {
            newState.tree.activeSection = action.payload;
        }
        else if(action.type === TREE_ACTIONS.CREATE) {
            newState.tree.render = action.payload;
            newState.tree.isLoaded = true;
        }
        else if(action.type === TREE_ACTIONS.SET_TREE_SEARCH) {
            newState.treeSearchQuery = action.payload
        }
        else if(action.type === TREE_ACTIONS.RELOAD) {
            newState.tree.isLoaded = false;
        }
        else if(action.type === TREE_ACTIONS.SEARCH) {
            newState.tree.search = action.payload;
        }
        else if(action.type === TREE_ACTIONS.SEARCH_TOGGLE) {
            newState.tree.isSearch = action.payload;
        }
        else if(action.type === TREE_ACTIONS.SELECT_NODE) {
            newState.tree.activeNode = action.payload;
        }
        else if(action.type === TREE_ACTIONS.TOGGLE_IS_LOADING){
            newState.tree.isLoading = !state.tree.isLoading;
        }
        else if(action.type === TREE_ACTIONS.TOGGLE_FOLDERS) {
            newState.tree.openedFolders = action.payload.openedFolders;
        }

        // mainTable
        else if(action.type === MAIN_TABLE_ACTIONS.DOWNLOAD_DOC_COLLECTION) {
            newState.mainTable.collection = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.CHANGE_SORT_DIRECTION) {
            newState.mainTable.sortDirection = action.payload.dir;
            newState.mainTable.sortField = action.payload.sortField;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.COMBINE_ATTRIBUTE) {
            newState.mainTable.header = action.payload.Headers;
            newState.mainTable.body = action.payload.Body;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.RESET) {
            newState.mainTable = mainTable;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.RESET_FOR_UPDATE) {
            newState.mainTable = {...newState.mainTable, ...{
                    settings : action.payload.settings,
                    checkedFields : [],
                    paginationPageNumber : action.payload.settings.lRecordsOffset ?? 1,
                    activeRow : null
                }};
        }
        else if(action.type === MAIN_TABLE_ACTIONS.SET_PAGINATION_ON_PAGE_SIZE) {
            newState.mainTable.paginationPageSize = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.SET_PAGINATION_PAGE_NUMBER) {
            newState.mainTable.paginationPageNumber = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.SET_DEFAULT_WIDTH_OF_COLUMN) {
            newState.mainTable.header = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.ADD_ROW_TO_CHECKED) {
            newState.mainTable.checkedFields = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED) {
            newState.mainTable.checkedFields = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE) {
            newState.mainTable.checkedFields = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.SELECT_ACTIVE_ROW) {
            newState.mainTable.activeRow = action.payload;
        }
        else if(action.type === MAIN_TABLE_ACTIONS.CHANGE_WRAP) {
            newState.mainTable.isWrap = action.payload;
        }
        else if(action.type ===MAIN_TABLE_ACTIONS.CLOSE_OPENED_TABS) {
            newState.mainTable.closeOpenedTabs = action.payload
        }


        // rootState
        else if(action.type === DOCUMENT_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE) {
            newState.rootState.viewMode = action.payload;
        }
        else if(action.type === DOCUMENT_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE_SEARCH) {
            newState.rootState.viewModeSearch = action.payload;
        }
        else if(action.type === DOCUMENT_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU) {
            newState.rootState.isOpenAsideMenu = action.payload;
        }

        // preview
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.CREATE_BY_NEW_OPEN_DOC) {
            newState.preview.files = action.payload;
            newState.preview.activeFileIndex = 0;
            newState.preview.isReady = true;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_FILE) {
            newState.preview.activeFileIndex = action.payload.Index;
            newState.preview.typeFile = action.payload.Type;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SET_SIZE_PREVIEW) {
            newState.preview.fixingPreview = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.RESET) {
            newState.preview = preview;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.TYPE_File) {
            newState.preview.typeFile = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SELECT_VIEW_FOR_ACTIVE_FILE) {
            if(action.payload.IsCard) {
                let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.activeCardId);
                newState.cards.collection[cardIndex].preview.activeView = action.payload.view;
            } else if(action.payload.IsRedactorMode) {
                newState.cards.redactorData.preview.activeView = action.payload.view;
            } else {
                newState.preview.activeView = action.payload.view;
            }
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SET_FILE_CONTENT) {
            if(action.payload.IsCard) {
                let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.activeCardId);
                newState.cards.collection[cardIndex].additionalSections.contentSection.content = action.payload.Content;
            } else if(action.payload.IsRedactorMode) {
                newState.cards.redactorData.additionalSections.contentSection.content = action.payload.Content;
            } else {
                newState.additionalSections.state.contentSection.content =  action.payload.Content;
            }
        } else if (action.type === DOCUMENT_PREVIEW_ACTIONS.COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL) {
            newState.additionalSections.state.attributesSection.attributes = action.payload.attributesSection;
            newState.additionalSections.state.contentSection.content = action.payload.contentSection;
            newState.additionalSections.state.filesSection.files = action.payload.filesSection;
            newState.additionalSections.state.versionSection.versions = action.payload.versionSection;
            newState.additionalSections.state.documentPackagesSection.packages = action.payload.documentPackagesSection;
            newState.additionalSections.state.versionSection.activeVersionIndex = action.payload.activeVersionIndex;
            newState.additionalSections.state.departmentsSection.departmentsData = action.payload.departmentsSection
            newState.additionalSections.isReady = true;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_DATA_FOR_DEPARTMENTS_SECTION) {
            newState.additionalSections.state.departmentsSection.departmentsData = action.payload.departmentsSection
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.TOGGLE_ADDITIONAL_SECTIONS_OPEN) {
            newState.additionalSections.state[action.payload.name].isOpen = action.payload.isOpen;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.CREATE_DOC_CARDS) {
            if (!action.payload?.departmentsSection) newState.cards.collection.push(action.payload);
            else if (state.cards.collection.length !== 0) newState.cards.collection[0].additionalSections.departmentsSection.content = action.payload.departmentsSection
            newState.cards.isReady = true;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_DOC_CARDS) {
            let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.Id);
            newState.cards.collection[cardIndex] = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_DOC_CARDS) {
            newState.cards.activeCardId = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS) {
            let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.CardId);
            newState.cards.collection[cardIndex].preview.activeFileIndex = action.payload.ActiveFileIndex;
            newState.cards.collection[cardIndex].preview.typeFile = action.payload.Type;
        }
        //TODO Электронная подпись
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_ROW_FILE) {
            newState.additionalSections.state.filesSection.activeRowFile = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SET_ROW_FILE_FOR_SHOW_SIGNATURE) {
            //Ищем файл и ставим у него признак просмотра подписи
            if (!action.payload.activeCardId) {
                let lFile = newState.additionalSections.state.filesSection.files.find(item => item.id === action.payload.id);
                if (lFile)
                    lFile.showSignatures = !lFile.showSignatures
            }
            else
            //Для карточки спецобработка
            {
                let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.activeCardId);
                let lFile = newState.cards.collection[cardIndex].additionalSections.filesSection.files.find(item => item.id === action.payload.id);
                if (lFile)
                    lFile.showSignatures = !lFile.showSignatures
            }
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.DELETE_SIGNATURE) {
            //Ищем файл и удаляем у него подпись
            let lFile = null;
            if (!action.payload.activeCardId)
               lFile = newState.additionalSections.state.filesSection.files.find(item => item.id === action.payload.IdDocFile)
            else
            {
                let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.activeCardId);
                lFile = newState.cards.collection[cardIndex].additionalSections.filesSection.files.find(item => item.id === action.payload.IdDocFile);
            }
            if (lFile) {
                lFile.signatures = lFile.signatures.filter(item => item.Id !== action.payload.Id)
            }
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_SIGNATURE) {
            //Ищем файл и заменяем у него подпись
            let lFile = null;
            if (!action.payload.RowInfo.activeCardId) {
                lFile = newState.additionalSections.state.filesSection.files.find(item => item.id === (action.payload.RowInfo.Id ? action.payload.RowInfo.IdDocFile : action.payload.RowInfo.idDocFile));
            } else
            //Для карточки спецобработка
            {
                let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.RowInfo.activeCardId);
                lFile = newState.cards.collection[cardIndex].additionalSections.filesSection.files.find(item => item.id === (action.payload.RowInfo.Id ? action.payload.RowInfo.IdDocFile : action.payload.RowInfo.idDocFile));

            }
            if (lFile)
                if (action.payload.RowInfo.Id) {
                    for (let i in lFile.signatures) {
                        if (lFile.signatures[i].Id == action.payload.RowInfo.Id) {
                            lFile.signatures[i] = action.payload.lSignature;
                            break;
                        }
                    }
                } else {
                    lFile.signatures = action.payload.lSignature.Records;
                }
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.TOGGLE_ADDITIONAL_SECTIONS_OPEN_CARDS) {
            let cardIndex = newState.cards.collection.findIndex(card => card.Id === action.payload.CardId);
            newState.cards.collection[cardIndex].additionalSections[action.payload.name].isOpen = action.payload.isOpen;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.DELETE_DOC_CARDS) {
            newState.cards.collection = action.payload.cards;
            newState.cards.activeCardId = action.payload.activeCardId;
        }
        // редактирование карточки
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.CREATE_REDACTOR_DATA) {
            newState.cards.redactorData = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_ATTRIBUTES_REDACTOR_DATA) {
            newState.cards.redactorData.additionalSections.attributesSection.attributes = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_CONTENT_REDACTOR_DATA) {
            newState.cards.redactorData.additionalSections.contentSection.content = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_DOC_TYPE_REDACTOR_DATA) {
            newState.cards.redactorData.additionalSections.docTypeSection = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_NAME_OR_DESCRIPTION) {
            newState.cards.redactorData.additionalSections.filesSection.files = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_ADD_NEW) {
            newState.cards.redactorData.additionalSections.filesSection.newFiles = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_DELETE) {
            newState.cards.redactorData.additionalSections.filesSection.deleteFiles = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.CHECK_INPUT_DATA_BEFORE_UPDATE) {
            newState.cards.redactorDataCollected = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_DELETE_NEW_ATTACHMENT) {
            newState.cards.redactorData.additionalSections.filesSection.newFiles = action.payload;
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.SET_MAIN_FILE) {
            newState.cards.redactorData.additionalSections.filesSection.idMainFile = action.payload.id;
            if(!action.payload.isUpdated) {
                newState.cards.redactorData.additionalSections.filesSection.idMainFileOriginal = action.payload.id;
            }
        }

        else if(action.type === DOCUMENT_SEARCH_ACTIONS.CHANGE_INPUTS) {
            newState.search.searchInputs = action.payload;
        }
        else if(action.type === DOCUMENT_SEARCH_ACTIONS.CHANGE_VIEW_MODE) {
            newState.search.isSearch = action.payload;
        }


        // temporaryData
        else if(action.type === DOCUMENT_TEMPORARY_DATA.SAVE) {
            newState.temporaryData[action.payload.name] = action.payload.data;
        }
        else if(action.type === DOCUMENT_TEMPORARY_DATA.CLEAR) {
            newState.temporaryData = {};
        }
        //Массивом сделано из-за вероятности принадлежности одного документа к разным типам документов
        else if(action.type === DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS) {
            if (newState.verifyDocs[Number(action.payload.id)])
                newState.verifyDocs[Number(action.payload.id)] = __.deepCopy(newState.verifyDocs[Number(action.payload.id)]).push(action.payload.data)
            else
                newState.verifyDocs[Number(action.payload.id)] = [action.payload.data];
        }
    });

}

