import {baseUrl} from "./apiUrl";
import $ from "jquery/dist/jquery";

export class apiRequest {
    // headers пока убрали, нужно будет добавим
    constructor(action, params = null, headers = null, endUrl = "") {
        this.action = action;
        this.baseUrl = baseUrl;
        this.params = params;
        this.endUrl = endUrl;
    }

    async execute(callback = null, errorCallback = null) {
        let self = this;

        await $.ajax({
            url: `${baseUrl}${self.action.url}${self.endUrl}`,
            type: 'POST',
            processData: false,
            credentials : "same-origin",
            contentType : 'application/json',
            data: JSON.stringify({...self.params}),
            success : (data) => {
                if(callback) callback(data);
            },
            error : (data) => {
                if(errorCallback) errorCallback(data);
            }
        });
    }
}



export class apiRequestNew {
    // headers пока убрали, нужно будет добавим
    constructor({action, params = null, headers = null, endUrl = ""}) {
        this.action = action;
        this.baseUrl = baseUrl;
        this.params = params;
        this.headers = headers;
        this.endUrl = endUrl ? "/" + endUrl : "";
        this.isFile = false;
    }

    isFileUpload(condition = false) {
        this.isFile = condition;
        return this;
    }


    async execute() {
        return new Promise((resolve, reject) => {
            let self = this;

            let response;
            if(!self.isFile) {
                response = fetch(`${baseUrl}${self.action.url}${self.endUrl}`, {
                    method: 'POST',
                    body: JSON.stringify({...self.params}),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'credentials': "same-origin",
                    },
                });
            } else {
                response = fetch(`${baseUrl}${self.action.url}${self.endUrl}`, {
                    method: 'POST',
                    processData: false,
                    body: self.params,
             //       credentials: "same-origin",
           //         contentType : 'multipart/form-data',
                });
            }

            resolve(response);
        });
    }
}


// использовать для загрузки файлов
export class apiRequestFiles {
    constructor(action, urlEndValue = "", formData = null, headers = null) {
        this.action = action;
        this.urlEndValue = urlEndValue;
        this.baseUrl = baseUrl;
        this.formData = formData;
    }

    async execute(callback = null, errorCallback = null) {
        let self = this;

        $.ajax({
            url: `${baseUrl}${self.action.url}${self.urlEndValue}`,
            type: 'POST',
            processData: false,
       //     contentType: false,
        //    processData: false,
       //     credentials : "same-origin",
           contentType : 'multipart/form-data',
            data: this.formData,
            success : (data) => {
                if(callback) callback(data);
            },
            error : (data) => {
                if(errorCallback) errorCallback(data);
            }
        });
    }
}

export class apiRequestTest {
    // headers пока убрали, нужно будет добавим
    constructor(action, params = null, headers = null, endUrl = "") {
        this.action = action;
        this.baseUrl = baseUrl;
        this.params = params;
        this.endUrl = endUrl;
    }

    async execute(callback = null, errorCallback = null) {
        let self = this;

        $.ajax({
            url: `${baseUrl}${self.action.url}${self.endUrl}`,
            type: 'POST',
            processData: false,
          //  contentType: false,
            cache: false,
            credentials: "same-origin",
            contentLength : self.params.size,
            contentType: 'text/plain',
            data: self.params,
            success: (data) => {
                if(callback) callback(data);
            },
            error: (data) => {
                if(errorCallback) errorCallback(data);
            }
        });
    }
}

export class apiRequestTest2 {
    // headers пока убрали, нужно будет добавим
    constructor(action, params = null, headers = null, endUrl = "") {
        this.action = action;
        this.baseUrl = baseUrl;
        this.params = params;
        this.endUrl = endUrl;
    }

    async execute(callback = null, errorCallback = null) {
        let self = this;

        $.ajax({
            url: `${baseUrl}${self.action.url}${self.endUrl}`,
            type: 'POST',
            processData: false,
            cache: false,
            credentials: "same-origin",
            dataType: 'binary',
            xhrFields: {
                'responseType': 'blob'
            },
            data: null,
            success: (data, status, head) => {
                if(callback) callback(data, status, head);
            },
            error: (data) => {
                if(errorCallback) errorCallback(data);
            }
        });
    }
}
