import React from "react";
import stylePagination from "../../../media/styles/pagination.module.css";
import {connect} from "react-redux";
import Pagination from "../pagination/Pagination";
import {ActionQueue} from "../../../store/rootReducer";
import {setContextMenuData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    resetMainTableForUpdate_packagesDocumentRight,
    setPaginationPageNumber_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import TheadDocumentsRight from "./TheadDocumentsRight";
import TbodyDocumentsRight from "./TbodyDocumentsRight";
import PaginationCounterPackagesDocumentsRight from "./PaginationCounterPackagesDocumentsRight";
import MainTableSearchPackagesDocumentsRights from "./MainTableSearchPackagesDocumentsRights";
import {__} from "../../../tools/HelpFunctions";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";

class MainTableDocumentsRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lHeader: [],
            prevCells: [],

        }

        this.tableWidthRef = React.createRef();
        //TODO Чтобы ничего не перерендеривалось
        this.memoRef = React.createRef();

        this.fitInContainerSize = this.fitInContainerSize.bind(this);
        this.unFitInContainerSize = this.unFitInContainerSize.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.setWidth = this.setWidth.bind(this);
    }

    componentDidMount() {
        // TODO TP не убирать, при повторном открытии фильтра сбрасываются значения примененного фильтра
        //     if(this.props.searchInputs.length > 0) {
        //         return;
        //     }
        //
        //     this.props.changeDocumentSearchInputs_packagesDocumentRight({
        //         isDelete: true
        //     });
        if (!this.props.searchInputs.find(item => item.Attribute === "Дата")){
            this.props.changeDocumentSearchInputs_packagesDocumentRight({
                Attribute : "Дата",
                Value : "",
                isConstant : true,
                IdAttributeType: DataTypesEnum.DataTime,
                Name: "RegDate", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Дата").UserVisible,
                IsShowExtFilters: false,
            });
        }

        if (!this.props.searchInputs.find(item => item.Attribute === "Номер"))
            this.props.changeDocumentSearchInputs_packagesDocumentRight({
                Attribute : "Номер",
                Value : "",
                isConstant : true,
                IdAttributeType: DataTypesEnum.String,
                Name: "RegNumber", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Номер").UserVisible,
                IsShowExtFilters: false,
            });

        if (!this.props.searchInputs.find(item => item.Attribute === "Наименование"))
            this.props.changeDocumentSearchInputs_packagesDocumentRight({
                Attribute : "Наименование",
                Value : "",
                isConstant : true,
                IdAttributeType: DataTypesEnum.String, //определение типа для точных фильтров
                Name: "Name", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Наименование").UserVisible,
                IsShowExtFilters: false,
            });

        for(let i = 0; i < this.props.allAttribute.length; i++) {
            if (!this.props.allAttribute[i].IsConstant) {
                if (!this.props.searchInputs.find(item => item.Attribute?.Value === this.props.allAttribute[i].Value))
                    this.props.changeDocumentSearchInputs_packagesDocumentRight({
                        Attribute : this.props.allAttribute[i],
                        Value : "",
                        isConstant : false,
                        Name: this.props.allAttribute[i].Name,
                        IdAttributeType: this.props.allAttribute[i].IdAttributeType,
                        IsShowExtFilters: false,
                    });
            }
        }

        if (!this.props.searchInputs.find(item => item.Attribute === "Последнее изменение"))
            this.props.changeDocumentSearchInputs_packagesDocumentRight({
                Attribute : "Последнее изменение",
                Value : "",
                isConstant : true,
                IdAttributeType: DataTypesEnum.DataTime,
                Name: "Version.ModifyTime", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Последнее изменение").UserVisible,
                IsShowExtFilters: false,
            });

        let fillingFieldsCount = 0;
        for (let i = 0; i < this.props.searchInputs.length; i++) {
            if (typeof this.props.searchInputs[i].Value === "object") {
                fillingFieldsCount = Object.values(this.props.searchInputs[i].Value).filter(Boolean).length;
                if (fillingFieldsCount > 0) {
                    this.setState({extSearch: true})
                    break
                }
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //TODO Задача № 20169  БАГ. Дерево документов в доп таблице - новый реестр на старой странице
        if (this.props.nodeName != prevProps.nodeName)
            this.handlePageChange(1);
    }

    setWidth(itemSize, lItem) {
        if (this.state.prevCells.length == 0) {
            this.setState({prevCells: __.deepCopy(this.state.lHeader)});
        }
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);

            let lObjCell =  (Cells.find(item => item.Value === lItem.Value))
            if (lObjCell)
                lObjCell.Size = itemSize;
            //TODO использован реф для того, чтобы не перерисовывались дочки, т.к. если делать через стейт, то дочки перерисовываются, это и задержки и ошибки при ресайзе.
            // this.setState({prevCells: Cells});
            this.memoRef = Cells;
        }
    }


    fitInContainerSize() {
        let TableWidth = this.tableWidthRef?.current?.offsetWidth - 100;
        let Cells = __.deepCopy(this.state.lHeader);
        if (this.memoRef?.length)
            this.setState({prevCells: this.memoRef})
        else
            this.setState({prevCells: Cells});
        let RowWidth = 0;
        if (Cells && TableWidth) {
            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
                RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
            }

            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) {
                let size = (TableWidth / 100) * ((Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70) / RowWidth * 100);
                Cells[i].Size = size < 100 ? 100 : size;
                // }
            }
            let lHeader = (Item) => __.deepCopy(Item);
            this.setState({lHeader: lHeader(Cells)});
        }
    }

    unFitInContainerSize() {
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);
            let lHeader = (Item) => __.deepCopy(Item);
            this.setState({lHeader: lHeader(Cells)});
        }
    }

    // переключение страниц пагинации
    handlePageChange(pageNumber) {
        this.props.ActionQueue({
            List : [
                {action : this.props.resetMainTableForUpdate_packagesDocumentRight, params : {}, name: "resetMainTableForUpdate_packagesDocumentRight"},
                {action : this.props.setPaginationPageNumber_packagesDocumentRight, params : {PaginationPageNumber : pageNumber}, name : "setPaginationPageNumber_packagesDocumentRight"},
                {action : this.props.mainTableDownloadDocCollection_packagesDocumentRight, params : {}, name: "mainTableDownloadDocCollection_packagesDocumentRight"},
                {action : this.props.combineMainTableAttributes_packagesDocumentRight, params: {}, name : "combineMainTableAttributes_packagesDocumentRight"},

            ],
            debug : false
        });
    }

    render() {
        let header = __.deepCopy(this.props.header)

        if (JSON.stringify(header) !== JSON.stringify(this.state.lHeader))
            if (this.state.lHeader.length == 0)
                this.setState({lHeader: header})



        let isEmpty = false;
        if(Math.ceil(this.props.mainTable.collection.totalInServer / Number.parseInt(this.props.mainTable.paginationPageSize))){
            isEmpty=true
        }
            return (
                <>
                        <MainTableSearchPackagesDocumentsRights
                            fitInContainerSize={this.fitInContainerSize}
                            unFitInContainerSize={this.unFitInContainerSize}
                        />
                    <div  ref={this.tableWidthRef} className="card-body py-0 table-responsive" style={{height : "70vh"}}>

                        <div> {/*прячем контекстное меню при скроле таблицы*/}
                            <div className="" onScroll={()=>
                            {
                                if(this.props.contextMenu.name !== "") {
                                    this.props.setContextMenuData({});
                                }
                            }}>
                                <table className="table table-head-custom table-striped table-hover table-vertical-center" id="mainTable" style={{tableLayout : "fixed"}}>
                                    {<TheadDocumentsRight setWidth = {(itemSize, item) => this.setWidth(itemSize, item)} header={this.state.lHeader}/>}
                                    {<TbodyDocumentsRight /> }
                                </table>
                            </div>


                        </div>

                    </div>
                    <div className="card-footer py-0">

                        <div className="d-flex justify-content-between align-items-center flex-wrap pt-3 pb-3">
                            <Pagination
                                activePage={this.props.mainTable.paginationPageNumber} // активная страница
                                itemsCountPerPage={Number.parseInt(this.props.mainTable.paginationPageSize)} // элементов на странице
                                totalItemsCount={this.props.mainTable.collection.totalInServer} // всего элементов
                                // pageRangeDisplayed={this.props.mainTable.paginationButtonsCount} // сколько кнопок отображать
                                pageRangeDisplayed={1} // сколько кнопок отображать
                                onChange={this.handlePageChange}  // обработчик переключения
                                itemClass={stylePagination.item} // стили item-ов
                                linkClass={"btn btn-icon btn-sm btn-light-success mr-2 my-1 pagination-btn"} // стиль для ссылок
                                activeLinkClass={"btn btn-icon btn-sm btn-light-success active mr-2 my-1 pagination-btn"} // стиль для активных ссылок
                            />

                            {
                                isEmpty ?
                                    <span className='text-muted'>
                                        Cтр {this.props.mainTable.paginationPageNumber} из {Math.ceil(this.props.mainTable.collection.totalInServer / Number.parseInt(this.props.mainTable.paginationPageSize))}
                                    </span>

                                    :
                                    <span className='text-muted'>
                                         Нет данных для отображения
                                    </span>

                            }

                            <PaginationCounterPackagesDocumentsRight
                                totalItemsCount={this.props.mainTable.collection.totalInServer} // всего элементов
                                activePage={this.props.mainTable.paginationPageNumber} // активная страница
                                paginationPageSize={Number.parseInt(this.props.mainTable.paginationPageSize)} // элементов на странице
                            />
                        </div>

                    </div>
                </>
            );
    }
}


const  mapStateToProps = state => {
    return {
        allAttribute: state.packages.mainTable.header,
        searchInputs : state.packages.rightTableSearchDoc.searchInputs,
        contextMenu :  state.globalState.app.contextMenu,
        header : state.packages.mainTable.header,
        mainTable : state.packages.mainTable,
        nodeName : state.packages.treeForDocuments.activeNode.info.Name,
    }
}


const  mapDispatchToProps = {
    ActionQueue,
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    resetMainTableForUpdate_packagesDocumentRight,
    // TODO: подумать че с ней делать
    setContextMenuData,
    setPaginationPageNumber_packagesDocumentRight,
}

export default connect(mapStateToProps,mapDispatchToProps)(MainTableDocumentsRight);




