import {store} from "../../../index";
import {APP_ACTIONS, AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {API} from "../../../tools/API_NEW/API";
import {ModalTypes} from "../../../tools/StaticTypes";

export const movingDocumentsInPackageFromPackages = ({IdPackage = 2, IdDoc, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let collection=[];

            collection.push({
                IdDoc: IdDoc,
                IdPackage: IdPackage
            });

            let request = new apiRequest(apiUrl.PACKAGE.SAVE_OR_UPDATE_COLLECTION, {
                collection
            });

            request.execute(function(data) {

                // dispatch({type : PACKAGES_TREE_ACTIONS.PATH_TO_NODE, payload : {
                //         Nodes : data,
                //         tableType : TableType
                //     }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                if(data.status === 400) {
                    dispatch({type: APP_ACTIONS.SET_MODAL_DATA, payload: {
                            name : ModalTypes.app.info,
                            data : {content : data.responseJSON.Message, type : "fail"}
                        }})
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const movingDocumentsInPackageFromDocuments = ({IdPackage = 2, ActiveRow = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let IdDocs = store.getState().document.mainTable.checkedFields;
            if(IdDocs.length === 0 && ActiveRow) {
                IdDocs = [ActiveRow.Id];
            }
            let collection=[];

            for (let i = 0; i < IdDocs.length; i++) {
                collection.push(
                    {
                        IdDoc:IdDocs[i],
                        IdPackage: IdPackage
                    }
                )
            }


            let request = new apiRequest(apiUrl.PACKAGE.SAVE_OR_UPDATE_COLLECTION, {
                collection
            });

            request.execute(function(data) {

                // dispatch({type : PACKAGES_TREE_ACTIONS.PATH_TO_NODE, payload : {
                //         Nodes : data,
                //         tableType : TableType
                //     }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                if(data.status === 400) {
                    dispatch({type: APP_ACTIONS.SET_MODAL_DATA, payload: {
                            name : ModalTypes.app.info,
                            data : {content : data.responseJSON.Message, type : "fail"}
                        }})
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data)
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const movingDocumentsInPackageFromSearch = ({IdPackage = 2,  ActiveRow = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let IdDocs = store.getState().search.searchState.searchResults.find(item => item.Id === store.getState().search.rootState.activeTab.Id).mainTable.checkedFields
            // let IdDocs= store.getState().search.mainTable.checkedFields;
            if(IdDocs.length === 0 && ActiveRow) {
                IdDocs = [ActiveRow.Id];
            }

            let collection=[];

            for (let i = 0; i < IdDocs.length; i++) {
                collection.push(
                    {
                        IdDoc:IdDocs[i],
                        IdPackage: IdPackage
                    }
                )
            }


            let request = new apiRequest(apiUrl.PACKAGE.SAVE_OR_UPDATE_COLLECTION, {
                collection
            });

            request.execute(function(data) {

                // dispatch({type : PACKAGES_TREE_ACTIONS.PATH_TO_NODE, payload : {
                //         Nodes : data,
                //         tableType : TableType
                //     }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const movingPackageInPackage = ({PackageItem = null, IdParent = null, Error = null}) => {
    return async dispatch => {
        try {
            let IdFilial = store.getState().globalState.filial.Active.Id;
            if(PackageItem) {
                await API.packages().updateNamePackage({
                    entity: {
                        Id: PackageItem.Id,
                        Name: PackageItem.Name,
                        IdParent: IdParent,
                        IdFilial: IdFilial,
                    }
                });
            }
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
