import React from 'react';
import {Link} from "react-router-dom";
import Select from "react-select";
import {baseUrl} from "../../tools/API/apiUrl";
import Iframe from "react-iframe";
import {store} from "../../index";
import {connect} from "react-redux";

const selectStyles = {
    container: () => ({
        display: "none",
    }),
}

// Компонент приветствующий пользователя при первом запуске приложения
class HomeWelcome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {

        // let styleCard = {
        //     height: "250px",
        //     backgroundImage: "url('/images/logo/logo_tek.jpg')",
        //     backgroundPositionX : "90%",
        //     backgroundPositionY : "center",
        //     backgroundSize : "200px",
        // };
        //
        // let linkStyle = {
        //     borderRadius: "0.8em",
        //     marginLeft: "68px",
        //     marginTop: "-5px"
        // };


        const logoColor = this.props.modString === "yellow" ? "_graphite" :""

        return (
            <>
            {/*<div className="row pt-4" style={{paddingLeft : "15px", paddingRight : "15px"}}>*/}
                <div className="col-lg-12" style={{position: "relative", padding: "0"}}>
                    {/* TODO TP костыль для анимации левого меню start */}
                    <Select options={[{label: 1, value: 1}]} styles={selectStyles} />
                    {/* end */}
                        <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
                             // style={styleCard}
                        >
                            <div className="card-body d-flex" style={{padding: "4rem 3.25rem",  alignItems: "center"}}>
                                <div className="d-flex py-5 flex-column align-items-start flex-grow-1" style={{position:"relative"}}>
                                    <div className="flex-grow-1">
                                        <span className="text-welcome font-weight-bolder font-size-h1">
                                            Электронное хранилище документов <br/>
                                            {/*<b>Ликвидируемых Финансовых Организаций</b>*/}
                                        </span>
                                        <p className="text-welcome font-weight-bold mt-3">
                                            С его помощью хранение и систематизация<br/> электронных документов становится гораздо удобнее!
                                            {/*<Link to={"help"} className="btn btn-light font-weight-bolder text-success btn-lg"*/}
                                            {/*      style={linkStyle}>*/}
                                            {/*    Справка*/}
                                            {/*</Link>*/}
                                        </p>
                                    </div>
                                    <div style={{position: "absolute", top: "0%", right: "0%"}}>
                                        <img alt={"Logo"} src={`/images/logo/home${logoColor}.png`} style={{width:"500px"}}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="col-lg-12 row pt-4 text-center" style={{margin : "0 auto"}}>*/}
                    {/*    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">*/}
                    {/*        <div className="card card-custom gutter-b card-stretch">*/}
                    {/*            <div className="card-body pt-4">*/}
                    {/*                <div className="d-flex flex-center mt-6 mb-7">*/}
                    {/*                    <img src="./images/1hp.svg"/>*/}
                    {/*                </div>*/}
                    {/*                <div className="d-flex flex-column mb-12 mt-6">*/}
                    {/*                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">*/}
                    {/*                        Обучение программе*/}
                    {/*                    </span>*/}
                    {/*                </div>*/}
                    {/*                <p className="mb-12">Пройдите обучение, чтобы эффективно работать с программой</p>*/}
                    {/*                <span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                    {/*                    style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                    {/*                    Приступить*/}
                    {/*                </span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">*/}
                    {/*        <div className="card card-custom gutter-b card-stretch">*/}
                    {/*            <div className="card-body pt-4">*/}
                    {/*                <div className="d-flex flex-center mt-6 mb-7">*/}
                    {/*                    <img src="./images/2hp.svg"/>*/}
                    {/*                </div>*/}
                    {/*                <div className="d-flex flex-column mb-12 mt-6">*/}
                    {/*                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">*/}
                    {/*                        Работа с документами*/}
                    {/*                    </span>*/}
                    {/*                </div>*/}
                    {/*                <p className="mb-12">Загружайте, объединяйте в реестры и изменяйте документы</p>*/}
                    {/*                <Link to="/documents"*/}
                    {/*                    className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                    {/*                    style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                    {/*                    Приступить*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">*/}
                    {/*        <div className="card card-custom gutter-b card-stretch">*/}
                    {/*            <div className="card-body pt-4">*/}
                    {/*                <div className="d-flex flex-center mt-6 mb-7">*/}
                    {/*                    <img src="./images/3hp.svg"/>*/}
                    {/*                </div>*/}
                    {/*                <div className="d-flex flex-column mb-12 mt-6">*/}
                    {/*                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">*/}
                    {/*                        Работа с пакетами*/}
                    {/*                    </span>*/}
                    {/*                </div>*/}
                    {/*                <p className="mb-12">Сортируйте и управляйте сотнями, тысячами документов в пакетах!</p>*/}
                    {/*                <span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                    {/*                    style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                    {/*                    Приступить*/}
                    {/*                </span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                {/*</div>*/}

                    {/*<div className="col-lg-12">*/}
                    {/*    <div className="card card-custom gutter-b">*/}
                    {/*        <div className="card-header">*/}
                    {/*            <i className='icon-Doc_view_6 icon-color-red' style={{fontSize: '2.25rem'}}/>*/}
                    {/*            <h3 className='card-title' style={{marginLeft: "10px"}}>*/}
                    {/*                Документы, представленные в системе «ДокПрофи», являются собственностью Quadro Electric Technologies и не подлежат копированию*/}
                    {/*                и передаче сторонним организациям и третьим лицам с целью их коммерческого использования.*/}
                    {/*            </h3>*/}
                    {/*        </div>*/}
                    {/*        /!*<div className="card-body">*!/*/}
                    {/*        /!*    Документы, представленные в системе «ДокПрофи», являются собственностью Quadro Electric Technologies и не подлежат копированию*!/*/}
                    {/*        /!*    и передаче сторонним организациям и третьим лицам с целью их коммерческого использования.*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
        </>
        );
    }
}

const  mapStateToProps = state => {
    return {
        modString: store.getState().globalState.theme.modString,
    }
}



export default connect(mapStateToProps)(HomeWelcome);
