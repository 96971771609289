import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../../tools/CreateUniqueId";
import TreeDocsFolderPackagesRight from "./TreeDocsFolderPackagesRight";

class TreeDocsPackagesRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}

    }


    render() {
        let data = this.props.tree_isSearch
            ? this.props.tree_search
            : this.props.tree_render;
        let depth = 0;


        return(
            <ul className="menu-nav">
                {
                    data.children.map((item, index) => {
                        return (
                            <TreeDocsFolderPackagesRight
                                key={`${item.info.Name} | ${createUniqueIdString(3)}`}
                                data={item}
                                depth={depth+1}/>
                        );
                    })
                }
            </ul>
        );
    }
}


const  mapStateToProps = state => {
    return {
        tree_render : state.packages.treeForDocuments.render, // данные в обычном режиме
        tree_search : state.packages.treeForDocuments.search, // данные при поиске
        tree_isSearch : state.packages.treeForDocuments.isSearch, // обычный режим или режим поиска
    }
}

const  mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TreeDocsPackagesRight);
