import React from "react";
import {connect} from "react-redux";
import {
    AdminLevelEnum,
    attributesSectionsTypes,
    RootComponentsStateViewModeTypes,
} from "../../tools/StaticTypes";
import {
    createRedactorData,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards, updateAttributesRedactorData
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../tools/HelpFunctions";
import parse from "html-react-parser";
import {searchByParams, updateInputsFieldSearch} from "../../store/search/actionCreators/search_Inputs";
import {history} from  "../../index";
import {combineMainTableAttributesSearch} from "../../store/search/actionCreators/search_table";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";
import sanitizeHtml from "sanitize-html";
import {ActionQueue} from "../../store/rootReducer";
import {setLoaderModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";

class AttributesSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            calendarShow : false,
            calendarItem : null,
            calendarIsHover : false,
        };

        this.sectionName = attributesSectionsTypes.attributesSection;


        this.toggle = this.toggle.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.calendarOnChange = this.calendarOnChange.bind(this);
        this.search = this.search.bind(this);
    }

    search(value, type = null) {
        const idSearch = __.createUniqueIdString(6);
        //TODO SS 23683 избавляемся от кавычек в запросе для лучшего поиска
        value = `"${value.split('"').join('').split("'").join('')}"`
        if(type) {
            let RegDateAsString = null;
            let RegNumber= null;
            let Name= null;


            if(type === "RegDateAsString") RegDateAsString = value;
            else if(type === "RegNumber") RegNumber = value;
            else if(type === "Name") Name = value;

            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List : [
                    {action : this.props.setLoaderModalData, params : {data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: true,
                                gif: "search",
                                key: "AttributesSection62"
                            }}, name : "setLoaderModalData"},
                    {action : this.props.createRedactorData, params : {CardId : null}, name : "createRedactorData"},
                    {action : this.props.updateInputsFieldSearch, params : {
                            RegDateAsString,
                            RegNumber,
                            Name,
                            Id: idSearch,
                        }, name : "updateInputsFieldSearch"},
                    {action : this.props.searchByParams, params : { Id: idSearch }, name : "searchByParams"},
                    {action : this.props.combineMainTableAttributesSearch, params : {}, name : "combineMainTableAttributesSearch"},
                    {action : this.props.changeSearchViewMode, params : {ViewMode : RootComponentsStateViewModeTypes.SearchApp.MainTableOnly}, name : "changeSearchViewMode"},
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "AttributesSection73"}, name : "setLoaderModalData"},
                ],
                debug : false,
            });
            history.replace('/search')
        }
        else {
            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List : [
                    {action : this.props.setLoaderModalData, params : {data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: true,
                                gif: "search",
                                key: "AttributesSection62"
                            }}, name : "setLoaderModalData"},
                    {action : this.props.updateInputsFieldSearch, params : {AttributesText : value, Id: idSearch,}, name : "updateInputsFieldSearch"},
                    {action : this.props.searchByParams, params : { Id: idSearch, }, name : "searchByParams"},
                    {action : this.props.combineMainTableAttributesSearch, params : {}, name : "combineMainTableAttributesSearch"},
                    {action : this.props.changeSearchViewMode, params : {ViewMode : RootComponentsStateViewModeTypes.SearchApp.MainTableOnly}, name : "changeSearchViewMode"},
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "AttributesSection73"}, name : "setLoaderModalData"},
                ],
                debug : false,
            });
            history.replace('/search')
        }


    }


    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpen({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }

    // обработка изменения атрибутов
    editHandler(event, item) {
        if(item.IsConstant) {
            this.props.updateAttributesRedactorData({
                name : item.AttributeName,
                value : event.target.value,
                isConstant : item.IsConstant
            });
        } else {
            this.props.updateAttributesRedactorData({
                name : item.IdAttributeName,
                value : event.target.value,
                isConstant : item.IsConstant
            });
        }
    }


    calendarOnChange(event, item) {
        this.props.updateAttributesRedactorData({
            name : item.AttributeName,
            value : event.target.value,
            isConstant : item.IsConstant,
        });
    }

    render() {
        let sectionData;
        let isReady;
        let originalCard = null;
        let originalSectionData = null;
        if(this.props.viewMode === "normal") {
            sectionData = this.props.additionalSectionsData.state[this.sectionName];
            isReady = this.props.additionalSectionsData.isReady;
        }
        else if(this.props.viewMode === "card") {
            let card;
            if(this.props.redactorMode && this.props.cards.redactorData) {
                card = this.props.cards.redactorData;
                originalCard = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            } else {
                card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            }

            if(originalCard) {
                originalSectionData = __.deepCopy(originalCard.additionalSections[this.sectionName]);
            }

            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = this.props.cards.collection
                .find(card => card.Id === this.props.cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Атрибуты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">


                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-4">Атрибут</th>
                                <th className="col-lg-7">Значение</th>
                                {!this.props.redactorMode && <th className="col-lg-1">Найти</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.attributes.map((item, index) => {
                                    if(this.props.redactorMode) {
                                        if(item.AttributeName === "Version.ModifyTime") {
                                            return (
                                                <tr key={item.ColumnName}>
                                                    <td style={{verticalAlign: "middle"}}>{item.ColumnName}</td>
                                                    <td>
                                                        <input className="form-control"
                                                               type="datetime-local"
                                                               min="1900-01-01T00:00" max="3000-12-31T00:00"
                                                               disabled={true}
                                                               value={item.Value}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        else if(item.AttributeName === "RegDate") {

                                            if(originalSectionData.attributes[index].Value === item.Value) {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        <td style={{verticalAlign: "middle"}}>
                                                            <b>
                                                                {item.ColumnName}
                                                                <sup>*</sup>
                                                            </b>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                   type="datetime-local"
                                                                   min="1900-01-01T00:00" max="3000-12-31T00:00"
                                                                   value={item.Value}
                                                                   onChange={(event)=>{this.calendarOnChange(event, item)}}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            else {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        <td style={{verticalAlign: "middle", position : "relative"}}>
                                                            <b>{item.ColumnName}<sup>*</sup></b>
                                                            <span className="btn btn-icon btn-sm"
                                                                  style={{top : "13px", right : "0px", position : "absolute"}}
                                                                  title="Внесены изменения"
                                                            >
                                                                <i className="svg-icon icon-Edit icon-color-danger"/>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                   type="datetime-local"
                                                                   min="1900-01-01T00:00" max="3000-12-31T00:00"
                                                                   value={item.Value}
                                                                   onChange={(event)=>{this.calendarOnChange(event, item)}}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }


                                        }
                                        else {
                                            if(originalSectionData.attributes[index].Value === item.Value) {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        {
                                                            // (__.isUniqueAttribute(item.IdAttributeName) || item?.IsRequired) &&
                                                            (item?.IsRequired || item?.AttributeName === 'RegNumber' || item?.AttributeName === 'Name'  ) &&
                                                            <td style={{verticalAlign: "middle"}}>
                                                                <b>
                                                                    {item.ColumnName}
                                                                    <sup>*</sup>
                                                                </b>
                                                            </td>
                                                        }
                                                        {
                                                            (item.AttributeName !== 'RegNumber' && item.AttributeName !== 'Name')
                                                                && !item?.IsRequired
                                                            // (!__.isUniqueAttribute(item.IdAttributeName) && !item?.IsRequired) &&
                                                            &&
                                                            <td style={{verticalAlign: "middle"}}>{item.ColumnName}</td>
                                                        }
                                                        <td>
                                                            {
                                                                item.IdAttributeType !== 5 ?
                                                                    <input className="form-control"
                                                                           type={__.inputTypeByDataTypeId(item.IdAttributeType)}
                                                                           value={item.Value}
                                                                           onChange={(event) => {
                                                                               this.editHandler(event, item)
                                                                           }}
                                                                    />
                                                                    :
                                                                    <input className="form-control"
                                                                           type={'date'}
                                                                           min="1900-01-01" max="3000-12-31"
                                                                           value={__.dateToServer(item.Value, false, true)}
                                                                           onChange={(event) => {
                                                                               this.editHandler(event, item)
                                                                           }}
                                                                    />
                                                            }

                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            else {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        {
                                                            __.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle", position : "relative"}}>
                                                               <b> {item.ColumnName}<sup>*</sup></b>
                                                                <span className="btn btn-icon btn-sm"
                                                                      style={{top : "13px", right : "0px", position : "absolute"}}
                                                                      title="Внесены изменения"
                                                                >
                                                                    <i className="svg-icon icon-Edit icon-color-danger"/>
                                                                </span>
                                                            </td>
                                                        }
                                                        {
                                                            !__.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle", position : "relative"}}>
                                                                {item.ColumnName}
                                                                <span className="btn btn-icon btn-sm"
                                                                      style={{top : "13px", right : "0px", position : "absolute"}}
                                                                      title="Внесены изменения"
                                                                >
                                                                    <i className="svg-icon icon-Edit icon-color-danger"/>
                                                                </span>
                                                            </td>
                                                        }
                                                        <td>
                                                            {__.inputTypeByDataTypeId(item.IdAttributeType) === "date" ?
                                                                <input className="form-control"
                                                                       type={'date'}
                                                                       min="1900-01-01" max="3000-12-31"
                                                                       value={item.Value}
                                                                       onChange={(event)=>{this.editHandler(event, item)}}
                                                                />
                                                                :
                                                                <input className="form-control"
                                                                       type={__.inputTypeByDataTypeId(item.IdAttributeType)}
                                                                       value={item.Value}
                                                                       onChange={(event)=>{this.editHandler(event, item)}}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        }
                                    } else {
                                        let value = item.Value === "null" || item.Value === null ? "-" : item.Value;

                                        let type = null;
                                        if(item.AttributeName === "Name")  type = "Name";
                                        else if(item.AttributeName === "RegNumber")  type = "RegNumber";
                                        else if(item.AttributeName === "RegDate")  type = "RegDateAsString";

                                        if(item.AttributeName === "RegDate" || item.AttributeName === "Version.ModifyTime") {
                                            value = item.Value.split("T")[0].split("-").reverse().join(".");
                                        }

                                        if(item.IdAttributeType === 4) {
                                            return (
                                                <tr key={item.ColumnName}>
                                                    <td>{item.ColumnName}</td>
                                                    {
                                                        item.Value==1 ?
                                                        // <td><input type={"checkbox"} checked disabled/></td>
                                                         <td>
                                                             <span className="btn btn-icon  btn-xs">
                                                                <i className="svg-icon icon-color-primary icon-checked"/>
                                                             </span>
                                                         </td>
                                                            :
                                                        <td><input type={"checkbox"} disabled/></td>
                                                    }

                                                    <td>
                                                        {
                                                            (value != "-" && item.AttributeName !== "Version.ModifyTime") &&
                                                            <span className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                                  onClick={() => {this.search(value, type)}}
                                                            >
                                                                <i className="svg-icon icon-Search"/>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }else{
                                            return (
                                                <tr key={item.ColumnName}>
                                                    <td>{item.ColumnName}</td>
                                                    <td>{parse(sanitizeHtml(value))}</td>
                                                    <td>
                                                        {
                                                            (value != "-" && item.AttributeName !== "Version.ModifyTime") &&
                                                            <span
                                                                className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                                onClick={() => {
                                                                    this.search(value, type)
                                                                }}
                                                            >
                                                                <i className="svg-icon icon-Search"/>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    }
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        additionalSectionsData : state.document.additionalSections,
        cards : state.document.cards,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    createRedactorData,
    searchByParams,
    setLoaderModalData,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
    updateAttributesRedactorData,
    updateInputsFieldSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSection);
