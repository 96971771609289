import {HelpFunctions} from "./HelpFunctions";

export const FiltersType = {
    SIMPLE : "SIMPLE",
    ENTITY : "ENTITY"
}

export class EntityFilters {
    constructor() {
        this._EntityName = null;
        this._PropertyFilters = [];
    }

    // установить имя фильтра
    setEntityName(name) {
        this._EntityName = name;
        return this;
    }

    // добавить Property
    // add({Name, Value,Operator}) {
    //
    //     this._PropertyFilters.push({
    //         PropertyName : Name,
    //         Operator : Operator,
    //         FilterDataAsStringList : HelpFunctions.isString(Value)
    //             ? [Value]
    //             : [Value.toString()],
    //     });
    //     return this;
    // }
    add({Name, Value,Operator}){
        if(Operator !== undefined){
            if(Value){
                if(Array.isArray(Value)){
                    let stringArray = [];
                    for (let i = 0; i < Value.length; i++) {
                        if(HelpFunctions.isString(Value[i])) stringArray.push(Value[i]);
                        else stringArray.push(Value[i].toString());
                    }
                    this._PropertyFilters.push({
                        PropertyName: Name,
                        Operator: Operator,
                        FilterDataAsStringList: stringArray
                    });
                }
                else{
                    this._PropertyFilters.push({
                        PropertyName : Name,
                        Operator: Operator,
                        FilterDataAsStringList: HelpFunctions.isString(Value)?[Value]:[Value.toString()]
                    });
                }
            }
            else {
                this._PropertyFilters.push({
                    PropertyName : Name,
                    Operator: Operator
                })
            }
        }
        else{
            if(Value){
                if(Array.isArray(Value)){
                    let stringArray=[];
                    for (let i = 0; i < Value.length; i++) {
                        if(HelpFunctions.isString(Value[i])) stringArray.push(Value[i]);
                        else stringArray.push(Value[i].toString());
                    }
                    this._PropertyFilters.push({
                        PropertyName : Name,
                        FilterDataAsStringList: stringArray
                    })
                }
                else{
                    this._PropertyFilters.push({
                        PropertyName : Name,
                        FilterDataAsStringList: HelpFunctions.isString(Value)?[Value]:[Value.toString()]
                    });
                }
            }
            else{
                this._PropertyFilters.push({
                    PropertyName : Name
                });
            }
        }
        return this;
    }

    addList({Name, Value,Operator}) {

        let stringValue = [];
        for (let i = 0; i < Value.length; i++) {
            stringValue[i] = Value[i].toString();
        }

        this._PropertyFilters.push({
            PropertyName : Name,
            Operator : Operator,
            FilterDataAsStringList : stringValue
        });
        return this;
    }

    // добавить атрибут со значением, кроме EntityName
    addAttribute({Name, Value}) {
        if(Name !== "EntityName") {
            this[Name] = Value;
        }
        return this;
    }

    // получить собранный EntityFilters
    get() {

        let result = {
            EntityName : this._EntityName,
            PropertyFilters : this._PropertyFilters
        };
        for(let key in this) {
            if(key !== "_EntityName" && key !== "_PropertyFilters") {
                result[key] = this[key];
            }
        }
        return result;

    //    return this;   // можно возвращать просто this но пока так, ибо для более сложных фильстров не было бы ошибок

    }
}

export class Filter {
    constructor(type) {
        if(type === FiltersType.SIMPLE) {
            this._filter = {};
        }
        else if (type === FiltersType.ENTITY) {
            this._filter = {
                EntityFilters : []
            };
        }
        this._type = type;
    }

    add(filter) {
        if(this._type === FiltersType.SIMPLE) {
            this.addAttribute({Name : filter.Name, Value : filter.Value});
        }
        else if(this._type === FiltersType.ENTITY) {
            this._filter.EntityFilters.push(filter);
        }
        return this;
    }

    addAttribute({Name, Value}) {
        if(Name !== "EntityFilters") {
            this._filter[Name] = Value;
        }
        return this;
    }

    get() {
        return this._filter;
    }
}

export class Sorting {
    constructor() {
        this._sorting = {
            PropertySortings : []
        };
    }

    add(sorting) {
        this._sorting.PropertySortings.push(sorting);
        return this;
    }

    addAttribute({Name, Value}) {
        if(Name !== "PropertySortings") {
            this._sorting[Name] = Value;
        }
        return this;
    }

    get() {
        return this._sorting;
    }

}

export class ReportColumns {
    constructor() {
        this._reportColumns = [{
            PropertySortings : [],
        }];
    }

    add(sorting) {
        this._reportColumns.PropertySortings.push(sorting);
        return this;
    }

    addAttribute({Name, Value}) {
        if(Name !== "PropertySortings") {
            this._reportColumns[Name] = Value;
        }
        return this;
    }

    get() {
        return this._reportColumns;
    }

}


export class EntityFiltersExportDocs extends EntityFilters {

}
