import React, {useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {store} from "../../../index";
import {setLoaderModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import parse from "html-react-parser";
// @ts-ignore
import sanitizeHtml from "sanitize-html";
import {rootReducer} from "../../../store/rootReducer";

type OwnPropsType = {
    loaderModal?: {data:{
            content : string;
            disableButton : boolean;
            fullBackground : boolean;
            gif : string;
            countOpenLoaders : number;
            keys : string[];
            keysDelete : string[];
        }}
};

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = OwnPropsType & PropsFromRedux;

// modal.data.content => обязательно
export const LoaderModal = ({...props}: PropsType) => {

    const [contentOnHover, setContentOnHover] = useState(
        false
    );

    // скрывать по кнопке
    const hide = () => {
        store.dispatch(setLoaderModalData({}));
    }

    const hideOutSide = () => { // скрывать при клике вне окна
        if (!contentOnHover) {
            store.dispatch(setLoaderModalData({}));
        }
    }

// const lData = {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", countOpenLoaders: 0};

    const lData = props.loaderModal?.data;

    if (!lData)
        return null;
    if (lData.countOpenLoaders <= 0)
        return null;

    let modalStyle = {
        display : "block",
        backgroundColor: "var(--blackout-light)", //#061c3e66, вернуть старое значение если текущее не понравятся
        zIndex : "99999"
    };

    if(lData?.fullBackground) {
        modalStyle.backgroundColor = "var(--blackout)"; //rgba(1,4,35,0.98), вернуть старое значение если текущее не понравятся
    }

    let mod = store.getState().globalState.theme.modString;

    let imageUrl = "";
    if(lData?.gif) {
        if(lData.gif === "documents") imageUrl = `doc${mod}.gif`;
        else if(lData.gif === "packages") imageUrl = `pac${mod}.gif`;
        else if(lData.gif === "search") imageUrl = `search${mod}.gif`;
    }

    return (

        <div className="modal fade show" style={modalStyle} onClick={hideOutSide}>
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                 onMouseEnter={() => {setContentOnHover(true)}}
                 onMouseLeave={() => {setContentOnHover(false)}}>
                <div className="modal-content">
                    <div className="modal-header" style={{borderBottom:"none",justifyContent:"center"}}>
                        <h5 className="modal-title" id="exampleModalLabel"
                            style={{marginRight : "15px"}}>{parse(sanitizeHtml(lData?.content))}</h5>
                        {
                            lData?.gif &&
                            <img width={50} src={`/images/${imageUrl}`} alt="gif"/>
                        }
                    </div>
                    {
                        !lData?.disableButton &&
                        <div className="modal-footer"
                             style={{borderTop:"none", justifyContent:"center", paddingTop:"0.2em"}}>
                            <button type="button" className="btn btn-primary font-weight-bold" onClick={hide}>Окей!</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );


};

type RootReducerType = typeof rootReducer;
type AppStateTempType = ReturnType<RootReducerType>;
export type AppStateType = ReturnType<AppStateTempType>;

const mapStateToProps = (state: AppStateType) => {
    return {
        //TODO почему-то не считывает, видимо потому, что rootReducer js
        // loaderModal : state.globalState.app.loaderModal,
     };
};


const  mapDispatchToProps = {
    setLoaderModalData,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(LoaderModal);
