import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {SEARCH_INPUTS_ACTIONS, SEARCH_ROOT_STATE_ACTIONS} from "../searchActionsList";
import {store} from "../../../index";
import {setPaginationPageNumberSearch} from "./search_table";
import {__} from "../../../tools/HelpFunctions";
import {emptySearchFields} from "../../../tools/consts";






export const updateInputsFieldSearch = ({
                                            FilialIds=1,
                                            SearchText=null,
                                            AttributesText=null,
                                            FullText=null,
                                            TypeIds=null,
                                            RegDateAsString=null,
                                            RegPeriodStart=null,
                                            RegPeriodFinish=null,
                                            Name=null,
                                            RegNumber=null,
                                            VersionNumber=null,
                                            Id,
                                            IsSearchInAttachments=false,
                                            IsSearchInVersions=false,
                                            IsSearchInMarkedForDelete=false,
                                            Next = null,
                                            Error = null
}) =>{

    //if(RegDateAsString!==null)RegDateAsString=RegDateAsString.toString().split("T")[0] + "T00:00:00";
    if(RegPeriodStart !== null && RegPeriodStart !== "")
        RegPeriodStart = RegPeriodStart.toString().split("T")[0] + "T00:00:00";
    else RegPeriodStart = "";

    if(RegPeriodFinish !== null && RegPeriodFinish !== "")
        RegPeriodFinish = RegPeriodFinish.toString().split("T")[0] + "T00:00:00";
    else RegPeriodFinish = "";

//TODO Делал фильтр "С" "По"
    if(TypeIds?.length==0)TypeIds=null;
    return async (dispatch) => {
        try {

            dispatch({
                type : SEARCH_INPUTS_ACTIONS.UPDATE_INPUTS_FIELD,
                payload : {
                    FilialIds,
                    SearchText,
                    AttributesText,
                    FullText,
                    IsSearchInAttachments,
                    IsSearchInVersions,
                    IsSearchInMarkedForDelete,
                    TypeIds,
                    RegDateAsString,
                    RegPeriodStart,
                    RegPeriodFinish,
                    Name,
                    RegNumber,
                    VersionNumber,
                    Id
                }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка сохранения параметров поиска (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }

}




export const searchByParams = ({Id, Next = null, PaginationPageNumber = null, Error = null}) => {
    return async dispatch => {
        try {
            const {search: {searchState: {searchInputsList, searchResults}}} = store.getState();
            const inputFieldsIndex = searchInputsList.findIndex(item => item.Id === Id);
            const resultsFieldsIndex = searchResults.findIndex(item => item.Id === Id);

            await dispatch(setPaginationPageNumberSearch({
                PaginationPageNumber: PaginationPageNumber ? PaginationPageNumber : 1
            }));

            let PageSize = searchResults[resultsFieldsIndex]?.mainTable?.paginationPageSize ?? 25;

            let PageNumber = PaginationPageNumber;
            if(!PaginationPageNumber) {
                PageNumber = searchResults[resultsFieldsIndex]?.mainTable?.paginationPageNumber ?? 1;
            }


            //let test = store.getState().search.mainTable.inputField.TypeIds;
            let FilialIds=[];
            if(searchInputsList[inputFieldsIndex].FilialIds.length > 0){
                FilialIds = searchInputsList[inputFieldsIndex].FilialIds;
            }else{
                FilialIds.push(store.getState().globalState.filial.Active.Id);
            }


            let filialIds = [];
            let isParseFilials = false;
            if(FilialIds.length > 0 && FilialIds[0]?.value) {
                isParseFilials = true;
                for (let i = 0; i < FilialIds.length; i++) {
                    filialIds.push(FilialIds[i].value);
                }
            }

            let TypeIds = searchInputsList[inputFieldsIndex].TypeIds;
            let typeIds = [];
            let isParseTypeIds = false;
            if(TypeIds && TypeIds.length > 0 && TypeIds[0]?.value) {
                isParseTypeIds = true;
                for (let i = 0; i < TypeIds.length; i++) {
                    typeIds.push(TypeIds[i].value);
                }
            }

            let request = new apiRequest(apiUrl.SEARCH.SEARCH_BY_PARAMS, {
                searchParams : {

                    //FilialIds:[store.getState().search.mainTable.inputField.IdFilial],
                    FilialIds:isParseFilials ? filialIds : FilialIds,
                    PagingFilter:
                        {
                            PageSize: PageSize,
                            PageOffset: (PageNumber - 1) * PageSize,
                        }, //по дефолту 25
                    SearchText: searchInputsList[inputFieldsIndex].SearchText,//первое поле
                    AttributesText: searchInputsList[inputFieldsIndex].AttributesText,//поиск по атрибутам
                    FullText: searchInputsList[inputFieldsIndex].FullText,//поиск по полнотексту
                    IsSearchInAttachments: searchInputsList[inputFieldsIndex].IsSearchInAttachments, //искать в тексте прикрепленных файлов
                    IsSearchInVersions: searchInputsList[inputFieldsIndex].IsSearchInVersions, // искать в версиях
                    IsSearchInMarkedForDelete: searchInputsList[inputFieldsIndex].IsSearchInMarkedForDelete, // искать в// помеченных на удаление
                    TypeIds: isParseTypeIds ? typeIds : TypeIds,
                    RegDateAsString: searchInputsList[inputFieldsIndex].RegDateAsString,
                    RegPeriodFinish: searchInputsList[inputFieldsIndex].RegPeriodFinish,
                    RegPeriodStart: searchInputsList[inputFieldsIndex].RegPeriodStart,
                    Name: searchInputsList[inputFieldsIndex].Name,
                    VersionNumber: searchInputsList[inputFieldsIndex].VersionNumber,
                    RegNumber: searchInputsList[inputFieldsIndex].RegNumber,

                }
            });

            request.execute(function(data) {
                const result = {...data, Id};
                // поиск названия вкладки
                let nameTab = '';
                for(let key in searchInputsList[inputFieldsIndex]) {
                    if(typeof searchInputsList[inputFieldsIndex][key] === 'string' && searchInputsList[inputFieldsIndex][key] !== '') {
                        nameTab = searchInputsList[inputFieldsIndex][key];
                        break;
                    }
                }
                dispatch(setActiveTabSearch({Id, name: nameTab}));
                dispatch({type : SEARCH_INPUTS_ACTIONS.GET_DOC, payload : result});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setActiveTabSearch = ({Id, name, Next = null, PaginationPageNumber = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({
                type: SEARCH_ROOT_STATE_ACTIONS.ADD_ACTIVE_TAB,
                payload: {Id, name}
            });
        } catch (exception) {
            console.log("Ошибка выбора активной вкладки (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
export const closeTabSearch = ({Ids = null, Next = null, PaginationPageNumber = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({
                type: SEARCH_ROOT_STATE_ACTIONS.CLOSE_TAB,
                payload: Ids
            });
        } catch (exception) {
            console.log("Ошибка выбора активной вкладки (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
export const addTabSearch = ({Id, name, Next = null, PaginationPageNumber = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({
                type: SEARCH_ROOT_STATE_ACTIONS.ADD_TAB,
                payload: {Id, name}
            });
        } catch (exception) {
            console.log("Ошибка выбора активной вкладки (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
