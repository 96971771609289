// изменяем отображение

import {PACKAGE_ROOT_STATE_ACTIONS} from "../packageActionsList";
import {store} from "../../../index";

export const changePackagesViewMode = ({ViewMode ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: PACKAGE_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE, payload: ViewMode});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const changePackagesDownloadViewMode = ({ViewMode ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: PACKAGE_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE_DOWNLOAD, payload: ViewMode});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// изменяем отображение
export const packsToggleShowAsideMenu = ({isOpen = null}) => {
    return async dispatch => {
        try {
            if(isOpen === null) {
                isOpen = store.getState().packages.rootState.isOpenAsideMenu;
            }
            await dispatch({type: PACKAGE_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU, payload: !isOpen});
        } catch (exception) {
            console.log("Ошибка изменения видимости меню (catch): ", exception);
        }
    }
}
