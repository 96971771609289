import React from "react";
import {connect} from "react-redux";
import {setFilial} from "../../../store/globalState/actionCreators/globalState_UserActionCreator";
import {UserSettingsActionsTypes, UserSettingsNames} from "../../../tools/StaticTypes";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {toggleFilialFolders} from "../../../store/filials/actionCreators/filial_TreeActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history} from "../../../index";
import {createRedactorData} from "../../../store/documents/actionCreators/document_PreviewActionCreator";



// Компонент создает свои же копии,
// поэтому необходимо передавать ему информацию от первого родителя и вниз по цепочке.
// Для этого был использован prevContext
// (кроме this.props.data и this.props.depth)
class TreeFilialFolder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.setFilial = this.setFilial.bind(this);
    }

    // открытие или закрытие папки
    setFilial(data){

        // if(data.Id === 1 && data.type === "filial") {
        //     return;
        // }

        if(data.type === "filial"){

            let isContinue = true;
            if(this.props.redactorData) {
                isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
            }

            if(!isContinue) {
                return;
            }

            if(isContinue && this.props.redactorData) {
                window.onbeforeunload = null;
                window.addEventListener("popstate", null);
            }


            this.props.setFilial({
                Filial:data,
                Next : {
                    action : () => {
                        this.props.updateUserSettings({
                            Name : UserSettingsNames.FILIAL_CONFIG,
                            Action : UserSettingsActionsTypes.Update,
                            Next : {
                                action : () => {
                                    let query = this.props.router.location.query;
                                    let queryLink = "";

                                    if(this.props.router.location.pathname === "/documents") {
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {},
                                            excludedKeys : ["c", "d", "f"]
                                        });
                                    }
                                    else if(this.props.router.location.pathname === "/packages") {
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : 2},
                                            excludedKeys : ["c", "d", "p2", "d2", "dwp", "dwd", "dg2", "dt2", "dd2", "f"]
                                        });
                                    }

                                    this.props.createRedactorData({
                                        CardId : null
                                    });

                                    let link = `${this.props.router.location.pathname}${queryLink}`;

                                    history.push(link);
                                    window.location.reload();
                                },
                                params : {}
                            }
                        })
                    },
                    params: {}
                }
            });
        }else{
            this.props.toggleFilialFolders({
                IdFolder : data.Id
            })
        }
    }

    render() {
        let data = this.props.data;
        let subItemStyle = this.props.openedFolders.find(item => item === this.props.data.Id) ? {} : {display: "none"};

        let iconStyle = "icon-filial_user_add fsic4";
        if(data.type === "filial"){
            iconStyle = "icon-demo_filial_2 fsic4";
        }

        let isActive = false;
        if(data.Id === this.props.activeFilialId && data.type === "filial") {
            isActive = true;
        }

        let iconColor = "icon-color-primary-dark";
        let textColor = "text-color-primary-dark";
        if(this.props.isActive || isActive) {
            iconColor = "icon-color-secondary";
            textColor = "text-color-secondary";
        }
        //
        // if(data.Id === 1 && data.type === "filial") {
        //     iconColor = "icon-color-grey";
        //     textColor = "text-color-grey";
        // }

        return(
            <React.Fragment>
                <li className="menu-link-p menu-item">
                            <span className="menu-link menu-toggle" onClick={() => {this.setFilial(data)}}>
                                <span className="svg-icon menu-icon">
                                    <i className={`${iconStyle} ${iconColor}`}/>
                                </span>
                                <span className={`menu-text ${textColor}`}>
                                    {data.Name}
                                </span>
                                {
                                    (data.children && data.children.length > 0) &&
                                    <i className="arrow_m arrow-bottom icon-color-primary-dark icon-Arrows_1"/>
                                }

                            </span>

                    {
                        (data.children && data.children.length > 0) &&
                        data.children.map((item, index) => {

                                let isActive = false;
                                if(item.Id === this.props.activeFilialId) {
                                    isActive = true;
                                }

                                return (
                                    <div className="menu-submenu menu-submenu-p" key={item.Name} style={subItemStyle}>
                                        <ul className="menu-subnav mb-1">
                                            <TreeFilialFolder
                                                data={item}
                                                openedFolders={this.props.openedFolders}
                                                setFilial={this.props.setFilial}
                                                updateUserSettings={this.props.updateUserSettings}
                                                toggleFilialFolders={this.props.toggleFilialFolders}
                                                activeFilialId={this.props.activeFilialId}
                                                isActive={isActive}
                                                router={this.props.router}
                                                redactorData={this.props.redactorData}
                                                createRedactorData={this.props.createRedactorData}
                                                // depth={depth + 1}
                                            />
                                        </ul>
                                    </div>
                                );
                            }
                        )
                    }
                </li>
            </React.Fragment>
        );
    }


}


const  mapStateToProps = state => {
    return {
        openedFolders : state.filial.tree.openedFolders,
        router : state.router,
        redactorData : state.document.cards.redactorData
    }
}

const  mapDispatchToProps = {
    setFilial,
    updateUserSettings,
    toggleFilialFolders,
    createRedactorData
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeFilialFolder);
