import {PackageTreeItem} from "./PackageTreeItem";

export class PackageTree extends PackageTreeItem {


    TreeType;

    constructor(Data) {
        super(Data);
        this.TreeType = Data.TreeType;
    }
}