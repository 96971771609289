import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import {ResizableBox} from 'react-resizable';
import {
    setContextMenuData,
    setLoaderModalData
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {contextMenuTypes} from "../../../tools/StaticTypes";
import {
    changeSortDirection, PackagesPaginationMovePage,
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    getDocumentsForTreePackages, PackageAddNodesTree,
    PackageCreateTree, SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {
    handlerSearchPackages,
    searchByParamsPackages,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {__} from "../../../tools/HelpFunctions";


class ThMainTablePackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultWidth: (this.props.data.Value.length * 13) + 70,
            //TODO aa - исправлен баг кнопки вписать
            width: this.props.data.Size ? this.props.data.Size : (this.props.data.Value.length * 13) + 70,
            // width : (this.props.data.Value.length * 13) + 70
        };

        this.onResize = this.onResize.bind(this);
        this.getCurrentSearchInputs = this.getCurrentSearchInputs.bind(this);
        this.search = this.search.bind(this)
    }

    onResize = (event, {element, size, handle}) => {
        this.setState({width: size.width});
        this.props.setItemSize(size.width);
        this.props.setWidth(size.width);
    };


    componentDidMount() {
        this.props.setWidth(0);
        this.props.setItemSize(0);
        this.setState({width: (this.props.data.Value.length * 13) + 70})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let lSize = this.props.data.Size ? this.props.data.Size : (this.props.data.Value.length * 13) + 70;

        if (prevProps.data.Size != this.props.data.Size)
            this.setState({width: lSize})
    }

    getAppliedParams(searchInputs) {
        const result = {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false,}
        const {RegNumber, Name, TypeName, ModifyDate, RegDateAsString} = searchInputs
        if (Object.values(RegNumber).some(item => item)) {
            result.RegNumber = true
        }
        if (Object.values(Name).some(item => item)) {
            result.Name = true
        }
        if (Object.values(TypeName).some(item => item)) {
            result.TypeName = true
        }
        if (Object.values(ModifyDate).some(item => item)) {
            result.ModifyDate = true
        }
        if (Object.values(RegDateAsString).some(item => item)) {
            result.RegDateAsString = true
        }
        return result
    }

    getCurrentSearchInputs() {
        let searchInputs;

        if (this.props.tableRole === "main") {
            const {searchInputsMain} = this.props;
            if (searchInputsMain) {
                searchInputs = searchInputsMain
            }
        } else if (this.props.tableRole === "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional) {
                searchInputs = searchInputsAdditional
            }

        } else if (this.props.tableRole === "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload) {
                searchInputs = searchInputsDownload
            }

        }

        return searchInputs;
    }

    async search(event) {
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "ThMainTable6963"
            }
        });
        const sortedParams = __.deepCopy(this.props.sortedParams)
        let Value
        if (this.props.data.Name !== "Version.ModifyTime" && this.props.data.Name !== "Version.Version") {
            Value = (sortedParams[this.props.data.Name] % 2) +1
        } else if (this.props.data.Name === "Version.ModifyTime"){
            Value = (sortedParams["ModifyDate"] % 2) + 1
        }
        let SortingParam = this.props.data.Name !== "Version.ModifyTime" ? this.props.data.Name : "ModifyDate"
        this.props.updateInputsFieldSortingPackage({TableRole: this.props.tableRole, SortingParam, Direction: Value})
        this.props.changeSortDirection({dir: !this.props.sortDir, sortField: this.props.data})
        await this.props.PackagesPaginationMovePage({
            PageNumber: 1,
            TableType: this.props.tableRole
        });
        let searchInputs = this.getCurrentSearchInputs()
        const appliedParams = this.getAppliedParams(searchInputs)
        let lActiveNode;
        if (this.props.tableRole === "main")
            lActiveNode = this.props.activeNode.Node.Id
        else if (this.props.tableRole === "additional")
            lActiveNode = this.props.activeNodeAdditional.Node.Id
        else if (this.props.tableRole === "download")
            lActiveNode = this.props.activeNodeDownload.Node.Id;
        let Direction = "";
        if (this.props.sortDir) {
            Direction = Value;
        } else {
            Direction = Value;
        }
        this.props.updateInputsFieldPackage({
            TableRole: this.props.tableRole,
            IsSearch: searchInputs.IsSearch,
            RegDateAsString: searchInputs.RegDateAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            RegNumber: searchInputs.RegNumber ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            Name: searchInputs.Name ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            TypeName: searchInputs.TypeName ?? {In: ''},
            ModifyDate: searchInputs.ModifyDate ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            AppliedParams: appliedParams,
            Next: {
                action: () => {
                    this.props.PackageAddNodesTree({
                        FieldSorting: this.props.data,
                        Direction,
                        Id: lActiveNode,
                        TableType: this.props.tableRole,
                        Next: {
                            action: () => {
                                this.props.searchByParamsPackages({
                                    TableType: this.props.tableRole,
                                    FieldSorting: this.props.data,
                                    Direction,
                                    Next: {
                                        action: () => {
                                            this.props.handlerSearchPackages({
                                                TableType: this.props.tableRole,
                                                Next: {
                                                    action: () => {
                                                        this.props.SelectActivePackageNode({
                                                            Id: lActiveNode,
                                                            TableType: this.props.tableRole,
                                                        })
                                                        this.props.setLoaderModalData({keyDeleted: "ThMainTablePackage123"});},
                                                    params: {}
                                                }
                                            })
                                        },
                                        params: {}
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    rightClick(event, item) {
        this.props.setContextMenuData({
            name : contextMenuTypes.MainTablePackageTH,
            data : {
                tableRole: this.props.tableRole,
                th : this.props.data
            },
            position : {
                x : event.clientX,
                y : event.clientY}
        });
        event.preventDefault();
    }


    render() {

        let width = {
            width: this.state.width + "px", // TODO: Подобрать норм размеры изначальные (тут пока на глаз сделал)
            position: "relative",
            paddingLeft: "0px",
            //overflow: "hidden",
            // textOverflow: "ellipsis",
            //whiteSpace : "nowrap",
        }
        let styleSpan = {
            marginRight: "20px",
        }
        let styleI = {
            verticalAlign: "middle",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }

        //TODO БАГ. Фильтры в Пакетах при открытой доп таблице

        // const {searchInputs, data} = this.props;
        const {data} = this.props;

        let searchInputs;
        let sortedParams;

        if (this.props.tableRole == "main") {
            const {searchInputsMain, sortedParamsMain} = this.props;

            searchInputs = searchInputsMain
            sortedParams = sortedParamsMain

        } else if (this.props.tableRole == "additional") {
            const {searchInputsAdditional, sortedParamsAdditional} = this.props;

            searchInputs = searchInputsAdditional
            sortedParams = sortedParamsAdditional

        } else if (this.props.tableRole == "download") {
            const {searchInputsDownload, sortedParamsDownload} = this.props;

            searchInputs = searchInputsDownload
            sortedParams = sortedParamsDownload
        }

        let iconClass="icon-Filter fsic"

        if (this.props.data.Name !== "Version.ModifyTime" && this.props.data.Name !== "Version.Version") {
            const curParam = sortedParams[this.props.data.Name]
            if (curParam !== 0) {
                iconClass = curParam === 1 ? "icon-Filter_down" : "icon-Filter_up"
            }
        } else if (this.props.data.Name === "Version.ModifyTime") {
            const curParam = sortedParams["ModifyDate"]
            if (curParam !== 0) {
                iconClass = curParam === 1 ? "icon-Filter_down" : "icon-Filter_up"
            }
        }


                // if(searchInputs.find(item => {
                //     if(item.DirectionUp.indexOf("original") !== -1) {
                //         return item;
                //     }
                //     else if(item.DirectionDown.indexOf("original") !== -1) {
                //         return item;
                //     }
                // })){
                //     for (let i = 0; i < this.props?.iconSorting.length; i++) {
                //
                //         if(this.props?.iconSorting[i].DirectionDown.indexOf("icon-color-original")!==-1){
                //             if(this.props.iconSorting[i].isConstant){
                //                 if(this.props.data.Value===this.props.iconSorting[i].Attribute){
                //                     iconClass="icon-Filter_down"
                //                 }
                //             }else{
                //                 if(this.props.data.Value===this.props.iconSorting[i].Attribute.AttributeName.Name){
                //                     iconClass="icon-Filter_down"
                //                 }
                //             }
                //
                //         }else if(this.props?.iconSorting[i].DirectionUp.indexOf("icon-color-original")!==-1){
                //             if(this.props.iconSorting[i].isConstant){
                //                 if(this.props.data.Value===this.props.iconSorting[i].Attribute){
                //                     iconClass="icon-Filter_up"
                //                 }
                //             }else{
                //                 if(this.props.data.Value===this.props.iconSorting[i].Attribute.AttributeName.Name){
                //                     iconClass="icon-Filter_up"
                //                 }
                //             }
                //         }
                //
                //     }
                // }

                let colorStyle = "icon-color-original";
        let colorText = "";
        if (searchInputs) {
            let isFilter = false;
            for (let key in searchInputs) {
                if (
                    this.props?.data &&
                    key === this.props.data.Name &&
                    searchInputs.AppliedParams &&
                    searchInputs.AppliedParams[key]
                ) {
                    isFilter = true;
                }

                if (
                    key === "ModifyDate" &&
                    data &&
                    data.Name === "Version.ModifyTime" &&
                    searchInputs.AppliedParams &&
                    searchInputs.AppliedParams[key]
                ) {
                    isFilter = true;
                } else if (
                    key === "RegDateAsString" &&
                    data?.Name === "RegDate" &&
                    searchInputs.AppliedParams &&
                    searchInputs.AppliedParams[key]
                ) {
                    isFilter = true;
                }
            }
            //TODO Костыль был
            // if(this.props.tableRole === "main") {
            if (isFilter) {
                colorStyle = "icon-color-secondary";
                colorText = "text-color-secondary";
            }
            // }
        }
        if (this.props.data.Name !== "Version.Version") {
            return (
                <th className="i2color"
                    onContextMenu={(event) => {this.rightClick(event)}}
                    style={width}
                    title={this.props.data.Value}>
                    <ResizableBox style={{display: "inline-block"}}
                                  width={this.state.width}
                                  height={0}
                                  onResize={this.onResize}
                                  minConstraints={[this.state.defaultWidth / 2.5, 0]}
                                  maxConstraints={[this.state.defaultWidth * 3, 0]}
                                  axis={'x'}
                                  handle={(h, ref) => <span style={{
                                      cursor: "col-resize",
                                      position: "absolute",
                                      right: 0,
                                      width: "24px",
                                      background: "#fff",
                                      height: "24px"
                                  }} ref={ref}><i className={"svg-icon icon-Doc_view_2"}/></span>}
                    >
                            <span style={styleSpan}>
                              <span style={styleI}>  <i
                                  className={`svg-icon ${colorStyle} svg-icon-sm ${iconClass}`}> </i></span>&nbsp;&nbsp;
                                <span className={colorText} role="button"
                                      onClick={(event) => this.search()}
                                >
                                    {this.props.data.Value}
                                </span>
                            </span>
                    </ResizableBox>
                </th>
            );
        } else return null;
    }
}


const mapStateToProps = state => {
    return {
        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        activeNodeDownload: state.packages.treeDownload.activeNode,
        activeNodeId: state.packages.tree.activeNode.Node.Id,
        changeViewModeSearch: state.document.rootState.viewModeSearch,
        pageSize: state.packages.mainTable.paginationPageSize,
        searchInputs: state.packages.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,
        searchInputsMain: state.packages.tree.searchInputs,
        sortDir: state.packages.mainTable.sortDirection,
        sortField: state.packages.mainTable.sortField,
        sortedParams: state.packages.search.SortedParams,
        sortedParamsAdditional: state.packages.treeAdditional.SortedParams,
        sortedParamsDownload: state.packages.treeDownload.SortedParams,
        sortedParamsMain: state.packages.tree.SortedParams,
    }
}

const mapDispatchToProps = {
    changeSortDirection,
    getDocumentsForTreePackages,
    handlerSearchPackages,
    PackageCreateTree,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage,
    setLoaderModalData,
    searchByParamsPackages,
    SelectActivePackageNode,
    setContextMenuData
}

export default connect(mapStateToProps, mapDispatchToProps)(ThMainTablePackage);
