import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {
    ModalTypes,
    RootComponentsStateViewModeTypes,
} from "../../tools/StaticTypes";
import {searchByParams, updateInputsFieldSearch} from "../../store/search/actionCreators/search_Inputs";
import {
    combineMainTableAttributesSearch,
    verifyDocClientLinkIdsDocs
} from "../../store/search/actionCreators/search_table";
import {API} from "../../tools/API_NEW/API";
import {history, store} from "../../index";
import {apiRequest} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";
import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../tools/Filters";
import {__} from "../../tools/HelpFunctions";

import "../../media/styles/css_dp/alert.css";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";
import {ActionQueue} from "../../store/rootReducer";


class HistoryHome extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            attributes: false,
            fullText: false,
            date: false,
            additionalFilters: false,
            packageRecords: [],
            RecordsDoc: [],

            isLoading: true
        };


        this.search = this.search.bind(this);
        this.goToDocuments = this.goToDocuments.bind(this);
        this.goToPackage = this.goToPackage.bind(this);
        this.buildLink = this.buildLink.bind(this);
    }

    async componentDidMount() {
        let packageHistory = this.props.userSettings.find((userSetting => userSetting.Name === "packageConfig"));
        let documentHistory = this.props.userSettings.find((userSetting => userSetting.Name === "documentConfig"));

        let allDocs = [];
        let allPacks = [];
        for(let i = 0; i < this.props.allFilials.length; i++) {
            let testArrDoc = [];
            let testArrPackage = [];

            if (documentHistory) {
                for (let j = 0; j < documentHistory.Value.length; j++) {
                    if (documentHistory.Value[j].filial === this.props.allFilials[i].Id) {
                        testArrDoc.push(documentHistory.Value[j].IdDoc)
                    }
                }
            }
            if (testArrDoc.length > 0) {
                let requestDoc = await new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                    filter: new Filter(FiltersType.ENTITY)
                        .add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Id", Value: testArrDoc, Operator: 8})
                                .add({Name: "IdFilial", Value: this.props.allFilials[i].Id, Operator: 8})
                                .get()
                        ).get(),
                    sorting: new Sorting()
                        .addAttribute({Name: "IdAttributeName", Value: "-1"})
                        .add({PropertyName: "RegDate", Direction: 2})
                        .add({PropertyName: "RegNumber", Direction: 1})
                        .add({PropertyName: "Id", Direction: 1})
                        .get()
                })
                await requestDoc.execute((data) => {
                    data.Records.map(item => allDocs.push(item));
                });

            }

            if(packageHistory) {
                for (let j = 0; j < packageHistory.Value.length; j++) {
                    if (packageHistory.Value[j].filial === this.props.allFilials[i].Id) {
                        testArrPackage.push(packageHistory.Value[j].Id)
                    }
                }
            }
            if(testArrPackage.length > 0) {
                let request = await new apiRequest(apiUrl.PACKAGE.PAC_TREE, {
                    filter: new Filter(FiltersType.ENTITY)
                        .add(
                            new EntityFilters().setEntityName("DocPackageExt")
                                .add({Name: "Id", Value: testArrPackage, Operator: 8})
                                .add({Name: "IdFilial", Value: this.props.allFilials[i].Id, Operator: 8})
                                .get()
                        ).get(),
                        sorting: null
                    }
                )
                await request.execute((data) => {
                    data.Records.map(item => allPacks.push(item));
                })
            }
        }

        if(allDocs.length > 0) {
            this.setState({RecordsDoc: allDocs});
        }

        if(allPacks.length > 0) {
            this.setState({packageRecords: allPacks});
        }
    }


    search(item) {
        this.props.ActionQueue({
            List : [
                {action : this.props.setLoaderModalData, params : {
                        data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "search", key : "HistoryHome128" }
                    }, name : ""},
                {action : this.props.updateInputsFieldSearch, params : {
                        SearchText: item.SearchText,
                        AttributesText: item.AttributesText,
                        FullText: item.FullText,
                        IsSearchInVersions: item.IsSearchInVersions,
                        IsSearchInMarkedForDelete: item.IsSearchInMarkedForDelete,
                        IsSearchInAttachments: item.IsSearchInAttachments,
                        TypeIds: item.TypeIds,
                        FilialIds: item.FilialIds,
                        RegDateAsString: item.RegDateAsString,
                        RegPeriodStart: item.RegPeriodStart,
                        RegPeriodFinish: item.RegPeriodFinish,
                        RegNumber: item.RegNumber,
                        Name: item.Name
                    }, name : "updateInputsFieldSearch"},
                {action : this.props.searchByParams, params : {}, name : "searchByParams"},
                {action : this.props.combineMainTableAttributesSearch, params : {}, name : "combineMainTableAttributesSearch"},
                {action : this.props.changeSearchViewMode, params : {ViewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly}, name : "changeSearchViewMode"},
                {action : this.props.setLoaderModalData, params : {keyDeleted: "HistoryHome148"}, name : "setLoaderModalData"},
                {action : () => history.replace("/search"), params : {}, name : "history.replace"},
            ],
            debug : false,
        });
    }

    async buildLink(item) {

        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (this.props.verifyDocs)
        {
            if (this.props.verifyDocs[item.IdDoc]) {
                vFlagVerify = true;
                docRefInfo = this.props.verifyDocs[item.IdDoc][0];
            }
        }

        if (!vFlagVerify) {
             docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: item.IdDoc,
                    IdFilial: item.filial
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: item.IdDoc,
                    data: docRefInfo
                }
            });
        }


        if (docRefInfo.errorCode) {
            if (docRefInfo.message) {
                if (docRefInfo.message.Message) {
                    store.dispatch(setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            content: docRefInfo.message.Message,
                            disableButton: false,
                            type: "fail"
                        }
                    }));
                    return;
                }
            }
        }
        else {
            let link = `/documents`;
            let postfix = "";
            if (docRefInfo.IdDocGroup)
                postfix += `?g=${docRefInfo.IdDocGroup}`;
            if (docRefInfo.IdDocType)
                if (postfix != "")
                    postfix += `&t=${docRefInfo.IdDocType}`
                else
                    postfix += `?t=${docRefInfo.IdDocType}`;
            if (docRefInfo.IdDoc)
                if (postfix != "")
                    postfix += `&d=${docRefInfo.IdDoc}`;
                else
                    postfix += `?d=${docRefInfo.IdDoc}`;
            link += postfix;


            // let link = `/documents?g=${docRefInfo.IdDocGroup}&t=${docRefInfo.IdDocType}&d=${docRefInfo.IdDoc}`;
            if (item.filial !== this.props.activeFilialId) {
                link = link.concat(`&f=${item.filial}`);
            }

            history.replace(link);
        }
    }

    async goToDocuments(item) {
        //Задача № 22235
        // store.dispatch(setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents"}
        // }));

        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "HistoryHome265" }
        }));


        //Если прелоадер завис!
        // setTimeout(() => {
        //     if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
        //         this.props.setModalData({})
        // }, 10000);

        if(item.filial !== this.props.activeFilialId) {
            let filialName = this.props.allFilials.find(filial => filial.Id === item.filial).Name;
            this.props.setModalData({
                name : ModalTypes.app.info,
                data : {
                    type: "question",
                    content : `Данный документ был открыт в другом филиале: <b>${filialName}</b> <br />Сменить филиал и перейти к документу?`,
                    disableButton : false,
                    fullBackground : false,
                    onClickContinue: async () => {await this.buildLink(item)}}
            });
            this.props.setLoaderModalData({keyDeleted: "HistoryHome289"});
            return;
        }

        //TODO 289 и 293, добавлено, чтобы убрать бесконечный прелоадер при переходе с домашней страницы по документу
        this.props.setLoaderModalData({keyDeleted: "HistoryHome293"});
        await this.buildLink(item);
    }

    async goToPackage(item) {
        let link = `/packages?p=${item.Id}`;
        if(item.filial != this.props.activeFilialId) {
            let filialName = this.props.allFilials.find(filial => filial.Id == item.filial).Name;

            this.props.setModalData({
                name : ModalTypes.app.info,
                data : {
                    type: "question",
                    content: `Данный пакет был открыт в другогом филиале: <b>${filialName}</b> <br />Сменить филиал и перейти к пакету?`,
                    disableButton: false,
                    fullBackground: false,
                    onClickContinue: async () => {history.replace(link.concat(`&f=${item.filial}`))}
                }
            });
            return;
        }

        history.replace(link);
    }

    render() {
        let documentHistory = __.deepCopy(this.props.userSettings.find((userSetting => userSetting.Name === "documentConfig")));
        //let documentHistory = this.props.userSettings.find((userSetting => userSetting.Name === "documentConfig"));
        let searchHistory = this.props.userSettings.find((userSetting => userSetting.Name === "searchConfig"));
        let packageHistory = __.deepCopy(this.props.userSettings.find((userSetting => userSetting.Name === "packageConfig")));

        if (packageHistory) {
            packageHistory.Value.map(item => {
                const buf = this.state.packageRecords.find(pack => pack.Id === item.Id);
                if(buf) item.Name = buf.Name;
                return item
            })
        }

        if (documentHistory) {
            //TODO aa 15.03.2023 нужно ли заменять documentHistory документами с правами?
            documentHistory.Value.map(item => {
                const buf = this.state.RecordsDoc.find(doc => doc.Id === item.IdDoc);

                if(buf) item.Name = buf.Name + " " + buf?.ShortDescription
                return item
            })
        }

        // let attributes
        // let fullText
        // let date
        // let additionalFilters

        // if (this.state.attributes) {
        //     attributes = "btn-light-success"
        // } else {
        //     attributes = "btn-light"
        // }
        //
        // if (this.state.fullText) {
        //     fullText = "btn-light-success"
        // } else {
        //     fullText = "btn-light"
        // }
        //
        // if (this.state.date) {
        //     date = "btn-light-success"
        // } else {
        //     date = "btn-light"
        // }
        //
        // if (this.state.additionalFilters) {
        //     additionalFilters = "btn-light-success"
        // } else {
        //     additionalFilters = "btn-light"
        // }

        return (

            // <div className="row">
            <>
                {
                    documentHistory?.Value &&
                    <div className="row col-lg-12">
                        <div className="col-lg-12">
                            <div className="card card-custom gutter-b">


                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">Последние документы</h3>
                                    </div>
                                </div>


                                <div className="card-body">

                                    <div className="row">
                                        {
                                            documentHistory?.Value &&
                                            documentHistory?.Value.map((item, index) => {

                                                let iconColorStyle = {};
                                                if(item.filial != this.props.activeFilialId) {
                                                    iconColorStyle = {
                                                        opacity : "50%"
                                                    }
                                                }

                                                return (
                                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6"
                                                         title={item.Name}
                                                         key={createUniqueIdString(6)}>
                                                        <div className="packet packet-custom packet-stretch pb-4">
                                                        <span className="link" onClick={() => {this.goToDocuments(item)}}>
                                                            <div className="packet-body text-center pt-1">
                                                                <div className="my-2" role="button" >
                                                                    <div className="symbol">
                                                                        <span className="svg-icon menu-icon icon-xl" style={iconColorStyle}>
                                                                            <i className={`icon-Documents icon-color-primary`}
                                                                               style={{fontSize: "4rem"}}/>
                                                                        </span>
                                                                    </div>
                                                                 </div>
                                                                <div className="my-1">
                                                                    <p className="text-dark font-weight-bold">{item.Name}</p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !documentHistory?.Value &&
                    <div className="row col-lg-12">
                        <div className="col-lg-12">
                            <div className="card card-custom gutter-b col-lg-12">
                            <div className="card-header">
                                <div className="card-title"><h3 className="card-label">
                                    Отсутствует история последних документов</h3></div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="alert alert-custom alert-primary center">
                                        <div className="alert-text">Вы сможете увидеть историю просмотра или
                                            поиска после того, как воспользуетесь одним из разделов программы.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }


                {
                    packageHistory?.Value &&
                    <div className="row col-lg-12">
                        <div className="col-lg-12">
                            <div className="card card-custom gutter-b">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">Последние пакеты</h3>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row">

                                        {
                                            packageHistory?.Value &&
                                            packageHistory?.Value.map((item, index) => {

                                                let iconColorStyle = {};
                                                if(item.filial != this.props.activeFilialId) {
                                                    iconColorStyle = {
                                                        opacity : "50%"
                                                    }
                                                }


                                                return (
                                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6"
                                                         key={createUniqueIdString(6)}>
                                                        <div className="packet packet-custom packet-stretch pb-4">
                                                    <span className="link" onClick={() => {
                                                        this.goToPackage(item)
                                                    }}>
                                                        <div className="packet-body text-center pt-1">
                                                            <div className="my-2">
                                                <span className="svg-icon menu-icon icon-xl" style={iconColorStyle}>
                                                    <i className={`icon-pak_on icon-color-primary`}
                                                       style={{fontSize: "4rem"}}/>
                                                </span>
                                                            </div>
                                                            <div className="my-1">
                                                                <p className="text-dark font-weight-bold">{item.Name}</p>
                                                            </div>
                                                        </div>
                                                    </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !packageHistory?.Value &&
                    <div className="row col-lg-12">
                        <div className="col-lg-12">
                            <div className="card card-custom gutter-b col-lg-12">
                            <div className="card-header">
                                <div className="card-title"><h3 className="card-label">
                                    Отсутствует история последних пакетов</h3></div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="alert alert-custom alert-primary center">
                                        <div className="alert-text">Вы сможете увидеть историю просмотра или
                                            поиска после того, как воспользуетесь одним из разделов программы.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }


                {
                    searchHistory?.Value &&
                    <div className="row col-lg-12">
                        <div className="col-lg-12">
                            <div className="card card-custom gutter-b mb-2">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label">История поиска</h3>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }

                <div className="row col-lg-12">
                    <div className="col-lg-12">
                        <div className="row">
                            {
                                searchHistory?.Value &&
                                searchHistory.Value.map((item, index) => {
                                    //let filial = this.props.filials.find(f => f.Id == item.filial);
                                    //let filial = this.props.allFilials.find(f => f.Id == item?.filial)?.Name;
                                    let date = "btn-light";
                                    let attributes = "btn-light";
                                    let fullText = "btn-light";
                                    let additionalFilters = "btn-light";
                                    if (item.RegDateAsString || item.RegPeriodStart || item.RegPeriodFinish) {

                                        date = "btn-light-success";
                                    }
                                    if (item.AttributesText) {
                                        attributes = "btn-light-success";
                                    }
                                    if (item.FullText) {
                                        fullText = "btn-light-success";
                                    }
                                    if (item.FilialIds.length > 0 || item.IsSearchInAttachments || item.IsSearchInVersions || item.IsSearchInMarkedForDelete || item.TypeIds?.length > 0 || item.Name || item.RegNumber) {
                                        additionalFilters = "btn-light-success";
                                    }
                                    return (
                                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6" key={createUniqueIdString(6)} style={{maxWidth : "20%"}}>
                                            <div className="card card-custom gutter-b card-stretch">
                                                <div className="card-body text-left pt-4">
                                                    {/*<div className="my-6" style={{display: "flex",flexFlow: "row wrap",justifyContent: "space-evenly"}}>*/}
                                                    <div className="my-6" >
                                                        <span
                                                            className="text-dark font-weight-bold text-hover-primary font-size-h4"
                                                            style={{cursor: "help"}}
                                                            title={`${item.SearchText ? item.SearchText : "Отсутствует"}`}>
                                                             {`${item.SearchText ? item.SearchText : "Отсутствует"}`}
                                                        </span>
                                                        {/*<span*/}
                                                        {/*    className="text-dark font-weight-bold text-hover-primary font-size-h4"*/}
                                                        {/*    style={{cursor: "help"}}*/}
                                                        {/*    title={`Найдено: ${item.totalInServer ? item.totalInServer : ""}`}>*/}
                                                        {/*    {`Найдено: ${item.totalInServer ? item.totalInServer : ""}`}*/}
                                                        {/*</span>*/}
                                                        {/*<span*/}
                                                        {/*    className="text-dark font-weight-bold text-hover-primary font-size-h4"*/}
                                                        {/*    style={{cursor: "help"}}*/}
                                                        {/*    title={`${filial ? filial : ""}`}>*/}
                                                        {/*    {`ЛФО: ${filial ? filial : ""}`}*/}
                                                        {/*</span>*/}
                                                    </div>

                                                    <span
                                                        className={`btn btn-text ${attributes} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`${item.AttributesText ? item.AttributesText : "Нет"}`}>
                                                        Атрибуты
                                                    </span>

                                                    <span
                                                        className={`btn btn-text ${fullText} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`${item.FullText ? item.FullText : "Нет"}`}>
                                                        Полнотекст
                                                    </span>

                                                    <span
                                                        className={`btn btn-text ${date} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`Дата : ${item.RegDateAsString ? item.RegDateAsString : "Нет"}, C: ${item.RegPeriodStart ? item.RegPeriodStart : "Нет"}, ПО: ${item.RegPeriodFinish ? item.RegPeriodFinish : "Нет"}`}>
                                                        Дата
                                                    </span>

                                                    <span
                                                        className={`btn btn-text ${additionalFilters} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`Поиск в прикрепленных: ${item.IsSearchInAttachments ? "Да" : "Нет"}, Поиск в версиях:  ${item.IsSearchInVersions ? "Да" : "Нет"}, Поиск по удаленным: ${item.IsSearchInMarkedForDelete ? "Да" : "Нет"}, Наименование: ${item.Name ? item.Name : "Нет"}, Номер: ${item.RegNumber ? item.RegNumber : "Нет"}`}>
                                                        Дополнительные фильтры
                                                    </span>

                                                    <div className="mt-9" title={"Перейти к результатам поиска"}>
                                                        <span
                                                            className="btn btn-success font-weight-bolder btn-sm py-3 px-6 text-uppercase"
                                                            onClick={() => {
                                                                this.search(item)
                                                            }}>
                                                            <i className="svg-icon svg-icon-xl icon-Search_menu"/>
                                                            Найти
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );

                                })
                            }
                        </div>

                        {
                            !searchHistory?.Value &&
                            <div className="card card-custom gutter-b col-lg-12">
                                <div className="card-header">
                                    <div className="card-title"><h3 className="card-label">
                                        Отсутствует история поиска</h3></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="alert alert-custom alert-primary center">
                                            <div className="alert-text">Вы сможете увидеть историю просмотра или
                                                поиска после того, как воспользуетесь одним из разделов программы.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
            // </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        activeFilialId: state.globalState.filial.Active.Id,
        allFilials : state.globalState.filial.All,
        userSettings: state.globalState.userSettings,
        verifyDocs: state.document.verifyDocs,
    }
}

const mapDispatchToProps = {
    ActionQueue,
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    updateInputsFieldSearch,
    searchByParams,
    setLoaderModalData,
    setModalData,
    verifyDocClientLinkIdsDocs,
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryHome);
