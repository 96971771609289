import React from "react";
import {connect} from "react-redux";
import MySelect from "./MySelect";
import {saveValue} from "../../store/reports/actionCreators/report_Input";
import {downloadReport, getState, sendReport} from "../../store/reports/actionCreators/report_Send";
import Select from "react-select";
import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ModalTypes} from "../../tools/StaticTypes";
import {__} from "../../tools/HelpFunctions";

// const animatedComponents = makeAnimated();


class InputsReport extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isReady: false,
            isListData: false,
            typesOptions2: [],
            FilialIds: [],

            InputsValues: {},
            currentInput: {}
        };
        this.inputHandler = this.inputHandler.bind(this);
        this.sendReport = this.sendReport.bind(this);
        this.dataList = this.dataList.bind(this);
        this.filialSelectOnChange = this.filialSelectOnChange.bind(this);
        this.clearFields = this.clearFields.bind(this);

        //Динамическое создание ref в зависимости от количества полей ввода в props

        //Изначально было "i < this.props.inputs.length", но с этим был связан
        //один непонятый баг, при котором крашилось приложение.
        for (let i = 0; i < 25; i++) {
            this["inpRef" + [i]] = React.createRef();
        }
    }

    componentWillMount() {
        let typesOptions2 = [];
        typesOptions2.push({
            value: null,
            label: "Не выбрано",
            isDisabled: true
        });
        for (let i = 0; i < this.props.allFilial.length; i++) {
            typesOptions2.push({
                value: this.props.allFilial[i].Id,
                label: this.props.allFilial[i].Name,
                isDisabled: false
            });
        }
        this.setState({
            typesOptions2: typesOptions2
        })
    }

    componentWillReceiveProps(nextProps, nextState) {
        let isFilialNext = false;
        for (let i = 0; i < nextProps.inputs.length; i++) {
            if(nextProps.inputs[i]?.Name == "idFilial"){
                isFilialNext=true;
            }
        }
        let isFilial = false;
        for (let i = 0; i < this.props.inputs.length; i++) {
            if(this.props.inputs[i]?.Name == "idFilial"){
                isFilial=true;
            }
        }
        if(isFilialNext && !isFilial){
            let newFilialIds = __.deepCopy(this.state.FilialIds);
            newFilialIds.value = null;
            newFilialIds.label = "Не выбрано";
            this.setState({
                FilialIds:newFilialIds
            })
        }

    }

    filialSelectOnChange(item){
        this.setState({FilialIds:item});

        this.props.saveValue({
            nameField : "idFilial",
            value : item.value,
        });

    }

    inputHandler(event, item) {

        if(item.TypeCode==5 && item.IsList){
            this.setState({
                isListData:!this.state.isListData
            })
        }
        if(item.TypeCode==4){
            event.target.value="on" ? 1 : 0;
        }

        this.props.saveValue({
            nameField : item.Name,
            value : event.target.value,
        });


        let InputsValuesNew = __.deepCopy(this.state.InputsValues);
        InputsValuesNew[item.Name] = event.target.value;

        this.setState({
            InputsValues : InputsValuesNew
        });

    }

    clearFields() {

        let newFilialIds = __.deepCopy(this.state.FilialIds);
        newFilialIds.value = null;
        newFilialIds.label = "Не выбрано";
        this.setState({
            FilialIds:newFilialIds
        })
        let bufArr = Object.keys(this.state.InputsValues);
        let InputsValuesNew={};
        for(let i = 0; i < bufArr.length; i++){

            InputsValuesNew[bufArr[i]] = "";
        }

        this.setState({
            InputsValues : InputsValuesNew
        })
    }

    sendReport(){
        //Новый прелоадер
        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка отчета...", disableButton : true, fullBackground : false, gif : "documents"}
        // });
        this.props.setLoaderModalData({
            data : {content : "Загрузка отчета...", disableButton : true, fullBackground : false, gif : "documents", key: "InputReports150"}
        });

        this.props.sendReport({
            Next : {
                action : ()=>{
                    this.props.getState({
                        Next : {
                            action : ()=>{
                                this.props.downloadReport({
                                    Next:{
                                        action : () => {
                                            // this.props.setModalData({});
                                            //Новый прелоадер
                                            this.props.setLoaderModalData({keyDeleted: "InputReports164"});
                                        },
                                        params : {}
                                    },
                                    Error :{
                                        action : () => {
                                            this.props.setModalData({
                                                name : ModalTypes.app.info,
                                                data : {content : "Ошибка формирования отчета", type : "fail"}
                                            });
                                            //Новый прелоадер
                                            this.props.setLoaderModalData({keyDeleted: "InputReports175"});
                                        },
                                        params : {}
                                    }
                                })
                            },
                            params : {}
                        },
                        Error :{
                            action : () => {
                                this.props.setModalData({
                                    name : ModalTypes.app.info,
                                    data : {content : "Ошибка формирования отчета", type : "fail"}
                                });
                                //Новый прелоадер
                                this.props.setLoaderModalData({keyDeleted: "InputReports190"});
                            },
                            params : {}
                        }
                    })
                },
                params : {}
            },
            Error :{
                action : () => {
                    this.props.setModalData({
                        name : ModalTypes.app.info,
                        data : {content : "Ошибка формирования отчета", type : "fail"}
                    });
                },
                params : {}
            }
        });
    }

    dataList(isListData){
        this.setState({
            isListData:isListData
        })
    }

    render() {
        let displayChangeInput
        let displayChangeDiv
        if(this.state.isListData){
            displayChangeInput={
                display:"block"
            }
            displayChangeDiv={
                display:"none"
            }
        }else{
            displayChangeInput={
                display:"none"
            }
            displayChangeDiv={
                display:"block"
            }
        }

        // displayChange=this.state.isListData ? "display: block" : "display: none"
        // let idCurrent = this.props.inputs[0]?.IdReport;
        let idCurrent = this.props.activeRow.Id;


        let name = this.props.allReports.find(report=>report.Id===idCurrent)?.Name;
        let isEmptyParams = this.props.inputs.length <= 0;

        let textIconNameTrue = "icon-Line_text"
        let textIconNameFalse = "icon-Delete icon-color-red";


        return (

            <div className="card card-custom container-solid"  style={{borderRadius: "20px", border: "0.3em solid var(--light-primary)"}}>
                <div className="card-header">

                    <div className="card-title">
												<span className="card-icon">

													</span>
                        <h4 className="card-label text-weight-bolder text-color-primary"
                            title="Настройки отчета">{name} </h4>
                    </div>


                </div>
                {
                    !isEmptyParams &&
                    <div className="card-body  p-5">

                        {
                            this.props.isReady &&
                            this.props.inputs.map((item, index) => {

                                let currentName = this["inpRef" + index]?.current?.name
                                    ? this["inpRef" + index]?.current?.name
                                    : "undefined";

                                if(item.Name === "idFilial"){

                                    return (
                                        <div className="mt-2 mb-2" key={`${item.Name} - ${index}`}>
                                            {
                                                <label style={{width:"250px"}}>
                                                    {item.Description}
                                                    <Select
                                                        closeMenuOnSelect={true}
                                                        key={`my_unique_select_key__${this.state.FilialIds}`}
                                                        value={this.state.FilialIds || ''}
                                                        //components={animatedComponents}
                                                        // value={this.state.FilialIds}
                                                        // isMulti
                                                        onChange={(item)=>{this.filialSelectOnChange(item)}}
                                                        //       onChange={(item)=>{this.setState({test : item})}}
                                                        options={this.state.typesOptions2}
                                                        placeholder="Выберите филиал"
                                                        title="Выберите филиал"
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: 'var(--hover-primary)',
                                                                primary25: 'var(--light-primary)',
                                                                primary50: 'var(--hover-primary)',
                                                                primary: 'var(--originaltwo)',
                                                            },
                                                        })}
                                                    />
                                                </label>
                                            }
                                        </div>
                                    )
                                }

                                if(item.IsList){
                                    if(item.TypeCode==5){
                                        return (

                                            <React.Fragment key={`${item.Name} - ${index}`}>
                                                <div className="mt-2 mb-2">
                                                    <label className="m-0">{item.Description}</label>
                                                    <div style={displayChangeDiv}>

                                                        <MySelect
                                                            nameField={item.Name}
                                                            type={item.TypeCode}
                                                            dataList={this.dataList}
                                                            index={index}
                                                        />
                                                    </div>
                                                </div>

                                                <input type="date" style={displayChangeInput} onChange={(event) => {
                                                    this.inputHandler(event,item)
                                                }} className="form-control form-control-lg h-40px" />

                                            </React.Fragment>
                                        )
                                    }else{
                                        return (

                                            <div className="mt-2 mb-2" key={`${item.Name} - ${index}`}>
                                                <label className="m-0">{item.Description}</label>

                                                <MySelect
                                                    nameField={item.Name}
                                                    type={item.TypeCode}
                                                    dataList={this.dataList}
                                                    index={index}
                                                />
                                            </div>


                                        )
                                    }



                                }else if(item.TypeCode===1 || item.TypeCode===2){
                                    return(
                                        <div key={`${item.Name} - ${index}`}>
                                            <label className="m-0">{item.Description}</label>
                                            <div className="input-icon h-40px my-4" key={item.Id}>

                                                <input
                                                    className="form-control form-control-lg h-40px"
                                                    placeholder={item.Description}
                                                    onChange={(event) => {
                                                        this["inpRef" + index].current.value = event.target.value
                                                    }}
                                                    value={this.state.InputsValues[item.Name]}
                                                    name={item.Name}
                                                    ref={this["inpRef" + index]}
                                                />
                                                <span className="svg-icon svg-icon-lg"
                                                      role='button'
                                                      onMouseEnter={() => {
                                                          this.setState({
                                                              currentInput: item
                                                          })
                                                      }}
                                                      onMouseLeave={() => {
                                                          this.setState({
                                                              currentInput: ''
                                                          })
                                                      }}
                                                      onClick={() => this["inpRef" + index].current.value = ''}
                                                >
                                                <i className={"svg-icon svg-icon-lg icon-color-primary "
                                                    + (this.state.currentInput?.Name === currentName ? textIconNameFalse : textIconNameTrue)
                                                }
                                                   id="FullText"/>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }else if(item.TypeCode===3){
                                    return(
                                        <div key={`${item.Name} - ${index}`}>
                                            <label className="m-0">{item.Description}</label>
                                            <div className="input-icon h-40px my-4" key={item.Id}>

                                                <input type="text" className="form-control form-control-lg h-40px"
                                                       placeholder={item.Description}
                                                       onChange={(event) => {
                                                           this["inpRef" + index].current.value = event.target.value
                                                       }}
                                                       value={this.state.InputsValues[item.Name]}
                                                       name={item.Name}
                                                       ref={this["inpRef" + index]}
                                                />
                                                <span className="svg-icon svg-icon-lg"
                                                      role='button'
                                                      onMouseEnter={() => {
                                                          this.setState({
                                                              currentInput: item
                                                          })
                                                      }}
                                                      onMouseLeave={() => {
                                                          this.setState({
                                                              currentInput: ''
                                                          })
                                                      }}
                                                      onClick={() => this["inpRef" + index].current.value = ''}
                                                >
                                            <i className={"svg-icon svg-icon-lg icon-color-primary "
                                            + (this.state.currentInput?.Name === currentName ? textIconNameFalse : textIconNameTrue)
                                            }
                                               id="FullText"/>
                                        </span>
                                            </div>
                                        </div>
                                    )
                                }else if(item.TypeCode===4){
                                    //todo Boolean
                                    return(
                                        <div key={`${item.Name} - ${index}`}>
                                            <label className="m-0">{item.Description}</label>
                                            <div className="input h-40px my-4" key={item.Id}>

                                         <span className="switch switch-success switch-outline">
                                             <label><input type="checkbox" name="select" onChange={(event) => {
                                                 this.inputHandler(event,item)
                                             }}/><span/></label>
                                         </span>

                                                {/*<input type="text" className="form-control form-control-lg h-40px"  placeholder={item.Description}*/}
                                                {/*       onChange={(event) => {*/}
                                                {/*           this.inputHandler(event,item)*/}
                                                {/*       }}*/}
                                                {/*    // value={this.state.FullText}*/}
                                                {/*       name={item.Name}*/}
                                                {/*/>*/}
                                                {/*<span className="svg-icon svg-icon-lg"*/}
                                                {/*      onMouseEnter={()=>{this.setState({clearHoverFullText : true})}}*/}
                                                {/*      onMouseLeave={()=>{this.setState({clearHoverFullText : false})}}*/}
                                                {/*    ///onClick={(event)=>this.clearInput(event)}*/}
                                                {/*>*/}
                                                {/*    <i className={"svg-icon svg-icon-lg icon-color-primary icon-Line_text"} id="FullText"/>*/}
                                                {/*</span>*/}
                                            </div>
                                        </div>
                                    )
                                }

                                else{
                                    return(
                                        <div key={`${item.Name} - ${index}`}>
                                            <label className="m-0">{item.Description}</label>
                                            <div className="input-icon h-40px my-4" key={item.Id}>
                                                <input type="date"
                                                       className="form-control form-control-lg h-40px"
                                                       placeholder={item.Description}
                                                       min="1900-01-01"
                                                       max="3000-12-31"
                                                       onChange={(event) => {
                                                           this.inputHandler(event,item)
                                                       }}
                                                       name={item.Name}
                                                       value={this["inpRef" + index]?.current?.value}
                                                       ref={this["inpRef" + index]}
                                                />
                                                <span className="svg-icon svg-icon-lg"
                                                      role='button'
                                                      onMouseEnter={() => {
                                                          this.setState({
                                                              currentInput: item
                                                          })
                                                      }}
                                                      onMouseLeave={() => {
                                                          this.setState({
                                                              currentInput: ''
                                                          })
                                                      }}
                                                      onClick={() => this["inpRef" + index].current.value = " "}
                                                >
                                                    <i className={"svg-icon svg-icon-lg icon-color-primary "
                                                        + (this.state.currentInput?.Name === currentName ? textIconNameFalse : textIconNameTrue)
                                                    }
                                                       id="FullText"/>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }


                            })
                        }

                    </div>
                }


                <div className="card-footer d-flex justify-content-between" style={isEmptyParams ? {border: 0} : {}}>
                    <span className="btn btn-primary font-weight-bold" onClick={this.sendReport}>Выгрузить в XLS</span>
                    <span className={`btn btn-outline-secondary font-weight-bold ${isEmptyParams ? "disabled" : ""}`} onClick={this.clearFields}>Очистить</span>
                </div>

            </div>


        );
    }
}

const  mapStateToProps = state => {
    return {
        activeRow: state.report.leftMenu.activeRow,
        inputs: state.report.leftMenu.params,
        isReady: state.report.leftMenu.isReady,
        allFilial: state.globalState.filial.All,
        allReports: state.report.leftMenu.collection.reports
    }
}

const  mapDispatchToProps = {
    saveValue,
    sendReport,
    getState,
    downloadReport,
    setModalData,
    setLoaderModalData
}

export default connect(mapStateToProps,mapDispatchToProps)(InputsReport);
