import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {
    setModalData,
    setUserModalShow
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import TreePackagesFolderSearch from "../../mainTable/Package/TreePackagesFolderSearch";
import TreePackageSearch from "../../tree/packages/TreePackageSearch";
import {
    movingPackageInPackage
} from "../../../store/packages/actionCreators/package_TransferActionCreator";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import parse from "html-react-parser";
import {
    breadcrumbsAdd,
    getDocumentsForTreePackages,
    getPackageGroupsForTree,
    GetPackageTypes,
    PackageAddNodesTree,
    PackageCreateTree,
    SelectActivePackageNode,
    togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {store} from "../../../index";
import sanitizeHtml from "sanitize-html";
import {setTreeSearchQuery} from "../../../store/packages/actionCreators/package_TreeSearchActionCreator";

class TransferPackageModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover : true,
            // TODO: MS 22.03 : Если по дефолту false => закрывается модалка если пользователь не убирал курсов вне моадлки и снова открывал. Для понимания работы — раскомментить когсоль в функции changeContentOnHover
            selectNode : null,
            tooltipShow : false
        };

        this.tooltipRef = React.createRef();

        this.modalName = ModalTypes.documents.mainTable.ChangeDocumentType;

        this.hideOutSide = this.hideOutSide.bind(this);
        this.hide = this.hide.bind(this);
        this.selectNode = this.selectNode.bind(this);
        this.moveDocInPack = this.moveDocInPack.bind(this);
        this.changeContentOnHover = this.changeContentOnHover.bind(this);
    }

    componentDidMount() {
        this.props.setUserModalShow({
            isShow : false
        })
    }


    // скрывать при клике вне окна
    hideOutSide() {
        if(!this.state.contentOnHover) {

            this.props.setModalData({});
        }
    }

    // скрывать по кнопке
    async hide() {
        await this.props.setTreeSearchQuery({})

        this.props.setModalData({});
    }

    selectNode(item) {
        this.setState({selectNode : item});
    }

    async loadPackages(id, callback) {
        let request = await new apiRequest(apiUrl.PACKAGE.GET_PACKAGE_PATH, {id: id});
        await request.execute(async (data) => {
            this.props.setModalData({
                name: ModalTypes.app.alert,
                data: {content: "Загрузка...", disableButton: true, fullBackground: true, gif: "packages"}
            });
            // //Новый прелоадер
            // store.dispatch(setLoaderModalData({
            //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "TransferPackageModal80"}
            // }));
            for (let i = 0; i < data.length; i++) {
                if (i === 0) continue;
                // эмулировать клик по пакету
                await store.dispatch(await PackageAddNodesTree({
                    Id: data[i].Id,
                    IdDocOffset: null,
                    TableType: "main",
                }));
            }
            callback();
        });
    }

    async moveDocInPack() {
        const {modalData} = this.props;
        if (!this.state.selectNode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Необходимо выбрать пакет для переноса`}
            });
            return;
        }

        this.props.setModalData({
            name: ModalTypes.app.alert,
            data: {content: "Загрузка...", disableButton: true, fullBackground: true, gif: "packages"}
        });
        // //Новый прелоадер
        // store.dispatch(setLoaderModalData({
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "TransferPackageModal111"}
        // }));
        let activeNode = this.props.activeNode.Node;

        await this.props.movingPackageInPackage({
            PackageItem: modalData.Node,
            IdParent: this.state.selectNode.Id
        });

        //для обновления дерева пакетов
        await this.loadPackages(modalData.Node.Id, async () => {
            await this.loadPackages(this.state.selectNode.Id, async () => {
                await this.props.PackageAddNodesTree({Id: activeNode.Id});
                this.props.SelectActivePackageNode({Id: activeNode.Id})
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "success", content: `Пакет успешно перенесен`}
                });

                this.props.breadcrumbsAdd({
                    NodeId: activeNode.Id,
                    Refresh : true,
                    Name: activeNode.Name,
                    ParentId: activeNode.IdParent,
                    TableType: "main",
                });

                if(this.props.modalData.Node.Id === activeNode.Id) {
                    this.props.togglePackagesFolders({IdFolder : this.props.modalData.Node.IdParent});
                }
            })
        });
        await this.props.setTreeSearchQuery({})

    }

    changeContentOnHover(isHover) {
        this.setState({contentOnHover: isHover})
        // console.log('isHover', isHover)
    }

    render() {
        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };

        let selectPackage = this.props.modalData;

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div
                        style={{minWidth: '87vw', minHeight: '85vh'}}
                        className="modal-content"
                         onMouseEnter={()=> this.changeContentOnHover(true)}
                         onMouseLeave={()=> this.changeContentOnHover(false)}>
                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Выберите пакет и подтвердите перенос
                            </h5>
                        </div>
                        <div className="modal-body beautiful-scroll-10" style={{padding:" 0.8rem 1.75rem", minHeight: "20vh"}}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p>
                                        <span className="label label-inline label-light-success font-weight-bold">
                                            Пакет
                                        </span> Выбранный пакет
                                    </p>
                                    <div className="table-responsive" style={{maxHeight: "auto"}}>
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Наименование пакета</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal"/></th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "1em"}}>
                                            <tr key={createUniqueIdString(6)}>
                                                <td className="change_table_text_3"
                                                    title={`${selectPackage.Node.Name}`}>
                                                    {parse(sanitizeHtml(selectPackage.Node.Name))}
                                                </td>
                                                <td>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <p>
                                        <span className="label label-inline label-light-primary font-weight-bold mr-2">
                                            Пакеты
                                        </span>
                                        Выберите пакет для переноса
                                    </p>
                                    <div className="table-responsive" style={{maxHeight: "max-content"}}>
                                        <TreePackageSearch searchText={(searchText)=>{this.setState({selectNode : null})}} tableRole={"doc"} />
                                        <div className="aside-menu-wrapper flex-column-fluid px-2 py-2" style={{height: "max-content"}}>
                                            <div className="aside-menu">
                                                <TreePackagesFolderSearch backFocus={()=> this.changeContentOnHover(true)} selectNode={this.selectNode}
                                                                          tableRole={"doc"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                            <button type="button" className="btn btn-primary font-weight-bolder"
                                    disabled={this.state.selectNode === null}
                                    onClick={this.moveDocInPack}>
                                Перенести в пакет
                            </button>
                            <button type="button" className="btn btn-light font-weight-bold"
                                    onClick={this.hide}>
                                Отменить действие
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


const  mapStateToProps = state => {
    return {
        modalData : state.globalState.app.modal.data,
        openedFolders: state.packages.tree.openedFolders,
        activeNode: state.packages.tree.activeNode,
        idBaseFilial : state.globalState.filial.Active.Id,

    }
}

const  mapDispatchToProps = {
    setModalData,
    movingPackageInPackage,
    getPackageGroupsForTree,
    getDocumentsForTreePackages,
    GetPackageTypes,
    SelectActivePackageNode,
    PackageAddNodesTree,
    PackageCreateTree,
    breadcrumbsAdd,
    togglePackagesFolders,
    setTreeSearchQuery,
    setUserModalShow



}

export default connect(mapStateToProps, mapDispatchToProps)(TransferPackageModal);
