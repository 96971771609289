import React, {createRef} from "react";
import {connect} from "react-redux";
import {
    setLoaderModalData,
    setModalData,
    setUserModalShow
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ModalTypes} from "../../../tools/StaticTypes";
import {MyTooltip} from "../../overPage/tooltip/MyTooltip";
import {ActionQueue} from "../../../store/rootReducer";
import {__} from "../../../tools/HelpFunctions";
import {
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight, deleteRowFromChecked_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    mainTableSearchDoc_packagesDocumentRight,
    resetMainTableForUpdate_packagesDocumentRight, selectActiveRow_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {toggleIsLoading} from "../../../store/documents/actionCreators/document_TreeActionCreator";
import {store} from "../../../index";

class MainTableSearchPackagesDocumentsRights extends React.Component {
    resizeObserver = null;
    resizeElement = createRef();
    nameDocRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            applyFilterTooltipShow : false,
            cancelFilterTooltipShow : false,
            timeFilterTooltipShow : false,
            exportXlsTooltipShow : false,
            deleteDocTooltipShow : false,
            updateTableTooltipShow : false,
            nameDocTooltipShow : false,
            search : false,
            fitInContainerSizeTooltipShow : false,
            isFitInContainerSize : false
        }


        this.applyFilterRef = React.createRef();
        this.cancelFilterRef = React.createRef();
        this.timeFilterRef = React.createRef();
        this.exportXlsRef = React.createRef();
        this.deleteDocRef = React.createRef();
        this.updateTableRef = React.createRef();
        this.fitInContainerSize = React.createRef();

        this.updateTable = this.updateTable.bind(this);
        this.search = this.search.bind(this);
        this.getCheckedFieldsView = this.getCheckedFieldsView.bind(this)
    }

    componentWillUnmount() {
        store.dispatch(deleteRowFromChecked_packagesDocumentRight({isClear: true}))
    }

    search(event){
        if(event.target.id === "show") {
            //TODO скрытие фильтра
            if ( this.props.userModalShow &&  this.props.userModalType == "treeDocumentsSearch") {
                this.props.setUserModalShow({
                    isShow: false
                });
            } else {
                this.props.setUserModalShow({
                    isShow: true,
                    Type: "treeDocumentsSearch"
                })
            }
        } else {
            this.props.setUserModalShow({
                isShow : false,
                Type : "treeDocumentsSearch"
            })

            this.props.changeDocumentSearchInputs_packagesDocumentRight({isDelete: true});

            this.props.ActionQueue({
                List: [
                    {action: this.props.toggleIsLoading, params : {}, name: "toggleIsLoading"},
                    {action: this.props.mainTableSearchDoc_packagesDocumentRight, params: {}, name: "mainTableSearchDoc_packagesDocumentRight"},
                    {action: this.props.combineMainTableAttributes_packagesDocumentRight, params: {}, name: "combineMainTableAttributes_packagesDocumentRight"},
                    {action: this.props.setUserModalShow, params: {isShow:false}, name: "setUserModalShow"},
                    {action: this.props.toggleIsLoading, params : {}, name: "toggleIsLoading"},

                ],
                debug: true
            });
        }
    }




    // обновление таблицы документов
    updateTable() {
        let activeRow = __.deepCopy(this.props.activeRow);

        this.props.ActionQueue({
            List : [
                {action: this.props.setLoaderModalData, params: {name: ModalTypes.app.alert, data: {content : `Загрузка...`, disableButton : true, fullBackground : true, gif : "documents", key: "MainTableSearchPackagesDocumentsRights93"}}, name: "setLoaderModalData"},
                {action: this.props.resetMainTableForUpdate_packagesDocumentRight, params : {}, name: "resetMainTableForUpdate_packagesDocumentRight"},
                {action: this.props.mainTableDownloadDocCollection_packagesDocumentRight, params : {ActiveNode : this.props.activeNode}, name: "mainTableDownloadDocCollection_packagesDocumentRight"},
                {action: this.props.combineMainTableAttributes_packagesDocumentRight , params : { ActiveNode : this.props.activeNode}, name: "combineMainTableAttributes_packagesDocumentRight"},
                {action: this.props.selectActiveRow_packagesDocumentRight , params : { RowInfo : activeRow}, name: "selectActiveRow_packagesDocumentRight"},
                // {action: this.props.setModalData, params: {name: ModalTypes.app.info, data: {type : "success", content : `Таблица успешно обновлена`}}, name: "setModalData"},
                //Новый прелоадер
                {action: this.props.setLoaderModalData, params: {keyDeleted: "MainTableSearchPackagesDocumentsRights99"}, name: "setModalData"},
                // TODO: MS 30.03 — убрал по просьбе Полины

            ],
            debug : false
        });
    }


    getCheckedFieldsView() {
        if (this.props.tableRole === "main" || this.props.checkedFields.length === 0) return null

        return <div>Выбрано файлов: {this.props.checkedFields.length}</div>

    }


    render() {
        let header = this.props.activeNode.info.Name;

        let filterStyle="icon-color-primary";
        if(this.props.searchInputs.length > 0) {
            this.props.searchInputs.forEach(item => {
                if (filterStyle === "icon-color-secondary") return
                if(typeof item.Value === "object") {
                    filterStyle = item.IsApplied ? "icon-color-secondary" : "icon-color-primary"
                } else if(item.Value !== "") {
                    filterStyle = "icon-color-secondary";
                }
            })
        }
        // if (JSON.stringify(this.props.searchInputs) !== "{}") {
        //     if(this.props?.searchInputs?.find(item => item.Value !== "")) {
        //         filterStyle = "icon-color-secondary";
        //     }
        // }

        return(
            <>
            <div className="card-header flex-column align-items-baseline border-0 py-2" ref={this.resizeElement}>
                <div className="form pt-1 pb-7 w-100">
                    <h6 className="text-color-primary font-weight-bolder text-dark font-weight-bold my-1 mr-3 d-flex">
                        {
                            this.state.nameDocTooltipShow &&
                            <MyTooltip target={this.nameDocRef}
                                       text={header}
                                       show={this.state.nameDocTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="text-truncate" ref={this.nameDocRef}
                              onMouseEnter={()=>{this.setState({nameDocTooltipShow : true})}}
                              onMouseLeave={()=>{this.setState({nameDocTooltipShow : false})}}>
                            {header}
                        </span>
                        <span className="text-muted font-size-lg ml-2 font-weight-normal">{this.props.activeNode.type === 'DOCUMENT' && "Документы"}</span>
                    </h6>
                </div>

                <div className="d-flex w-100 justify-content-between py-2" style={{minHeight: "70px"}}>
                    <div className="btn-toolbar card-toolbar">
                        {
                            this.state.applyFilterTooltipShow &&
                            <MyTooltip target={this.applyFilterRef}
                                       text={"Показать/скрыть фильтр"}
                                       show={this.state.applyFilterTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="btn btn-icon btn-circle btn-sm mx-2" ref={this.applyFilterRef}
                              onMouseEnter={()=>{this.setState({applyFilterTooltipShow : true})}}
                              onMouseLeave={()=>{this.setState({applyFilterTooltipShow : false})}}
                              onClick={(event)=>{this.search(event)}}>
                        <i id="show" className={`svg-icon icon-Table_edit_7 ${filterStyle}`}/>
                    </span>

                        {
                            this.state.cancelFilterTooltipShow &&
                            <MyTooltip target={this.cancelFilterRef}
                                       text={"Сбросить и закрыть фильтр"}
                                       show={this.state.cancelFilterTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="btn btn-icon btn-circle btn-sm" ref={this.cancelFilterRef}
                              onMouseEnter={()=>{this.setState({cancelFilterTooltipShow : true})}}
                              onMouseLeave={()=>{this.setState({cancelFilterTooltipShow : false})}}
                              onClick={(event)=>{this.search(event)}}>
                        <i id="disabled" className="svg-icon icon-Table_edit_8 icon-color-primary"/>
                    </span>

                        {/*{*/}
                        {/*    this.state.timeFilterTooltipShow &&*/}
                        {/*    <MyTooltip target={this.timeFilterRef}*/}
                        {/*               text={"Фильтр по дате"}*/}
                        {/*               show={this.state.timeFilterTooltipShow}*/}
                        {/*               placement={"top"} delay={500}*/}
                        {/*    />*/}
                        {/*}*/}
                        {/*<span className="btn btn-icon btn-circle btn-sm ml-2 mrimp" style={{ marginRight: "auto !important"}}*/}
                        {/*      ref={this.timeFilterRef}*/}
                        {/*      onMouseEnter={()=>{this.setState({timeFilterTooltipShow : true})}}*/}
                        {/*      onMouseLeave={()=>{this.setState({timeFilterTooltipShow : false})}}>*/}
                        {/*    <i className="svg-icon icon-Filter_data"/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="card-toolbar">
                        {
                            this.state.fitInContainerSizeTooltipShow &&
                            <MyTooltip target={this.fitInContainerSize}
                                       text={"Вписать"}
                                       show={this.state.fitInContainerSizeTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                              onClick={
                                  () => {
                                      if (!this.state.isFitInContainerSize)
                                          this.props.fitInContainerSize()
                                      else
                                          this.props.unFitInContainerSize();
                                      this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                      // this.props.fitInContainerSize
                                  }
                              }
                              onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                              onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}>
                                    <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                                    </span>


                        {
                            this.state.updateTableTooltipShow &&
                            <MyTooltip target={this.updateTableRef}
                                       text={"Обновить таблицу"}
                                       show={this.state.updateTableTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="btn btn-icon btn-sm mx-2" ref={this.updateTableRef}
                              onClick={this.updateTable}
                              onMouseEnter={()=>{this.setState({updateTableTooltipShow : true})}}
                              onMouseLeave={()=>{this.setState({updateTableTooltipShow : false})}}>
                        <i className="svg-icon icon-Reload icon-color-primary"/>
                    </span>

                        {/*{*/}
                        {/*    this.state.exportXlsTooltipShow &&*/}
                        {/*    <MyTooltip target={this.exportXlsRef}*/}
                        {/*               text={"Экспорт в XLS"}*/}
                        {/*               show={this.state.exportXlsTooltipShow}*/}
                        {/*               placement={"top"} delay={500}*/}
                        {/*    />*/}
                        {/*}*/}
                        {/*<span className="btn btn-icon btn-circle btn-sm" ref={this.exportXlsRef}*/}
                        {/*      onMouseEnter={()=>{this.setState({exportXlsTooltipShow : true})}}*/}
                        {/*      onMouseLeave={()=>{this.setState({exportXlsTooltipShow : false})}}>*/}
                        {/*    <i className="svg-icon icon-Table_edit_10"/>*/}
                        {/*</span>*/}

                        {/*{*/}
                        {/*    this.state.deleteDocTooltipShow &&*/}
                        {/*    <MyTooltip target={this.deleteDocRef}*/}
                        {/*               text={"Удаление документа"}*/}
                        {/*               show={this.state.deleteDocTooltipShow}*/}
                        {/*               placement={"top"} delay={500}*/}
                        {/*    />*/}
                        {/*}*/}
                        {/*<span className="btn btn-icon btn-circle btn-sm mx-2" ref={this.deleteDocRef}*/}
                        {/*      onMouseEnter={()=>{this.setState({deleteDocTooltipShow : true})}}*/}
                        {/*      onMouseLeave={()=>{this.setState({deleteDocTooltipShow : false})}}>*/}
                        {/*    <i className="svg-icon icon-Table_edit_12"/>*/}
                        {/*</span>*/}
                    </div>
                </div>
            </div>
                <div style={{height: "4.2rem"}}>
                    {this.getCheckedFieldsView()}
                </div>
</>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeNode : state.packages.treeForDocuments.activeNode,
        activeRow : state.packages.mainTable.activeRow,
        checkedFields : state.packages.mainTable.checkedFields,
        // searchInputs: state.packages.search.searchInputs,
        searchInputs : state.packages.rightTableSearchDoc.searchInputs,
        userModalShow : state.globalState.app.userModalShow,
        userModalType : state.globalState.app.userModalType,
        viewMode : state.packages.rootState.viewMode,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
    deleteRowFromChecked_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    mainTableSearchDoc_packagesDocumentRight,
    resetMainTableForUpdate_packagesDocumentRight,
    selectActiveRow_packagesDocumentRight,
    setLoaderModalData,
    setModalData,
    setUserModalShow,
    toggleIsLoading,
}



export default connect(mapStateToProps, mapDispatchToProps)(MainTableSearchPackagesDocumentsRights);
