import React from "react";
import {connect} from "react-redux";
import {
    ContextMenuActionsSearchEnum,
    contextMenuTypes, ModalTypes,
} from "../../../tools/StaticTypes";
import {setContextMenuData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import ContextMenuItem from "./ContextMenuItem";
import {__} from "../../../tools/HelpFunctions";
import {emptySearchFields} from "../../../tools/consts";
import {
    closeTabSearch,
    setActiveTabSearch,
    updateInputsFieldSearch
} from "../../../store/search/actionCreators/search_Inputs";
class ContextMenuSearchTabs extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.setActiveTab = this.setActiveTab.bind(this);
    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.SearchTabs) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    setActiveTab() {
        const id = __.createUniqueIdString(6)
        this.props.setActiveTabSearch({Id: id, name: "Поиск"});
        this.props.updateInputsFieldSearch({...emptySearchFields, Id: id});
    }

    setTabActions(ev, action) {
        const {contextMenu: {data}, tabs, activeTab} = this.props;
        switch (action) {
            case ContextMenuActionsSearchEnum.ADD_TAB:
                this.setActiveTab();
                break;
            case ContextMenuActionsSearchEnum.CLOSE_TAB:
                this.props.closeTabSearch({Ids: [data.Id]});
                if(data.Id === activeTab.Id) {
                    if(tabs.length > 0) {
                        this.props.setActiveTabSearch({Id: tabs[0].Id, name: tabs[0].name});
                    } else {
                        this.setActiveTab();
                    }
                }
                break;
            case ContextMenuActionsSearchEnum.CLOSE_OTHER_TAB:
                this.props.closeTabSearch({Ids: tabs.filter(tab => tab.Id !== data.Id).map(item => item.Id)});
                if(data.Id !== activeTab.Id) {
                    this.props.setActiveTabSearch({Id: data.Id, name: data.name});
                }
                break;
            case ContextMenuActionsSearchEnum.CLOSE_ALL_TAB:
                this.props.closeTabSearch({Ids: tabs.map(tab => tab.Id)});
                this.setActiveTab();
                break;
            default: break;
        }
        this.props.setContextMenuData({});
    }

    render() {
        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x,
            zIndex: 1100
        };

        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
                 onMouseEnter={(event) => {this.setState({isHover : true})}}
                 onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <ContextMenuItem Name={'Добавить новую вкладку'} OnClick={(ev) => this.setTabActions(ev, ContextMenuActionsSearchEnum.ADD_TAB)} />
                    <ContextMenuItem Name={'Закрыть вкладку'} OnClick={(ev) => this.setTabActions(ev, ContextMenuActionsSearchEnum.CLOSE_TAB)} />
                    <ContextMenuItem Name={'Закрыть остальные вкладки'} OnClick={(ev) => this.setTabActions(ev, ContextMenuActionsSearchEnum.CLOSE_OTHER_TAB)} />
                    <ContextMenuItem Name={'Закрыть все вкладки'} OnClick={(ev) => this.setTabActions(ev, ContextMenuActionsSearchEnum.CLOSE_ALL_TAB)} />
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu: state.globalState.app.contextMenu,
        activeTab: state.search.rootState.activeTab,
        tabs: state.search.rootState.tabs
    }
}

const  mapDispatchToProps = {
    setContextMenuData,
    setActiveTabSearch,
    updateInputsFieldSearch,
    closeTabSearch

}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuSearchTabs);
