import {APP_ACTIONS} from "../globalStateActionsList";
import {store} from "../../../index";
import {docsToggleShowAsideMenu} from "../../documents/actionCreators/document_RootStateActionCreator";
import {packsToggleShowAsideMenu} from "../../packages/actionCreators/package_RootStateActionCreator";
import {searchToggleShowAsideMenu} from "../../search/actionCreators/search_RootStateActionCreator";

// Открыть или закрыть левое меню с деревом;
// Если не указать isClose, то значение будет
// противоположным текущему;
// 29.11.22 параметр rootName добавлен для запоминания настроек пользователя для конкретного раздела (скрыто или открыто aside меню)
export const setStateLeftAsideMenu = ({isClose = null, rootName = null, Next = null, Error = null}) => { // TODO: сделать
    return async dispatch => {
        try {
            if(rootName) {
                switch(rootName) {
                    case "DocumentApp": dispatch(docsToggleShowAsideMenu({isOpen: isClose})); break;
                    case "PackageApp": dispatch(packsToggleShowAsideMenu({})); break;
                    case "SearchApp": dispatch(searchToggleShowAsideMenu({})); break;
                    default: return;
                }
            } else {
                if(isClose == null) isClose = !store.getState().globalState.app.asideMenuIsClose;
               // 04.10.22 тег body заменен на класс aside-left-menu, т.к. в Пакетах при открытии дерева документов и скрытии левого меню,
                // дети дерева документов скрывались, т.к. класс aside-minimize применялся ко всем aside, а не только для leftMenu
                let aside =  document.querySelector('.aside-left-menu');
                if (isClose) {
                    aside.classList.add('aside-minimize')
                } else {
                    aside.classList.remove('aside-minimize')
                }

                dispatch({type : APP_ACTIONS.SET_ASIDE_MENU_STATE, payload : isClose});
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения  (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// устанавливаем данные для модального окна
// если не передавать name и data, то модальное окно,
// которое было открыто - будет закрыто
export const setModalData = ({name = "", data = {}, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : APP_ACTIONS.SET_MODAL_DATA, payload : {name, data}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка установки данных модального окна  (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// устанавливаем данные для модального окна прелоадера
// если не передавать name и data, то модальное окно прелоадера,
// которое было открыто - будет закрыто
export const setLoaderModalData = ({data = {}, keyDeleted = "", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : APP_ACTIONS.SET_LOADER_MODAL_DATA, payload : {data, keyDeleted}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка установки данных модального окна  (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// устанавливаем данные для контекстного меню
// если не передавать name и data, то контекстное меню,
// которое было открыто - будет закрыто
// position - координаты появления контекстного меню
export const setContextMenuData = ({name = "", data = {}, position = {x : 0, y : 0}, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : APP_ACTIONS.SET_CONTEXT_MENU_DATA, payload : {name, data, position}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка установки данных контекстного меню  (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setUserModalShow = ({isShow, Type = "", TableRole = "main", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : APP_ACTIONS.SET_SHOW_USER_MODAL, payload : {
                    isShow,
                    Type,
                    // для скрытия пакетов при закрытии модального окна с фильтрами в таблице пакетов загрузки
                    TableRole
            }});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка установки данных UserModal  (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
