import React from "react";
import {connect} from "react-redux";
import ThMainTablePackage from "./ThMainTablePackage";
import {
    checkAllRowsOnPage_MainTable,
    checkAllRowsOnPage_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {AdminLevelEnum, TableTypesEnum} from "../../../tools/StaticTypes";
import {__} from "../../../tools/HelpFunctions";


class TheadPackage extends  React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.setItemSize = this.setItemSize.bind(this);

    }

    // выделение или снятие всех галок
    checkboxOnChange() {
        let docs = this.props.body.filter(item => item.Type === "PackagesDocument" && !item.Node.IsMarkedForDelete);
        if (this.props.tableRole === "main") this.props.checkAllRowsOnPage_MainTable({Body: docs})
        else this.props.checkAllRowsOnPage_packagesDocumentRight({Body: docs});
    }
    setItemSize = (lItem, lSize) => {
        //проверка от зацикливания
        if (lItem)
            lItem.Size = lSize;
    }

    render() {
        let HeaderColumns = this.props.HeaderColumns;
        let {
            body,
        } = this.props;

        let checkedFields
        if(this.props.tableRole === "main") checkedFields = this.props.checkedFieldsMainTable;
        else checkedFields = this.props.checkedFields

       let docs = body.filter(item => item.Type === "PackagesDocument" && !item.Node.IsMarkedForDelete);
       let checked = (checkedFields.length >= docs.length) && docs.length > 0;


        return (
            <thead>
            <tr className="text-uppercase tablez1" >

                        <th className={"py-0"} style={{width:"35px"}}>
                            {/*{*/}
                            {/*    (this.props.adminLevel > AdminLevelEnum.CommonUser*/}
                            {/*        || this.props.tableRole !== TableTypesEnum.Main) &&*/}
                                <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                    <input type="checkbox" checked={checked} onChange={this.checkboxOnChange}/>
                                    {/*<span className={this.props.tableRole === "main" ? "main-table-delete main-table-head-delete" : "row-checked"} > </span>*/}
                                    <span className="row-checked" > </span>
                                </label>
                            {/*}*/}
                            {/*<label className="checkbox checkbox-lg checkbox-inline mr-2" >*/}
                            {/*    <input type="checkbox" checked={checked} onChange={this.checkboxOnChange}/>*/}
                            {/*    <span> </span>*/}
                            {/*</label>*/}
                        </th>
                {
                    HeaderColumns.map((item, index) => {
                        item = __.deepCopy(item)
                        return <ThMainTablePackage
                            key={item.Name}
                            tableRole={this.props.tableRole}
                            data={item}
                            setWidth = {(itemSize) => this.props.setWidth(itemSize, item)}
                            setItemSize={(ItemSize) => {
                                this.setItemSize(item, ItemSize)
                            }}
                        />
                    })
                }
            </tr>
            </thead>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeNode: state.packages.tree.activeNode,
        adminLevel : state.globalState.user.AdminLevel,
        checkedFields: state.packages.mainTable.checkedFields,
        checkedFieldsMainTable: state.packages.mainTable.checkedFieldsMainTable,
    }
}

const  mapDispatchToProps = {
    checkAllRowsOnPage_MainTable,
    checkAllRowsOnPage_packagesDocumentRight,
}

export default connect(mapStateToProps,mapDispatchToProps)(TheadPackage);
