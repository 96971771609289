import { RootComponentsStateViewModeTypes } from "./StaticTypes";

export const DOCUMENTS_PREVIEW_NORMAL = [
    RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
    RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_Hide,
    RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewFullScreen,
    RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen];

export const SEARCH_PREVIEW_NORMAL = [
    RootComponentsStateViewModeTypes.SearchApp.MainTableOnly,
    RootComponentsStateViewModeTypes.SearchApp.PreviewNormal,
    RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_Hide,
    RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewFullScreen,
    RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewHalfScreen];

