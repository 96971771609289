import React from "react";
import {connect} from "react-redux";
import TreePackageFolder from "./TreePackageFolder";
import {__} from "../../../tools/HelpFunctions";
import {
    SelectActivePackageDocument,
    SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";

class TreePackages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curRef: null
        }
        this.rowRef = React.createRef();
        this.setRef = this.setRef.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot){

        //TODO aa 29.03.2023 упрощен алгоритм фокусировки, теперь он будет вызван когда отрисуются все узлы дерева
        // if (this.state.curRef) {
        //     if (this.state.curRef.current) {
        //         const d = +store.getState().router.location.query?.d
        //         if (d) {
        //             if (prevState.curRef) {
        //                 if (prevState.curRef.current !== this.state.curRef.current) {
        //                     store.dispatch(setModalData({
        //                         name : ModalTypes.app.alert,
        //                         data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages"}
        //                     }))
        //                     setTimeout(() => {
        //                         this.props.SelectActivePackageNode({Id: this.state.curRef.current.tabIndex})
        //                         this.props.SelectActivePackageDocument({Id: d})
        //                         this.state.curRef.current.focus();
        //                         // store.dispatch(setModalData({}))
        //                     }, 1000)
        //                 }
        //             } else {
        //                 if (this.props.activeNode.Node.Id !== this.state.curRef.current.tabIndex) {
        //                     store.dispatch(setModalData({
        //                         name : ModalTypes.app.alert,
        //                         data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages"}
        //                     }))
        //                 setTimeout(() => {
        //                         this.props.SelectActivePackageNode({Id: this.state.curRef.current?.tabIndex})
        //                         this.props.SelectActivePackageDocument({Id: d})
        //                         this.state.curRef.current.focus();
        //                         // store.dispatch(setModalData({}))
        //                     }, 1000)
        //                 }
        //             }
        //         }
        //         else if (!prevState.curRef) {
        //             setTimeout(async () => {
        //                 if (this.state.curRef?.current) {
        //                     this.props.SelectActivePackageNode({Id: this.state.curRef.current?.tabIndex})
        //                     this.state.curRef.current.blur();
        //                     this.state.curRef.current.focus();
        //                 }
        //
        //                 // store.dispatch(setModalData({}))
        //             }, 1000)
        //         }
        //         else {
        //             if (
        //                 // JSON.stringify(prevProps.tree_render.Children)!= JSON.stringify(this.props.tree_render.Children) ||
        //                 // prevProps.activeNode?.Node?.Id != this.props.activeNode?.Node?.Id ||
        //                 prevState.curRef != this.state.curRef) {
        //             //     this.props.SelectActivePackageNode({Id: this.state.curRef.current?.tabIndex})
        //             setTimeout(async () => {
        //                 if (this.state.curRef?.current) {
        //                     this.state.curRef.current.blur();
        //                     this.state.curRef.current.focus();
        //                 }
        //             }, 1000)
        //             }
        //         }
        //     }
        // }
    }

    setRef = (lRef) => {
        //проверка от зацикливания
        if (lRef && lRef.current) {
            if (this.state.curRef) {
                if (this.state.curRef.current != lRef.current)
                    this.setState({curRef: lRef})
            } else {
                this.setState({curRef: lRef})
            }
        }
    }

    render() {
        let isAdditional = this.props.isAdditional ?? false;
        let data = isAdditional ?
            this.props.isSearch ? this.props.treeSearchAdditional : this.props.treeAdditional
            :
            __.deepCopy(this.props.tree_render);
        let depth = 0;

        if(!data) return null;
        return(
            <ul className="menu-nav">
                {
                    data.Children.map((item, index) => {
                        return (
                            <TreePackageFolder
                                key={item.TreeId}
                                data={item}
                                depth={depth+1}
                                rootId={data.Node.Id}
                                setRef={(Item) => {
                                    this.setRef(Item)
                                }}
                                setLast={() => {if (index == data.Children.length-1) {
                                    if (this.state.curRef?.current) {
                                        this.state.curRef.current.blur();
                                        this.state.curRef.current.focus();
                                        this.setState({curRef: null})
                                    }
                                }
                                }}
                                isAdditional={isAdditional}
                            />
                        );
                    })
                }
            </ul>
        );
    }

}


const  mapStateToProps = state => {
    return {
        activeNode: state.packages.tree.activeNode,
        tree_render : state.packages.tree.render, // данные в обычном режиме
        treeAdditional: state.packages.treeAdditional.render,
        treeSearchAdditional: state.packages.treeSearchAdditional.folders,
        isSearch: state.packages.treeAdditional.searchInputs.IsSearch
    }
}

const  mapDispatchToProps = {
    SelectActivePackageNode,
    SelectActivePackageDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(TreePackages);
