import React from 'react';
import {connect} from "react-redux";
import {
    createRedactorData,
    deleteDocumentCard,
    selectActiveDocumentCard
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {HelpFunctions} from "../../../tools/HelpFunctions";
import {setStateLeftAsideMenu} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {history} from "../../../index";
import {__} from "../../../tools/HelpFunctions";


class DocTabsItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            IsIconHover : false,
            refsComplete: []
        };

        this.tabRef = React.createRef();

        this.iconHoverHandler = this.iconHoverHandler.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
    }


    componentDidMount() {
        if (this.props.setRef) {
            if (!this.state.refsComplete.find(lItem => lItem === this.tabRef)) {
                const lCopy = __.deepCopy(this.state.refsComplete);
                lCopy.push(this.tabRef);
                this.setState({refsComplete: lCopy});
                if (this.tabRef.current) {
                    this.props.setRef(this.tabRef);
                }
            }

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.setRef) {
            if (!this.state.refsComplete.find(lItem => lItem === this.tabRef)) {
                const lCopy = __.deepCopy(this.state.refsComplete);
                lCopy.push(this.tabRef);
                this.setState({refsComplete: lCopy});
                if (this.tabRef.current) {
                    this.props.setRef(this.tabRef);
                }
            }
        }
    }



    iconHoverHandler(status) {
        this.setState({
            IsIconHover : status
        });
    }

    // клик на вкладку выбрать вкладку с карточкой активной
    onClickHandler(e, link) {
        if(!this.state.IsIconHover) {
            if(this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.None ||
                this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly ||
                this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal ||
                this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen ||
                this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewFullScreen) {
                this.props.setStateLeftAsideMenu({
                    isClose : true,
                    rootName: "DocumentApp",

                    Next : {
                        action : () => {
                            this.props.selectActiveDocumentCard({
                                CardId : this.props.data.Id,
                                Next : {
                                    action : () => {
                                        this.props.changeDocumentViewMode({
                                            ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                                        })
                                        history.push(link);
                                    },
                                    params : {}
                                }
                            });
                        },
                        params : {}
                    }
                });
            }
            else {

                let isContinue = true;
                if(this.props.redactorData) {
                    isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
                }

                if(!isContinue) {
                    return;
                }

                this.props.selectActiveDocumentCard({
                    CardId : this.props.data.Id,
                    Next : {
                        action :  ()=>{
                            this.props.changeDocumentViewMode({
                                ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                            });
                            this.props.createRedactorData({
                                CardId : null
                            });

                            window.onbeforeunload = null;
                            window.addEventListener("popstate", null);

                            history.push(link);
                        },
                        params : {}
                    }
                });
            }
        }
        if (this.props.OnTabClick)
            this.props.OnTabClick(this.props.data.Id)

        if (this.props.onClick)
            this.props.onClick(e.target.className.includes("nav"))
    }

    // закрыть карточку
    deleteCard() {
        if(this.props.active) {

            let isContinue = true;
            if(this.props.redactorData) {
                isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
            }

            if(!isContinue) {
                return;
            }

            let viewMode = this.props.activeRow == null
                ? RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
                : RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal;

            this.props.changeDocumentViewMode({
                ViewMode : viewMode,

                Next : {
                    action: () => {
                        this.props.deleteDocumentCard({
                            CardId : this.props.data.Id
                        });
                        this.props.setStateLeftAsideMenu({
                            isClose : false,
                            rootName: "DocumentApp"
                        });
                        this.props.createRedactorData({
                            CardId : null
                        });

                        window.onbeforeunload = null;
                        window.addEventListener("popstate", null);

                        let link = this.props.router.location.pathname + this.props.router.location.search.split("&c")[0];
                        history.push(link);
                    },
                    params : {}
                }
            });
        } else {
            this.props.deleteDocumentCard({
                CardId : this.props.data.Id
            });
        }

        let link = this.props.router.location.pathname + this.props.router.location.search.split("&c")[0];
        link = link.split("&d")[0];
        history.push(link)
    }

    render() {
        let styleIcon = {}
        let styleSpan ={}
        if (this.props.isDropdown){
            styleIcon={
                marginLeft: "-15px"
            }
            styleSpan={
                marginLeft: "20px"
            }
        }
        let active = this.props.active ? " active" : "";

        let icon = null;
        if(this.state.IsIconHover) {
            icon = (
                <span className="svg-icon menu-icon nav-icon pl-1" style={styleIcon}
                      onClick={this.deleteCard}
                      onMouseEnter={()=>{this.iconHoverHandler(true)}}
                      onMouseLeave={()=>{this.iconHoverHandler(false)}}>
                    <i className="menu-icon icon-Delete icon-color-red"/>
                </span>
            );
        } else {
            icon = (
                <span className="svg-icon menu-icon nav-icon pl-1"
                      onMouseEnter={()=>{this.iconHoverHandler(true)}}
                      onMouseLeave={()=>{this.iconHoverHandler(false)}}
                      style={styleIcon}
                >
                    <i className="menu-icon icon-Menu_pack" style={{color: active ? "var(--success)" : "#B5B5C3"}}/>
                </span>
            );
        }


        let link = `/documents?g=${this.props.data.IdDocGroup}&t=${this.props.data.TypeId}&d=${this.props.data.IdDoc}&c=${this.props.data.IdDoc}`;

        return (
            <li tabIndex={this.props.lIndex} ref={this.tabRef} className="nav-item" style={{ whiteSpace: "nowrap", color: active ? "var(--success)" : "#B5B5C3"}} title={`${this.props.data.name} ${this.props.data.ShortDescription ?? ""}`} onClick={(e)=>{this.onClickHandler(e, link)}}>
                <span className={"nav-link" + active} data-toggle="tab">
                   {icon}
                      <span className="nav-text" style={styleSpan}>{HelpFunctions.cutLongString(`${this.props.data.name} ${this.props.data.ShortDescription ?? ""}`, 30, true)}</span>
                </span>
            </li>
        );
    }
}

// n  => node_tree
// d => idDoc
// v => idVersion
// p => package
// m => moveView


const  mapStateToProps = state => {
    return {
        activeRow : state.document.mainTable.activeRow,
        redactorData : state.document.cards.redactorData,
        rootState : state.document.rootState,
        router : state.router,
    }
}

const  mapDispatchToProps = {
    changeDocumentViewMode,
    createRedactorData,
    deleteDocumentCard,
    selectActiveDocumentCard,
    setStateLeftAsideMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocTabsItem);
