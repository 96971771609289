import React from "react";
import {connect} from "react-redux";
import {setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";

import TableSearch from "../mainTable/search/TableSearch";
import {AdminLevelEnum, RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import AttributesSectionSearch from "../additionalDataSection/search/AttributesSectionSearch";
import ContentSectionSearch from "../additionalDataSection/search/ContentSectionSearch";
import VersionSectionSearch from "../additionalDataSection/search/VresionSectionSearch";
import DocumentPackagesSectionSearch from "../additionalDataSection/search/DocumentPackagesSectionSearch";
import InputSearch from "../inputSearch/inputSearch";
import DocPreviewSearch from "../preview/SearchPreview";
import {store} from "../../index";
import MobileMenu from "../static/MobileMenu";
import SearchWelcome from "../static/SearchWelcome";
import FilesSection from "../additionalDataSection/FilesSection";
import DepartmentsSection from "../additionalDataSection/DepartmentsSection";




class SeacrhApp extends React.Component {
    constructor(props) {
        super(props);

        this.lFlagWithDepartments = false;
        let {globalSettings} = this.props;
        if (globalSettings?.WithDepartments === "1" && this.props.adminLevel > AdminLevelEnum.CommonUser)
            this.lFlagWithDepartments = true

        this.state = {

            SearchText: "",//первое поле
            AttributesText: "",//поиск по атрибутам
            FullText: "",//поиск по полнотексту
            IsSearchInAttachments: false, //искать в тексте прикрепленных файлов
            IsSearchInVersions: false, // искать в версиях
            IsSearchInMarkedForDelete: false // искать в помеченных на удаление

        };

        // this.props.setStateLeftAsideMenu({
        //     isClose: false
        // });

         if(this.props.router.location.pathname === "/search") {
             store.dispatch({type : "finish"});
         }


        this.viewRender = this.viewRender.bind(this);
        this.viewRender_None = this.viewRender_None.bind(this);
        this.viewRender_MainTableOnly = this.viewRender_MainTableOnly.bind(this);
        this.viewRender_PreviewNormal = this.viewRender_PreviewNormal.bind(this);
        this.viewRender_PreviewNormal_Hide = this.viewRender_PreviewNormal_Hide.bind(this);
        this.viewRenderPreviewNormal_PreviewFullScreen = this.viewRenderPreviewNormal_PreviewFullScreen.bind(this);
        this.viewRenderPreviewNormal_PreviewHalfScreen = this.viewRenderPreviewNormal_PreviewHalfScreen.bind(this);
    }

    componentDidMount() {
        // this.props.setStateLeftAsideMenu({
        //     isClose: false
        // });
    }

    // выбор режима отображения
    viewRender() {
        const { rootState: { tabs, activeTab } } = this.props;
        const viewMode = tabs.find(tab => tab.Id === activeTab.Id)?.viewMode;
        let rootStateType= RootComponentsStateViewModeTypes;

        if(viewMode === rootStateType.SearchApp.None || !viewMode) {
            return this.viewRender_None();
        }
        else
        if (viewMode === rootStateType.SearchApp.MainTableOnly) {
            return this.viewRender_MainTableOnly();
        }

        // предпросмотр
        else if (viewMode === rootStateType.SearchApp.PreviewNormal) {
            return this.viewRender_PreviewNormal();
        } else if (viewMode === rootStateType.SearchApp.PreviewNormal_PreviewHalfScreen) {
            return this.viewRenderPreviewNormal_PreviewHalfScreen();
        } else if (viewMode === rootStateType.SearchApp.PreviewNormal_PreviewFullScreen) {
            return this.viewRenderPreviewNormal_PreviewFullScreen();
        }  else if(viewMode === rootStateType.SearchApp.PreviewNormal_Hide ) {
            return this.viewRender_PreviewNormal_Hide();
        }
    }


    viewRender_None() {
        //return <DocumentsWelcome/>;
        return <SearchWelcome/>;
    }

// отображение только таблицы
    viewRender_MainTableOnly() {
        return (
            <div className="card card-custom container-solid col-xl-12">
                <TableSearch/>
            </div>
        );
    }

    // отображение обычно превью с атрибутами внизу
    viewRender_PreviewNormal() {
        return (
            <>
                <div className="row" style={{paddingLeft: "15px"}}>
                    <div className="card card-custom container-solid col-xl-9">
                        <TableSearch/>
                    </div>
                    <div className="card card-custom container-solid col-xl-3">
                        <DocPreviewSearch viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{paddingLeft: "15px"}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            <ContentSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {/*<FilesSectionSearch viewMode={"normal"} redactorMode={false}/>*/}
                            <FilesSection
                                search = {this.props.search}
                                typeSection = {"search"}
                                signatureNotEditable = {true}/>

                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSectionSearch viewMode={"normal"}/>
                            <DocumentPackagesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection IdDoc={this.props.IdDoc} search viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // отображение обычно превью с атрибутами внизу
    viewRender_PreviewNormal_Hide() {
        return (
            <>
                <div className="row" style={{paddingLeft: "15px"}}>
                    <div className="card card-custom container-solid col-xl-11">
                        <TableSearch/>
                    </div>
                    <div className="card card-custom container-solid col-xl-1 align-items-center">
                        <DocPreviewSearch viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{paddingLeft: "15px"}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            <ContentSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {/*<FilesSectionSearch viewMode={"normal"} redactorMode={false}/>*/}
                            <FilesSection
                                search = {this.props.search}
                                typeSection = {"search"}
                                signatureNotEditable = {true}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSectionSearch viewMode={"normal"}/>
                            <DocumentPackagesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection IdDoc={this.props.IdDoc} search viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

// отображение окно предпросмотра в увеличенном виде
    viewRenderPreviewNormal_PreviewHalfScreen() {
        return (
            <>
                <div className="row" style={{paddingLeft: "15px", alignItems: 'stretch'}}>
                    <div className="card card-custom container-solid col-xl-6" style={{flex: 1}}>
                        <TableSearch/>
                    </div>
                    <div className="card card-custom container-solid col-xl-6"
                         style={{minHeight: "70vh", flex: 1}}>
                        <DocPreviewSearch viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{paddingLeft: "15px"}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            <ContentSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {/*<FilesSectionSearch viewMode={"normal"} redactorMode={false}/>*/}
                            <FilesSection
                                search = {this.props.search}
                                typeSection = {"search"}
                                signatureNotEditable = {true}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSectionSearch viewMode={"normal"} redactorMode={false}/>
                            <DocumentPackagesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection IdDoc={this.props.IdDoc} search viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // отображение окно предпросмотра развернутое на полный экран
    viewRenderPreviewNormal_PreviewFullScreen() {
        return (
            <>
                <div className="row" style={{paddingLeft: "15px"}}>
                    <div className="card card-custom container-solid col-xl-9"
                         style={{display: "none"}}>
                        <TableSearch/>
                    </div>
                    <div className="card card-custom container-solid col-xl-12"
                         style={{height: "90vh"}}>
                        <DocPreviewSearch viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{paddingLeft: "15px"}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            <ContentSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {/*<FilesSectionSearch viewMode={"normal"} redactorMode={false}/>*/}
                            <FilesSection
                                search = {this.props.search}
                                typeSection = {"search"}
                                signatureNotEditable = {true}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSectionSearch viewMode={"normal"} redactorMode={false}/>
                            <DocumentPackagesSectionSearch viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection IdDoc={this.props.IdDoc} search viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }







    render() {
        const { rootState } = this.props;
        const { rootState: { tabs, activeTab } } = this.props;
        const viewMode = tabs.find(tab => tab.Id === activeTab.Id)?.viewMode;
        const isOpenAsideMenuActive = rootState.isOpenAsideMenu ?? true;
        // содержание страницы
        let content = this.viewRender();

        // const rootState = this.props.rootState;
        let rootStateType = RootComponentsStateViewModeTypes;
        let paddingTopMenu = {};
        if (viewMode !== rootStateType.SearchApp.PreviewCards ||
            viewMode !== rootStateType.SearchApp.PreviewCards_HalfScreen ||
            viewMode !== rootStateType.SearchApp.PreviewCards_FullScreen) {
            paddingTopMenu = {
                paddingBottom: "0px"
            };
        }

        let viewModeActive = "normal";
        if (viewMode === rootStateType.SearchApp.PreviewCards ||
            viewMode === rootStateType.SearchApp.PreviewCards_HalfScreen ||
            viewMode === rootStateType.SearchApp.PreviewCards_FullScreen) {
            viewModeActive = "card";
        } else if (viewMode === rootStateType.SearchApp.PreviewCards_RedactorMode ||
            viewMode === rootStateType.SearchApp.PreviewCards_RedactorMode_HalfScreen ||
            viewMode === rootStateType.SearchApp.PreviewCards_RedactorMode_FullScreen) {
            viewModeActive = "redactor";
        }


        // let topMenuStyle = {};
        // if (viewMode === "card" || viewMode === "redactor") {
        //     topMenuStyle = {
        //         position: "fixed",
        //         zIndex: "10",
        //         width: "100%"
        //     };
        // }

        let leftMenuStyle = !isOpenAsideMenuActive ? {display : "none"} : {};


        let asideMobileStyle = "";
        if(isOpenAsideMenuActive) {
            asideMobileStyle = "aside-on";
        }

        return (
            <div>
                <div className={`header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu ${!isOpenAsideMenuActive ? 'aside-minimize' : ''}`}>

                    <MobileMenu rootName={"SearchApp"}/>

                    <div className="d-flex flex-column flex-root">
                        <div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>
                            <LeftMenu rootName="SearchApp"/>
                            {
                                <div className="aside-secondary d-flex-none flex-row-fluid" style={{}}>
                                    <div className="aside-workspace scroll scroll-push my-2">
                                        <div className="tab-content">
                                            <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active">

                                                <div className="aside-menu-wrapper flex-column-fluid pr-10 py-2 beautiful-scroll-5" style={{overflowX: "hidden", marginRight : "-16px"}}>
                                                    <div className="aside-menu min-h-lg-800px" style={leftMenuStyle}>
                                                        {<InputSearch/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper" style={paddingTopMenu}>
                            <div className="content d-flex flex-column flex-column-fluid" style={paddingTopMenu}>
                                <TopMenu rootName="SearchApp"  viewMode={viewModeActive}/>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-row-fluid wrapper" style={{}}>
                            <div className="container-new" style={{}}>
                                {content}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}


const  mapStateToProps = state => {
    return {
        adminLevel : state.globalState.user.AdminLevel,
        globalSettings: state.globalState.settings.Content,
        depData: state.search.additionalSections.state.departmentsSection,
        IdDoc: state.search.mainTable.activeRow?.info.DocVersion.IdDoc,
        rootState : state.search.rootState,
        asideMenuIsClose : state.globalState.app.asideMenuIsClose,
        search: state.search,
        activeTab: state.search.rootState.activeTab
    }
}

const  mapDispatchToProps = {
    setStateLeftAsideMenu,
}


export default connect(mapStateToProps, mapDispatchToProps)(SeacrhApp);
