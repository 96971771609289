import React from "react";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
  combineMainTableAttributes,
  mainTableDownloadDocCollection
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {connect} from "react-redux";
import {
  deleteSectionsForTree,
  getSectionsForTree, saveSectionsForTree
} from "../../../store/documents/actionCreators/document_TreeActionCreator";
import {__} from "../../../tools/HelpFunctions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {ModalTypes} from "../../../tools/StaticTypes";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {store} from "../../../index";
import {Sorting} from "../../../tools/Filters";

const animatedComponents = makeAnimated();

const sbSelect = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },

    "::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      /*background-color: rgb(239 137 137 / 20%);*/
      backgroundColor: "var(--background-colorBS)",
    },

    "::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.35)",
    }
  })
}
class NewDocumentByVersion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      LoadGroups: [],
      SelectedGroups: null,
      sortingFoldersName: [],
      newDocument: {
        comment: '',
        docAttributes: [],
        docHeader:{
          IdFilial: '',
          Name: '',
          RegDate: '',
          RegNumber: '',
          Type: {
            Name: '',
            Id: ''
          }
        },
        idSourceVersion: ''
      },
      targetDataType: {
        TypeAttributes: []
      },
      countEmptyRequiredFields: 0,
    };

    this.hide = this.hide.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeRegNumber = this.changeRegNumber.bind(this);
    this.changeRegDate = this.changeRegDate.bind(this);
    this.changeTargetDataType = this.changeTargetDataType.bind(this);
    this.changeComment = this.changeComment.bind(this);
    this.setTargetDataType = this.setTargetDataType.bind(this);
    this.checkRequired = this.checkRequired.bind(this);
    this.changeAttributeValue = this.changeAttributeValue.bind(this);
    this.postNewDocumentByVersion = this.postNewDocumentByVersion.bind(this);
    this.changeVersion = this.changeVersion.bind(this);
  }

  async componentDidMount() {
    // const initDate = new Date(this.props.activeDocument.Info.RegDate)
    // const convertDate = `${initDate.getFullYear()}-${initDate.getMonth().length > 1 ? initDate.getMonth(): '0' + initDate.getDay()}-${initDate.getDay().length > 1 ? initDate.getDay() : '0' + initDate.getDay()}`

    await this.setState({
      sortingFoldersName: this.props.allFolders
        .filter(group => group.TypeAttributes.length > 0)
        .map(group => ({
          Attributes: group.TypeAttributes,
          label: group.Name,
          value: group.Id,
        })),
      newDocument: {
        docAttributes: this.props.activeDocument.Info.Attributes,
        docHeader: {
          Id: this.props.activeDocument.Info.Id,
          IdFilial: this.props.activeDocument.Info.IdFilial,
          Name: this.props.activeDocument.Info.Name,
          RegDate: this.props.activeDocument.Info.RegDate.split('T')[0],
          RegNumber: this.props.activeDocument.Info.RegNumber,
          Type: {
            Id: this.props.activeDocument.Info.Type.Id,
            Name: this.props.activeDocument.Info.Type.Name
          }
        },
        idSourceVersion: {
          label: `${this.props.versionList[0].Version} от ${this.props.versionList[0].VersionTime}`,
          value: this.props.versionList[0].Id
        }
      }
    })
    await this.setTargetDataType(this.props.activeDocument.Info.Type)
  }

  async setTargetDataType(folder) {
    const folderId = folder?.value ?? folder.Id
    let findFolder = this.props.allFolders.find(item => item.Id === folderId)
    let docAttributes = []
    findFolder.TypeAttributes.forEach((item) => {
      let attributeValue = this.props.activeDocument.Info.Attributes.find(doc => doc.AttributeName.Id === item.AttributeName.Id)?.AttributeValue
      docAttributes.push({
        AttributeName: {
          Id: item.AttributeName.Id,
          IdAttributeType: item.AttributeName.IdAttributeType,
          Name: item.AttributeName.Name,
        },
        AttributeValue: attributeValue,
        IdDoc: this.props.activeDocument.Info.Version.IdDoc
      })
    })
    let copyNewDocument = __.deepCopy(this.state.newDocument)
    copyNewDocument.docAttributes = docAttributes
    this.setState({newDocument: copyNewDocument})
    let copyUpdatedDocumentAttributes = __.deepCopy(this.state.newDocument)
    copyUpdatedDocumentAttributes.docHeader.Type = {
      Id: findFolder.Id,
      Name: findFolder.Name
    }
    await this.setState({newDocument: copyUpdatedDocumentAttributes})
    await this.setState({targetDataType: findFolder})
    await this.checkRequired()


    ///////////////////////

    let request_groups = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE_GROUP, {
      filter : {IdFilial : store.getState().globalState.filial.Active.Id},
      sorting : new Sorting()
          .add({Direction: 1,PropertyName : "Name"})
          .add({Direction: 1,PropertyName : "GroupOrder"})
          .get()
    });
    request_groups.execute((data)=> {
      this.setState({
        LoadGroups: data.Records.filter(group => group.Type === 2).sort((a,b) => {return a.GroupOrder - b.GroupOrder}),
      });
      console.log('data.Records.filter(group => group.Type === 2).sort((a,b) => {return a.GroupOrder - b.GroupOrder})', data.Records.filter(group => group.Type === 2).sort((a,b) => {return a.GroupOrder - b.GroupOrder}))
    },(data)=>{console.log("Ошибка загрузки списка групп документов: ", data)});


  }

  async checkRequired(){
    let requiredFields = await this.state.targetDataType.TypeAttributes.filter(item => item.IsRequired)
    let countEmptyRequiredFields = requiredFields
      .map(item => this.state.newDocument.docAttributes
        .find(doc => doc.AttributeName.Id === item.AttributeName.Id)?.AttributeValue ? null : true)
      .filter((item) => item)
    this.setState({countEmptyRequiredFields: countEmptyRequiredFields})
  }

  changeVersion(value){
    let copyNewDocument = __.deepCopy(this.state.newDocument)
    copyNewDocument.idSourceVersion = value
    this.setState({newDocument: copyNewDocument})
  }

  changeName(value){
    let copyNewDocument = __.deepCopy(this.state.newDocument)
    copyNewDocument.docHeader.Name = value
    this.setState({newDocument: copyNewDocument})
  }

  changeRegNumber(value){
    let copyNewDocument = __.deepCopy(this.state.newDocument)
    copyNewDocument.docHeader.RegNumber = value
    this.setState({newDocument: copyNewDocument})
  }

  changeRegDate(value){
    let copyNewDocument = __.deepCopy(this.state.newDocument)
    copyNewDocument.docHeader.RegDate = value
    this.setState({newDocument: copyNewDocument})
  }

  changeTargetDataType(value){
    this.setTargetDataType(value)
    let copyNewDocument = __.deepCopy(this.state.newDocument)
    copyNewDocument.docAttributes = value.Attributes
    copyNewDocument.docHeader.Type = {
      Id: value.value,
      Name: value.label
    }
    this.setState({newDocument: copyNewDocument})
  }

  changeComment(value){
    let copyNewDocument = __.deepCopy(this.state.newDocument)
    copyNewDocument.comment = value
    this.setState({newDocument: copyNewDocument})
  }

  changeAttributeValue(id, value, isRequired) {
    this.setState({
      newDocument: {
        ...this.state.newDocument,
        docAttributes: this.state.newDocument.docAttributes.map((item) => {
          if(item.AttributeName.Id === id) {
            return {
              ...item,
              AttributeValue: value ?? null
            }
          }
          else return item;
        })
      }
    });
    if (isRequired){
      this.checkRequired()
    }
  }
  async postNewDocumentByVersion(){
    const newDocumentByVersion = {
      ...this.state.newDocument,
      docHeader: {
        ...this.state.newDocument.docHeader,
        RegDate: this.state.newDocument.docHeader.RegDate + 'T00:00:00'
      },
      idSourceVersion: this.state.newDocument.idSourceVersion.value
    }
    delete newDocumentByVersion.docHeader.Id;
    // let request = await new apiRequest(apiUrl.DOCUMENTS.DOCS_NEW, newDocumentByVersion)
    let request = await new apiRequest(apiUrl.DOCUMENTS.DOCS_NEW_BY_VER, newDocumentByVersion)
    let resultDocId
    await request.execute((res) => resultDocId = res)
      .then( async () => {
        if (this.state.SelectedGroups) {
          let requestSaveCollection = await new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
            collection: [
              {
                Id: 0,
                IdDoc: resultDocId.IdDoc,
                IdDocGroup: this.state.SelectedGroups.value
              }
            ]
          })
          requestSaveCollection.execute( (res) => {})
        }
      })
        .then( async () => {
          // TODO SS 23960 чтобы не сбрасывалась фильтрация при создании документа
          // await this.props.mainTableDownloadDocCollection({});
          // await this.props.combineMainTableAttributes({});

          //Новый прелоадер

          await this.props.setModalData({});
          // TODO: СМЕРЖИТЬ 13.04.
          // await this.props.setLoaderModalData({keyDeleted: "NewDocumentByVersion272"});

          await this.props.setModalData({
            name: ModalTypes.app.info,
            data: {type : "success", content : `Документ из версии успешно создан!`}
          });
        })
        .catch((e) => {
          this.props.setModalData({
            name: ModalTypes.app.info,
            data: {type : "fail", content : e.responseJSON.Message}
          });
        })

      // .catch((e) => {
      //   this.props.setModalData({
      //     name: ModalTypes.app.info,
      //     data: {type : "fail", content : `${e.responseJSON.Message}`}
      //   });
      // })
  }

  hide() {
    this.props.setModalData({});
  }

  render() {

    const sbStyle = {
      menuList: (base) => ({
        ...base,

  "::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },

  "::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      /*background-color: rgb(239 137 137 / 20%);*/
      backgroundColor: "var(--background-colorBS)",
    },

  "::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.35)",
    }
      })
    }

    let modalStyle = {
      display : "block",
      backgroundColor: "#061c3e66",
    };

    const defaultType = {
      label: this.props.activeDocument.Info.Type.Name,
      value: this.props.activeDocument.Info.Type.Id
    }

    let mismatchAttributes = []

    if(this.state.targetDataType){
      mismatchAttributes = this.state?.targetDataType?.TypeAttributes.map(item => item.AttributeName.Name)
    }

    const isDisabled = !(this.state.countEmptyRequiredFields.length === 0) || !(this.state.newDocument.comment?.length > 0)

    const versions = this.props.versionList.map((item) => {
      return {
        label: `${item.Version} от ${item.VersionTime}`,
        value: item.Id
      }
    })

    //TODO: Мб поменять на что-то получше
    // const initDate = new Date(this.props.activeDocument.Info.RegDate)
    let formatDate = this.props.activeDocument.Info.RegDate.split('T')[0]


    let groupsOptions = [];
    groupsOptions.push({
      value :  null,
      label :  "Не выбрано",
      isDisabled : true,
      groupTypes: []
    });
    for(let i = 0; i < this.state.LoadGroups.length; i++) {
      groupsOptions.push({
        value :  this.state.LoadGroups[i].Id,
        label :  this.state.LoadGroups[i].Name,
        isDisabled : false,
        groupTypes: this.state.LoadGroups[i].GroupTypeRefs,
      });
    }
    groupsOptions = groupsOptions.filter(item => item.groupTypes.find(type => type.IdDocType === this.state.newDocument.docHeader.Type.Id))


    // TODO TP №21638,  №21567

    console.log('groupsOptions', groupsOptions)
    console.log(' this.state.newDocument',  this.state.newDocument)

    return(
        <div className="modal fade show" style={modalStyle} onClick={this.hide}>
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
            <div className="modal-content"
                 onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                <h5 className="modal-title text-success font-weight-bolder ">
                  Создание документа из версии
                </h5>
              </div>
              <div className="modal-body beautiful-scroll-10" style={{height: "30vw", padding:"0.8rem 1.75rem"}}>
                <div className="col">
                  <div className="row">
                    <div className="col-sm">
                      <div className="row">
                        <div className="col">
                          <label>Версия документа: </label>
                          <Select
                              style={{padding: "0"}}
                              defaultValue={versions[0]}
                              value={this.state.newDocument.idSourceVersion}
                              onChange={(item) => this.changeVersion(item)}
                              options={versions}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  text: 'var(--hover-primary)',
                                  primary25: 'var(--light-primary)',
                                  primary50: 'var(--hover-primary)',
                                  primary: 'var(--originaltwo)',
                                },
                              })}
                          />
                        </div>
                        <div className="col">
                          <label>Дата: </label>
                          <input type={"date"} className="form-control" value={formatDate} disabled/>
                        </div>
                      </div>
                      <label>Номер: </label>
                      <input className="form-control" type={"text"} value={this.props.activeDocument.Info.RegNumber} disabled/>
                      <label>Наименование: </label>
                      <input className="form-control" type={"text"} value={this.props.activeDocument.Info.Name} disabled/>
                      <label>Текущий тип документа: </label>
                      <input className="form-control" type={"text"} value={this.props.activeDocument.Info.Type.Name} disabled/>
                    </div>
                    <div className="col-sm">
                      <label className="font-weight-bolder">* Дата: </label>
                      <input className="form-control" onChange={(e) => this.changeRegDate(e.target.value)} value={this.state.newDocument.docHeader.RegDate} type={"date"}/>
                          <label className="font-weight-bolder">* Номер: </label>
                          <input
                              style={{}}
                              className="form-control"
                              type={"text"}
                              onChange={(e) => this.changeRegNumber(e.target.value)} value={this.state.newDocument.docHeader.RegNumber}
                          />
                          {/*<label className="font-weight-bolder">Раздел документа: </label>*/}
                          {/*<Select*/}
                          {/*    styles={sbSelect}*/}
                          {/*    placeholder="Выберите раздел"*/}
                          {/*    title="Выберите раздел документа"*/}
                          {/*    closeMenuOnSelect={false}*/}
                          {/*    components={animatedComponents}*/}
                          {/*    onChange={(value) => {*/}
                          {/*      this.setState({SelectedGroups: value})*/}
                          {/*      console.log('vaa', value)*/}
                          {/*    }  }*/}
                          {/*    options={groupsOptions}*/}
                          {/*    noOptionsMessage={() => "Нет доступных разделов"}*/}
                          {/*    theme={(theme) => ({*/}
                          {/*      ...theme,*/}
                          {/*      colors: {*/}
                          {/*        ...theme.colors,*/}
                          {/*        text: 'var(--hover-primary)',*/}
                          {/*        primary25: 'var(--light-primary)',*/}
                          {/*        primary50: 'var(--hover-primary)',*/}
                          {/*        primary: 'var(--originaltwo)',*/}
                          {/*      },*/}
                          {/*    })}*/}
                          {/*/>*/}
                      <label className="font-weight-bolder">* Наименование: </label>
                      <input className="form-control" type={"text"} onChange={(e) => this.changeName(e.target.value)} value={this.state.newDocument.docHeader.Name}/>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '47%'}}>
                        {this.state.newDocument.docHeader.Type && <>
                          <label className="font-weight-bolder">* Новый тип документа: </label>
                          <Select
                              styles={sbStyle}
                              defaultValue={defaultType}
                              components={animatedComponents}
                              onChange={(item) => {
                                this.changeTargetDataType(item);
                              }}
                              options={this.state.sortingFoldersName}
                              placeholder="Выберите папку"
                              title="Из папки"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  text: 'var(--hover-primary)',
                                  primary25: 'var(--light-primary)',
                                  primary50: 'var(--hover-primary)',
                                  primary: 'var(--originaltwo)',
                                },
                              })}
                          />
                        </>}
                        </div>
                        <div style={{width: '47%'}}>
                          <label className="font-weight-bolder">Раздел документа: </label>
                          <Select
                              styles={sbSelect}
                              placeholder="Выберите раздел"
                              title="Выберите раздел документа"
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              onChange={(value) => {
                                this.setState({SelectedGroups: value})
                              }  }
                              options={groupsOptions}
                              noOptionsMessage={() => "Нет доступных разделов"}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  text: 'var(--hover-primary)',
                                  primary25: 'var(--light-primary)',
                                  primary50: 'var(--hover-primary)',
                                  primary: 'var(--originaltwo)',
                                },
                              })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-lg-6">
                      <p>
                      <span className="label label-inline label-light-success font-weight-bold">
                        Текущие
                      </span>
                        Атрибуты реестра “{this.props.activeDocument.Info.Type.Name}”
                      </p>

                      <div className="table-responsive">
                        <table
                            className="table table-hover table-striped table-borderless table-vertical-center">
                          <thead className="thead-light">
                          <tr className="text-uppercase">
                            <th style={{borderRadius: "1em 0 0 0"}}><p
                                className="text-success m-0 font-weight-normal">Атрибут</p></th>
                            <th style={{borderRadius: "0 1em  0 0"}}><p
                                className="text-success m-0 font-weight-normal">Значение</p></th>
                          </tr>
                          </thead>
                          <tbody style={{fontSize: "0.75em"}}>
                          {
                            this.props.activeDocument.Info.Attributes.map((item)=> {
                              let isMismatch = false;
                              if(this.state.targetDataType)(
                                  isMismatch = mismatchAttributes.find(attribute => attribute === item.AttributeName.Name)
                              )
                              return <tr style={this.state.targetDataType && !isMismatch ? {backgroundColor: '#F58B8B'} : this.state.targetFolder ? {backgroundColor: 'limegreen'} : null} key={item.Id}>
                                <td>{item.AttributeName.Name}</td>
                                <td><input value={item.AttributeValue} className="form-control" disabled/></td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p>
                     <span className="label label-inline label-light-primary font-weight-bold">
                       Новые
                     </span>
                        Атрибуты реестра “{this.state.targetDataType.Name}”
                      </p>
                      <div className="table-responsive">
                        <table
                            className="table table-hover table-striped table-borderless table-vertical-center">
                          <thead className="thead-light">
                          <tr className="text-uppercase">
                            <th style={{borderRadius: "1em 0 0 0"}}><p
                                className="text-success m-0 font-weight-normal">Атрибут</p></th>
                            <th style={{borderRadius: "0 1em  0 0"}}><p
                                className="text-success m-0 font-weight-normal">Значение</p></th>
                          </tr>
                          </thead>
                          <tbody style={{fontSize : "0.75em"}}>
                          { this.state.targetDataType?.TypeAttributes.map((item) => {
                            let targetValue = this.props.activeDocument.AllAttributes.find((atr) => atr.AttributeName?.Id === item.AttributeName?.Id)?.Value
                            return <tr style={!targetValue && item.IsRequired ? {backgroundColor: '#F0F2B0'} : targetValue ? {backgroundColor: '#B0F2B7'} : null}  key={item.AttributeName.Id}>
                              <td style={item.IsRequired ? {fontWeight: '700'} : null}>{item.AttributeName.Name}</td>
                              <td>
                                {
                                    item.AttributeName.IdAttributeType === 1
                                    &&
                                    <input
                                        className="form-control"
                                        value={targetValue}
                                        type={'number'}
                                        step={'any'}
                                        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />
                                }

                                {
                                    item.AttributeName.IdAttributeType === 2
                                    &&
                                    <input
                                        className="form-control"
                                        value={targetValue}
                                        type={'number'}
                                        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />
                                }
                                {
                                    item.AttributeName.IdAttributeType === 3
                                    &&
                                    <input
                                        className="form-control"
                                        value={targetValue}
                                        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />
                                }
                                {
                                  item.AttributeName.IdAttributeType === 4
                                    &&
                                    <input
                                        className="form-control"
                                        value={targetValue}
                                        type={'checkbox'}
                                        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />
                                }
                                {
                                    item.AttributeName.IdAttributeType === 5
                                    &&
                                    <input
                                        className="form-control"
                                        type={'date'}
                                        min="1000-01-01"
                                        max="9999-12-31"
                                        // value={__.dateToServer(targetValue, false, true)}
                                        value={targetValue}
                                        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id , __.dateToRussia(e.target.value ), item.IsRequired)} />
                                }
                                {/*{*/}
                                {/*    item.AttributeName.IdAttributeType === 6*/}
                                {/*    &&*/}
                                {/*    <input*/}
                                {/*        className="form-control"*/}
                                {/*        value={targetValue}*/}

                                {/*        // type={'file'}*/}
                                {/*        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    item.AttributeName.IdAttributeType === 7*/}
                                {/*    &&*/}
                                {/*    <input*/}
                                {/*        className="form-control"*/}
                                {/*        value={targetValue}*/}
                                {/*        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    item.AttributeName.IdAttributeType === 8*/}
                                {/*    &&*/}
                                {/*    <input*/}
                                {/*        className="form-control"*/}
                                {/*        value={targetValue}*/}
                                {/*        onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />*/}
                                {/*}*/}
                                {/*<input*/}
                                {/*    className="form-control"*/}
                                {/*    value={targetValue}*/}
                                {/*    onChange={(e) => this.changeAttributeValue(item.AttributeName.Id, e.target.value, item.IsRequired)} />*/}
                              </td>
                            </tr>
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <div className="d-flex flex-column">
                      <label className="font-weight-bolder">* Комментарий к новому документу:</label>
                      <textarea style={{resize: "none"}} name="comment" id="1" cols="30" rows="10" onChange={e => this.changeComment(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                <button type="button" disabled={isDisabled} className="btn btn-primary font-weight-bolder" onClick={this.postNewDocumentByVersion}>
                  Создать новый документ из версии
                </button>
                <button
                    type="button"
                    className="btn btn-light font-weight-bold"
                    onClick={this.hide}>
                  Отменить действие
                </button>
              </div>
            </div>
          </div>
        </div>

    )
  }
}

const  mapStateToProps = state => {
  return {
    activeNode : state.document.tree.activeNode,
    activeDocument : state.document.mainTable.activeRow,
    activeDocumentSection: state.document.tree.activeSection,
    allFolders: state.document.tree.docs,
    router : state.router,
    versionList: state.document.additionalSections.state.versionSection.versions
  }
}

const  mapDispatchToProps = {
  setModalData,
  combineMainTableAttributes,
  mainTableDownloadDocCollection,
  getSectionsForTree,
  deleteSectionsForTree,
  saveSectionsForTree
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDocumentByVersion);


