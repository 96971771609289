import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {
    toggleAdditionalSectionsOpen, toggleAdditionalSectionsOpen_Cards
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {attributesSectionsTypes} from "../../tools/StaticTypes";
import {__} from "../../tools/HelpFunctions";
import {Routing} from "../../tools/routing/Routing";
import {history} from "../../index";
import {setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";


class DocumentPackagesSection extends React.Component {
    constructor(props) {
        super(props);


        this.state = {

        };

        this.sectionName = attributesSectionsTypes.documentPackagesSection;

        this.toggle = this.toggle.bind(this);
        this.goToPackage = this.goToPackage.bind(this);
    }


    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpen({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }


    async goToPackage(link) {
            await history.push(link);
            await history.replace(link);
    }


    render() {
        let sectionData;
        let isReady;
        if(this.props.viewMode === "normal") {
            sectionData = this.props.additionalSectionsData.state[this.sectionName];
            isReady = this.props.additionalSectionsData.isReady;
        }
        else if(this.props.viewMode === "card") {
            let card;
            if(this.props.redactorMode && this.props.cards.redactorData) {
                card = this.props.cards.redactorData;
            } else {
                card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            }
            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = this.props.cards.collection
                .find(card => card.Id === this.props.cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }


        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Пакеты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">

                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-11">Пакет</th>
                                {
                                    !this.props.redactorMode &&
                                    <th className="col-lg-1">Перейти</th>
                                }

                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.packages.map((item, index) => {

                                    let query = this.props.router.location.query;
                                    let queryLink = "";

                                    let idDoc;
                                    if(this.props.viewMode === "normal") {
                                        idDoc = this.props?.activeRow?.Version?.IdDoc;
                                    }
                                    else if(this.props.viewMode === "card") {
                                        idDoc = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId).IdDoc;
                                    }

                                    if(item.Type == 1) {
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : 2, dwp : item.Id, dwd : idDoc},
                                            excludedKeys : ["g", "t", "c", "d", "s", "r"]
                                        });
                                    }
                                    else if(item.Type == 2){
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : item.Id, d : idDoc},
                                            excludedKeys : ["g", "t", "c", "s", "r"]
                                        });
                                    }

                                    let link = `/packages${queryLink}`;

                                    return (
                                        <tr key={item.Name + `-${createUniqueIdString(6)}`}>
                                            <td>
                                                {item.Name}
                                            </td>
                                            {
                                                !this.props.redactorMode &&
                                                <td>
                                                    <span onClick={ () => {
                                                        this.goToPackage(link);
                                                    }
                                                    } className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                        <i className="svg-icon icon-Doc_view_6"/>
                                                    </span>
                                                </td>
                                            }
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        additionalSectionsData : state.document.additionalSections,
        activeRow : state.document.mainTable.activeRow,
        cards : state.document.cards,
        router : state.router,
    }
}

const  mapDispatchToProps = {
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
    setModalData
}



export default connect(mapStateToProps, mapDispatchToProps)(DocumentPackagesSection);
