import React from "react";
import {connect} from "react-redux";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";


class ContentSectionPackagesMini extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen : false
        };

        this.toggle = this.toggle.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }


    render() {
        let activeDocument;
        if(this.props.tableRole === "main") activeDocument = this.props.activeDocument;
        else if(this.props.tableRole === "additional") activeDocument = this.props.activeDocumentAdditional;
        else if(this.props.tableRole === "download") activeDocument = this.props.activeDocumentDownload;
        else if(this.props.tableRole === "documentsRight") activeDocument = this.props.activeDocumentRight;

        if(!activeDocument) return null;
        let isReady = activeDocument.additionalSections.isReady;
        let sectionData = sanitizeHtml(activeDocument.additionalSections.contentSection.content)


        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Содержание
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body content-section p-6">
                        {
                            isReady &&
                            <pre className="content-text">{parse(sectionData)}</pre>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,
        activeDocumentRight : state.packages.mainTable.activeDocument,
    }
}

const  mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSectionPackagesMini);