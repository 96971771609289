import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";


// modal.data.content => обязательно
class InfoModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover : false,
        };

        this.modalName = ModalTypes.app.info;

        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
    }

    // скрывать по кнопке
    hide() {

        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover && !this.props.modal.data.type === "criticalFail") {

            this.props.setModalData({});
        }
    }

    render() {

        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };


        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                     onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                     onMouseLeave={() => {this.setState({ contentOnHover : false})}}>
                    <div className="modal-content" style={{borderRadius:"25px"}}>
                        {
                            this.props.modal.data.type === "success" &&
                                <div>
                            <div className="modal-body align-content-center text-center">
                                <div className="d-flex flex-center mb-5">
                                    <img src="/images/success.svg" alt=""/>
                                </div>
                            <p className="lead font-weight-bold">{parse(sanitizeHtml(this.props.modal.data.content))}</p>
                            </div>
                            <div className="modal-footer  align-content-center text-center justify-content-center">
                            <button type="button" className="btn btn-lg btn-light-success" onClick={this.hide}>Отлично!</button>
                            </div>
                                </div>
                        }
                        {
                            this.props.modal.data.type === "fail" &&
                            <div>

                                <div className="modal-body align-content-center text-center">
                                    <div className="d-flex flex-center mb-5">
                                        <img src="/images/unsuccessful.svg" alt=""/>
                                    </div>
                                    <p className="lead font-weight-bold">{parse(sanitizeHtml(this.props.modal.data.content))}</p>
                                </div>
                                <div className="modal-footer  align-content-center text-center justify-content-center">
                                    <button type="button" className="btn btn-lg btn-light-success" onClick={ async () => {
                                        if (this.props.modal.data.onClickSaveHandler) await this.props.modal.data.onClickSaveHandler(true);
                                        this.hide();
                                    }
                                    }>Закрыть</button>
                                </div>
                            </div>
                        }
                        {
                            this.props.modal.data.type === "criticalFail" &&
                            <div>

                                <div className="modal-body align-content-center text-center">
                                    <div className="d-flex flex-center mb-5">
                                        <img src="/images/unsuccessful.svg" alt=""/>
                                    </div>
                                    <p className="lead font-weight-bold">{parse(sanitizeHtml(this.props.modal.data.content))}</p>
                                </div>
                                <div className="modal-footer  align-content-center text-center justify-content-center">
                                    {/*<button type="button" className="btn btn-lg btn-light-success">Закрыть</button>*/}
                                </div>
                            </div>
                        }
                        {
                            this.props.modal.data.type === "question" &&
                            <div>

                                <div className="modal-body align-content-center text-center">
                                    <div className="d-flex flex-center mb-5">
                                        <img src="/images/question.svg" alt=""/>
                                    </div>
                                    <p className="lead font-weight-bold">{parse(this.props.modal.data.content)}</p>
                                </div>
                                <div className="modal-footer">
                                    {/*<button type="button" className="btn btn-lg btn-light-success" onClick={this.hide}>Продолжить</button>*/}
                                    {/*<button type="button" className="btn btn-lg btn-light-success" onClick={this.hide}>Закрыть</button>*/}

                                    <button type="button" className="btn btn-primary font-weight-bolder" onClick={() => this.props.modal.data.onClickContinue()}>Продолжить</button>
                                    <button type="button" className="btn btn-light font-weight-bold" onClick={this.hide}>Закрыть</button>
                                </div>
                            </div>
                        }
                        {
                            this.props.modal.data.type === 'getMoreInfo' &&
                            <div>
                                <div className="modal-body align-content-center text-center">
                                    <p className="lead font-weight-bold">{parse(sanitizeHtml(this.props.modal.data.content))}</p>
                                </div>
                                <div className="modal-footer  align-content-center text-center justify-content-center">
                                    <button type="button" className="btn btn-lg btn-light-success" onClick={ async () => {
                                        this.hide();
                                    }}>
                                        Закрыть
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );

    }
}


const  mapStateToProps = state => {
    return {
        modal : state.globalState.app.modal
    }
}

const  mapDispatchToProps = {
    setModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);
