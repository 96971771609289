

export class Breadcrumbs {


    // айди папки в дереве (int)
    NodeId;
    // имя текущей крошки (string)
    Name;
    // id следующей крошки (int)
    NextId;
    // id предыдущей крошки (int)
    PrevId;
    // актива ли данная крошка (boolean)
    isCurrent;
    //страница текущей крошки
    PageNumber;


    constructor({NodeId, Name, NextId, PrevId, isCurrent, PageNumber}) {
        this.NodeId = NodeId;
        this.Name = Name;
        this.NextId = NextId;
        this.PrevId = PrevId;
        this.isCurrent = isCurrent;
        this.PageNumber = PageNumber ?? null
    }
}