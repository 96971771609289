import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import {
    changeVersionDocumentCards,
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    toggleAdditionalSectionsOpen, toggleAdditionalSectionsOpen_Cards
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {attributesSectionsTypes, ModalTypes, TableTypesEnum} from "../../tools/StaticTypes";
import {__} from "../../tools/HelpFunctions";
import {store} from "../../index";
import {setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {setDepartmentsData} from "../../store/packages/actionCreators/package_TreeActionCreator";


class DepartmentsSection extends React.Component {
    constructor(props) {
        super(props);

        let departmentRefs = __.deepCopy(this.props.departments)
        departmentRefs.forEach(item => {
                if (departmentRefs.some(dep => dep.IdParent === item.Id)) {
                    item.expandChildren = true
                }
            }
        )
        this.state = {
            expandAll: true,
            isOpen: !this.props.depMini,
            departmentRefs,
            departmentsData: null,
        };

        this.toggle = this.toggle.bind(this);
        this.changeDepartments = this.changeDepartments.bind(this)
        this.setIndeterminate = this.setIndeterminate.bind(this)
        this.hasActiveChildren = this.hasActiveChildren.bind(this)
        this.expand = this.expand.bind(this)
    }

    async componentDidMount() {
        if (this.props.IdDoc && !this.props.search) {
            await this.props.setDepartmentsData({TableType: this.props.tableRole, IdDoc: this.props.IdDoc})
        }
        this.setIndeterminate()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)){
                if (this.props.IdDoc && !this.props.search) await this.props.setDepartmentsData({TableType: this.props.tableRole, IdDoc: this.props.IdDoc})
            this.setIndeterminate()
        }
    }

    //декоративный элемент, промежуточное состояние чекбокса
    setIndeterminate() {
        this.state.departmentRefs.forEach(item => {
            if (this.hasActiveChildren(item) && !item.isChecked) {
                setTimeout(() => {
                    if (document.getElementById(`cb_${item.Id}`)) {
                        document.getElementById(`cb_${item.Id}`).indeterminate = true
                    }
                },0)
            }
        })
    }

    // открытье\закрытие раздела
    toggle() {
        this.setState({isOpen: !this.state.isOpen})
        const sectionName = attributesSectionsTypes.departmentsSection;
        if (this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpen({
                Name: sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name: sectionName
            });
        }
    }

    changeDepartments() {
        let IdDoc = this.props.IdDoc
        let tableRole = this.props.tableRole

        this.props.setModalData({
            name: ModalTypes.documents.mainTable.ChangeDepartments,
            data: {IdDoc, tableRole, departmentsData: this.state.departmentsData}
        });

    }


    //проверяет, есть ли checked потомки
    hasActiveChildren(parent) {
        let children = this.state.departmentRefs.filter(item => item.IdParent === parent.Id)
        if (children.some(item => item.isChecked)) return true
        else return children.some(item => this.hasActiveChildren(item))
    }

    expand(parent) {
        const departmentRefs = this.state.departmentRefs
        departmentRefs.find(item => (item.Id === parent.Id)).expandChildren = !parent.expandChildren
        this.setState({departmentRefs: departmentRefs})
    }

    render() {
        let departmentRefs = this.state.departmentRefs
        const sectionName = attributesSectionsTypes.departmentsSection;
        let sectionData;
        if (this.props.viewMode === "card") {
            let card;
            if (this.props.redactorMode && this.props.cards.redactorData) {
                card = this.props.cards.redactorData;
            } else {
                card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            }
            sectionData = __.deepCopy(card.additionalSections[sectionName]);
            departmentRefs.map(item => {
                item.isChecked = !!sectionData.content.find(dep => item.Id === +dep.IdDepartment)
                return item
            })
            sectionData.isOpen = this.props.cards.collection
                .find(card => card.Id === this.props.cards.activeCardId)
                .additionalSections[sectionName].isOpen;

        } else {
            let departmentsData
            if (this.props.tableRole) {

                let activeDocument;
                if(this.props.tableRole === TableTypesEnum.Main) activeDocument = this.props.activeDocument;
                else if(this.props.tableRole === TableTypesEnum.Additional) activeDocument = this.props.activeDocumentAdditional;
                else if(this.props.tableRole === TableTypesEnum.Download) activeDocument = this.props.activeDocumentDownload;
                else if(this.props.tableRole === TableTypesEnum.DocumentsRight) activeDocument = this.props.activeDocumentRight;

                departmentsData = activeDocument.additionalSections?.departmentsSection
            } else {
                const {activeTab, searchResults} = this.props;
                let additionalSectionsData = this.props.search ?
                searchResults.find(res => res.Id === activeTab.Id)?.additionalSections
                :
                this.props.additionalSectionsData;
                sectionData = __.deepCopy(additionalSectionsData.state[sectionName]);
                sectionData.isOpen = this.state.isOpen
                departmentsData = sectionData.departmentsData
            }

            if (departmentsData) {
                if (JSON.stringify(departmentsData) !== JSON.stringify(this.state.departmentsData)) this.setState({departmentsData})
                departmentRefs.map(item => {
                    item.isChecked = !!departmentsData.find(dep => item.Id === +dep.IdDepartment)
                    return item
                })
            }

        }

        const departmentIds = []
        const depRefsList = departmentRefs.filter(dep => dep.isChecked);
            //компонент для рекурсивной обработки списка
        const parentComponent = ({parent}) => {
            const hasActiveChildren = this.hasActiveChildren(parent)
            if (!parent.isChecked && !hasActiveChildren) return null
            const children = parent.expandChildren ? departmentRefs.filter(item => item.IdParent === parent.Id) : null
            departmentIds.push(parent.Id)
            return (
                <li key={__.createUniqueIdString(6)} className="li-podr">
                    <input type="checkbox" checked={parent.isChecked} readOnly id={`cb_${parent.Id}`} onClick={this.setIndeterminate}/>
                    <span>
                        <i className="svg-icon text-success svg-icon-xs icon-filial_24 mr-1 ml-1"/>
                        {hasActiveChildren ? <b>{parent.Name}</b> : parent.Name}
                    </span>
                    {hasActiveChildren
                        && <>
                            <span className={`ml-4 ${parent.expandChildren ? "icon-Arrows_2" : "icon-Arrows_3"}`} onClick={() => this.expand(parent)} role="button"/>
                            {parent.expandChildren &&
                                <ul>
                                    {hasActiveChildren && children.map(item => parentComponent({parent: item}))}
                                    {departmentRefs.filter(item => item.isChecked).map(item => {
                                        if (item.IdParent === parent.Id && !departmentIds.includes(item.Id)) {
                                            departmentIds.push(item.Id)
                                            {hasActiveChildren && parentComponent({parent: item})}
                                            return (
                                                <li key={__.createUniqueIdString(6)}
                                                    className={`li-podr ${!parent.expandChildren ? "d-none" : ""}`}>
                                                    <input type="checkbox"
                                                           checked={item.isChecked}
                                                           readOnly
                                                    />
                                                    <span><i className="svg-icon text-success svg-icon-xs icon-filial_24 mr-1 ml-1"/>
                                                        {item.Name}</span>
                                                </li>)
                                        } else return null
                                    })}
                                </ul>
                            }
                        </>
                    }
                </li>
            )
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={`card-title ${!this.state.isOpen && "collapsed"}`}>
                        Подразделения
                    </div>
                </div>

                <div className={`collapse ${this.state.isOpen && "show"}`}>
                    {
                        depRefsList.length > 0 ?
                            <div className="card-body p-4">
                                {/*<a onClick={this.changeDepartments} role="button">*/}
                                {/*    <p className="d-flex align-items-center m-0 mb-4">*/}
                                {/*        <i className="svg-icon text-success svg-icon-sm icon-Settings mr-3" />*/}
                                {/*        Изменить подразделения*/}
                                {/*    </p>*/}
                                {/*</a>*/}

                                <div className="modal-custom">
                                    <ul className="main-ul">
                                        {departmentRefs.length !== 0 &&
                                            <div>
                                                <span>
                                                    <i className="svg-icon text-success svg-icon-xs icon-department mr-1 ml-1"/>
                                                    <b>{this.props.filialName}</b>
                                                </span>
                                                <span className={`svg-icon text-success svg-icon-xs ${this.state.expandAll ? "icon-Arrows_2" : "icon-Arrows_3"} mr-1 ml-4`}
                                                      onClick={() => this.setState({expandAll: !this.state.expandAll})}
                                                      role="button"
                                                />
                                                {this.state.expandAll &&
                                                    <ul>
                                                        {departmentRefs.filter(parent => !parent.IdParent).map((parent) => parentComponent({parent}))}
                                                    </ul>
                                                }
                                            </div>
                                        }
                                    </ul>
                                </div>
                            </div>
                            :
                            <div className="card-body p-4 text-center">
                                Доступен для всех подразделений
                            </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,
        activeDocumentRight : state.packages.mainTable.activeDocument,

        additionalSectionsData: state.document.additionalSections,
        additionalSectionsDataSearch: state.search.additionalSections,

        cards: state.document.cards,
        departments: state.globalState.departments,
        filialName: state.globalState.filial.Active.Name,
        activeTab: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults,

    }
}

const mapDispatchToProps = {
    changeVersionDocumentCards,
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    setDepartmentsData,
    setModalData,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsSection);
