import React from "react";
import {connect} from "react-redux";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";

import TreePackages from "../tree/packages/TreePackages";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    breadcrumbsAdd,
    copyMainTreeToAdditional,
    getDocumentsForTreePackages,
    getPackageGroupsForTree,
    GetPackageTypes,
    PackageAddNodesTree,
    PackageCreateTree,
    SelectActivePackageNode,
    togglePackagesFolders
} from "../../store/packages/actionCreators/package_TreeActionCreator";
import TreePackageSearch from "../tree/packages/TreePackageSearch";
import MainTablePackage from "../mainTable/Package/MainTablePackage";
import PackagesBreadcrumbs from "../mainTable/Package/PackagesBreadcrumbs";
import {AdminLevelEnum, ModalTypes, RootComponentsStateViewModeTypes, UserSettingsNames} from "../../tools/StaticTypes";
import PackagesPreview from "../preview/PackagesPreview";
import AttributesSectionPackages from "../additionalDataSection/packages/AttributesSectionPackages";
import ContentSectionPackages from "../additionalDataSection/packages/ContentSectionPackages";
import VersionSectionPackages from "../additionalDataSection/packages/VersionSectionPackages";
import DocumentPackagesSectionPackages from "../additionalDataSection/packages/DocumentPackagesSectionPackages";
import PackagesPreviewMini from "../preview/PackagesPreviewMini";
import TreePackagesFolderSearch from "../mainTable/Package/TreePackagesFolderSearch";
import InputSearchPackage from "../inputSearch/inputSearchPackage";
import MainTableDocumentsRight from "../mainTable/Package/MainTableDocumentsRight";
import {history, store} from "../../index";
import {
    createTree_packagesDocumentRight,
    getDocumentGroupsForTree_packagesDocumentRight,
    getDocumentsForTree_packagesDocumentRight
} from "../../store/packages/actionCreators/package_DocumentRightActionCreator";
import PackagesPreviewMiniDocumentsRight from "../preview/PackagesPreviewMiniDocumentsRight";
import MobileMenu from "../static/MobileMenu";
import PackagesWelcome from "../static/PackagesWelcome";
import {setViewModePreviewPackages} from "../../store/packages/actionCreators/package_MainTableActionCreator";
import TreeControlButtons from "../tree/TreeControlButtons";
import styles from '../../media/styles/packageButtons.module.css'
import {ActionQueue} from "../../store/rootReducer";
import FilesSection from "../additionalDataSection/FilesSection";
import DepartmentsSection from "../additionalDataSection/DepartmentsSection";



class PackageApp extends React.Component {
    constructor(props) {
        super(props);

        this.lFlagWithDepartments = false;
        let {globalSettings} = this.props;
        if (globalSettings?.WithDepartments === "1" && this.props.adminLevel > AdminLevelEnum.CommonUser)
            this.lFlagWithDepartments = true

        this.state = {
            asideMenuOnHover : false,
            left: 6,
            right: 6,
        };

        if(!this.props.packagesTree.render) {
            // store.dispatch(setModalData({
            //     name : ModalTypes.app.alert,
            //     data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages"}
            // }))
            //Новый прелоадер

            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages", key: "PackageApp72"}
            }))

            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List : [
                    {action : this.props.getPackageGroupsForTree, params : {}, name : "getPackageGroupsForTree"},
                    {action : this.props.getDocumentsForTreePackages, params : {Id : 2}, name : "getDocumentsForTreePackages"},
                    {action : this.props.GetPackageTypes, params : {idPackage : 2}, name : "GetPackageTypes"},
                    {action : this.props.PackageCreateTree, params : {}, name : "PackageCreateTree"},
                    {action : this.props.SelectActivePackageNode, params : {Id : 2}, name : "SelectActivePackageNode"},
                    {action : this.props.breadcrumbsAdd, params : {NodeId : 2, Name : "Пакеты", ParentId : null,}, name : "breadcrumbsAdd"},
                    {action : this.props.breadcrumbsAdd, params : {NodeId : 2, Name : "Пакеты", TableType : "download", ParentId : null,}, name : "breadcrumbsAdd"},
                    {action : this.props.copyMainTreeToAdditional, params : {}, name : "copyMainTreeToAdditional"},
                    {action : this.props.getDocumentsForTree_packagesDocumentRight, params : {filial : this.props.filial}, name : "getDocumentsForTree_packagesDocumentRight"},
                    {action : this.props.getDocumentGroupsForTree_packagesDocumentRight, params : {filial : this.props.filial}, name : "getDocumentGroupsForTree_packagesDocumentRight"},
                    {action : this.props.createTree_packagesDocumentRight, params : {}, name : "createTree_packagesDocumentRight"},
                    // {action : this.props.setModalData, params : {}, name : "setModalData"},
                    //Новый прелоадер
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "PackageApp89"}, name : "setModalData"},
                    {action : store.dispatch, params : {type : "finish"}, name : "finish"},
                ],
                debug : false,
            });
        }

        this.viewMode_welcome = this.viewMode_welcome.bind(this);
        this.render_viewMode = this.render_viewMode.bind(this);
        this.viewMode_normal = this.viewMode_normal.bind(this);
        this.viewMode_twoTable = this.viewMode_twoTable.bind(this);
        this.viewMode_leftMenu = this.viewMode_leftMenu.bind(this);
        this.viewMode_leftMenu_tree = this.viewMode_leftMenu_tree.bind(this);
        this.viewMode_leftMenu_search = this.viewMode_leftMenu_search.bind(this);
        this.viewMode_leftMenu_search_right = this.viewMode_leftMenu_search_right.bind(this);

        this.viewMode_normal_preview = this.viewMode_normal_preview.bind(this);
        this.viewMode_leftMenu_tree_search = this.viewMode_leftMenu_tree_search.bind(this);

        this.viewMode_right_document_table = this.viewMode_right_document_table.bind(this);
        this.viewMode_twoTable_download = this.viewMode_twoTable_download.bind(this);

        this.collapseTree = this.collapseTree.bind(this);
        this.recursiveExpand = this.recursiveExpand.bind(this);
        this.findNode = this.findNode.bind(this);

        this.decreaseLeft = this.decreaseLeft.bind(this);
        this.decreaseRight = this.decreaseRight.bind(this);

    }

    componentDidMount() {
        // this.props.setStateLeftAsideMenu({
        //     isClose : false
        // });
    }
    decreaseLeft(){
        let min=3;

        if(this.state.left>min){
            this.setState({left: this.state.left - 1})
            this.setState({right: this.state.right + 1})
        }
    }
    decreaseRight(){
        let min=3;
        if(this.state.right>min){
            this.setState({right: this.state.right - 1})
            this.setState({left: this.state.left + 1})
        }
    }
    // Поиск в дереве вложенных элементов по Id
    findNode(List, Id) {
        for(let i = 0; i < List.length; i++) {
            if(List[i].Node.Id === Id) {
                return List[i]
            } else {
                if(List[i].Type === "PackagesFolder" && List[i].Children.length > 0) {
                    const foundItem = this.findNode(List[i].Children, Id);
                    if(foundItem) {
                        return foundItem
                    }
                }
            }
        }
    }

    // Рекурсивное открывание всех дочерних нод дерева, включая вложенные
    // nodeList: [] - список детей дерева
    // т.к. мы добавляем детей динамически, т.е. на момент создания функции нам известны только дети первого уровня,
    // то для рендера вложенных детей нам нужна функция, которая будет искать их в обновленном дереве
    async recursiveExpand(nodeList) {
        for(let i = 0; i < nodeList.length; i++) {
            if(nodeList[i].Type === "PackagesFolder" && nodeList[i].Node.ContainsChildren) {
                //if(!(this.props.openedFolders.includes(nodeList[i].Node.Id))) {
                    await this.props.PackageAddNodesTree({Id: nodeList[i].Node.Id,});
                    this.props.togglePackagesFolders({IdFolder: nodeList[i].Node.Id,});

                    if(nodeList[i].Node.ContainsChildren) {
                        // если у ноды есть дочерние элементы, то ищем их в новом состоянии дерева,
                        let list = this.findNode(this.props.tree_render.Children, nodeList[i].Node.Id);
                        if(list) await this.recursiveExpand(list.Children);
                    }
               // }
            }
        }
    }

    createPackage() {
        let IdParent = 2
        this.props.setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type : "createNewPackages",
                root : true,
                idParent : IdParent,
                activeNode : this.props.tree_render
            }
        });
    }

    // Функция управления кнопками свернуть/развернуть
    // action: string
    async collapseTree(action) {
        switch(action) {
            case "expandSubTree": {
                try {
                    // this.props.setModalData({
                    //     name: ModalTypes.app.alert,
                    //     data: {content: "Загрузка...", disableButton: true, fullBackground: true, gif: "packages"}
                    // });
                    //Новый прелоадер
                    store.dispatch(setLoaderModalData({
                        data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "PackageApp195"}
                    }));

                    if (!(this.props.openedFolders.includes(this.props.activeNode.Node.Id))) {
                        this.props.togglePackagesFolders({
                            IdFolder: this.props.activeNode.Node.Id,
                        });
                    }

                    await this.recursiveExpand(this.props.activeNode.Children);
                    // this.props.setModalData({});
                    //Новый прелоадер
                    this.props.setLoaderModalData({keyDeleted: "PackageApp202"});
                    return
                } catch (e) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Ошибка загрузки: <br/>${e.message}`}
                    });
                    return
                }
            }
            case "collapseSubTree":
                if(this.props.openedFolders.includes(this.props.activeNode.Node.Id)) {
                    this.props.togglePackagesFolders({
                        TableType: "main",
                        IdFolder: this.props.activeNode.Node.Id
                    });
                }
                return
            case "all": {
                this.props.togglePackagesFolders({
                    IsCollapse: action === "all",
                    TableType: "main",
                });
                return
            }
            default: return
        }
    }

    viewMode_leftMenu() {
        if(this.props.viewMode === RootComponentsStateViewModeTypes.PackagesApp.Tree) {
            return this.viewMode_leftMenu_tree();
        }
        else  if(this.props.viewMode === RootComponentsStateViewModeTypes.PackagesApp.Search) {
            return this.viewMode_leftMenu_search();
        }
        else  if(this.props.viewMode === RootComponentsStateViewModeTypes.PackagesApp.Search_Right) {
            return this.viewMode_leftMenu_search_right();
        }
        else  if(this.props.viewMode === RootComponentsStateViewModeTypes.PackagesApp.Tree_Search) {
            return this.viewMode_leftMenu_tree_search();
        }
    }

    viewMode_leftMenu_tree() {
        const { isIncreaseScope } = this.props;
        return (
            <div className="aside-secondary d-flex-none flex-row-fluid" style={{}}>
                <div className={`aside-workspace  ${isIncreaseScope ? "aside-workspace-p" : ""} scroll scroll-push my-2`}
                     onMouseEnter={()=>{this.setState({asideMenuOnHover : true})}}
                     onMouseLeave={()=>{this.setState({asideMenuOnHover : false})}}
                    >
                    <div className="tab-content">
                        <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active">
                            <div className="d-flex">
                                {/*<div className="d-flex align-items-center justify-content-between w-100 p-2 p-lg-2 my-1 my-lg-2">*/}
                                <div className="d-flex align-items-center  w-100 p-2 p-lg-2 my-1 my-lg-2">
                                    <h3 className="text-color-primary font-weight-bolder mb-0"
                                        style={{cursor : "pointer"}}
                                        title={"Главная"}
                                        onClick={()=>{
                                            this.props.setViewModePreviewPackages({
                                                ViewMode : "welcome",
                                                Next : {
                                                    action : ()=>{
                                                        history.push("/packages");
                                                    },
                                                    params : {}
                                                }
                                            });
                                        }}>Пакеты</h3>
                                    <div>
                                        <TreeControlButtons onCollapseAll={() => this.collapseTree("all")}
                                                            onCollapseSubTree={() => this.collapseTree("collapseSubTree")}
                                                            onExpandSubTree={() => {this.collapseTree("expandSubTree")}}
                                                            onCreatePackage={() => {this.createPackage()}}
                                        />
                                        {/*<button className="btn btn-icon btn-sm px-5 mt-2 py-2 ml-2 btn-light-success"*/}
                                        {/*        title={"Свернуть все"}*/}
                                        {/*        disabled={this.props.openedFolders.length <= 1}*/}
                                        {/*        onClick={() => {this.collapseTree("all")}}>*/}
                                        {/*    <i className="svg-icon icon-collapse-expand_16" />*/}
                                        {/*</button>*/}

                                        {/*<button className={`btn btn-icon btn-sm px-5 mt-2 py-2 ml-2 btn-light-success`}*/}
                                        {/*        disabled={disabledBtns || !(openedFolders.includes(activeNode.Node.Id))}*/}
                                        {/*        title={"Свернуть поддерево"}*/}
                                        {/*        onClick={() => {this.collapseTree("collapseSubTree")}}>*/}
                                        {/*    <i className="svg-icon icon-collapse_16" />*/}
                                        {/*</button>*/}

                                        {/*<button className="btn btn-icon btn-sm px-5 mt-2 py-2 ml-2 btn-light-success"*/}
                                        {/*        disabled={disabledBtns}*/}
                                        {/*        title={"Развернуть поддерево (может занять длительное время)"}*/}
                                        {/*        onClick={() => {this.collapseTree("expandSubTree")}}>*/}
                                        {/*    <i className="svg-icon icon-expand_16"/>*/}
                                        {/*</button>*/}
                                    </div>



                                </div>
                            </div>
                            <TreePackageSearch/>
                            <div className="aside-menu-wrapper flex-column-fluid px-2 py-2 beautiful-scroll-5" style={{overflowX: "hidden", marginRight : "-16px", height: '85vh'}}>
                                {/*// TODO: BUG FIX MS #21543*/}
                                <div className="aside-menu min-h-lg-800px" >
                                    <TreePackages/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    viewMode_leftMenu_search() {
        return (
            <div className="aside-secondary d-flex-none flex-row-fluid" style={{}}>
                <div className="aside-workspace scroll scroll-push my-2"
                     onMouseEnter={()=>{this.setState({asideMenuOnHover : true})}}
                     onMouseLeave={()=>{this.setState({asideMenuOnHover : false})}}>
                    <div className="tab-content">
                        <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active">
                            {/*<TreePackageSearch/>*/}
                            <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">
                                <div className="aside-menu min-h-lg-800px">
                                    <InputSearchPackage tableRole={"main"} captionUnvisible={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    viewMode_leftMenu_search_right() {
        const { isIncreaseScope } = this.props;
        return (
            <div className="aside-secondary d-flex-none flex-row-fluid" style={{}}>
                <div className={`aside-workspace ${isIncreaseScope ? "aside-workspace-p" : ""} scroll scroll-push my-2`}
                     onMouseEnter={()=>{this.setState({asideMenuOnHover : true})}}
                     onMouseLeave={()=>{this.setState({asideMenuOnHover : false})}}>
                    <div className="tab-content">
                        <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active">
                            {/*<TreePackageSearch/>*/}
                            <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">
                                <div className="aside-menu min-h-lg-800px">
                                    <InputSearchPackage tableRole={"additional"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    viewMode_leftMenu_tree_search() {
        const { isIncreaseScope } = this.props;
        return (
            <div className="aside-secondary d-flex-none flex-row-fluid" style={{}}>
                <div className={`aside-workspace  ${isIncreaseScope ? "aside-workspace-p" : ""} scroll scroll-push my-2`}
                     onMouseEnter={()=>{this.setState({asideMenuOnHover : true})}}
                     onMouseLeave={()=>{this.setState({asideMenuOnHover : false})}}>
                    <div className="tab-content">
                        <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active">
                            <div className="d-flex">
                                <div className="p-2 p-lg-2 my-1 my-lg-2">
                                    <h3 className="text-color-primary font-weight-bolder">Пакеты</h3>
                                </div>
                            </div>
                            <TreePackageSearch tableRole={"main"}/>
                            <div className="aside-menu-wrapper flex-column-fluid px-2 py-2 beautiful-scroll-5" style={{overflowX: "hidden", marginRight : "-16px"}}>
                                <div className="aside-menu min-h-lg-800px">
                                    <TreePackagesFolderSearch tableRole={"main"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render_viewMode() {
        if(this.props.viewModePreview === "welcome") {
            return this.viewMode_welcome();
        }
        else if(this.props.viewModePreview === "none") {
            return this.viewMode_normal();
        }
        else if(this.props.viewModePreview === "normal") {
            return  this.viewMode_normal_preview();
        }
        else if(this.props.viewModePreview === "two_table_none") {
            return this.viewMode_twoTable();
        }
        else if(this.props.viewModePreview === "right_document_table") {
            return this.viewMode_right_document_table();
        }
        else if(this.props.viewModePreview === "two_table_download") {
            return this.viewMode_twoTable_download();
        }
    }

    viewMode_welcome() {
        return (
            // <div className="tab-content">
            //     <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active">
                    <div className="d-flex flex-column flex-row-fluid wrapper" style={{}}>
                        <div className="container-new"  style={{}}>
                            <PackagesWelcome/>
                        </div>
                    </div>
            //     </div>
            // </div>
        );
    }

    viewMode_normal() {
        return (
            <div className="d-flex flex-column flex-row-fluid wrapper" >
                <div className="container-new px-8">
                    <div className="row">
                        <div className="card card-custom container-solid col-xl-12" style={{height : "85vh"}}>
                            <PackagesBreadcrumbs tableRole={"main"}/>
                            <MainTablePackage tableRole={"main"} viewMode={"normal"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    viewMode_normal_preview() {

        let leftSize = "col-xl-8";
        let rightSize = "col-xl-4";
        if(this.props.viewModePreview_Size === "normal") {
            leftSize = "col-xl-9";
            rightSize = "col-xl-3";
        }
        else if(this.props.viewModePreview_Size === "half") {
            leftSize = "col-xl-6";
            rightSize = "col-xl-6";
        }
        else if(this.props.viewModePreview_Size === "full") {
            leftSize = "col-xl-12";
            rightSize = "col-xl-12";
        }
        else if(this.props.viewModePreview_Size === "hide") {
            leftSize = "col-xl-11"
            rightSize = "col-xl-1"
        }
        const activeDocument = store.getState().packages.tree?.activeDocument
        let IdDoc
        if (activeDocument) IdDoc = activeDocument.Node.Version.IdDoc
        let content = (
            <div className="container-new"  style={{paddingLeft : "25px"}}>
                <div className="row">
                    <div className={`card card-custom container-solid ${leftSize}`} style={{height : "85vh"}}>
                        <PackagesBreadcrumbs tableRole={"main"}/>
                        <MainTablePackage tableRole={"main"} viewMode={"normal"}/>
                    </div>
                    <div className={`card card-custom container-solid ${this.props.viewModePreview_Size === "hide" ? "align-items-center" : ""} ${rightSize}`} style={{height : "85vh"}}>
                        <PackagesPreview tableRole={"main"}/>
                    </div>
                </div>
            </div>
        );

        if(this.props.viewModePreview_Size === "full") {
            content = (
                <div className="container-new"  style={{paddingLeft : "25px"}}>
                    <div className="row">
                        <div className={`card card-custom container-solid ${rightSize}`} style={{height : "85vh"}}>
                            <PackagesPreview tableRole={"main"}/>
                        </div>
                        <div className={`card card-custom container-solid ${leftSize}`} style={{display: "none"}}>
                            <PackagesBreadcrumbs tableRole={"main"}/>
                            <MainTablePackage tableRole={"main"} viewMode={"normal"}/>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="d-flex flex-column flex-row-fluid wrapper">
                    {content}
                <div className="container-new pt-1"  style={{paddingLeft : "25px"}}>
                    <div className="row">
                        <div className={`card card-custom container-solid col-xl-8`} >
                            <div className="accordion accordion-solid accordion-toggle-plus py-4">
                                <AttributesSectionPackages/>
                                <ContentSectionPackages/>
                                {/*TODO aa № 22822 для избежания кучи повторяющего кода и множества рутины переиспользован компонент FilesSection*/}
                                {/* FilesSectionPackages будет после тестирования удален*/}
                                {/*<FilesSectionPackages/>*/}
                                <FilesSection
                                              activeDocument={store.getState().packages.tree?.activeDocument}
                                              typeSection = {"packages"}
                                              signatureNotEditable = {true}
                                />
                            </div>
                        </div>
                        <div className="card card-custom container-solid col-xl-4">
                            <div className="accordion accordion-solid accordion-toggle-plus py-4">
                                <VersionSectionPackages/>
                                <DocumentPackagesSectionPackages/>
                                {this.lFlagWithDepartments &&
                                    <DepartmentsSection
                                        IdDoc={IdDoc}
                                        viewMode={"normal"}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    viewMode_twoTable() {
        let styleTableLeft= `card card-custom container-solid col-xl-${this.state.left}`;
        let styleTableRight= `card card-custom container-solid col-xl-${this.state.right}`;
        return (
            <div className="d-flex flex-column flex-row-fluid wrapper">
                <div className="container-new px-8">
                    <div className="row position-relative">
                        <div className={`${styleTableLeft}`} style={{height : "90vh"}}>
                            {/*<div className="card card-custom container-solid col-xl-6" style={{height : "90vh"}}>*/}
                            <PackagesPreviewMini tableRole={"main"}/>
                            <PackagesBreadcrumbs tableRole={"main"}/>
                            <MainTablePackage tableRole={"main"} viewMode={"twoTable"}/>
                        </div>
                        <div style={{left: 8.333 * this.state.left + "%"}}
                             className={[styles["container"], styles["container:hover"]].join(" ")}
                        >
                            <div
                                role="button"
                                className={[styles["btn"], styles["btn-prev"], " btn btn-icon btn-outline-success btn-circle btn-sm"].join(" ")}
                                onClick={()=>{this.decreaseLeft()}}
                            >
                                <i className="svg-icon icon-Previous_page"></i>
                            </div>
                            <div
                                role="button"
                                className={[styles["btn"], styles["btn-next"], " btn btn-icon btn-outline-success btn-circle btn-sm"].join(" ")}
                                onClick={()=>{this.decreaseRight()}}
                            >
                                <i className="svg-icon icon-Next_page"></i>
                            </div>
                        </div>
                        <div className={`${styleTableRight}`} style={{height : "90vh"}}>
                            {/*<div className="card card-custom container-solid col-xl-6" style={{height : "90vh"}}>*/}
                            <PackagesPreviewMini tableRole={"additional"}/>
                            <PackagesBreadcrumbs tableRole={"additional"}/>
                            <MainTablePackage tableRole={"additional"} viewMode={"twoTable"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    // viewMode_twoTable() {
    //     let styleTableLeft= `card card-custom container-solid col-xl-${this.state.left}`;
    //     let styleTableRight= `card card-custom container-solid col-xl-${this.state.right}`;
    //     return (
    //         <Splitter style={{height: '300px'}} layout="vertical">
    //             <div className="d-flex flex-column flex-row-fluid wrapper">
    //                 <div className="container-new row"  style={{paddingLeft:"25px"}}>
    //                     <SplitterPanel>
    //                     <div className={`${styleTableLeft}`} style={{height : "90vh"}}>
    //                         {/*<div className="card card-custom container-solid col-xl-6" style={{height : "90vh"}}>*/}
    //                         <PackagesPreviewMini tableRole={"main"}/>
    //                         <PackagesBreadcrumbs tableRole={"main"}/>
    //                         <MainTablePackage tableRole={"main"} viewMode={"twoTable"}/>
    //                         <div
    //                             onClick={()=>{this.decreaseLeft()}}
    //                             style={{position:"absolute", top:"400px", width: "40px",height: "40px", backgroundColor:"red",zIndex:"99", left:"670px", cursor:"pointer"}}>test1</div>
    //                     </div>
    //                     </SplitterPanel>
    //                     {/*<div>test</div>*/}
    //                     <SplitterPanel>
    //                     <div className={`${styleTableRight}`} style={{height : "90vh"}}>
    //                         {/*<div className="card card-custom container-solid col-xl-6" style={{height : "90vh"}}>*/}
    //                         <PackagesPreviewMini tableRole={"additional"}/>
    //                         <PackagesBreadcrumbs tableRole={"additional"}/>
    //                         <MainTablePackage tableRole={"additional"} viewMode={"twoTable"}/>
    //                         <div
    //                             onClick={()=>{this.decreaseRight()}}
    //                             style={{position:"absolute", top:"400px", width: "40px",height: "40px", backgroundColor:"red",zIndex:"99",cursor:"pointer"}}>test2
    //                         </div>
    //                     </div>
    //                     </SplitterPanel>
    //                 </div>
    //             </div>
    //         </Splitter>
    //     );
    // }
    viewMode_twoTable_download() {
        let styleTableLeft= `card card-custom container-solid col-xl-${this.state.left}`;
        let styleTableRight= `card card-custom container-solid col-xl-${this.state.right}`;
        return (
            <div className="d-flex flex-column flex-row-fluid wrapper">
                <div className="container-new px-8">
                    <div className="row position-relative">
                        <div className={`${styleTableLeft}`} style={{height : "90vh"}}>
                            <PackagesPreviewMini tableRole={"main"}/>
                            <PackagesBreadcrumbs tableRole={"main"}/>
                            <MainTablePackage tableRole={"main"} viewMode={"twoTable"}/>
                        </div>
                        <div style={{left: 8.333 * this.state.left + "%"}}
                             className={[styles["container"], styles["container:hover"]].join(" ")}
                        >
                            <div
                                role="button"
                                className={[styles["btn"], styles["btn-prev"], " btn btn-icon btn-outline-success btn-circle btn-sm"].join(" ")}
                                onClick={()=>{this.decreaseLeft()}}
                            >
                                <i className="svg-icon icon-Previous_page"></i>
                            </div>
                            <div
                                role="button"
                                className={[styles["btn"], styles["btn-next"], " btn btn-icon btn-outline-success btn-circle btn-sm"].join(" ")}
                                onClick={()=>{this.decreaseRight()}}
                            >
                                <i className="svg-icon icon-Next_page"></i>
                            </div>
                        </div>
                        <div className={`${styleTableRight}`} style={{height : "90vh"}}>
                            <PackagesPreviewMini tableRole={"download"}/>
                            <PackagesBreadcrumbs tableRole={"download"}/>
                            <MainTablePackage tableRole={"download"} viewMode={"twoTable"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    viewMode_right_document_table() {
        let styleTableLeft= `card card-custom container-solid col-xl-${this.state.left}`;
        let styleTableRight= `card card-custom container-solid col-xl-${this.state.right}`;
        return (
            <div className="d-flex flex-column flex-row-fluid wrapper">
                <div className="container-new px-8">
                    <div className="row position-relative">
                        <div className={`${styleTableLeft}`} style={{height : "90vh"}}>
                            <PackagesPreviewMini tableRole={"main"}/>
                            <PackagesBreadcrumbs tableRole={"main"}/>
                            <MainTablePackage tableRole={"main"} viewMode={"twoTable"}/>
                        </div>
                        <div style={{left: 8.333 * this.state.left + "%"}}
                            className={[styles["container"], styles["container:hover"]].join(" ")}
                        >
                            <div
                                role="button"
                                className={[styles["btn"], styles["btn-prev"], " btn btn-icon btn-outline-success btn-circle btn-sm"].join(" ")}
                                onClick={()=>{this.decreaseLeft()}}
                            >
                                <i className="svg-icon icon-Previous_page"></i>
                            </div>
                            <div
                                role="button"
                                className={[styles["btn"], styles["btn-next"], " btn btn-icon btn-outline-success btn-circle btn-sm"].join(" ")}
                                onClick={()=>{this.decreaseRight()}}
                            >
                                <i className="svg-icon icon-Next_page"></i>
                            </div>
                        </div>
                        <div className={`${styleTableRight}`} style={{height : "90vh"}}>
                            <PackagesPreviewMiniDocumentsRight tableRole={"documentsRight"}/>
                            <MainTableDocumentsRight/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        const { asideMenuIsClose, isOpenAsideMenu, viewMode, isIncreaseScope } = this.props;
        const isSearch = viewMode === RootComponentsStateViewModeTypes.PackagesApp.Search
        let isShowAsideMenu = isSearch ? !asideMenuIsClose : isOpenAsideMenu;

        let asideMobileStyle = "";
        if(isShowAsideMenu) {
            asideMobileStyle = "aside-on";
        }

        return (
            <div>
                <div className={`header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu ${!isShowAsideMenu ? 'aside-minimize' : ''}`}>

                    <MobileMenu rootName={"PackageApp"}/>

                    <div className="d-flex flex-column flex-root">
                        <div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>
                            <LeftMenu rootName="PackageApp" asideMenuOnHover={isIncreaseScope ? this.state.asideMenuOnHover : false} isSearch={isSearch} />
                            {
                                isShowAsideMenu &&
                                <>
                                    {this.viewMode_leftMenu()}
                                </>
                            }
                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper">
                            <div className="content d-flex flex-column flex-column-fluid">
                                <TopMenu rootName="PackageApp"/>
                            </div>
                        </div>
                            {this.render_viewMode()}
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        adminLevel : state.globalState.user.AdminLevel,
        globalSettings: state.globalState.settings.Content,

        asideMenuIsClose : state.globalState.app.asideMenuIsClose,
        isOpenAsideMenu: state.packages.rootState.isOpenAsideMenu,
        viewMode : state.packages.rootState.viewMode,
        viewModePreview : state.packages.rootState.viewModePreview,
        viewModePreview_Size : state.packages.rootState.viewModePreview_Size,
        filial : state.globalState.filial.Active,
        packagesTree :  state.packages.tree,

        openedFolders: state.packages.tree.openedFolders,
        tree_render: state.packages.tree.render,
        activeNode: state.packages.tree.activeNode,
        isIncreaseScope: state.globalState.userSettings.find(item => item.Name === UserSettingsNames.GENERAL_CONFIG)?.Value?.packageIsIncreaseScope,
    }
}

const  mapDispatchToProps = {
    setStateLeftAsideMenu,
    getPackageGroupsForTree,
    getDocumentsForTreePackages,
    GetPackageTypes,
    PackageCreateTree,
    SelectActivePackageNode,
    breadcrumbsAdd,
    copyMainTreeToAdditional,
    getDocumentsForTree_packagesDocumentRight,
    getDocumentGroupsForTree_packagesDocumentRight,
    createTree_packagesDocumentRight,
    setViewModePreviewPackages,
    PackageAddNodesTree,
    togglePackagesFolders,
    setModalData,
    ActionQueue,
    setLoaderModalData
}


export default connect(mapStateToProps, mapDispatchToProps)(PackageApp);
