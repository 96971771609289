import React from "react";
import {connect} from "react-redux";
// import  "../../media/styles/font-icon/style.css";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import TreeDocsFolder from "./TreeDocsFolder";

class TreeDocs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}

    }


    render() {
        let data = this.props.tree_isSearch
            ? this.props.tree_search
            : this.props.tree_render;
        let depth = 0;


        return(
            <ul className="menu-nav">
                {
                    data.children.map((item, index) => {
                        return (
                            <TreeDocsFolder
                                key={`${item.info.Name} | ${createUniqueIdString(3)}`}
                                data={item}
                                depth={depth+1}/>
                        );
                    })
                }
            </ul>
        );
    }

}


const  mapStateToProps = state => {
    return {
        activeNode : state.document.tree.activeNode,
        tree_render : state.document.tree.render, // данные в обычном режиме
        tree_search : state.document.tree.search, // данные при поиске
        tree_isSearch : state.document.tree.isSearch, // обычный режим или режим поиска
    }
}

const  mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TreeDocs);
