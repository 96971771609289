import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {Sorting} from "../../../tools/Filters";
import {TREE_ACTIONS} from "../documentActionsList";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {
    TreeCombine,
    TreeTypes
} from "../../../tools/TreeCombine";
import {store} from "../../../index";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {EntityFilters, Filter, FiltersType} from "../../../tools/API_NEW/ApiFilter";
import {__} from "../../../tools/HelpFunctions";
import {API} from "../../../tools/API_NEW/API";
import {setLoaderModalData} from "../../globalState/actionCreators/globalState_AppActionCreator";


export const toggleIsLoading = ({Next = null, Error = null}) => {
    return dispatch => {
        try {
            dispatch({type: TREE_ACTIONS.TOGGLE_IS_LOADING})
            if (store.getState().document.tree.isLoading ) {
                // dispatch(setModalData({
                //     name : ModalTypes.app.alert,
                //     data : {content : "Загрузка документов...", disableButton : true, fullBackground : true, gif : "documents"}
                // }))
                //Новый прелоадер
                store.dispatch(setLoaderModalData({
                    data : {content : "Загрузка документов...", disableButton : true, fullBackground : false, gif : "documents", key : "document_TreeActionCreator31" }
                }));
            } else {
                // dispatch(setModalData({}));
                //Новый прелоадер
                dispatch(setLoaderModalData({keyDeleted: "document_TreeActionCreator32"}));
            }

            if (Next) {
                Next.action(Next.params);
            } // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка получения дерева документов (catch) ", exception);

            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}

// получение типов документов для дерева с атрибутами
export const getDocumentsForTree = ({filial, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let id;
            if (!filial){
                id = store.getState().globalState.filial.Active.Id;
            }else{
               id= filial.Id;
            }
            if(store.getState().document.tree.docs.length==0){
                let request = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE, {
                    filter : {IdFilial : id},
                    sorting :new Sorting().add({Direction: 1,PropertyName : "Name"}).get()
                });

                request.execute(async function (data) {
                    await dispatch({type: TREE_ACTIONS.GET_DOCS, payload: data.Records});

                    if (Next) {
                        Next.action(Next.params);
                    } // следующее действие если есть цепочка
                },function(data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    console.log("Ошибка получения дерева документов (post): ", data)
                });
            }else{
                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            }

        } catch (exception) {
            console.log("Ошибка получения дерева документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// получение групп документов для дерева (папки)
export const getDocumentGroupsForTree = ({filial, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE_GROUP, {
                filter : {IdFilial : filial.Id},
                sorting : new Sorting()
                    .add({Direction: 1,PropertyName : "Name"})
                    .add({Direction: 1,PropertyName : "GroupOrder"})
                    .get()
            });

            request.execute(function(data) {
                dispatch({type : TREE_ACTIONS.GET_GROUP, payload : data.Records});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева группы документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

//Структура групп документов
export const getDocGroupStructureNodes = ({filial, Next = null, Error = null}) => {
    return async dispatch => {

        try {
            await API.documents().getDocGroupStructureNodes({
                filter : {IdFilial : filial.Id},
                sorting : {}
            })
                .then(async function (data) {
                    await dispatch({type : TREE_ACTIONS.GET_STRUCTURE, payload : data.Records});

                    if(Next) {Next.action(Next.params);}// следующее действие если есть цепочка
                })
                .catch(function(data) {
                    if (data.status === 401) {
                        dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                    }
                    console.log("Ошибка получения дерева группы документов (post): ", data);
                })

        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// генерация дерева из полученных данных
export const createTree = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let treeStore = store.getState().document.tree;
            let tree = new TreeCombine({docs : treeStore.docs, group : treeStore.group}).create().sort().get();

            dispatch({type : TREE_ACTIONS.CREATE, payload : tree});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка построения дерева документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// поиск родителя для структур групп документов в дереве
const findParent = (List, Id) => {
    for (let i = 0; i < List.length; i++) {
        if (List[i].id == Id) return List[i]
        else {
            if (List[i].children && List[i].children.length > 0) {
                const foundItem = findParent(List[i].children, Id);
                if (foundItem) {
                    return foundItem
                }
            }
        }
    }
}

const setTreeUiGroupRecursive = async (arrNewNodesItem, groups, arrNewNodes) => {
    if (!arrNewNodesItem.idParent) {
        return arrNewNodes;
    }
    const lFoundParentGroup = groups.find(item => item.Id === arrNewNodesItem.idParent);
    if (lFoundParentGroup) {
        //Добавляем новый узел, старый перекидываем под него
        const lFindNode = arrNewNodes.find(item => item.id === lFoundParentGroup.Id);
        if (lFindNode) {
            lFindNode.children ? lFindNode.children.push(arrNewNodesItem) : lFindNode.children = [arrNewNodesItem];
            if (lFindNode.treeId)
                arrNewNodesItem.parent = lFindNode.treeId;

            if (!lFindNode.isVisible)
                lFindNode.isVisible = arrNewNodesItem.isVisible;

            //Убираем узел из основного массива
            arrNewNodes = arrNewNodes.filter(lItem => lItem.id != arrNewNodesItem.id)
            return arrNewNodes;

        }
        else {
            const lTreeId = createUniqueIdString(8);
            arrNewNodes.push({
                id: lFoundParentGroup.Id,
                idParent: lFoundParentGroup.IdParent,
                children: arrNewNodes.children ? arrNewNodes.children.push(arrNewNodesItem) : [arrNewNodesItem],
                info: {
                    GroupOrder: 0,
                    GroupTypeRefs: [],
                    Id: lFoundParentGroup.Id,
                    Name: lFoundParentGroup.Name,
                    Type: 1,
                    StructureNode: lFoundParentGroup,
                    UiGroup: true
                },
                parent: arrNewNodesItem.parent,
                treeId: lTreeId, // уникальный id для определения конкретного узла дерева
                type: "FOLDER",
                isVisible: arrNewNodesItem.isVisible
            });
            if (lTreeId)
                arrNewNodesItem.parent = lTreeId;

            const lNewNode = arrNewNodes[arrNewNodes.length - 1];
            // //Убираем узел из основного массива
            arrNewNodes = arrNewNodes.filter(lItem => lItem.id != arrNewNodesItem.id);

            //Продолжаем раскручивать парента
            arrNewNodes = await setTreeUiGroupRecursive(lNewNode, groups, arrNewNodes);

            return arrNewNodes;
        }
    }
}

export const renderStructureTree = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let treeStore = await store.getState().document.tree;

            let tree = new TreeCombine({docs: treeStore.docs, group: treeStore.group}).create().sort().get();

            let lTree = __.deepCopy(tree);
            let groups = __.deepCopy(store.getState().document.tree.structure);

            //находим для группы ui группы документов
            let arrNewNodes = [];

            let arrNodesForDelete = [];

            for (let i = 0; i < lTree.children.length; i++) {
                for (let j = 0; j < groups.length; j++) {
                    if (groups[j]?.Id)
                        if (lTree.children[i].info?.StructureNode?.Id === groups[j]?.Id) {
                            const lFindNode = arrNewNodes.find(item => item.id === groups[j]?.Id);
                            if (lFindNode) {
                                lFindNode.children ? lFindNode.children.push(lTree.children[i]) : lFindNode.children = [lTree.children[i]];
                                if (lFindNode.treeId)
                                    lTree.children[i].parent = lFindNode.treeId;

                                if (!lFindNode.isVisible)
                                    lFindNode.isVisible = lTree.children[i].isVisible;

                                // //Убираем узел из основного массива
                                arrNodesForDelete.push(lTree.children[i]);
                                // lTree.children = lTree.children.filter(lItem => lItem != lTree.children[i])
                            }
                            else {
                                const lTreeId = createUniqueIdString(8);
                                let treeItem = {
                                    id: groups[j]?.Id,
                                    idParent: groups[j]?.IdParent,
                                    children: arrNewNodes.children ? arrNewNodes.children.push(lTree.children[i]) : [lTree.children[i]],
                                    info: {GroupOrder: 0, GroupTypeRefs: [], Id: groups[j]?.Id, Name: groups[j].Name, Type: 1, StructureNode: groups[j], UiGroup: true},
                                    parent: lTree.children[i].parent,
                                    treeId: lTreeId, // уникальный id для определения конкретного узла дерева
                                    type: "FOLDER",
                                    isVisible: lTree.children[i].isVisible
                                };

                                // проверяем нет ли родителя среди уже существующих групп массива
                                let foundParent = groups[j]?.IdParent ? findParent(arrNewNodes, groups[j]?.IdParent) : null;
                                if(foundParent) {
                                    let duplicate = foundParent.children.find(child => child.info.Id === treeItem.info.Id)
                                    if (duplicate) {
                                        duplicate.children = [...duplicate.children, ...treeItem.children]
                                    }
                                    else {
                                        treeItem = {...treeItem, parent: foundParent.treeId};
                                        foundParent.children.push(treeItem);
                                    }
                                }
                                else arrNewNodes.push(treeItem);



                                if (lTreeId) lTree.children[i].parent = lTreeId;
                                if (!arrNewNodes.isVisible)
                                    arrNewNodes.isVisible = lTree.children[i].isVisible;

                                // //Убираем узел из основного массива
                                arrNodesForDelete.push(lTree.children[i]);
                            }
                        }
                }
            }



            let arr = __.deepCopy(arrNewNodes); // deepCopy т.к. массив изменяется в функции setTreeUiGroupRecursive
            // Для найденных групп добавляем родительские группы
           for (const i of arr) {
               arrNewNodes = await setTreeUiGroupRecursive(i, groups, arrNewNodes);
           }


            for (const i of arrNodesForDelete) {
                lTree.children = lTree.children.filter(item => item != i);
            }

            lTree.children = lTree.children.concat(arrNewNodes);

            dispatch({type : TREE_ACTIONS.CREATE, payload : lTree});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка получения данных о ЛФО USER_ACTIONS.GET_FILIAL (catch): ", exception);
             if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// поиск по дереву документов
export const treeSearch = ({SearchText, tree_render, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let tree_render_search = {
                treeId: createUniqueIdString(8), // уникальный id для определения конкретного узла дерева
                parent: null,
                type: TreeTypes.ROOT,
                info: {},
                children: []
            }


            const tree_render = JSON.parse(JSON.stringify(store.getState().document.tree.render))
            const foldersToOpen = new Set()

            // Поиск элементов по дереву
            function searchFunction(searchParent, nested = false) {
                if (!searchParent.children || searchParent.children.length === 0) return false
                for (const child of searchParent.children) {
                    let matchFound = false
                    let name = child.info.Name.toLowerCase();

                    if (name.includes(SearchText.toLowerCase())) matchFound = true
                    else matchFound = searchFunction(child, true)

                    if (matchFound) {
                        if (!nested) tree_render_search.children.push(child)
                        else {
                            foldersToOpen.add(searchParent.info.Id)
                            return true
                        }
                    } else searchParent.children = searchParent.children.filter(item => item !== child)
                }
            }

            // Более глубокая фильтрация, включая имена документов
            function filterSearchResults(parent) {
                for (const child of parent.children) {
                    if (child.type === "FOLDER") {
                        const name = child.info.Name.toLowerCase()
                        if (name.includes(SearchText.toLowerCase())) {
                            openFolders(child)
                            foldersToOpen.add(child.info.Id)
                            continue
                        }
                        filterSearchResults(child)
                    } else if (child.type === "DOCUMENT") parent.children = parent.children
                        .filter(item => item.info.Name.toLowerCase()
                            .includes(SearchText.toLowerCase()))
                }
                return parent
            }

            // Если в папках с очень глубокой вложенностью имя по запросу находилось на низком уровне вложенности,
            // то поиск прекращался,

            function openFolders(folder) {
                let toOpen = false
                if (folder.children.length !== 0) {
                    if (folder.children[0].type !== "DOCUMENT") folder.children.forEach(item => openFolders(item))
                    toOpen = folder.children.some(item => item.info.Name.toLowerCase().includes(SearchText.toLowerCase()))
                }
                if (toOpen) foldersToOpen.add(folder.info.Id)
            }

            // Если текст поиска совпадает с именем папки, но ни с одним файлом в ней, файлы выводятся без изменений
            // Если текст поиска совпадает хотя бы с одним именем файла, файлы выводятся согласно этого текста (несовпадающие не выводятся)
            searchFunction(tree_render)
            tree_render_search = filterSearchResults(tree_render_search)

            foldersToOpen.forEach(item => dispatch(toggleFoldersForTree({IdFolder: item, KeepOpen: true})))


            dispatch({type: TREE_ACTIONS.SET_TREE_SEARCH, payload: SearchText})
            dispatch({type: TREE_ACTIONS.SEARCH, payload: {...tree_render_search}});

            if (Next) Next.action(Next.params) // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка поиска по дереву (catch) ", exception);

            if (Error) Error.action(Error.params) // если ошибка колбек
        }
    }
}

export const setTreeSearchQuery = ({SearchText = "",  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: TREE_ACTIONS.SET_TREE_SEARCH, payload: SearchText})
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
} catch (exception) {
    console.log("Ошибка сохранения текста запроса в дереве документов ", exception);
    if(Error) {Error.action(Error.params);} // если ошибка колбек
}
}
}
export const treeSearchToggle = ({State = false,  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : TREE_ACTIONS.SEARCH_TOGGLE, payload : State});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения отображения дерева при поиске (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// сохраняем выбранный узел дерева
export const treeSelectNode = ({Node = null,  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            await dispatch({type : TREE_ACTIONS.SELECT_NODE, payload : Node});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения отображения дерева при поиске (catch): ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const getSectionsForTree = ({idDoc, isClear= false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(isClear){
                dispatch({type: TREE_ACTIONS.GET_SECTIONS, payload: []});
                return
            }
            let request = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE_SECTION, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocGroupRef")
                            .add({Name: "IdDoc", Value: idDoc}).get()
                    ).get()
                ,
                sorting : null
            });

            await request.execute(function (data) {
                dispatch({type: TREE_ACTIONS.GET_SECTIONS, payload: data.Records});
                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            }, function (data) {
                if (data.status === 401) {
                    dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                }
                console.log("Ошибка получения дерева группы документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const saveSectionsForTree = ({saveSections, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
                collection: saveSections
            });

            await request.execute(function (data) {}, function (data) {
                if (data.status === 401) {
                    dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                }
                console.log("Ошибка получения дерева группы документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const deleteSectionsForTree = ({deleteSections, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.DOCUMENTS.DELETE_DOC_TREE_SECTION, {
                collection: deleteSections
            });

            await request.execute(function (data) {}, function (data) {
                if (data.status === 401) {
                    dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                }
                console.log("Ошибка получения дерева группы документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const toggleFoldersForTree = ({IdFolder, KeepOpen = false, isCollapse = false, Next = null, params = null, Error = null}) => {
    return async dispatch => {
        try {
            let openedFolders;
            if(isCollapse) {
                dispatch({type : TREE_ACTIONS.TOGGLE_FOLDERS, payload : { openedFolders : [] }});
                return
            }

            openedFolders = __.deepCopy(store.getState().document.tree.openedFolders);

            let openedFoldersSet = new Set(openedFolders);
            if(openedFoldersSet.has(IdFolder) && !KeepOpen) openedFoldersSet.delete(IdFolder);
            else openedFoldersSet.add(IdFolder);

            dispatch({type : TREE_ACTIONS.TOGGLE_FOLDERS,
                payload : {openedFolders : Array.from(openedFoldersSet)}
            });
            if (Next) {
                Next.action(Next.params);
            } // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
