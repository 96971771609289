import produce from "immer";
import {SEARCH_INPUTS_ACTIONS, SEARCH_PREVIEW_ACTIONS, SEARCH_ROOT_STATE_ACTIONS, SEARCH_TABLE_ACTIONS, SEARCH_TEMPORARY_DATA} from "./searchActionsList";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {DOCUMENT_PREVIEW_ACTIONS} from "../documents/documentActionsList";
import {__} from "../../tools/HelpFunctions";


const mainTable = {
    collection : {
        docs : [],
        totalInServer : 0,
    },

    body : null,
    // собранные заголовки таблицы
    header: null,
    // отмеченные документы в таблице
    // использовать как Set : методы -> add, has, delete, size
    checkedFields: [],
    // номер страницы в пагинации
    paginationPageNumber: 1,
    // кол-во документов отображаемых в таблице
    paginationPageSize: 25,
    // кол-во кнопок отображаемых при пагинации (с цифрами)
    paginationButtonsCount: 5,
    // существующие для таблицы настройки
    settings: {
        // размер таблицы от размера столбцов
        width : "100%",
        // режим отображения текста
        textViewMode : {
            header : "oneLine", // oneLine or multiLine
            body : "oneLine" // oneLine or multiLine
        },
        // скрытые столбцы таблицы
        hideColumns: [],
        isFitInContainerSize: false
    },
    // примененные к таблице фильтры
    filters: [],
    // выбранная в таблице строка (документ)
    activeRow: null,
};


// окно предпросмотра документа
const preview = {
    // готов ли компонент к первичному отображению
    isReady : false,
    // файлы предпросмотра (link, name, size, type)
    files : [],
    // выбранный файл предпросмотра
    activeFileIndex : 0,
    //оригинал или предпросморт
    typeFile: "image",
    activeView: "preview"
};

const additionalSections = {
    // готов ли компонент к первичному отображению
    isReady : false,

    // данные для каждой отдельной секции
    state : {
        // раздел всех атрибутов документа
        attributesSection: {
            // открыт\закрыт
            isOpen: true,
            // коллекция всех атрибутов
            attributes : [],
        },
        // раздел с пакетами в которых содержится документ
        documentPackagesSection: {
            // открыт\закрыт
            isOpen: false,
            // коллекция содержащих пакетов
            packages: [],
        },
        // раздел с версиями документа
        versionSection: {
            // открыт\закрыт
            isOpen: true,
            // коллекция версий документов
            versions : [],
            // выбранная для предпросмотра версия (index в коллекции versions)
            activeVersionIndex : 0,
        },
        subdivisionsSection: {
            // открыт\закрыт
            isOpen: false,

        },
        // раздел с содержимым документа
        contentSection: {
            // открыт\закрыт
            isOpen: false,
            // содержание документа
            content : "Содержание отсутствует",
        },
        // раздел с подразделениями
        departmentsSection: {
            // открыт\закрыт
            isOpen: true,
            departmentsData: []
        },
        // раздел с файлами прикрепленными к документу
        filesSection: {
            // открыт\закрыт
            isOpen: false,
            // коллекция прикрепленных к документу файлов
            files : [],
        }
    }
};
// карточки документов
const cards = {
    // готов ли компонент к отображению
    isReady : false,
    // активные карточки документов
    collection : [],
    // карточка документа, которая отображается в данный момент
    activeCardId : null,
    // данные собранные для создания новой версии документа
    redactorData : null,
    // собранные после изменения данные
    redactorDataCollected : null
};

// временные данные
// нужны для предотвращения
// повторных запросов к серверу
const temporaryData = {};

// состояние всего корневого компонента
const rootState = {
    // режим вида отображения компонента
    // какие компоненты в каком виде будут отображены
    // viewMode: RootComponentsStateViewModeTypes.SearchApp.None,
    // переключение видимости asideMenu
    isOpenAsideMenu: true,
    // вкладки поиска
    tabs: [],
    activeTab: '',
};

const searchState = {
    searchResults: [],
    searchInputsList: []
}

const initialState = {
    rootState,
    searchState,
    additionalSections,
    mainTable,
    temporaryData,
    cards,
    preview
};

export const searchReducer = (state = initialState, action) => {
    return produce(state, newState => {

       //получение документов из поиска
        if(action.type === SEARCH_INPUTS_ACTIONS.GET_DOC) {
            const index = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(index !== -1) {
                newState.searchState.searchResults[index] = {
                    ...newState.searchState.searchResults[index],
                    mainTable : {
                        ...newState.searchState.searchResults[index].mainTable,
                        collection: {
                            docs: action.payload.Records,
                            totalInServer: action.payload.TotalRecordsCount,
                        }
                    }
                }
            }

        }//сохранение параметров поиска
        else if(action.type === SEARCH_INPUTS_ACTIONS.UPDATE_INPUTS_FIELD) {
            const index = newState.searchState.searchInputsList.findIndex(item => item.Id === action.payload.Id);
            if(index !== -1) {
                newState.searchState.searchInputsList[index] = action.payload;
            } else {
                newState.searchState.searchInputsList.push(action.payload);
            }
        }//собираем таблицу
        else if(action.type === SEARCH_TABLE_ACTIONS.COMBINE_ATTRIBUTE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);

            newState.searchState.searchResults[tableIndex].mainTable = {
                ...newState.searchState.searchResults[tableIndex].mainTable,
                header: action.payload.Headers,
                body: action.payload.Body
            }
        }//записываем выбранный документ
        else if(action.type === SEARCH_TABLE_ACTIONS.SELECT_ACTIVE_ROW) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.activeRow = action.payload.RowInfo;
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.RESET) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.preview = preview;
            }
        }
        else if(action.type === DOCUMENT_PREVIEW_ACTIONS.TYPE_File) {
            newState.preview.typeFile = action.payload;
        }
        else if(action.type === SEARCH_TABLE_ACTIONS.SET_PAGINATION_PAGE_NUMBER) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.paginationPageNumber = action.payload;
            }
        }
        else if(action.type === SEARCH_TABLE_ACTIONS.SET_DEFAULT_WIDTH_OF_COLUMN) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.header = action.payload;
            }
        }
        // temporaryData
        else if(action.type === SEARCH_TEMPORARY_DATA.SAVE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].temporaryData[action.payload.name] = action.payload.data;
            }
        }
        else if(action.type === SEARCH_TEMPORARY_DATA.CLEAR) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].temporaryData = {};
            }
        }
        // preview
        else if(action.type === SEARCH_PREVIEW_ACTIONS.CREATE_BY_NEW_OPEN_DOC) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].preview = {
                    ...newState.searchState.searchResults[tableIndex].preview,
                    files: action.payload,
                    activeFileIndex: 0,
                    isReady: true
                };
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                const {searchState: {searchResults}} = newState;
                searchResults[tableIndex].additionalSections.state.attributesSection.attributes = action.payload.attributesSection;
                searchResults[tableIndex].additionalSections.state.contentSection.content = action.payload.contentSection;
                searchResults[tableIndex].additionalSections.state.filesSection.files = action.payload.filesSection;
                searchResults[tableIndex].additionalSections.state.versionSection.versions = action.payload.versionSection;
                searchResults[tableIndex].additionalSections.state.documentPackagesSection.packages = action.payload.documentPackagesSection;
                searchResults[tableIndex].additionalSections.state.versionSection.activeVersionIndex = action.payload.activeVersionIndex;
                searchResults[tableIndex].additionalSections.state.departmentsSection.departmentsData = action.payload.departmentsSection;

                searchResults[tableIndex].additionalSections.isReady = true;
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.UPDATE_DATA_FOR_DEPARTMENTS_SECTION) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].additionalSections.state.departmentsSection.departmentsData = action.payload.departmentsSection
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_FILE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].preview = {
                    ...newState.searchState.searchResults[tableIndex].preview,
                    activeFileIndex: action.payload.Index,
                    typeFile: action.payload.Type
                }
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                let cardIndex = newState.searchState.searchResults[tableIndex].cards.collection.findIndex(card => card.Id === action.payload.CardId);
                newState.searchState.searchResults[tableIndex].cards.collection[cardIndex].preview.activeFileIndex = action.payload.ActiveFileIndex;
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.SELECT_ACTIVE_DOC_CARDS) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].cards.activeCardId = action.payload;
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.UPDATE_SIGNATURE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                //Ищем файл и заменяем у него подпись
                const lFile = newState.searchState.searchResults[tableIndex].additionalSections?.state?.filesSection?.files?.find(item => item.id === (action.payload.RowInfo.Id ? action.payload.RowInfo.IdDocFile : action.payload.RowInfo.idDocFile));
                if (lFile)
                    if (action.payload.RowInfo.Id) {
                        for (let i in lFile.signatures) {
                            if (lFile.signatures[i].Id == action.payload.RowInfo.Id) {
                                lFile.signatures[i] = action.payload.lSignature;
                                break;
                            }
                        }
                    } else {
                        lFile.signatures = action.payload.lSignature.Records;
                    }
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.SET_ROW_FILE_FOR_SHOW_SIGNATURE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                //Ищем файл и ставим у него признак просмотра подписи
                const lFile = newState.searchState.searchResults[tableIndex].additionalSections?.state?.filesSection?.files?.find(item => item.id === action.payload.id);

                if (lFile)
                    lFile.showSignatures = !lFile.showSignatures
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.TOGGLE_ADDITIONAL_SECTIONS_OPEN) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].additionalSections.state[action.payload.name].isOpen = action.payload.isOpen;
            }
        }
        else if(action.type === SEARCH_TABLE_ACTIONS.ADD_ROW_TO_CHECKED) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.checkedFields = action.payload;
            }
        }
        else if(action.type === SEARCH_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.checkedFields = action.payload;
            }
        }
        else if(action.type === SEARCH_TABLE_ACTIONS.SET_PAGINATION_ON_PAGE_SIZE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.paginationPageSize = action.payload;
            }
        }
        else if(action.type === SEARCH_TABLE_ACTIONS.IS_FIT_IN_WIDTH) {
            const tabIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tabIndex !== -1) {
                newState.searchState.searchResults[tabIndex].mainTable.settings.isFitInContainerSize = action.payload;
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.CREATE_DOC_CARDS) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].cards.collection.push(action.payload);
                newState.searchState.searchResults[tableIndex].cards.isReady = true;
            }
        }
        else if(action.type === SEARCH_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].mainTable.checkedFields = action.payload;
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.SET_FILE_CONTENT) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].additionalSections.state.contentSection.content = action.payload;
            }
        }
        else if(action.type === SEARCH_PREVIEW_ACTIONS.SELECT_VIEW_FOR_ACTIVE_FILE) {
            const tableIndex = newState.searchState.searchResults.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tableIndex !== -1) {
                newState.searchState.searchResults[tableIndex].preview.activeView = action.payload;
            }
        }

        // rootState
        else if(action.type === SEARCH_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE) {
            const tabIndex = newState.rootState.tabs.findIndex(item => item.Id === newState.rootState.activeTab.Id);
            if(tabIndex !== -1) {
                newState.rootState.tabs[tabIndex].viewMode = action.payload
            }
        }
        else if(action.type === SEARCH_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU) {
            newState.rootState.isOpenAsideMenu  = action.payload;
        }
        else if(action.type === SEARCH_ROOT_STATE_ACTIONS.ADD_TAB) {
            newState.rootState.tabs.push(action.payload);
        }
        else if(action.type === SEARCH_ROOT_STATE_ACTIONS.CLOSE_TAB) {
            let tabs = __.deepCopy(newState.rootState.tabs);
            let searchResults = __.deepCopy(newState.searchState.searchResults);
            let searchInputsList = __.deepCopy(newState.searchState.searchInputsList);
            action.payload.map(item => {
                tabs = tabs.filter(tab => tab.Id !== item);
                searchResults = searchResults.filter(res => res.Id !== item);
                searchInputsList = searchInputsList.filter(inp => inp.Id !== item);
            });
            newState.rootState.tabs = tabs;
            newState.searchState.searchResults = searchResults;
            newState.searchState.searchInputsList = searchInputsList;
        }
        else if(action.type === SEARCH_ROOT_STATE_ACTIONS.ADD_ACTIVE_TAB) {
            newState.rootState.activeTab = action.payload;
            const tabIndex = newState.rootState.tabs.findIndex(item => item.Id === action.payload.Id);
            if(tabIndex !== -1) {
                // newState.rootState.tabs[tabIndex] = {
                //     Id: action.payload.Id,
                //     name: action.payload.name,
                // };
                newState.rootState.tabs[tabIndex].Id = action.payload.Id;
                newState.rootState.tabs[tabIndex].name = action.payload.name;

            } else {
                newState.rootState.tabs.push({
                    Id: action.payload.Id,
                    name: action.payload.name,
                    viewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly
                });
                newState.searchState.searchResults.push({
                    Id: action.payload.Id,
                    additionalSections,
                    mainTable,
                    temporaryData,
                    cards,
                    preview
                });
            }
        }
    });
}

