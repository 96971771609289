import {apiRequest, apiRequestNew} from "../../../tools/API/apiRequest";
import {addToEndUrl, apiUrl} from "../../../tools/API/apiUrl";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {
    PACKAGE_ROOT_STATE_ACTIONS,
    PACKAGE_SEARCH,
    PACKAGES_TABLE_ACTIONS,
    PACKAGES_TREE_ACTIONS
} from "../packageActionsList";
import {APP_ACTIONS, AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {store} from "../../../index";
import {TreeCombinePackage} from "../../../tools/TreeCombinePackage";
import {PackagesTypes} from "../../../tools/classes/packages/PackagesTypes";
import {PackagesFolder} from "../../../tools/classes/packages/PackagesFolder";
import {PackagesDocument} from "../../../tools/classes/packages/PackagesDocument";
import {__} from "../../../tools/HelpFunctions";
import {Breadcrumbs} from "../../../tools/classes/packages/Breadcrumbs";
import {API, baseUrl} from "../../../tools/API_NEW/API";
import {PackagesPaginationMovePage} from "./package_MainTableActionCreator";
import {
    ModalTypes,
    PackagesViewFilters,
    RootComponentsStateViewModeTypes,
    TableTypesEnum
} from "../../../tools/StaticTypes";
import {DocFilePart} from "../../../tools/API_NEW/ServerTypes";
import {Attribute} from "../../../tools/classes/attributes/Attribute";

// получение документов в пакете
export const getDocumentsForTreePackages = ({
                                                Id = null,
                                                IsDownload = false,
                                                IdDocOffset = null,
                                                PageSize = null,
                                                PageOffset = null,
                                                TableType = "main",
                                                FieldSorting = "",
                                                Direction = 1,
                                                ForTypes = false,
                                                Next = null,
                                                Error = null
                                            }) => {
    return async dispatch => {
        try {
            let IdFilial = store.getState().globalState.filial.Active.Id;

            if(!PageSize && PageSize!==0) {
                if(IsDownload || TableType === "download") {
                    PageSize = store.getState().packages.treeDownload.paginationPageSize;
                }
                else {
                    PageSize = TableType === "main"
                        ? store.getState().packages.tree.paginationPageSize
                        : store.getState().packages.treeAdditional.paginationPageSize;
                }
            }

            if(PageOffset === null) {
                if(IsDownload || TableType === "download") {
                    PageOffset = store.getState().packages.treeDownload.paginationPageNumber - 1;
                }
                else {
                    PageOffset = TableType === "main"
                        ? store.getState().packages.tree.paginationPageNumber - 1
                        : store.getState().packages.treeAdditional.paginationPageNumber - 1;
                }
            }

            let response;


            //TODO Костыль, т.к. сервер ругается доп таблицы - загрузки
            if (PageOffset < 0)
                PageOffset = 0;

            let sorting = new Sorting()
            if(FieldSorting.Name=="Name" || FieldSorting === "Name"){
                sorting.add({PropertyName: "Name", Direction})
            }else if(FieldSorting.Name=="RegDate"|| FieldSorting === "RegDate"){
                sorting.add({PropertyName: "RegDate", Direction})
            }
            else if(FieldSorting.Name=="Version.ModifyTime"|| FieldSorting === "ModifyDate"){
                sorting.add({PropertyName: "Version.ModifyTime", Direction})
            }
            else if(FieldSorting.Name=="TypeName"|| FieldSorting === "TypeName"){
                sorting.add({PropertyName: "Type.Name", Direction})
            }
            else if(FieldSorting.Name=="RegNumber"|| FieldSorting === "RegNumber"){
                sorting.add({PropertyName: "RegNumber", Direction})
            } else {
                sorting
                    .add({Direction: 2,PropertyName : "RegDate"})
                    .add({Direction: 1,PropertyName : "RegNumber"})
                    .add({Direction: 1,PropertyName : "Id"})
            }

            if(IdDocOffset) {
                response = await new apiRequestNew({
                    action : apiUrl.PACKAGE.DOC_IN_PAC,
                    params : {
                        filter : new Filter(FiltersType.ENTITY)
                            .addAttribute({Name : "IdPackage", Value : Id})
                            .addAttribute({Name : "PagingFilter", Value : {PageSize, PageOffset}})
                            .add(
                                new EntityFilters().setEntityName("DocInPackage")
                                    .add({Name: "IdFilial", Value: IdFilial})
                                    .get()
                            ).get(),
                        id : IdDocOffset,
                        sorting : sorting.get()
                    },
                    headers : null,
                    endUrl : "",
                }).execute();
            }
            else {
                response = await new apiRequestNew({
                    action : apiUrl.PACKAGE.DOC_IN_PAC,
                    params : {
                        filter : new Filter(FiltersType.ENTITY)
                            .addAttribute({Name : "IdPackage", Value : Id})

                            //TODO aa алгоритм пагинации проблема с пагинацией временный костыль
                            .addAttribute({Name : "PagingFilter", Value : {PageSize, PageOffset}})
                            .add(
                                new EntityFilters().setEntityName("DocInPackage")
                                    .add({Name: "IdFilial", Value: IdFilial})
                                    .get()
                            ).get(),
                        sorting : sorting.get()
                    },
                    headers : null,
                    endUrl : "",
                }).execute();
            }



            let result = await response.json();

            if(!response.ok) {
                if(response.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения документов в пакете (post): ", response, result)
                return;
            }

            let documents = [];
            if(result.Records)
                if(result.Records.length > 0) {
                    for(let i = 0; i < result.Records.length; i++) {
                        documents.push(new PackagesDocument(result.Records[i]));
                    }
                }

            if (ForTypes) {
                dispatch({type : PACKAGES_TREE_ACTIONS.GET_DOC_IN_PAC_FOR_TYPES, payload : {
                        documents,
                        tableType : TableType,
                        IsDownload : IsDownload
                    }});
            } else
                dispatch({type : PACKAGES_TREE_ACTIONS.GET_DOC_IN_PAC, payload : {
                    documents : documents,
                    totalRecordsCount : result.TotalRecordsCount,
                    documentsOffset : result.RecordsOffset,
                    tableType : TableType,
                    IsDownload : IsDownload
            }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка


        } catch (exception) {
            console.log("Ошибка получения дерева документов (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// получение групп пакетов для дерева (папки)
export const getPackageGroupsForTree = ({
                                            IdParent = 2,
                                            IsDownload = false,
                                            IdPackageType = 2,
                                            PageSize = 25,
                                            PageOffset = 0,
                                            TableType = "main",
                                            FieldSorting = "",
                                            Direction = 1,
                                            Next = null,
                                            Error = null,
                                            IsLeftMenu = false
}) => {
    return async dispatch => {
        try {

            let IdFilial = store.getState().globalState.filial.Active.Id;

            let sorting = new Sorting();

            if(FieldSorting.Name=="Name" || FieldSorting === "Name"){
                sorting.add({PropertyName: "Name", Direction})
            }else if(FieldSorting.Name=="RegDate"|| FieldSorting === "RegDate"){
                sorting.add({PropertyName: "CreateDate", Direction})
            }
            else if(FieldSorting.Name=="Version.ModifyTime"|| FieldSorting === "ModifyDate"){
                sorting.add({PropertyName: "ModifyDate", Direction})
            } else {
                sorting.add({PropertyName: "Name", Direction: 1})
            }

            sorting = sorting.get()
            let response = await new apiRequestNew({
                action : apiUrl.PACKAGE.PAC_TREE,
                params : {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocPackageExt")
                            .add({Name: "IdParent", Value: IdParent})
                            .add({Name: "IdFilial", Value: IdFilial})
                            .get()
                    )
                //    .addAttribute({Name : "PagingFilter", Value : {PageSize, PageOffset}}) // TODO: tyt111!
                    .addAttribute({Name:"IdPackage",Value: IdPackageType}).get(),
                sorting
                },
                headers : null,
                endUrl : ""
            }).execute();

            let result = await response.json();

            if(!response.ok) {
                if(response.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева группы документов (папки) -> (post): ", response, result);
                return;
            }

            let folders = [];
            if(result.Records.length > 0) {
                for(let i = 0; i < result.Records.length; i++) {
                    folders.push(new PackagesFolder(result.Records[i]));
                }
            }

            dispatch({type : PACKAGES_TREE_ACTIONS.GET_GROUP, payload : {
                folders : folders,
                totalRecordsCount : result.TotalRecordsCount,
                tableType : TableType,
                IsDownload : IsDownload
            }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// получение типов документов в пакете
export const GetPackageTypes = ({idPackage, IsDownload = false, TableType = "main", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(store.getState().router?.location?.query?.p==idPackage){
                let response = await new apiRequestNew({
                    action : apiUrl.PACKAGE.DOC_TYPES,
                    params : {
                        idPackage : idPackage
                    },
                    headers : null,
                    endUrl : ""
                }).execute();

                let result = await response.json();

                if(!response.ok) {
                    if(response.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    console.log("Ошибка получения типов документов в пакете (post): ", response, result);
                    return;
                }

                let types = [];
                if(result.length > 0) {
                    for(let i = 0; i < result.length; i++) {
                        types.push(new PackagesTypes(result[i]));
                    }
                }


                dispatch({type : PACKAGES_TREE_ACTIONS.GET_TYPE_DOC, payload : {
                        types : types,
                        tableType : TableType,
                        IsDownload : IsDownload
                    }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            }else{
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            }
        } catch (exception) {
            console.log("Ошибка получения типов документов в пакете (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setDepartmentsData = ({IdDoc = null, TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request_departments_refs = new apiRequest(apiUrl.DOCUMENTS.GET_DEPARTMENTS_REFS, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocDepartmentRef")
                            .add({Name: "IdDoc", Value: IdDoc})
                            .get()
                    ).get(),
                sorting : null
            });

            let result = {
                tableType: TableType,
                departmentsSection: []
            };

            request_departments_refs.execute(function (data) {
                    result.departmentsSection = data.Records.reverse();
                    dispatch({type: PACKAGES_TREE_ACTIONS.SET_DEPARTMENTS_DATA, payload: result})
                },
                function (data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    console.log("Ошибка получения пакетов документа (post): ", data);
                });
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка построения дерева документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// генерация дерева из полученных данных
export const PackageCreateTree = ({TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
        //    let treeStore = store.getState().packages.lastLoad;
            let tree = new TreeCombinePackage(false, TableType, IsDownload).create(IsDownload).get();

            dispatch({type : PACKAGES_TREE_ACTIONS.CREATE, payload : {
                tree : tree,
                tableType : TableType,
                IsDownload : IsDownload
            }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка построения дерева документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// обновление дерева из полученных данных
export const PackageAddNodesTree = ({
                                        Id,
                                        IdDocOffset = null,
                                        IsDownload = false,
                                        TableType = "main",
                                        FieldSorting = "",
                                        Direction = null,
                                        Next = null,
                                        Error = null,
                                        IsEmulatedClick = false}) => {
    return async dispatch => {
        try {
            if(IsDownload) {
                await dispatch(getPackageGroupsForTree({
                    IdParent : Id,
                    TableType,
                    FieldSorting,
                    Direction,
                    IsDownload,
                    IdPackageType : 1,
                }));

                if (!IsEmulatedClick || Direction)
                     await dispatch(getDocumentsForTreePackages({
                         Id, TableType, FieldSorting, Direction, IdDocOffset, IsDownload}));

                await dispatch(GetPackageTypes({idPackage : Id, TableType, IsDownload}));
            }
            else {
                await dispatch(getPackageGroupsForTree({IdParent : Id, TableType, FieldSorting, Direction, IsDownload}));
                if (!IsEmulatedClick || Direction)
                    await dispatch(getDocumentsForTreePackages({Id : Id, TableType, FieldSorting, Direction, IdDocOffset, IsDownload}));
                await dispatch(GetPackageTypes({idPackage : Id, TableType, IsDownload}));
            }


            //TODO aa 20.03.2023 в момент отмены фильтра по дополнительной таблице пакетов, пропускается ветка дерева и ломается дерево, ошибка не стабильная,
            // поставил await, но скорее всего это не очень то решает проблему
            // Есть дерево Пакет1 -> Пакет2, Документ 1 -> Документ 2
            // Иногда в рендер строится дерево Пакет1 -> Документ 2 из-за этого система сходит с ума

            let tree = await new TreeCombinePackage(true, TableType, IsDownload).update(Id).get();

            await dispatch({type : PACKAGES_TREE_ACTIONS.CREATE, payload : {
                tree : tree,
                tableType : TableType,
                IsDownload : IsDownload
            }});

            if (Direction) {
                let activeNode = await new TreeCombinePackage(true, TableType, IsDownload).searchById(Id)
                dispatch({
                    type: PACKAGES_TREE_ACTIONS.SELECT_NODE, payload: {
                        activeNode,
                        tableType: TableType
                    }
                })
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка обновления дерева документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// генерация дерева из полученных данных
export const SelectActivePackageNode = ({Id, contextSearch = false, IsSearch = false, IsDownload = false, TableType = "main", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let searchNode
            let isSearch

            if(TableType==="main") {
                searchNode = store.getState().packages.tree.searchNode;
                isSearch = store.getState().packages.tree.isSearch;
            } else if (TableType === "additional") {
                searchNode = store.getState().packages.treeAdditional.searchNode;
                isSearch = store.getState().packages.treeAdditional.isSearch;
            } else if (TableType === "download") {
                searchNode = store.getState().packages.treeDownload.searchNode;
                isSearch = store.getState().packages.treeDownload.isSearch;
            }

            //TODO если мы переносим пакет, который в текущий момент открыт в центральной части, то после переноса - не обновляются брэдкрамсы - путь к нему, остается прежний

            let lFlagSearchNode = false;
            let lFlagSearchNodeIs = false;

            if (searchNode.length !== 0)
                if (searchNode.Node.Id === Id) {
                    lFlagSearchNode = true;
                    lFlagSearchNodeIs = true;
                } else {
                    lFlagSearchNode = true;
                    lFlagSearchNodeIs = false;
                }

            if ((isSearch && lFlagSearchNodeIs) || contextSearch) {//&& (searchNode && searchNode.id == Id)
                dispatch({
                    type: PACKAGES_TREE_ACTIONS.SELECT_NODE, payload: {
                        activeNode: searchNode,
                        tableType: TableType,
                        IsDownload: IsDownload
                    }
                });
            } else {
                // //TODO если мы переносим пакет, который в текущий момент открыт в центральной части, то после переноса - не обновляются брэдкрамсы - путь к нему, остается прежний
                if (lFlagSearchNode && !lFlagSearchNodeIs) {
                    dispatch({
                        type: PACKAGES_TREE_ACTIONS.IS_SEARCH, payload: {
                            tableType: TableType
                        }
                    });
                    //TODO DK 05.04.2023 если разкоментить то вот этот баг № 22828
                    store.dispatch({type : PACKAGES_TREE_ACTIONS.SEARCH_NODE, payload : {
                            searchNode : [],
                            tableType : TableType
                        }});
                    dispatch({
                        type: PACKAGE_SEARCH.UPDATE_INPUTS_FIELD,
                        payload: {
                            TableRole: TableType,
                            IdFilial: 1,
                            SearchText: null,
                            AttributesText: null,
                            FullText: null,
                            TypeIds: null,
                            RegDateAsString: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                            RegPeriodStart: null,
                            RegPeriodFinish: null,
                            Name: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                            RegNumber: {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                            TypeName: {In: ''},
                            ModifyDate: {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                            IsSearchInAttachments: false,
                            IsSearchInVersions: false,
                            IsSearchInMarkedForDelete: false,
                            Next: null,
                            Error: null
                        }
                    });
                    dispatch({
                        type: PACKAGE_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE,
                        payload: RootComponentsStateViewModeTypes.PackagesApp.Tree
                    });
                    dispatch({
                        type: PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_FILTER, payload: {
                            newValue: PackagesViewFilters.All,
                            tableType: TableType,
                            IsDownload: false
                        }
                    });
                }
                let activeNode = new TreeCombinePackage(true, TableType, IsDownload).searchById(Id);
                // //TODO Костыль, т.к. не находит по id = 2
                // if (!activeNode)
                //     activeNode = searchNode;
                dispatch({
                    type: PACKAGES_TREE_ACTIONS.SELECT_NODE, payload: {
                        activeNode,
                        IsDownload,
                        tableType: TableType
                    }
                });
            }

            // if(activeNode===null){
            //     dispatch(SelectActivePackageNode({Id : Id, TableType}))
            // }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сохранения выбранного узла (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// выбор активного документа в пакетах
export const SelectActivePackageDocument = ({Id = -1, VersionIndex = null, ActiveDoc = null,  TableType = "main", IsDownload = false, IsVisibleModal = true, Next = null, Error = null}) => {
    return async dispatch => {
        try {

            if(Id === -1) {
                dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_DOCUMENT, payload : {
                    activeDocument : null,
                    tableType : TableType,
                    IsDownload : IsDownload
                }});
                if(Next) Next.action(Next.params);
                return;
            }
            //TODO DK оптимизица ТЭК сначало выделяем активную строку потом дозугружаем инфу по документу
            if(Id) {
                dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_ACTIVE_ROW, payload : {
                        activeRow : Id,
                        tableType : TableType,
                        IsDownload,
                        IsVisibleModal,
                    }});
                // if(Next) {Next.action(Next.params);}
            }
            let activeDocument = __.deepCopy(new TreeCombinePackage(true, TableType, IsDownload, null, true).searchById(Id, null, "PackagesDocument")); //
            if(activeDocument === null && ActiveDoc) {
                activeDocument = __.deepCopy(ActiveDoc);
            }

            if(!activeDocument) return

            activeDocument.additionalSections = {
                isReady : true,
                attributesSection : {
                    attributes : {}
                },
                documentPackagesSection : {
                    packages : []
                },
                versionSection : {
                    versions : [],
                    activeVersionIndex : 0
                },
                contentSection : {
                    content : ""
                },
                filesSection : {
                    files : []
                }
            };

            const activeDocId = TableType === TableTypesEnum.DocumentsRight ? activeDocument.Info.Id : activeDocument.Node.Id;


            activeDocument.additionalSections.versionSection.versions = await API.documents().getAllVersions({
                filter : new Filter(FiltersType.ENTITY).add(
                    new EntityFilters().setEntityName("DocVersion").add({
                        Name: "IdDoc",
                        Value: activeDocId
                    }).get()
                ).get(),
                sorting : null,
            });
            activeDocument.additionalSections.versionSection.versions.Records = activeDocument.additionalSections.versionSection.versions.Records.reverse();

            let VersionId = 0;
            if(VersionIndex !== null) {
                VersionId = activeDocument.additionalSections.versionSection.versions.Records[VersionIndex].Id;
            } else {
                VersionId = activeDocument.Node.Version.Id;
                VersionIndex = activeDocument.additionalSections.versionSection.versions.Records
                    .findIndex(version => version.Id === VersionId);
            }
            activeDocument.additionalSections.versionSection.activeVersionIndex = VersionIndex ? VersionIndex : 0;
            // получение атрибутов
            // start
            let attributes;
            if(VersionIndex !== null) {
                const currentDocAttributes = await new API.documents().getCurrentDocAttributes({
                    filter : new Filter(FiltersType.ENTITY)
                        .add(
                            new EntityFilters().setEntityName("DocAttribute")
                                .add({Name: "IdDoc", Value: activeDocId})
                                .add({Name: "IdDocVersion", Value: VersionId})
                                .get()
                        ).get(),
                    sorting : null
                });
                attributes = currentDocAttributes.Records;
            }
            else {
                attributes = TableType === TableTypesEnum.DocumentsRight ? activeDocument.Info.Attributes : activeDocument.Node.Attributes;
            }

            activeDocument.additionalSections.attributesSection.attributes.Records = [];
            const lTempDocTypes = __.deepCopy(store.getState().document.tree.docs);
            const typeId = TableType === TableTypesEnum.DocumentsRight ? activeDocument.Info.Type?.Id : activeDocument.Node.Type?.Id
            const lDocType = lTempDocTypes.filter(docType => docType.Id === typeId)
            const lTypeAttributes = lDocType[0].TypeAttributes.sort((a, b) => parseFloat(a.FieldOrder) - parseFloat(b.FieldOrder));




            let lFlagOldAttr = false;
            for (let i = 0; i < lTypeAttributes.length; i++) {
                const lLoadDoc = attributes.filter(attr => attr.AttributeName.Id === lTypeAttributes[i].AttributeName.Id)
                //TODO aa Задача № 22815 в предыдущей версии могут быть совсем другие атрибуты
                if (lLoadDoc && lLoadDoc.length > 0) {
                    activeDocument.additionalSections.attributesSection.attributes.Records.push(
                        new Attribute({
                            Id: lLoadDoc[0].Id,
                            AttributeName: lTypeAttributes[i].AttributeName,
                            AttributeValue: (lLoadDoc.length > 0 && lLoadDoc[0]?.AttributeValue !== "") ? lLoadDoc[0]?.AttributeValue : "-"
                        })
                    );
                } else {
                    lFlagOldAttr = true;
                    break;
                }
            }

            if (lFlagOldAttr)
                activeDocument.additionalSections.attributesSection.attributes.Records = attributes;

            // end


            let getContentForCurrentFile  = await API.documents().getContentForCurrentFile({
                idDoc : activeDocId,
                idVersion : VersionId
            });
            if (getContentForCurrentFile?.errorCode) {
                dispatch({type: APP_ACTIONS.SET_MODAL_DATA, payload: {
                        name : ModalTypes.app.info,
                        data : {content : getContentForCurrentFile.message.Message, type : "fail"}
                    }})
                return false
            } else {
                activeDocument.additionalSections.contentSection.content = getContentForCurrentFile
            }


            activeDocument.additionalSections.documentPackagesSection.packages = await API.packages().loadPackages({
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("PackageDocRef")
                            .add({Name: "IdDoc", Value: activeDocId})
                            .addAttribute({Name : "ReferenceProperty", Value : "IdPackage"})
                            .addAttribute({Name : "RootEntityProperty", Value : "Id"})
                            .get()
                    ).add(new EntityFilters().setEntityName("DocPackage").get()).get(),
                sorting : null
            });

            activeDocument.additionalSections.filesSection.files = await API.documents().getFilesByVersion({
                idDoc : activeDocId,
                idVersion : VersionId
            });


            for(let i = 0; i <  activeDocument.additionalSections.filesSection.files.Records.length; i++) {
                activeDocument.additionalSections.filesSection.files.Records[i].link =
                    addToEndUrl({
                    url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                    collection : [
                        activeDocument.additionalSections.filesSection.files.Records[i].Id,
                        VersionId,
                        "true"
                    ]
                });
            }


            activeDocument.preview = {
                files : [],
                activeFileIndex : 0,
                activeView: "preview"
            };

            // activeDocument.preview.files.push({
            //     name : "Предпросмотр",
            //     link : addToEndUrl({
            //         url : apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
            //         collection : [
            //             activeDocument.Node.Id,
            //             VersionId
            //         ]
            //     }),
            //     type : "image",
            //     description : "",
            //     size : null
            // });

            let previewPdf = activeDocument.additionalSections.filesSection.files.Records.find(file => file.AttributeName.Id === 2);

            if(previewPdf) {
                activeDocument.preview.files.push({
                    id : previewPdf.Id,
                    name : previewPdf.Name,
                    originalLink: addToEndUrl({
                        url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                        collection: [
                            previewPdf.Id,
                            VersionId,
                            "false" // для просмотра (true - для загрузки)
                        ]
                    }),
                    previewLink: addToEndUrl({
                        url: apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                        collection: [
                            activeDocId,
                            VersionId
                        ]
                    }),
                    type : "pdf",
                    description : "Оригинал",
                    size : previewPdf.Size,
                    AttributeName : previewPdf.AttributeName,
                });
            }


            let otherFiles = activeDocument.additionalSections.filesSection.files.Records.filter(file => file.AttributeName.Id === 4);
            if(otherFiles) {
                for(let i = 0; i < otherFiles.length; i++) {
                    if(otherFiles[i].Name.toLowerCase().indexOf(".pdf") > 0) {
                        activeDocument.preview.files.push({
                            id : otherFiles[i].Id,
                            name : otherFiles[i].Name,
                            previewLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=1&isAttachment=${false}`,
                            originalLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=0&isAttachment=${false}`,
                            // link : addToEndUrl({
                            //     url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                            //     collection : [
                            //         otherFiles[i].Id,
                            //         VersionId,
                            //         "false"
                            //     ]
                            // }),
                            //type : "pdf",
                            type: "image",
                            description : otherFiles[i].Description,
                            size : otherFiles[i].Size,
                            AttributeName : otherFiles[i].AttributeName,
                            isAttach: true
                        });
                    }
                }
            }

            activeDocument.additionalSections.filesSection.files.Records = activeDocument.additionalSections.filesSection.files.Records
                .filter(file => file.AttributeName.Id === 4 || file.AttributeName.Id === 3);

            dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_DOCUMENT, payload : {
                activeDocument : activeDocument,
                tableType : TableType,
                IsDownload : IsDownload,
                IsVisibleModal: IsVisibleModal,
            }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка при выборе документа в пакетах (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setFilesContent = ({document = null, TableType = "main"}) => {
    return async dispatch => {
        try {
            if(document) {
                let content;
                if(document.isAttach) {
                    content = await API.documents().getCurrentFileText({id: document.id, part: DocFilePart.Text, isAttachment: false});
                } else {
                    content = await API.documents().getContentForCurrentFile({idDoc: document.Id, idVersion: document.Version.Id})
                }
                dispatch({type: PACKAGES_TREE_ACTIONS.SET_FILE_CONTENT, payload: {content: content, tableType: TableType}});

            }
        } catch (exception) {
            console.log("Ошибка сбора данных для предпросмотра (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}

export const selectViewActiveFileForPreview = ({View = null, TableType = "main"}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_VIEW_FOR_ACTIVE_FILE, payload : {view: View, tableType: TableType}});
        } catch (exception) {
            console.log("Ошибка сброса данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const selectActiveFileForPreviewPackages = ({Type="image",Index, TableType = "main", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.SELECT_ACTIVE_PREVIEW_FILE, payload : {
                    index : Index,
                    Type: Type,
                    tableType : TableType
                }});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка переключения файла предпросмотра в пакетах (catch) ", exception);
            if(Error) Error.action(Error.params);
        }
    }
}




// генерация дерева из полученных данных
export const togglePackagesFolders = ({IsCollapse = false, IdFolder, TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let openedFolders;
            let arrBreadcrumbs;
            if(IsCollapse) {
                dispatch({type : PACKAGES_TREE_ACTIONS.TOGGLE_FOLDERS, payload : {
                        openedFolders : [],
                        tableType : TableType,
                        IsDownload : IsDownload
                }});
                return
            }

            if(IsDownload || TableType === "download") {
                openedFolders = __.deepCopy(store.getState().packages.treeDownload.openedFolders);
                arrBreadcrumbs = __.deepCopy(store.getState().packages.treeDownload.breadcrumbs);
            }
            else {
                if(TableType === "main") {
                    openedFolders = __.deepCopy(store.getState().packages.tree.openedFolders);
                    arrBreadcrumbs = __.deepCopy(store.getState().packages.tree.breadcrumbs);
                }
                    else {
                    openedFolders = __.deepCopy(store.getState().packages.treeAdditional.openedFolders);
                    arrBreadcrumbs = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
                }
            }


            let openedFoldersSet = new Set(openedFolders);
            let arrDeletedFolder = [];
            //TODO почему находясь на пакете в дереве - я не могу его раскрыть ниже? при этом в центральной части я вижу внутри вложенный пакет
            if(openedFoldersSet.has(IdFolder)) {
                openedFoldersSet.delete(IdFolder);
                arrDeletedFolder.push(IdFolder);
            }
            else
            {
                openedFoldersSet.add(IdFolder);
            }

            //TODO если закрыть активный узел дерева, и открыть соседний, то открывает всегда и активный тоже, скорее всего это фича...
            //Из breadcrums берем id
            for (let lBreadcrumbs of arrBreadcrumbs)
                if (!openedFoldersSet.has(lBreadcrumbs.NodeId) && !arrDeletedFolder.find(Value =>
                    Value === lBreadcrumbs.NodeId))
                    openedFoldersSet.add(lBreadcrumbs.NodeId);

            dispatch({type : PACKAGES_TREE_ACTIONS.TOGGLE_FOLDERS, payload : {
                openedFolders : Array.from(openedFoldersSet),
                tableType : TableType,
                IsDownload : IsDownload
            }});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения открыт/закрыт папок дерево пакетов (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// найти и добавить крошку в нужное место
export const breadcrumbsAdd = ({NodeId, Name = null, ParentId = null, Refresh = false, TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let breadcrumbsStore;
            if(IsDownload || TableType === "download"){
                breadcrumbsStore = __.deepCopy(store.getState().packages.treeDownload.breadcrumbs);
            }else{
                if(TableType === "main")
                    breadcrumbsStore = __.deepCopy(store.getState().packages.tree.breadcrumbs);
                else breadcrumbsStore = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
            }

            if(breadcrumbsStore.length === 0) {
                breadcrumbsStore.push({
                    NodeId : NodeId,
                    Name: Name,
                    NextId: null,
                    PrevId: null,
                    isCurrent: true
                });
                dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_ADD, payload : {
                    breadcrumbsStore : breadcrumbsStore,
                    tableType : TableType,
                    IsDownload : IsDownload
                }});
                if(Next) Next.action(Next.params);
                return;
            }
            if(Refresh) {
                breadcrumbsStore = [];
            }

            let foundIndex = breadcrumbsStore.findIndex(b => b.NodeId === NodeId);
            // если такой узел уже есть
            if(foundIndex >= 0) {
                breadcrumbsStore = breadcrumbsStore.slice(0, foundIndex + 1);
                for(let i = 0; i < breadcrumbsStore.length; i++) {
                    breadcrumbsStore[i].isCurrent = false;
                    if(Name && NodeId === breadcrumbsStore[i].NodeId) {
                        breadcrumbsStore[i].Name = Name;
                    }
                }
                breadcrumbsStore[breadcrumbsStore.length - 1].isCurrent = true;
                dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_FIND_AND_ADD, payload : {
                    breadcrumbsStore : breadcrumbsStore,
                    tableType : TableType,
                    IsDownload : IsDownload
                }});
                if(Next) Next.action(Next.params);
                return;
            }

            let treeRender = new TreeCombinePackage(true, TableType, IsDownload);
            let foundTreeNode = treeRender.searchById(NodeId);
            if(!foundTreeNode) return;

            let newBreadcrumbs = [];
            newBreadcrumbs.push(new Breadcrumbs({
                NodeId : foundTreeNode.Node.Id,
                Name : foundTreeNode.Node.Name,
                PrevId : foundTreeNode.Node.IdParent,
                NextId : null,
                isCurrent : true
            }));

            while(true) {
                foundTreeNode = treeRender.searchById(newBreadcrumbs[newBreadcrumbs.length - 1].PrevId, true);

                if(!foundTreeNode) break;

                if(foundTreeNode.Node.Id === 2) {
                    newBreadcrumbs.push(new Breadcrumbs({
                        NodeId : 2,
                        Name : "Пакеты",
                        PrevId : null,
                        NextId : newBreadcrumbs[newBreadcrumbs.length - 1].NodeId,
                        isCurrent : false
                    }));

                }
                else if(foundTreeNode.Node.Id === 1) {
                    newBreadcrumbs.push(new Breadcrumbs({
                        NodeId : 1,
                        Name : "Пакеты Загрузки",
                        PrevId : null,
                        NextId : newBreadcrumbs[newBreadcrumbs.length - 1].NodeId,
                        isCurrent : false
                    }));
                }
                else {
                    let foundBreadcrumb = breadcrumbsStore.find(id => id.NodeId === foundTreeNode.Node.Id);
                    newBreadcrumbs.push(new Breadcrumbs({
                        NodeId : foundTreeNode.Node.Id,
                        Name : foundTreeNode.Node.Name,
                        PrevId : foundTreeNode.Node.IdParent,
                        NextId : newBreadcrumbs[newBreadcrumbs.length - 1].NodeId,
                        isCurrent : false,
                        PageNumber: foundBreadcrumb ? foundBreadcrumb.PageNumber : 1
                    }));
                }
            }

            breadcrumbsStore = newBreadcrumbs.reverse();
            dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_FIND_AND_ADD, payload : {
                breadcrumbsStore : breadcrumbsStore,
                tableType : TableType,
                IsDownload : IsDownload
            }});


            // let openedFolders;
            // let arrBreadcrums;
            // if(IsDownload) {
            //     openedFolders = __.deepCopy(store.getState().packages.treeDownload.openedFolders);
            //     arrBreadcrums = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
            // }
            // else {
            //     if(TableType === "main") {
            //         openedFolders = __.deepCopy(store.getState().packages.tree.openedFolders);
            //         arrBreadcrums = __.deepCopy(store.getState().packages.tree.breadcrumbs);
            //     }
            //     else {
            //         openedFolders = __.deepCopy(store.getState().packages.treeAdditional.openedFolders);
            //         arrBreadcrums = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
            //     }
            // }
            //
            //
            // let arrBreadcrumsSet = new Set(arrBreadcrums);
            // let openedFoldersSet = new Set(openedFolders);
            //
            // //TODO почему находясь на пакете в дереве - я не могу его раскрыть ниже? при этом в центральной части я вижу внутри вложенный пакет
            // //Из breadcrums берем id
            // for (let lopenedFolders of openedFolders) {
            //
            //     if (!arrBreadcrums.find(item => item.NodeId === lopenedFolders))
            //         openedFoldersSet.delete(lopenedFolders);
            // }
            // dispatch({type : PACKAGES_TREE_ACTIONS.TOGGLE_FOLDERS, payload : {
            //         openedFolders : Array.from(openedFoldersSet),
            //         tableType : TableType,
            //         IsDownload : IsDownload
            //     }});


            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка поиска и добавления хлебной крошки в пакетах (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// вернуться в предыдущий пакет по кнопке назад
export const breadcrumbsMovePrev = ({TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let breadcrumbsStore;
            if(IsDownload || TableType === "download") {
                breadcrumbsStore = __.deepCopy(store.getState().packages.treeDownload.breadcrumbs);
            } else {
                if(TableType === "main")
                    breadcrumbsStore = __.deepCopy(store.getState().packages.tree.breadcrumbs);
                else breadcrumbsStore = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
            }


            let foundIndex = breadcrumbsStore.findIndex(b => b.isCurrent);
            if(foundIndex <= 0) {
                dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_PREV, payload : {
                    breadcrumbs : breadcrumbsStore,
                    breadcrumbsArrowPrev : false,
                    tableType : TableType,
                    IsDownload : IsDownload
                }});
                if(Next) Next.action(Next.params);
                return;
            }

            for(let i = 0; i < breadcrumbsStore.length; i++) {
                breadcrumbsStore[i].isCurrent = false;
            }
            breadcrumbsStore[foundIndex - 1].isCurrent = true;

            await dispatch(SelectActivePackageNode({Id : breadcrumbsStore[foundIndex - 1].NodeId, TableType, IsDownload}));

            dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_PREV, payload : {
                    breadcrumbs : breadcrumbsStore,
                    breadcrumbsArrowPrev : foundIndex >= 2,
                    tableType : TableType,
                    IsDownload : IsDownload
            }});

            dispatch(PackagesPaginationMovePage({
                TableType: TableType,
                IsDownload: IsDownload,
                PageNumber: breadcrumbsStore[foundIndex - 1].PageNumber ?? 1,
            }));

            if(Next) Next.action(Next.params); // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка перехода назад по хлебной крошке в пакетах (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// вернуться в предыдущий пакет по кнопке назад
export const breadcrumbsMoveNext = ({TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let breadcrumbsStore;
            if(IsDownload || TableType === "download") {
                breadcrumbsStore = __.deepCopy(store.getState().packages.treeDownload.breadcrumbs);
            }
            else {
                if(TableType === "main")
                    breadcrumbsStore = __.deepCopy(store.getState().packages.tree.breadcrumbs);
                else breadcrumbsStore = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
            }


            let foundIndex = breadcrumbsStore.findIndex(b => b.isCurrent);
            if(foundIndex < 0 || foundIndex >= breadcrumbsStore.length - 1) {
                dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_NEXT, payload : {
                    breadcrumbs : breadcrumbsStore,
                    breadcrumbsArrowNext : false,
                    tableType : TableType,
                    IsDownload : IsDownload
                }});
                if(Next) Next.action(Next.params);
                return;
            }

            for(let i = 0; i < breadcrumbsStore.length; i++) {
                breadcrumbsStore[i].isCurrent = false;
            }
            breadcrumbsStore[foundIndex + 1].isCurrent = true;


            await dispatch(SelectActivePackageNode({Id : breadcrumbsStore[foundIndex + 1].NodeId, TableType, IsDownload}));

            dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_NEXT, payload : {
                    breadcrumbs : breadcrumbsStore,
                    breadcrumbsArrowNext : foundIndex + 1 < breadcrumbsStore.length - 1,
                    tableType : TableType,
                    IsDownload : IsDownload
            }});

            dispatch(PackagesPaginationMovePage({
                TableType: TableType,
                IsDownload: IsDownload,
                PageNumber: breadcrumbsStore[foundIndex + 1].PageNumber ?? 1,
            }));

            if(Next) Next.action(Next.params); // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка перехода вперед по хлебной крошке в пакетах (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// перейти к конкретной крошке
export const breadcrumbsMoveSelect = ({TableType = "main", IsDownload = false, NodeId, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            await dispatch(SelectActivePackageNode({Id : NodeId, TableType, IsDownload, isFromBreadcrumbs: true}));
            let breadcrumbsStore;
            if(IsDownload || TableType === "download") {
                breadcrumbsStore = __.deepCopy(store.getState().packages.treeDownload.breadcrumbs);
            }
            else {
                if(TableType === "main")
                    breadcrumbsStore = __.deepCopy(store.getState().packages.tree.breadcrumbs);
                else breadcrumbsStore = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
            }


            let foundIndex = breadcrumbsStore.findIndex(b => b.NodeId === NodeId);
            if(foundIndex < 0) return;

            for(let i = 0; i < breadcrumbsStore.length; i++) {
                breadcrumbsStore[i].isCurrent = false;
            }
            breadcrumbsStore[foundIndex].isCurrent = true;


            await dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_SELECT, payload : {
                breadcrumbs : breadcrumbsStore,
                breadcrumbsArrowPrev : foundIndex >= 1,
                breadcrumbsArrowNext : foundIndex < breadcrumbsStore.length - 1,
                tableType : TableType,
                IsDownload : IsDownload
            }});

            await dispatch(PackagesPaginationMovePage({
                TableType: TableType,
                IsDownload: IsDownload,
                PageNumber: breadcrumbsStore[foundIndex].PageNumber ?? 1,
                fromBreadcrumbs: true
            }));

            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка перехода вперед по хлебной крошке в пакетах (catch) ", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

// запись страницы при пагинации
export const breadcrumbsSetPage = ({TableType = "main", IsDownload = false, NodeId, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let breadcrumbsStore;
            let pageNumber = 1;
            if(IsDownload || TableType === "download") {
                breadcrumbsStore = __.deepCopy(store.getState().packages.treeDownload.breadcrumbs);
                pageNumber = store.getState().packages.treeDownload.paginationPageNumber;
            }
            else {
                if(TableType === "main") {
                    breadcrumbsStore = __.deepCopy(store.getState().packages.tree.breadcrumbs);
                    pageNumber = store.getState().packages.tree.paginationPageNumber;
                } else {
                    breadcrumbsStore = __.deepCopy(store.getState().packages.treeAdditional.breadcrumbs);
                    pageNumber = store.getState().packages.treeAdditional.paginationPageNumber;
                }
            }

            let foundIndex = breadcrumbsStore.findIndex(b => b.NodeId === NodeId);
            if(foundIndex < 0) return;

            breadcrumbsStore[foundIndex].PageNumber = pageNumber;

            dispatch({type : PACKAGES_TREE_ACTIONS.BREADCRUMBS_SET_PAGE_NUMBER, payload : {
                    breadcrumbs : breadcrumbsStore,
                    tableType : TableType,
                    IsDownload : IsDownload
                }});

            if(Next) Next.action(Next.params); // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка перехода вперед по хлебной крошке в пакетах (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// скопировать основное дерево в дополнительное
export const copyMainTreeToAdditional = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
             let additionalTree = __.deepCopy(store.getState().packages.tree);
            dispatch({type : PACKAGES_TREE_ACTIONS.COPY_TO_ADDITIONAL_TREE, payload : additionalTree});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка копирования дерева покатов в дополнительное дерево", exception);
            if(Error) Error.action(Error.params);
        }
    }
}


