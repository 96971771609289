import React from "react";
import {connect} from "react-redux";
import {attributesSectionsTypes} from "../../../tools/StaticTypes";
import {
    toggleAdditionalSectionsOpen_Cards, updateContentRedactorData
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {toggleAdditionalSectionsOpenSearch} from "../../../store/search/actionCreators/search_PreviewActionCreator";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
const { domToReact } = parse;


class ContentSectionSearch extends React.Component {
    constructor(props) {
        super(props);


        this.state = {

        };

        this.sectionName = attributesSectionsTypes.contentSection;


        this.toggle = this.toggle.bind(this);
        this.editHandler = this.editHandler.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpenSearch({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }

    // обработка изменения атрибутов
    editHandler(event) {
        this.props.updateContentRedactorData({
            value : event.target.value
        });
    }

    render() {
        const {activeTab, searchResults} = this.props;
        let sectionData;
        let isReady;
        let originalCard = null;
        let originalSectionData = null;
        const additionalSectionsData = searchResults.find(res => res.Id === activeTab.Id)?.additionalSections;
        if(this.props.viewMode === "normal") {
            sectionData = additionalSectionsData.state[this.sectionName];
            isReady = additionalSectionsData.isReady;
        }
        else if(this.props.viewMode === "card") {
            let card;
            const cards = searchResults.find(res => res.Id === activeTab.Id)?.cards;
            if(this.props.redactorMode && this.props.cards.redactorData) {
                card = cards.redactorData;
                originalCard = cards.collection.find(card => card.Id === cards.activeCardId);
            } else {
                card = cards.collection.find(card => card.Id === cards.activeCardId);
            }

            if(originalCard) {
                originalSectionData = __.deepCopy(originalCard.additionalSections[this.sectionName]);
            }

            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = cards.collection
                .find(card => card.Id === cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }



      //  return null;

        let sectionDataText = sanitizeHtml(sectionData.content, {
            allowedAttributes: {span: ["class", "className"]}
        });

        let contentText = <div>{parse(sectionDataText)}</div>;
        // let contentText = "";
        // try {
        //     contentText = parse(sectionData.content, {replace : (domNode) => {
        //         if(domNode.type === "text") {
        //             return  domNode;
        //         }
        //         else if(domNode.type === "tag" && HtmlTags.findIndex(tag => tag === domNode.name) !== -1) {
        //             console.log("domNode",domNode);
        //             if(domNode.name === "a") {
        //                 return <></>;
        //             }
        //             return  domNode;
        //         }
        //         else {
        //             return <></>;
        //         }
        //     }});
        // }
        // catch (e) {
        //     contentText = sectionData.content
        // }




        let editIcon = null;
        if(originalSectionData) {
            if(originalSectionData.content !== sectionData.content) {
                editIcon = (
                    <span className="btn btn-icon btn-sm" title="Внесены изменения"
                          style={{position: "absolute", top: "7px", left: "120px"}}>
                    <i className="svg-icon icon-Edit icon-color-danger"/>
                </span>
                );
            }
        }


        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        let content = this.props.redactorMode
            ? <textarea onChange={this.editHandler} className="form-control beautiful-scroll-10" rows={7} defaultValue={contentText}/>
            : <pre className="content-text">{contentText}</pre>;

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Содержание
                        {editIcon}
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body content-section p-6">
                        {
                            isReady && content
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        rootState : state.search.rootState,
        searchResults: state.search.searchState.searchResults,
        activeTab: state.search.rootState.activeTab
    }
}

const  mapDispatchToProps = {
    toggleAdditionalSectionsOpenSearch,
    toggleAdditionalSectionsOpen_Cards,
    updateContentRedactorData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSectionSearch);
