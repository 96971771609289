import React from "react";
import {connect} from "react-redux";
import ThMainTable from "./ThMainTable";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {
    checkAllRowsOnPage,
    setDefaultWidthOfColumn
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {__} from "../../tools/HelpFunctions";

class Thead extends  React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.checkboxOnChange = this.checkboxOnChange.bind(this);
    }

    // выделение или снятие всех галок
    checkboxOnChange() {
        this.props.checkAllRowsOnPage({});
    }

    componentDidMount() {
        if(this.props.header) {
            const copyHeaders = __.deepCopy(this.props.header)
            copyHeaders.map(item => {
                if (item.Name !== "Version.Version") item.SizeDefault = (item.Value.length * 13) + 70
                return item
            })
            this.props.setDefaultWidthOfColumn({updatedHeaders: copyHeaders})
        }

    }


    render() {
        let {
            header,
            body,
            checkedFields
        } = this.props;

        if(!header || header.length <= 0) {
            return null;
        }
        let countIsDelete = body.filter(item => item.Info.IsMarkedForDelete);
        countIsDelete = countIsDelete?.length ? countIsDelete.length : 0;

        let checked = checkedFields.length + countIsDelete >= body.length;

        return (
            <thead>
            <tr className="text-uppercase tablez1" >
                <th style={{width:"35px"}}>
                    <label className="checkbox checkbox-lg checkbox-inline mr-2" title='Выделить все' >
                    {/*<label className="checkbox-lg checkbox-inline mr-2" title='Выделить все' >*/}
                        <input type="checkbox" checked={checked} onChange={this.checkboxOnChange}/>
                        <span className={"row-checked"}> </span>
                    </label>
                </th>
                {
                    header.map((item, index) => {
                        return <ThMainTable
                            key={item.Value + "-" + createUniqueIdString(3)}
                            data={item}
                            setWidth = {(itemSize) => this.props.setWidth(itemSize, item)}
                        />
                    })
                }
            </tr>
            </thead>
        );
    }
}


const  mapStateToProps = state => {
    return {
        header : state.document.mainTable.header,
        body :  state.document.mainTable.body,
        checkedFields : state.document.mainTable.checkedFields,
    }
}

const  mapDispatchToProps = {
    checkAllRowsOnPage,
    setDefaultWidthOfColumn
}

export default connect(mapStateToProps,mapDispatchToProps)(Thead);
