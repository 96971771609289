
// версия документа
export class DocumentVersion {

    // number
    Id;
    // number
    IdDoc;
    // boolean
    IsCurrent;
    //  // string (преобразовать в DateTime)
    ModifyTime;
    // number
    Version;
    // string
    VersionComment;
    // string (преобразовать в DateTime)
    VersionTime;


    constructor(DataFromServer) {
        this.Id = DataFromServer.Id;
        this.IdDoc = DataFromServer.IdDoc;
        this.IsCurrent = DataFromServer.IsCurrent;
        this.ModifyTime = DataFromServer.ModifyTime;
        this.Version = DataFromServer.Version;
        this.VersionComment = DataFromServer.VersionComment;
        this.VersionTime = DataFromServer.VersionTime;
    }
}