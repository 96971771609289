// export const siteUrl = "http://archive10.test.itb.local"; // ссылка на текущий домен
//export const siteUrl = ""; // ссылка на текущий домен
export const baseUrl = "/cit-api"; // ссылка для обращение к api

// список ссылок (+ примерные данные отправки и получения | потом их можно удалить)
export const apiUrl = {
    AUTH : {
        // вход в систему
        LOGIN : {
            url : "/",
            body : {
                armType : "NotNull",
                userName : "NotNull",
                password : "NotNull"
            },
            response : {
                CreationTime : null,
                Token : null
            }
        },

        // выход из системы
        LOG_UOT : {
            url : "/Logoff",
            body : null,
            response : null
        },
        IS_AUTH : {
            url : "/IsAuthenticated",
            body : null,
            response : null
        }
    },
    SETTINGS : {
        GLOBAL : {
            url : "/GlobalClientSettings/Get",
            body : {
                armType : "NotNull"
            },
            response : {
                ArmType : null,
                Content : null, // xml
                Description : null,
                Id : null
            }
        },
        DEPARTMENTS : {
            url : "/Departments/Load",
            body : {
                filter : null,
                sorting : null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        UPLOAD_DEPARTMENTS : {
            url : "/DocDepartmentRefs/SaveOrUpdateAndDeleteByFilter",
            body : {
                filter : null,
                sorting : null
            },
        },
        USER : {
            url : "/UserSettings/GetSettings",
            body : null,
            response : [
                {
                    Id: null,
                    ArmType: null,
                    Name: null,
                    User: {
                        Id: null,
                        AccountExpirationTime: null,
                        AdDomain: null,
                        AdLogin: null,
                        AdminLevel: null,
                        Department: {
                            Id : null,
                            IdParent: null,
                            DepNumber: null,
                            IdFilial: null,
                            Name: null
                        },
                        IsActive: true,
                        IsBoundToDepartments: null,
                        Login: null,
                        Name: null,
                        UserMail: null,
                        UserPhone: null
                    },
                    Value: null
                }
            ]
        },
        UPDATE_USER : {
            url : "/UserSettings/SaveOrUpdateCollection",
            body : {
                collections: [
                    {
                        ArmType : 2,
                        Id : null,
                        Name : "",
                        User : {},
                        Value : "{}"
                    }
                ]
            },
            response : {
                collections: [
                    {
                        ArmType : 2,
                        Id : null,
                        Name : "",
                        User : {},
                        Value : "{}"
                    }
                ]
            }
        }
    },
    USERS : {
        GET_CORRECT : {
            url : "/Users/GetCurrent",
            body : null,
            response : {
                Id : null,
                AccountExpirationTime : null,
                AdDomain : null,
                AdLogin : null,
                AdminLevel : null,
                Department : {
                    Id: null,
                    IdParent: null,
                    DepNumber: null,
                    IdFilial: null,
                    Name: null
                },
                IsActive: true,
                IsBoundToDepartments: false,
                Login: null,
                Name: null,
                UserMail: null,
                UserPhone: null
            }
        },

        // обновление пароля
        UPDATE_PASSWORD : {
            url : "/Users/ChangePassword",
            body : {
                idUser : 0,
                oldPassword : null,
                newPassword : null
            },
            response : null
        },
        GET_FILIAL : {
            url : "/Filials/Load",
            body : null,
            response : {
                Records : [
                    {
                        Id: null,
                        Name: null
                    }
                ],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },

        GET_FILIAL_GROUPS : {
            url : "/FilialGroups/Load",
            body:{
                filter: {},
                sorting:{PropertySortings:
                        [
                            {
                                PropertyName:"IdParent",
                                Direction:1
                            },
                            {PropertyName:"Name",
                                Direction:1
                            }
                        ]
                }
            }
            },
        GET_GUIDES : {
            url : "/UserGuides/Load",
            body:{
                filter: {},
                sorting:{}
            }
        }


    },
    DOCUMENTS : {
        // создание (загрузка) нового документа
        DOCS_SAVE : {
            url : "/Docs/Save",
            body : {
                docHeader : {},
                docAttributes : [],
                comment: "",
                fileInfoList : []
            },
            response : "String[]"
        },
        // создание нового документа из версии
        DOCS_NEW : {
            url: "/Docs/New",
            body: {
                comment: "",
                docAttributes: [],
                docHeader:{
                    IdFilial: "",
                    Name: "",
                    RegDate: "",
                    RegNumber: "",
                    Type: {
                        Name: "",
                        Id: ""
                    }
                },
                idSourceVersion: ""
            }
        },
        // создание нового документа из версии
        DOCS_NEW_BY_VER : {
            url: "/Docs/NewByVersion",
            body: {
                comment: "",
                docAttributes: [],
                docHeader:{
                    IdFilial: "",
                    Name: "",
                    RegDate: "",
                    RegNumber: "",
                    Type: {
                        Name: "",
                        Id: ""
                    }
                },
                idSourceVersion: ""
            }
        },
        // загрузка новых файлов к документу (после Docs/Save)
        DOCS_UPLOAD : {
            url : "/DocFiles/Upload",
            body : "fileStream",
            response : ""
        },
        // завершение загрузки нового документа
        DOCS_SAVE_COMPLETE : {
            url : "/Docs/SaveComplete",
            body : "true/false", //TODO aa исправил опечатку, было fasle
            response : null
        },
        // проверка процесса загрузки файла при создании нового документа
        DOCS_GET_UPLOAD_PROGRESS_BY_GUID : {
            url : "/DocFiles/GetUploadProgressByGuid",
            body : {
                uploadGuid : "string"
            },
            response : "",
        },
        DOC_TREE : { // TODO: дерево документов DocTypesWithRights/Load сами документы с атрибутами для таблицы
            url : "/DocTypesWithRights/Load",
            body : {
                filter : null,
                sorting : null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        DOC_TREE_GROUP : {
            url : "/DocGroupsWithRefs/Load",
            body : {
                filter : null,
                sorting : null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        DOC_COLLECTION : {
            url : "/DocsExtended/Load",
            body : {
                filter : null,
                sorting : null
            },
            response : {
                // TODO: хз пока, записать не забыть
            }
        },
        // получение документа для предпросмотра по версии
        GET_BY_VERSION : {
            url : "/DocFileHeaders/GetByVersion",
            body : {
                idDoc: null,
                idVersion: null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        DOC_TREE_SECTION : {
            url : "/DocGroupRefs/Load",
            body : {

            },
            response : {

            }
        },
        SAVE_DOC_TREE_SECTION : {
            url : "/DocGroupRefs/SaveCollection",
            body : {

            },
            response : {

            }
        },
        DELETE_DOC_TREE_SECTION : {
            url : "/DocGroupRefs/DeleteCollection",
            body : {

            },
            response : {

            }
        },
        // получение картинки для предпросмотра url/{idDoc}/{idVersion}
        DOWNLOAD_PREVIEW : {
            type : "noneBody", // получение get запросом с генерацией ссылки (просто пометка | потом можно удалить)
            url : "/DocFiles/DownloadPreview/",
        },
        // ссылка на просмотр или скачивание файла (в конце имеет true или false)
        DOWNLOAD_FILE : {
            type : "noneBody", // получение get запросом с генерацией ссылки (просто пометка | потом можно удалить)
            url : "/DocFiles/Download/",
        },

        //TODO электронная подпись
        // ссылка на просмотр или скачивание файла (в конце имеет true или false)
        DOWNLOAD_SIGNATURE : {
            type : "noneBody", // получение get запросом с генерацией ссылки (просто пометка | потом можно удалить)
            url : "/DocFileSignatures/Download/",
            //DocFileSignatures/Download
            // Скачка электронной подписи файла документа

        },
        // получить все версии конкретного документа выбранного в таблице
        GET_ALL_VERSIONS : {
            url : "/DocVersions/Load/",
            body : {
                filter : null,
                sorting : null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },

        // //newFeature departmentsRef
        // // получить дерево подразделений для документа
        GET_DEPARTMENTS_REFS : {
            url : "/DocDepartmentRefs/Load",
            body : {
                filter : null,
                sorting : null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        // получить атрибуты для конкретного документа
        GET_ATTRIBUTES_FOR_CURRENT_DOC : {
            url : "/DocAttributes/Load",
            body : {
                filter : null,
                sorting : null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        // получить содержимое документа
        GET_CONTENT_FOR_CURRENT_DOC : {
            url : "/DocFiles/DownloadContent",
            body : {
                idDoc: null,
                idVersion: null
            },
            response : {
                Records: [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        // загрузить новый файл к определенному документу
        LOAD_FILE_FOR_CURRENT_DOC : {
            // в конец добавить IdDoc
            url : "/DocFiles/UploadAttachedFile",
            body : {},
            response : {}
        },
        // TODO электронная подпись
        LOAD_SIG_FILE_FOR_CURRENT_FILE_DOC : {

            url : "/DocFileSignatures/Attach",
            body : {},
            response : {}
        },
        // TODO электронная подпись
        UPDATE_SIG_FILE_FOR_CURRENT_FILE_DOC : {

            url : "/DocFileSignatures/Update",
            body : {},
            response : {}
        },
        // создание новой версии документа
        CREATE_NEW_DOC_VERSION : {
            url : "/Docs/CreateNewVersion/",
            body : {
                // комментарий к версии
                comment : "",
                // копировать ли файлы из старой версии
                copyAttachment : "true/false",
                // id документа
                id : "",
            },
            response : null
        },
        // изменение атрибутов документа
        UPDATE_DOC_ATTRIBUTES : {
            url : "/Docs/Update",
            body : {
                // новые атрибуты документа
                docAttributes : [],
                // новые заголовки (постоянные атрибуты) документа
                docHeader : {
                    // id документа
                    Id : "",
                    // id филиала
                    IdFilial : "",
                    // наименование документа
                    Name : "",
                    // дата документа
                    RegDate : "",
                    // номер документа
                    RegNumber : "",
                    // тип документа
                    Type : {
                        // id типа документа
                        Id : "",
                        // название типа документа (Например: Договор)
                        Name : ""
                    }
                },
            },
            response : null
        },
        // изменение содержания файла документа
        UPDATE_DOC_TEXT : {
            url : "/DocFiles/UpdateText",
            body : {
                id : "",
                text : ""
            },
            response : null
        },
        // установить файл как основной
        SET_MAIN_FILE : {
            url : "/DocFiles/SetMain",
            body : {
                id : ""
            },
            response : null
        },
        // удалить прикрепленный файл
        DELETE_FILE : {
            url : "/DocFiles/DeleteAttachedFile",
            body : {
                id : ""
            },
            response : null
        },
        // сброс файла предпросмотра
        RESET_PREVIEW_FILE : {
            url : "/DocFiles/ResetPreview",
            body : {
                id : 0,
                isAvailable : true
            },
            response : null
        },
        RENAME_FILE : {
            url : "/DocFiles/Rename",
            body : {
                id : 0,
                newDescription : "",
                newName : "",
            },
            response : null
        }
    },
    PACKAGE: {
        // я так понимаю загрузка инфы об одном пакете
        LOAD: {
            url: "/Packages/Load",
            body: {
                filter: null,
                sorting: null
            },
            response: {
                Records: [],
                RecordsOffset: null,
                TotalRecordsCount: null
            }
        },
        PAC_TREE: {
            url: "/PackagesExt/Load",
            body: {
                filter: null,
                sorting: null
            },
            response: {
                Records: [],
                RecordsOffset: null,
                TotalRecordsCount: null
            }
        },
        DOC_TYPES: {
            url: "/DocTypes/GetPackageTypes",
            body: {
                idPackage: null
            },
            response: {
                Records: [],
                RecordsOffset: null,
                TotalRecordsCount: null
            }
        },
        DOC_IN_PAC: {
            url: "/DocsInPackage/Load",
            body: {
                filter: null,
                sorting: null
            },
            response: {
                Records: [],
                RecordsOffset: null,
                TotalRecordsCount: null
            }
        },
        SAVE_OR_UPDATE_COLLECTION: {
            url: "/PackageDocRefs/SaveOrUpdateCollection",
            body: {
                collection: [
                    {
                        IdDoc: null,
                        IdPackage: null
                    }
                ]
            }
        },

        DELETE_COLLECTION: {
            url: "/PackageDocRefs/DeleteCollection",
            body: {
                collection: [
                    {
                        Id: "",
                    }
                ]
            }
        },

        GET_PACKAGE_PATH: {
            url: "/Packages/GetPackagePath",
            body : {}
        }
    },
    SEARCH : {
        //первое поле в разделе поиска
        SEARCH_BY_PARAMS : {
            url : "/Searcher/SearchByParams",
            body : {
                searchParams : {
                    IdFilial: "1",
                    PagingFilter: {PageSize: null}, //по дефолту 25
                    SearchText: "",//первое поле
                    AttributesText: "",//поиск по атрибутам
                    FullText: "",//поиск по полнотексту
                    IsSearchInAttachments : true, //искать в тексте прикрепленных файлов
                    IsSearchInVersions : true, // искать в версиях
                    IsSearchInMarkedForDelete : true // искать в помеченных на удаление
                }
            },
            response : {
                Records : [],
                RecordsOffset : null,
                TotalRecordsCount : null
            }
        },
        //пока хз работает когда нажимаем на определенный документ
        SEARCH_BY_PARAMS_IN_FULLTEXT: {
            url : "/Searcher/SearchByParamsInFullText",
            body : {
                idDoc: null,
                idVersion: null,
                searchParams : {
                    IdFilial: "",
                    SearchText: "",
                    PagingFilter: {PageSize: null},
                }

            },
            response : {
                Description: null,
                FullText: "",
                TotalRecordsCount: null,
                Id: null,
                IdDoc: null,
                IdDocVersion: null,
                IsContainingFoundText: false,
                IsCurrent: true,
                Name: "",
                OriginalFileHeader : {
                    Id: null,
                    PageCount: null,
                    PageFormat: null,
                    Size: null
                },
                Type : null
            }
        },
        //пока хз работает когда нажимаем на определенный документ
        SEARCH_BY_PARAMS_IN_ATTRIBUTES: {
            url : "/Searcher/SearchByParamsInAttributes",
            body : {
                idDoc: null,
                idVersion: null,
                searchParams : {
                    IdFilial: "1",
                    SearchText: "тест",
                    PagingFilter: {PageSize: 25}
                }
            },
            response : {
                AttributeName : {
                    Id: null,
                    IdAttributeType: null,
                    IsSystem: false,
                    Name: "",
                },
                AttributeValue : "",
                Id : null
            }
        },
        //ссылка на документ в реестре
        VERIFY_DOC_CLIENT_LINK_IDS: {
            url : "/Docs/VerifyDocClientLinkIds",
            body : {
                linkIds : {
                    IdDoc: "",
                    IdDocGroup: "",
                    IdDocType: "",
                    IdFilial: ""
                }
            },
            response : {
                IdDoc : null,
                IdDocGroup : null,
                IdDocType : null,
                IdFilial : null,
            }
        }

    },

    REPORTS : {
        //загрузка отчетов
        STORED_REPORT:{
            url : "/StoredReports/Load",
            body : {

            },
            response:{

            }
        },
        //загрузка полей отчета

        STORED_REPORT_PARAMS:{
            url : "/StoredReportParams/Load",
            body : {

            },
            response:{

            }
        },

        CREATE_QUERY_REPORT:{
            url : "/Reports/CreateQueryReport",
            body : {

            },
            response:{

            }
        },

        GET_REPORT_STATE:{
            url:"/Reports/GetReportState",
            body : {

            },
            response:{

            }

        },
        DOWNLOAD_REPORT:{
            url:"/Reports/",
            body : {

            },
            response:{

            }

        }
    }

};

// для pdf
// DocFiles/Download/idDoc/idVersion/false
/// false - показать файл
// true - скачать файл


// url -> "string", collection -> [array]
// добавляем к ссылке данные по порядку из collection
export const addToEndUrl = ({url , collection}) => {
    if(url) {
        let result = url;

        if(result[result.length - 1] !== "/") {
            result += "/";
        }

        for(let i = 0; i < collection.length; i++) {
            result += collection[i] + "/";
        }

        // return siteUrl + baseUrl + result.substr(0, result.length - 1);
        return baseUrl + result.substr(0, result.length - 1);
    }
    else {
        return false;
    }
}
