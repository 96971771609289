import React from "react";
import {connect} from "react-redux";
import {
    setTreeSearchQuery,
    toggleFoldersForTree,
    treeSearch,
    treeSearchToggle,
    treeSelectNode
} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {history} from "../../index";
import {createRedactorData} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../tools/HelpFunctions";


class TreeSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // TreeSearch : "", // текст для поиска
            clearHover : false,
        };

       this.SearchSubmit = this.SearchSubmit.bind(this);
       this.inputOnChange = this.inputOnChange.bind(this);
       this.startSearch = this.startSearch.bind(this);
       this.clearInput = this.clearInput.bind(this);
    }

    SearchSubmit(event) {
        event.preventDefault();
        this.startSearch(this.props.TreeSearch);
    }

    inputOnChange(event) {
        this.props.setTreeSearchQuery({SearchText: event.target.value})
        this.startSearch(event.target.value);
        // this.setState((state) => {
        //     return { TreeSearch: event.target.value };
        // });
    }

    startSearch(SearchText) {
        const {tree: {activeNode},} = this.props;
        if(SearchText.length <= 1) {
            if(this.props.tree.isSearch) {
                this.props.treeSearchToggle({State : false});
                this.props.toggleFoldersForTree({isCollapse: true});
                if(activeNode) {
                    let ids = [];
                    __.findParents(this.props.tree.render.children, activeNode.parent, ids);
                    ids.forEach(id =>  this.props.toggleFoldersForTree({IdFolder: id}));
                }

            }
        }
        else {
            this.props.treeSearch({
                SearchText,
                // tree_render: this.props.tree.render,
                Next : {
                    action : () => {
                        if(!this.props.tree.isSearch) {
                            this.props.treeSearchToggle({State : true});
                        }
                    },
                    params : {}
                }
            });
        }
    }

    // сбрасываем поле ввода
    clearInput() {
        this.startSearch("");
        this.props.setTreeSearchQuery({SearchText: ""})
    }

    collapseTree(action) {
        switch (action) {
            case "all": {
                this.props.toggleFoldersForTree({isCollapse: true});
                return
            }
            case "collapseSub": {
                return
            }
            case "ExpandSub": {
                return
            }
            default: return;
        }
    }

    render() {

        let searchIcon = "icon-Search ";
        if(this.state.clearHover) { // для отображения красного крестика
            searchIcon = "icon-Delete icon-color-red";
        }


        return(
           <div style={{marginBottom: "1rem"}}>
                <div className="d-flex">
                   <div className="d-flex align-items-center justify-content-between w-100 p-2 p-lg-2 my-1 my-lg-2"
                        style={{cursor : "pointer"}}>
                       <h3 className="text-color-primary font-weight-bolder mb-3" title="Главная"
                           onClick={()=>{

                               let isContinue = true;
                               if(this.props.redactorData) {
                                   isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
                               }

                               if(!isContinue) {
                                   return false;
                               }

                               this.props.changeDocumentViewMode({
                                   ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.None,
                                   Next : {
                                       action : ()=>{
                                           window.onbeforeunload = null;
                                           window.addEventListener("popstate", null);

                                           history.push("/documents");
                                           this.props.createRedactorData({CardId : null});
                                           //this.props.treeSelectNode({Node: null})
                                       },
                                       params : {}
                                   }
                               });
                           }}>Документы
                       </h3>

                       {/*<TreeControlButtons onCollapseAll={() => {this.collapseTree("all")}}
                                           onCollapseSubTree={() => {this.collapseTree("collapseSub")}}
                                           onExpandSubTree={() => {this.collapseTree("expandSub")}}
                       />*/}

                       {/*<span className="text-muted mt-3 font-weight-bold font-size-sm">Основная директория</span>*/}

                       {/*<span className="text-muted mt-3 font-weight-bold font-size-sm">Основная директория</span>*/}
                   </div>
                </div>
                <div className="input-icon h-40px">
                    <form onSubmit={this.SearchSubmit}>
                       <input type="text"
                              className="form-control form-control-lg form-control-solid h-40px"
                              placeholder="Фильтр"
                              value={this.props.TreeSearch}
                              onChange={this.inputOnChange}/>
                    </form>
                   <span>
                        <span className="svg-icon svg-icon-lg" style={{cursor : "pointer"}}
                            onMouseEnter={()=>{this.setState({clearHover : true})}}
                            onMouseLeave={()=>{this.setState({clearHover : false})}}
                            onClick={this.clearInput}>
                            <i className={"svg-icon svg-icon-lg " + searchIcon}/>
                        </span>
                    </span>
                </div>
           </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        tree : state.document.tree,
        redactorData : state.document.cards.redactorData,
        TreeSearch: state.document.treeSearchQuery
    }
}

const  mapDispatchToProps = {
    treeSearch,
    treeSearchToggle,
    changeDocumentViewMode,
    createRedactorData,

    treeSelectNode,
    toggleFoldersForTree,
    setTreeSearchQuery,
}


export default connect(mapStateToProps, mapDispatchToProps)(TreeSearch);
