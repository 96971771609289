import React from "react";
import {connect} from "react-redux";
import parse from "html-react-parser";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {history} from "../../../index";
import {searchByParams, updateInputsFieldSearch} from "../../../store/search/actionCreators/search_Inputs";
import {combineMainTableAttributesSearch} from "../../../store/search/actionCreators/search_table";
import {changeSearchViewMode} from "../../../store/search/actionCreators/search_RootStateActionCreator";
import sanitizeHtml from "sanitize-html";
import {__} from "../../../tools/HelpFunctions";

class AttributesSectionPackages extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isOpen : true
        };

        this.toggle = this.toggle.bind(this);
        this.search = this.search.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }

    search(value) {
        const idSearch = __.createUniqueIdString(6);
        value = `"${value.split('"').join('').split("'").join('')}"`

        this.props.updateInputsFieldSearch({
            AttributesText : value,
            TypeIds: [],
            Id: idSearch,
            Next : {
                action : () => {
                    this.props.searchByParams({
                        Id: idSearch,
                        Next:{
                            action : () => {
                                this.props.combineMainTableAttributesSearch({
                                    Next : {
                                        action : () => {
                                            this.props.changeSearchViewMode({
                                                ViewMode : RootComponentsStateViewModeTypes.SearchApp.MainTableOnly
                                            });
                                            history.replace("/search");
                                        },
                                        params : {}
                                    }
                                })
                            },
                            params : {}
                        }
                    })
                },
                params : {}
            }
        });
    }


    render() {
        if(!this.props.activeDocument) return null;
        let isReady = this.props.activeDocument.additionalSections.isReady;
        let sectionData = this.props.activeDocument.additionalSections.attributesSection.attributes.Records;

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }


        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Атрибуты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">


                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-4">Атрибут</th>
                                <th className="col-lg-7">Значение</th>
                                <th className="col-lg-1">Найти</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.map((item, index) => {
                                    let value = item.AttributeValue == "null" || item.AttributeValue == null ? "-" : item.AttributeValue;
                                    return (
                                        <tr key={item.Id}>
                                            <td>{item.AttributeName.Name}</td>
                                            <td>{parse(sanitizeHtml(value))}</td>
                                            <td>
                                            {
                                                (item.AttributeValue != "null" && item.AttributeValue != null) &&
                                                <span className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                    onClick={()=>{this.search(item.AttributeValue )}}>
                                                    <i className="svg-icon icon-Search"/>
                                                </span>
                                            }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument
    }
}

const  mapDispatchToProps = {
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    searchByParams,
    updateInputsFieldSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSectionPackages);
