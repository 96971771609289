import React from 'react';
import HorizontalScroll from "react-scroll-horizontal";
import {connect} from "react-redux";
import DocTabsItem from "./DocTabsItem";
import {deleteDocumentCard} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../../tools/HelpFunctions";

class DocTabs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            closeAllTabsTooltipShow: false,
            tabRefs: []
        };

        this.OnTabClick = this.OnTabClick.bind(this);
        this.closeAllTabs = this.closeAllTabs.bind(this)
        this.setRef = this.setRef.bind(this);
        this.setRefOnName = this.setRefOnName.bind(this);

        this.closeAllTabsRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {collection} = this.props.cards;
        const id = collection.find(item => item.Id === this.props.cards.activeCardId)?.Id
        if (id) this.setRefOnName(id, prevState);
    }

    setRef = (Id, lRef) => {
        //Чтобы не было зацикливания проверяем наличие рефа в стейте
        // console.log(this.state.tabRefs)
        if (this.state.tabRefs)
            if (this.state.tabRefs.find(lItem => lItem.lRef === lRef))
                return;


        const tabRefsCopy = __.deepCopy(this.state.tabRefs) ?? [];

        if (tabRefsCopy && Id) {
            const tabRefsCopyFound = tabRefsCopy.find(lItem => lItem.Id === Id);
            if (tabRefsCopyFound)
                tabRefsCopyFound.lRef = lRef
            else
                tabRefsCopy.push({Id: Id, lRef: lRef});

            this.setState({
                tabRefs: tabRefsCopy
            })
        }
    }

    setRefOnName = (Id, prevState) => {
        let container = document.querySelector(".scroll-horizontal").querySelector("div")
        container.style.setProperty("transform", "translate3d(0px, 0px, 0px)")

        if (this.state.tabRefs) {
            const found = this.state.tabRefs.find(lItem => lItem.Id == Id);

            if (found && !this.props.redactorMode) {
                found.lRef.current.focus()
            }
        }
    }

    async OnTabClick(tab) {
        if (this.state.tabRefs) {
            const found = this.state.tabRefs.find(lItem => lItem.lDirectory == tab);
            if (found) {
               found.lRef.current.focus();
            }
        }

        // this.props.ConfSelectTab({TableType: tab});
        //
        // const {Conf} = this.props;
        // if(Conf.TableFilter.IsOpen && tab !== Conf.ActiveConfName) await this.props.ConfSwitchOpenFilter({CloseAll: true});
    }

    async closeAllTabs() {
        this.props.deleteDocumentCard({})

    }

    render() {
        let cards = this.props.cards;

        let styleTabs = {
            display: "flex",
            flexWrap: "nowrap",
            overflowY: "hidden",
            overflowX: "auto",
            height: "50px",
        };


        let tabsCount = cards.isReady ? cards.collection.length : 0;
        let tabsPlaceholderWidth = "0px";
        if(tabsCount < 8) {
            tabsPlaceholderWidth = (800 - (100 * tabsCount)) + "px";
        }

        return (
            <div className="card-header-tabs-line">
                <div className="card-toolbar">
                    {
                        (cards.isReady && cards.collection.length > 0) &&
                        <ul className="nav nav-tabs nav-bold nav-tabs-line beautiful-scroll-5" style={styleTabs}>
                            <HorizontalScroll reverseScroll={true} pageLock={true} style={{maxWidth : "750px"} }>
                                {
                                    cards.collection.map((item, index) => {
                                            return(
                                                <DocTabsItem
                                                    lIndex={index}
                                                    setRef={(Item)=>{this.setRef(item?.Id, Item)}}
                                                    OnTabClick={this.OnTabClick}
                                                    key={item.Id}
                                                    data={item}
                                                    active={this.props.cards.activeCardId === item.Id}
                                                />
                                            );
                                    })
                                }
                                <div style={{width: tabsPlaceholderWidth}}/>
                            </HorizontalScroll>
                        </ul>
                    }
                </div>
            </div>
        );
    }

}

const  mapStateToProps = state => {
    return {
        cards : state.document.cards
    }
}

const  mapDispatchToProps = {
    deleteDocumentCard
}

export default connect(mapStateToProps, mapDispatchToProps)(DocTabs);
