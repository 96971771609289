import React, { Component, KeyboardEventHandler } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import {connect} from "react-redux";
import {saveValue} from "../../store/reports/actionCreators/report_Input";

const components = {
    DropdownIndicator: null,
};

interface Option {
     label: string;
     value: string;
}

const createOption = (label: string) => ({
    label,
    value: label,
});

interface State {
     inputValue: string;
     value:  Option[];
}

 class MySelect extends Component<{}, State> {
    state: State = {
        inputValue: '',
        value: [],
    };


    handleChange = (
        value: OnChangeValue<Option, true>,
        actionMeta: ActionMeta<Option>
    ) => {


        console.groupEnd();
        this.setState({ value },()=>{
            this.props.saveValue({
                nameField: this.props.nameField,
                value: this.state.value
            });
            this.props.dataList(false)
        });





    };
    handleInputChange = (inputValue: string) => {
        this.setState({ inputValue });
    };
    handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        const { inputValue, value } = JSON.parse(JSON.stringify(this.state));
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':


                // value.push(createOption(inputValue));
                // let newValue = value.reduce((result, item) => {
                //     return !result.includes(item) ? result : false;
                // });

                value.push(createOption(inputValue));
                let newValue = [];
                for (let i = 0; i < value.length; i++) {
                    newValue.push(JSON.stringify(value[i]));
                }
                newValue = Array.from(new Set(newValue));
                for (let i = 0; i < newValue.length; i++) {
                    newValue[i] = JSON.parse(newValue[i]);
                }

                this.setState({
                    inputValue: '',
                    value: newValue,
                }, ()=> {

                        this.props.saveValue({
                            nameField: this.props.nameField,
                            value: this.state.value
                        });


                });

                event.preventDefault();
        }
    };
    test=()=>{
        if(this.props.type==5){
            this.props.dataList(true)
        }

    }

    render() {
        const { inputValue } = this.state;

        // let newValue = [];
        // for (let i = 0; i < value.length; i++) {
        //     newValue.push({
        //         label : value[i],
        //         value : value[i],
        //     })
        // }


        return (
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder={this.props.nameField}
                value={this.props.value[this.props.index].value}
                onFocus={this.test}
            />
        );
    }
}
const  mapStateToProps = state => {
    return {
        value : state.report.leftMenu.params
    }
}

const  mapDispatchToProps = {
    saveValue
}
export default connect(mapStateToProps,mapDispatchToProps)(MySelect);
