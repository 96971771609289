import React from "react";
import {connect} from "react-redux";
import AuthApp from "./components/root/AuthApp";
import {AUTH_isAuth} from "./store/globalState/actionCreators/globalState_AuthActionCreator";
import {setModalData} from "./store/globalState/actionCreators/globalState_AppActionCreator";
import HomeApp from "./components/root/HomeApp";
import {ActionQueue} from "./store/rootReducer";
import {
    getDepartments,
    getGlobalClientSettings,
    getUserSettings
} from "./store/globalState/actionCreators/globalState_SettingsActionCreator";
import {getCorrectUser, getFilial, getFilialGroups, renderFilialTree} from "./store/globalState/actionCreators/globalState_UserActionCreator";
import DocumentApp from "./components/root/DocumentApp";
import Preloader from "./components/overPage/other/Preloader";
import Modal from "./components/overPage/modal/Modal";
import ContextMenu from "./components/overPage/contextMenu/ContextMenu";
import PackageApp from "./components/root/PackageApp";
import SearchApp from "./components/root/SearchApp";
import ReportsApp from "./components/root/ReportsApp";
import UserModal from "./components/overPage/modal/UserModal";
import MasterApp from "./components/root/MasterApp";
import HelpApp from "./components/root/HelpApp";
import {history} from "./index";
import {getDocumentsForTree} from "./store/documents/actionCreators/document_TreeActionCreator";
import detect from "detect.js";
import {AdminLevelEnum, ModalTypes} from "./tools/StaticTypes";
import InfoModal from "./components/overPage/modal/InfoModal";
import {LoaderModal} from "./components/overPage/modal/LoaderModal";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredRequests : false, // ожидание загрузки необходимых минимальных данных для запуска интерфейса
            checkAuth : false, // чтобы не показывалось окно авторизации без необходимости
            loadingAuth : false,

            isAuthError : false,
        };

        window.onbeforeunload = null;
        window.addEventListener("popstate", null);

        this.loadRequiredData = this.loadRequiredData.bind(this);
        this.overPage = this.overPage.bind(this);
        this.changeLoadingAuth = this.changeLoadingAuth.bind(this);
    }

    changeLoadingAuth(isLoading, isError = false) {
        this.setState({isAuthError: isError});
        this.setState({loadingAuth: isLoading});
    }

    loadRequiredData() {
       const filialID= this.props.filial?.Id;
        this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
            List : [
                {action : this.props.getCorrectUser, params : {}, name : "getCorrectUser"},
                {action : this.props.getFilial, params : {}, name : "getFilial"},
                {action : this.props.getFilialGroups, params : {}, name : "getFilialGroups"},
                {action : this.props.renderFilialTree, params : {}, name : "renderFilialTree"},
                {action : this.props.getGlobalClientSettings, params : {ArmType : 2}, name : "getGlobalClientSettings"},
                {action : this.props.getUserSettings, params : {}, name : "getUserSettings"},
                {action : this.props.getDocumentsForTree, params : {filialID}, name : "getDocumentsForTree"},
                {action : ()=>{
                        let {globalSettings} = this.props;

                        // TODO tp убран уровень прав для корректной работы секции подразделения
                        //  в части ограничений для документа
                        // TODO SS вернул проверку на уровень пользователя, пока не исправят получение
                        //  подразделений для обычного пользователя
                            const lFlagWithDepartments = globalSettings?.WithDepartments === "1"
                                && this.props.adminLevel > AdminLevelEnum.CommonUser

                        if (lFlagWithDepartments) {
                            this.props.getDepartments({})
                        }

                    this.setState({requiredRequests : true})
                    }, params : {}, name : "requiredRequests-set->true"},
            ],
            debug : false,
        });

    }

    componentDidMount() {
        this.props.AUTH_isAuth({ // проверка авторизации
            Next : { // пользователь авторизован
                action : () => {
                  this.loadRequiredData();
                  this.setState({
                      checkAuth : true // проверка авторизации осуществлена
                  });

                },
                params : {}
            },
            Error : { // пользователь не авторизован или проблемы с сервером или сетью
                action : () => {
                    this.setState({
                        checkAuth : true // проверка авторизации осуществлена
                    });
                },
                params : {}
            }
        });
        let ua = navigator.userAgent;
        let isShowModalVersionError = false
        if (ua.search(/YaBrowser/) > 0) {
            if (+ua.slice(ua.search(/YaBrowser/) + 10).split(' ')[0].split('.')[0] <= 22) {
                isShowModalVersionError = true

            }
        } else if (ua.search(/Edg/) > 0) {
            if (+ua.slice(ua.search(/Edg/) + 4).split(' ')[0].split('.')[0] <= 92) {
                isShowModalVersionError = true
            }
        } else {
            let browser = detect.parse(navigator.userAgent)
            if (browser.browser.family === 'Chrome' && browser.browser.major <= 92) {
                isShowModalVersionError = true

            } else if (browser.browser.family === 'Opera' && browser.browser.major <= 78) {
                isShowModalVersionError = true

            } else if (browser.browser.family === 'Firefox' && browser.browser.major <= 91) {
                isShowModalVersionError = true

            } else if (browser.browser.family === 'Safari' && browser.browser.major <= 14) {
                 isShowModalVersionError = true
            } else if (browser.browser.family === 'IE') {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "criticalFail", content : `Браузер не поддерживается` }
                })
            }
        }
        isShowModalVersionError &&  this.props.setModalData({
            name: ModalTypes.app.info,
            data: {type: "fail", content : `Ваша версия браузра устарела. <br/> Система может работать некорректно.` }
        })


    }


    // добавляет к основному компоненту
    // компоненты общего назначения
    overPage(rootComponent) {
        return (
            <>
                {/*TODO разделение прелоадеров, т.к. сейчас нет возможности запустить прелоадер в открытой модалке*/}
                <LoaderModal loaderModal = {this.props.loaderModal}/>
                <Modal/>
                <ContextMenu/>
                {rootComponent}
                <UserModal/>
            </>
        );
    }

    render() {
        let visible = true;
        if(this.props.types?.length==0) visible =false;
        if(this.state.loadingAuth) {
            return <Preloader/>;
        }
        let browser = detect.parse(navigator.userAgent)

        if (browser.browser.family === 'IE') {
            return <InfoModal/>
        }



        if(!this.props.isAuth) {
            if(!this.state.checkAuth) {
                return <Preloader/>;
            } else return  <AuthApp isAuthError={this.state.isAuthError}
                                    changeLoadingAuth={this.changeLoadingAuth}
                                    success={this.loadRequiredData}/>;
        }
        else {
            if(this.state.requiredRequests) { // ждем пока пройдут необходимые для отображения запросы
                const hashPathName = this.props.router.location.hash ? this.props.router.location.hash.split("#")[1].split(/;|~/)[0] : null;
                if(!visible){
                    return this.overPage(<HelpApp/>);
                }
                if((this.props.router.location.pathname === "/" && !hashPathName) || hashPathName === "w") {
                    return this.overPage(<HomeApp/>);
                }
                else if(this.props.router.location.pathname === "/documents" || hashPathName === "d") {
                    return this.overPage(<DocumentApp/>);
                }
                else if(this.props.router.location.pathname === "/packages" || hashPathName === "p") {
                    return this.overPage(<PackageApp/>);
                }
                else if(this.props.router.location.pathname === "/search" || hashPathName === "s") {
                    return this.overPage(<SearchApp router={this.props.router}/>);
                }
                else if(this.props.router.location.pathname === "/report") {
                    return this.overPage(<ReportsApp/>);
                }
                else if(this.props.router.location.pathname === "/master") {
                    if(this.props?.adminLevel >= 1) {
                        return this.overPage(<MasterApp/>);
                    }
                    else {
                        history.replace("/help");
                        return this.overPage(<HelpApp/>);
                    }
                }
                else   if(this.props.router.location.pathname === "/help" || hashPathName === "h") {
                    return this.overPage(<HelpApp/>);
                }
                else {
                    return this.overPage(<HomeApp/>);
                }
            } else return <Preloader/>;
        }
    }
}


const  mapStateToProps = state => {
    return {
        isAuth : state.globalState.auth.isAuth, // прошла ли аутентификация
        router : state.router, // маршрутизатор
        adminLevel : state.globalState.user.AdminLevel,
        filial: state.globalState.filial.Active,
        types: state.document.tree.docs,
        loaderModal : state.globalState.app.loaderModal,
        globalSettings: state.globalState.settings.Content,
    }
}

const  mapDispatchToProps = {
    AUTH_isAuth, // проверка авторизации
    getDocumentsForTree,
    ActionQueue, // очередь для redux запросов
    getGlobalClientSettings, // получение глобальных настроек пользователя
    getUserSettings, // получение собственных настроек текущего пользователя
    getFilial, // получение доступных филиалов
    getCorrectUser, // получение информации о текущем пользователе
    getFilialGroups,
    renderFilialTree,
    setModalData,
    getDepartments
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
