import React from "react";
import {connect} from "react-redux";
import Iframe from 'react-iframe'
import {Dropdown} from "react-bootstrap";
import {__, HelpFunctions} from "../../tools/HelpFunctions";
import {
    changeVersionDocumentCards,
    checkInputDataBeforeUpdate,
    collectDataForAdditionalSections_Normal,
    createDocumentCards,
    createPreviewBySelectDocument,
    createRedactorData,
    resetDocumentPreview,
    selectActiveDocumentCard,
    selectActiveFileForPreview,
    selectActiveFileForPreview_Card,
    selectViewActiveFileForPreview,
    setFilesContent,
    setSizePreview
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {
    AdminLevelEnum,
    ModalTypes, RootComponentsStateViewModeTypes as rootStateType,
    RootComponentsStateViewModeTypes,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../tools/StaticTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {Link} from "react-router-dom";
import {history, store} from "../../index";
import {updateUserSettings} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {changeViewMode} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import FilesListDropdown from "./FilesListDropdown";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, selectActiveRow
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {treeSelectNode} from "../../store/documents/actionCreators/document_TreeActionCreator";

class DocPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backToTableTooltipShow : false,
            addNewCardTooltipShow : false,
            selectFileTooltipShow : false,
            halfScreenTooltipShow : false,
            fullScreenTooltipShow : false,
            openInNewTabTooltipShow : false,
            infoTooltipShow: false,
            showPreviewShow: false,
            hidePreviewShow: false,
            redactorModeTooltipShow : false,
            uploadFileTooltipShow : false,
            uploadFileAttachmentTooltipShow : false,
            deleteDocumentTooltipShow : false,
            rotateIcon : false,
        };

        this.backToTableRef = React.createRef();
        this.showPreviewRef = React.createRef();
        this.addNewCardRef = React.createRef();
        this.hidePreviewRef = React.createRef();
        this.selectFileRef = React.createRef();
        this.halfScreenRef = React.createRef();
        this.fullScreenRef = React.createRef();
        this.openInNewTabRef = React.createRef();
        this.redactorModeRef = React.createRef();
        this.uploadFileRef = React.createRef();
        this.uploadFileAttachmentRef = React.createRef();
        this.deleteDocumentRef = React.createRef();
        this.infoRef = React.createRef();

        this.selectActivePreviewFile = this.selectActivePreviewFile.bind(this);
        this.toggleSizePreview = this.toggleSizePreview.bind(this);
        this.backToTable = this.backToTable.bind(this);
        this.addNewCard = this.addNewCard.bind(this);
        this.toggleRedactorMode = this.toggleRedactorMode.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadFileAttachment = this.uploadFileAttachment.bind(this);
        this.saveRedactorChanges = this.saveRedactorChanges.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);

        this.redactorDataOn = this.redactorDataOn.bind(this);
        this.redactorDataOff = this.redactorDataOff.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
        this.onMoveBackOrFrontHistory = this.onMoveBackOrFrontHistory.bind(this);
        this.selectViewActiveFile = this.selectViewActiveFile.bind(this);

    }

    componentDidMount(){
        let size = this.props.preview.fixingPreview;
        const isViewcard = this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards;
        if(size === "normal"){
            this.props.changeDocumentViewMode({
                ViewMode: isViewcard ? this.props.rootState.viewMode : RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                Next : {
                    action: () => {
                        this.props.setSizePreview({
                            fixingPreview:"normal"
                        })
                    },
                    params : {}
                }
            });
        }
        else if (size === "fullScreen"){
            if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal ||
                this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen) {
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewFullScreen,
                    Next : {
                        action: () => {
                            this.props.setSizePreview({
                                fixingPreview:size
                            })
                        },
                        params : {}
                    }
                });
            } else {
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                    Next : {
                        action: () => {
                            this.props.setSizePreview({
                                fixingPreview:"normal"
                            })
                        },
                        params : {}
                    }
                });
            }
        }
        else if (size === "halfScreen") {
            if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal ||
                this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewFullScreen) {
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen,
                    Next : {
                        action: () => {
                            this.props.setSizePreview({
                                fixingPreview:"halfScreen"
                            })
                        },
                        params : {}
                    }
                });
            } else if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen) {
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                    Next : {
                        action: () => {
                            this.props.setSizePreview({
                                fixingPreview:"normal"
                            })
                        },
                        params : {}
                    }
                });
            } else {
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                    Next : {
                        action: () => {
                            this.props.setSizePreview({
                                fixingPreview:"normal"
                            })
                        },
                        params : {}
                    }
                });

            }
        }
        else if (size === "hide") {
            this.props.changeDocumentViewMode({
                ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_Hide,
                Next : {
                    action: () => {
                        this.props.setSizePreview({
                            fixingPreview:size
                        })
                    },
                    params : {}
                }
            });
        }
    }



    onbeforeunload(event) {
        if(this.props.redactorDataIsUpdated) {
            event.preventDefault();

            return event.returnValue = "Are you sure you want to exit?";
        }
    }

    onMoveBackOrFrontHistory(event) {
        if(this.props.redactorDataIsUpdated) {
            alert("Возможно имеются несохраненные данные. Они будут утеряны.");
            this.redactorDataOff();
            return event.preventDefault();
        }
    }

    redactorDataOn() {
        window.onbeforeunload = this.onbeforeunload;
        window.addEventListener("popstate", this.onMoveBackOrFrontHistory);
    }

    redactorDataOff() {
        window.onbeforeunload = null;
        window.removeEventListener("popstate", this.onMoveBackOrFrontHistory);
    }

    deleteDocument(){
        this.props.setModalData({
            name: ModalTypes.documents.mainTable.DeleteDocument,
            data: {
                type : "deleteDocument",
                IdDoc : this.props.activeRow.Info.Id,
                Name : `${this.props.activeRow.Info.Name} ${this.props.activeRow.Info.ShortDescription ?? ""}`,
                isMark : this.props.activeRow.Info.IsMarkedForDelete
            }
        });
    }

    // меняем активный файл предпросмотра
    async selectActivePreviewFile(item, index) {
        const isViewcard = this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards;
        // const isViewRedactorMode = this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode;
        this.props.setFilesContent({
            document: item.isAttach ? item : this.props.activeRow.Info,
            isCard: isViewcard,
            isRedactorMode: false
        });
        if (this.props.viewMode === "normal") {
            this.props.selectActiveFileForPreview({
                Type: item.type,
                Index: index
            });
        } else if (this.props.viewMode === "card") {
            this.props.selectActiveFileForPreview_Card({
                CardId: this.props.cards.activeCardId,
                ActiveFileIndex: index,
                Type: item.type,
            });
        }
    }

    // переключение между обычным режимом и режимом редактирования
    toggleRedactorMode() {
        if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_HalfScreen ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_FullScreen) {

            this.redactorDataOff();
            this.props.changeDocumentViewMode({
                ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards,
                Next: {
                    action: () => {
                        this.props.createRedactorData({
                            CardId: null
                        });
                    },
                    params: {}
                }
            });
        } else {
            this.redactorDataOn();
            // меняем версию на последнюю
            // после копируем данные для редактирования
            // и меняем режим отображения на редактирование
            this.props.changeVersionDocumentCards({
                VersionIndex: 0,
                CardId: this.props.cards.activeCardId,

                Next: {
                    action: () => {
                        this.props.createRedactorData({
                            CardId: this.props.cards.activeCardId,
                            Next: {
                                action: () => {
                                    this.props.changeDocumentViewMode({
                                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode
                                    });
                                },
                                params: {}

                            }
                        });
                    },
                    params: {}
                }
            });

        }
    }


    // отображение модального окна для проверки введенных данных
    saveRedactorChanges(card) {
        this.props.checkInputDataBeforeUpdate({
            isSaveChangeAttributes: true,
            Next: {
                action: () => {
                    if (store.getState().document.cards.redactorDataCollected.isUpdatedInAll === true) {
                        this.props.setModalData({
                            name: ModalTypes.documents.additionalSection.ChangeDocAttributes,
                            data: {
                                redactorDataOff: this.redactorDataOff,
                            }
                        });
                    } else if (store.getState().document.cards.redactorDataCollected.isUpdatedInAll === 'error') {
                        store.dispatch(setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: `Изменения не сохранены <br/> Не заполнен обязательный атрибут` }
                        }));
                    } else {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: 'Нет данных для сохранения.'}
                        });
                    }
                },
                params: {}
            }
        });
    }

    // изменяем размер предпросмотра
    // TODO: доделать для режима редактирования
    toggleSizePreview(size) {
        this.setState({rotateIcon: !this.state.rotateIcon})
        if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_HalfScreen ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_FullScreen) {
            if (size === "fullScreen") {
                if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_FullScreen) {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                    });
                } else {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_FullScreen
                    });
                }
            } else if (size === "halfScreen") {
                if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards ||
                    this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_FullScreen) {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_HalfScreen
                    });
                } else {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                    });
                }
            }
        } else if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_HalfScreen ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_FullScreen) {
            if (size === "fullScreen") {
                if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_FullScreen) {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode
                    });
                } else {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_FullScreen
                    });
                }
            } else if (size === "halfScreen") {
                if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode ||
                    this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_FullScreen) {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_HalfScreen
                    });
                } else {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode
                    });
                }
            }
        } else {
            if (size === "fullScreen") {
                if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal ||
                    this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen) {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewFullScreen,
                        Next : {
                            action: () => {
                                this.props.setSizePreview({
                                    fixingPreview:size
                                })
                            },
                            params : {}
                        }
                    });
                } else {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                        Next : {
                            action: () => {
                                this.props.setSizePreview({
                                    fixingPreview:"normal"
                                })
                            },
                            params : {}
                        }
                    });
                }
            } else if (size === "halfScreen") {
                if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal ||
                    this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewFullScreen) {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen,
                        Next : {
                            action: () => {
                                this.props.setSizePreview({
                                    fixingPreview:"halfScreen"
                                })
                            },
                            params : {}
                        }
                    });
                } else if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen) {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                        Next : {
                            action: () => {
                                this.props.setSizePreview({
                                    fixingPreview:"normal"
                                })
                            },
                            params : {}
                        }
                    });
                } else {
                    this.props.changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                        Next : {
                            action: () => {
                                this.props.setSizePreview({
                                    fixingPreview:"normal"
                                })
                            },
                            params : {}
                        }
                    });

                }
            } else if (size === "hide") {
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_Hide,
                    Next : {
                        action: () => {
                            this.props.setSizePreview({
                                fixingPreview:size
                            })
                        },
                        params : {}
                    }
                });
            }
        }
    }

    // возвращаемся в реестр документов (к таблице)
    async backToTable() {
        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {
        //         content: "Загрузка...",
        //         disableButton: true,
        //         fullBackground: false,
        //         gif: "documents"
        //     }
        // });

        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "DocPreview489"}
        }));

        let card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);

        // todo tp №22896
        await this.props.changeDocumentViewMode({ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal});
        if (this.props.activeRow === null || this.props.activeRow.Info.Id !== card.IdDoc) {
            const group_tree = __.findGroup(this.props.tree_docs, card.IdDocGroup, false, true);
            const type_tree = group_tree.children.find(type => type.info.Id === card.TypeId);
            await this.props.treeSelectNode({Node: type_tree});
            await this.props.mainTableDownloadDocCollection({
                id: card.IdDoc,
                ActiveNode: type_tree
            });
            await this.props.combineMainTableAttributes({ActiveNode: type_tree});
            let activeRow = store.getState().document.mainTable.body.find(tr => tr.Version.IdDoc === card.IdDoc);
            await this.props.selectActiveRow({RowInfo: activeRow});
            await this.props.resetDocumentPreview({});
            await this.props.createPreviewBySelectDocument({});
        }

        if(this.props.activeRow) {
            await this.props.collectDataForAdditionalSections_Normal({});
        }

        await this.props.selectActiveDocumentCard({});
        if (!this.props.rootState.isOpenAsideMenu) {
            this.props.setStateLeftAsideMenu({
                isClose: false,
                rootName: "DocumentApp"
            });
        }

        let link = this.props.router.location.pathname + this.props.router.location.search.split("&c")[0];
        history.push(link);

        // this.props.setModalData({});
        //Новый прелоадер
        this.props.setLoaderModalData({keyDeleted: "DocPreview501"});
    }

    async addNewCard() {
        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {content: "Загрузка...", disableButton: true, fullBackground: false, gif: "documents"}
        // });
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "DocPreview528"}
        }));

        this.props.createDocumentCards({
            IdDoc: this.props.activeRow.Version.IdDoc,
            IdVersion: this.props.activeRow.Version.Id,
            Next: {
                action: () => {
                    this.props.selectActiveDocumentCard({
                        CardId: this.props.cards.collection.find(card => card.IdDoc == this.props.activeRow.Version.IdDoc).Id,
                        Next: {
                            action: () => {
                                this.props.changeDocumentViewMode({
                                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards,
                                    Next: {
                                        action: () => {
                                            // this.props.setModalData({});
                                            //Новый прелоадер
                                            this.props.setLoaderModalData({keyDeleted: "DocPreview546"});
                                        },
                                        params: {}
                                    }
                                })
                            },
                            params: {}
                        }
                    });
                    // if(this.props.rootState.isOpenAsideMenu) {
                        this.props.setStateLeftAsideMenu({
                            isClose: true,
                            rootName: "DocumentApp"
                        });
                    // }
                    this.props.updateUserSettings({
                        Name: UserSettingsNames.DOCUMENT_CONFIG,
                        Action: UserSettingsActionsTypes.Update,
                        Data: {
                            IdDoc: this.props.activeRow.Version.IdDoc,
                            IdVersion: this.props.activeRow.Version.Id,
                            Name: `${this.props.activeRow.Info.Name} ${this.props.activeRow.Info.ShortDescription ?? ""}`
                        }
                    });
                    this.props.changeViewMode({
                        isSearch: false
                    });
                },
                params: {}
            }
        });
    }

    // прикрепление основного файла
    uploadFile() {
        // this.props.setModalData({
        //     name : ModalTypes.documents.additionalSection.DownloadDoc,
        //     data : {
        //         type : "main"
        //     }
        // });
    }

    // прикрепление дополнительных файлов
    uploadFileAttachment() {
        this.props.setModalData({
            name: ModalTypes.documents.additionalSection.DownloadDoc,
            data: {
                type: "attachment"
            }
        });


    }

    selectViewActiveFile(type) {
        const isViewcard = this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode;
        // const isViewRedactorMode = this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode;
        this.props.selectViewActiveFileForPreview({View: type, isCard: isViewcard, isRedactorMode: false});
    }

    render() {
        let isRedactorMode = false;
        if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_HalfScreen ||
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_FullScreen) {
            isRedactorMode = true;
        }

        const deleteAccess = this.props.activeNode.info.DeleteAccess
        const modifyAccess = this.props.activeNode.info.ModifyAccess

        let preview = this.props.preview;
        let documentName = "";

        // обычный режим отображения
        if (this.props.viewMode === "normal") {
            preview = this.props.preview;
            documentName = this.props.activeRow ? `${this.props.activeRow.Info.Name} ${this.props.activeRow.Info.ShortDescription ?? ""}` : "";
        }
        // режим если отображается карточка документа
        else if (this.props.viewMode === "card") {
            let card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            if (!card)
                return (<></>);
            preview = card.preview;
            documentName = `${card.name} ${card.ShortDescription ?? ""}`;
        }


        let body = <div className="card-body"/>;
        let activeLink = "";
        if (preview.isReady) {
            // let card = preview.typeFile==undefined
            if (preview.activeView === "preview") {
                activeLink = preview.files[preview.activeFileIndex].previewLink;
                // console.log('activeLink', activeLink)
                //
                // if(preview.activeView === "preview") {
                //     link = preview.files[preview.activeFileIndex].previewLink;
                // } else link = preview.files[preview.activeFileIndex].link;
                //if (preview.files[preview.activeFileIndex].type === "image") {
                    body = (
                        <div className="card-body beautiful-scroll-10" style={{}}>
                            <img src={activeLink} alt="" style={{
                                boxShadow: "0 0 15px -5px grey",
                                width: "100%"}}/>
                        </div>
                    );
                //}
                // else {
                //     body = (
                //         <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
                //             <Iframe url={preview.files[preview.activeFileIndex].previewLink}
                //                     width="100%"
                //                     height="100%"
                //                     id="test"
                //                     display="initial"
                //                     position="relative"
                //             />
                //         </div>
                //     );
                // }
            }
            else {
                // let activeFileIndex = preview.files.findIndex(file => file.type === 'pdf')
                activeLink = preview.files[preview.activeFileIndex].originalLink;
                body = (
                    <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
                            <Iframe url={activeLink}
                                    width="100%"
                                    height="100%"
                                    id="test"
                                    display="initial"
                                    position="relative"
                            />
                    </div>
                );
            }
        }

        let backToTableColor = this.state.backToTableTooltipShow
            ? "svg-icon icon-Delete icon-color-red"
            : "svg-icon icon-Delete icon-color-primary";


        let link = `${this.props.router.location.pathname}${this.props.router.location.search.split("&c")[0]}`;
        if (this.props.activeRow && this.props.activeRow.Version) {
            link += `&c=${this.props.activeRow.Version.IdDoc}`;
        }

        let buttonActiveStyleHalf = {};
        let buttonActiveStyleFull = {};
        let arrowButtonActiveStyleHalf = {};
        let arrowButtonActiveStyleFull = {};

        if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_HalfScreen
            || this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewHalfScreen
            || this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_HalfScreen) {
            buttonActiveStyleHalf = {

                backgroundColor: "var(--originaltwo)",
                borderColor: "var(--originaltwo)"
            };
            arrowButtonActiveStyleHalf = {
                color: "white"
            }
        }
        if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_PreviewFullScreen
            || this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_RedactorMode_FullScreen
            || this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards_FullScreen) {
            buttonActiveStyleFull = {
                backgroundColor: "var(--originaltwo)",
                borderColor: "var(--originaltwo)"
            };
            arrowButtonActiveStyleFull = {
                color: "white"
            }
        }

        let deleteDocumentIcon = "icon-Doc_view_9 icon-color-red";
        if (this.props?.activeRow?.Info.IsMarkedForDelete) {
            deleteDocumentIcon = "icon-Doc_view_7 icon-color-primary";
        }

        const isPreview = [rootStateType.DocumentsApp.PreviewNormal,
            rootStateType.DocumentsApp.PreviewNormal_PreviewHalfScreen,
            rootStateType.DocumentsApp.PreviewNormal_PreviewFullScreen].includes(this.props.rootState.viewMode)

        const isAttachFiles = preview.files.filter(file => file.isAttach).length > 0;
        const {adminLevel} = this.props;

        return (
            this.props.rootState.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal_Hide ?
                <>
                    <div className="card-body preview-hide col align-items-center">
                        <div className="row" style={{ justifyContent: 'center' }}>
                                {
                                    this.state.showPreviewShow &&
                                    <MyTooltip target={this.showPreviewRef}
                                               text={"Раскрыть предпросмотр документа"}
                                               show={this.state.showPreviewShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <button type="button" className="btn btn-outline p-0" onClick={() => {this.toggleSizePreview("halfScreen")}}
                                        ref={this.showPreviewRef}
                                        onMouseEnter={() => { this.setState({showPreviewShow: true}) }}
                                        onMouseLeave={() => { this.setState({showPreviewShow: false}) }}>
                                <span className="btn btn-icon btn-sm">
                                    <i style={{fontSize: '2rem'}} className="svg-icon icon-window_out_right icon-color-primary text-success" />
                                </span>
                                </button>
                                <div className="separator separator-solid mb-2 mt-2" style={{minWidth: '3vw'}} />
                            {
                                this.props.viewMode === "normal" &&
                                <div className="d-flex align-items-center justify-content-center flex-wrap">
                                    {/*{*/}
                                    {/*    this.state.infoTooltipShow &&*/}
                                    {/*    <MyTooltip target={this.infoRef}*/}
                                    {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                                    {/*               show={this.state.infoTooltipShow}*/}
                                    {/*               placement={"left"} delay={500}*/}
                                    {/*    />*/}
                                    {/*}*/}
                                    {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                                    {/*      ref={this.infoRef}*/}
                                    {/*      onMouseEnter={()=>{this.setState({infoTooltipShow : true})}}*/}
                                    {/*      onMouseLeave={()=>{this.setState({infoTooltipShow : false})}}>*/}
                                    {/*     <i className="svg-icon icon-Info"/>*/}
                                    {/* </span>*/}
                                    {
                                        this.state.deleteDocumentTooltipShow &&
                                        <MyTooltip target={this.deleteDocumentRef}
                                                   text={"Пометить на удаление/Снять пометку"}
                                                   show={this.state.deleteDocumentTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    {
                                        deleteAccess && this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                        <span className="btn btn-icon btn-sm" ref={this.deleteDocumentRef}
                                              onClick={() => {
                                                  this.deleteDocument()
                                              }}
                                              onMouseEnter={() => {
                                                  this.setState({deleteDocumentTooltipShow: true})
                                              }}
                                              onMouseLeave={() => {
                                                  this.setState({deleteDocumentTooltipShow: false})
                                              }}>
                                        <i className={`svg-icon ${deleteDocumentIcon}`}/>
                                    </span>
                                    }
                                    {
                                        this.state.addNewCardTooltipShow &&
                                        <MyTooltip target={this.addNewCardRef}
                                                   text={"Карточка документа"}
                                                   show={this.state.addNewCardTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <Link to={link} className="btn btn-icon btn-sm" ref={this.addNewCardRef}
                                          onClick={this.addNewCard}
                                          onMouseEnter={() => {
                                              this.setState({addNewCardTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({addNewCardTooltipShow: false})
                                          }}>
                                        <i className="svg-icon icon-Table_edit_3 icon-color-primary"/>
                                    </Link>
                                </div>
                            }
                            <div className="separator separator-solid mb-2 mt-2" style={{minWidth: '3vw'}} />
                            {
                                this.state.openInNewTabTooltipShow &&
                                <MyTooltip target={this.openInNewTabRef}
                                           text={"Открыть в новой вкладке"}
                                           show={this.state.openInNewTabTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <a href={this.props.preview.isReady ? activeLink : "#"}
                               ref={this.openInNewTabRef}
                               onMouseEnter={() => {
                                   this.setState({openInNewTabTooltipShow: true})
                               }}
                               onMouseLeave={() => {
                                   this.setState({openInNewTabTooltipShow: false})
                               }}
                               target="_blank"
                               rel="noreferrer"
                               className="btn btn-icon btn-sm">
                                <i style={{fontSize: '2rem'}} className="svg-icon icon-Doc_view_5 icon-color-primary"/>
                            </a>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="card-header beautiful-scroll-5">
                        <div className="row flex-lg-nowrap">
                        {
                            this.state.hidePreviewShow &&
                            <MyTooltip target={this.hidePreviewRef}
                                       text={"Скрыть предпросмотр документа"}
                                       show={this.state.hidePreviewShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        {isPreview &&
                            <button type="button" className="btn btn-outline p-0" onClick={() => {this.toggleSizePreview("hide")}}
                                    ref={this.hidePreviewRef}
                                    onMouseEnter={() => { this.setState({hidePreviewShow: true}) }}
                                    onMouseLeave={() => { this.setState({hidePreviewShow: false}) }}>
                                <span className="btn btn-icon btn-sm">
                                    <i style={{fontSize: '2rem'}} className="svg-icon icon-window_out_left icon-color-primary text-success" />
                                </span>
                            </button>
                        }
                        <div className="card-title">
                            {
                                (isAttachFiles && !isRedactorMode) ?
                                    <FilesListDropdown docName={documentName}
                                                       preview={preview}
                                                       selectActivePreviewFile={this.selectActivePreviewFile}
                                    />
                                    :
                                    <h4 className="card-label text-weight-bolder text-color-primary"
                                        style={{whiteSpace: "nowrap"}}
                                        title={documentName}>
                                        {HelpFunctions.cutLongString(documentName, 15, true)}
                                    </h4>
                            }
                        </div>
                        {
                            this.props.viewMode === "card" &&
                            <div className="d-flex align-items-center">
                                {/*{*/}
                                {/*    this.state.infoTooltipShow &&*/}
                                {/*    <MyTooltip target={this.infoRef}*/}
                                {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                                {/*               show={this.state.infoTooltipShow}*/}
                                {/*               placement={"right"} delay={500}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                                {/*      ref={this.infoRef}*/}
                                {/*      onMouseEnter={() => {*/}
                                {/*          this.setState({infoTooltipShow: true})*/}
                                {/*      }}*/}
                                {/*      onMouseLeave={() => {*/}
                                {/*          this.setState({infoTooltipShow: false})*/}
                                {/*      }}>*/}
                                {/*         <i className="svg-icon icon-Info"/>*/}
                                {/*     </span>*/}
                                {
                                    (this.state.redactorModeTooltipShow && !this.props.redactorMode) &&
                                    <MyTooltip target={this.redactorModeRef}
                                               text={"Режим редактирования"}
                                               show={this.state.redactorModeTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    (!this.props.redactorMode && modifyAccess && adminLevel > AdminLevelEnum.CommonUser) &&
                                    <div onClick={this.toggleRedactorMode} ref={this.redactorModeRef}
                                         onMouseEnter={() => {
                                             this.setState({redactorModeTooltipShow: true})
                                         }}
                                         onMouseLeave={() => {
                                             this.setState({redactorModeTooltipShow: false})
                                         }}>
                                    <span className="btn btn-icon btn-sm">
                                        <i className="svg-icon icon-Table_edit_1 icon-color-primary"/>
                                    </span>
                                    </div>
                                }

                                {
                                    (this.state.redactorModeTooltipShow && this.props.redactorMode) &&
                                    <MyTooltip target={this.redactorModeRef}
                                               text={"Сохранить изменения"}
                                               show={this.state.redactorModeTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    this.props.redactorMode &&
                                    <div onClick={this.saveRedactorChanges} ref={this.redactorModeRef}
                                         onMouseEnter={() => {
                                             this.setState({redactorModeTooltipShow: true})
                                         }}
                                         onMouseLeave={() => {
                                             this.setState({redactorModeTooltipShow: false})
                                         }}>
                                    <span className="btn btn-icon btn-sm">
                                        <i className="svg-icon icon-Okey icon-color-primary"/>
                                    </span>
                                    </div>
                                }


                                {
                                    // this.state.uploadFileTooltipShow &&
                                    // <MyTooltip target={this.uploadFileRef}
                                    //            text={"Загрузить новый файл предпросмотра (pdf)"}
                                    //            show={this.state.uploadFileTooltipShow}
                                    //            placement={"top"} delay={500}
                                    // />
                                }
                                {
                                    this.state.uploadFileAttachmentTooltipShow &&
                                    <MyTooltip target={this.uploadFileAttachmentRef}
                                               text={"Загрузить новый файл"}
                                               show={this.state.uploadFileAttachmentTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    ((this.props.redactorMode && modifyAccess) || ( adminLevel === AdminLevelEnum.CommonUser && modifyAccess))
                                    &&
                                    <React.Fragment>
                                        {/*<div onClick={this.uploadFile} ref={this.uploadFileRef}*/}
                                        {/*     onMouseEnter={() => {this.setState({uploadFileTooltipShow: true}) }}*/}
                                        {/*     onMouseLeave={() => {this.setState({uploadFileTooltipShow: false})}}>*/}
                                        {/*   <span className="btn btn-icon btn-sm">*/}
                                        {/*       <i className="svg-icon icon-Upload_document icon-color-primary"/>*/}
                                        {/*   </span>*/}
                                        {/*</div>*/}
                                        <div onClick={this.uploadFileAttachment} ref={this.uploadFileAttachmentRef}
                                             onMouseEnter={() => {
                                                 this.setState({uploadFileAttachmentTooltipShow: true})
                                             }}
                                             onMouseLeave={() => {
                                                 this.setState({uploadFileAttachmentTooltipShow: false})
                                             }}>
                                        <span className="btn btn-icon btn-sm">
                                            <i className="svg-icon icon-Doc_view_8 icon-color-primary"/>
                                        </span>
                                        </div>
                                    </React.Fragment>
                                }

                                {
                                    (this.state.backToTableTooltipShow && !this.props.redactorMode) &&
                                    <MyTooltip target={this.backToTableRef}
                                               text={"Вернуться в реестр"}
                                               show={this.state.backToTableTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    !this.props.redactorMode &&
                                    <span className="btn btn-icon btn-sm" ref={this.backToTableRef}
                                          onClick={this.backToTable}
                                          onMouseEnter={() => {
                                              this.setState({backToTableTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({backToTableTooltipShow: false})
                                          }}
                                    >
                                        <i className={"svg-icon icon-undo_16 icon-color-primary"}/>
                                    </span>
                                }

                                {
                                    (this.state.backToTableTooltipShow && this.props.redactorMode) &&
                                    <MyTooltip target={this.backToTableRef}
                                               text={"Отменить редактирование"}
                                               show={this.state.backToTableTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    this.props.redactorMode &&
                                    <span className="btn btn-icon btn-sm" ref={this.backToTableRef}
                                          onClick={this.toggleRedactorMode}
                                          onMouseEnter={() => {
                                              this.setState({backToTableTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({backToTableTooltipShow: false})
                                          }}>
                                <i className={backToTableColor}/>
                            </span>
                                }
                            </div>
                        }
                        {
                            this.props.viewMode === "normal" &&
                            <div className="d-flex align-items-center">
                                {/*{*/}
                                {/*    this.state.infoTooltipShow &&*/}
                                {/*    <MyTooltip target={this.infoRef}*/}
                                {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                                {/*               show={this.state.infoTooltipShow}*/}
                                {/*               placement={"left"} delay={500}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                                {/*      ref={this.infoRef}*/}
                                {/*      onMouseEnter={()=>{this.setState({infoTooltipShow : true})}}*/}
                                {/*      onMouseLeave={()=>{this.setState({infoTooltipShow : false})}}>*/}
                                {/*      <i className="svg-icon icon-Info"/>*/}
                                {/* </span>*/}
                                {
                                    this.state.deleteDocumentTooltipShow &&
                                    <MyTooltip target={this.deleteDocumentRef}
                                               text={"Пометить на удаление/Снять пометку"}
                                               show={this.state.deleteDocumentTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    deleteAccess && this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                    <span className="btn btn-icon btn-sm" ref={this.deleteDocumentRef}
                                          onClick={() => {
                                              this.deleteDocument()
                                          }}
                                          onMouseEnter={() => {
                                              this.setState({deleteDocumentTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({deleteDocumentTooltipShow: false})
                                          }}>
                                    <i className={`svg-icon ${deleteDocumentIcon}`}/>
                                </span>
                                }


                                {
                                    this.state.addNewCardTooltipShow &&
                                    <MyTooltip target={this.addNewCardRef}
                                               text={"Карточка документа"}
                                               show={this.state.addNewCardTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <Link to={link} className="btn btn-icon btn-sm" ref={this.addNewCardRef}
                                      onClick={this.addNewCard}
                                      onMouseEnter={() => {
                                          this.setState({addNewCardTooltipShow: true})
                                      }}
                                      onMouseLeave={() => {
                                          this.setState({addNewCardTooltipShow: false})
                                      }}>
                                    <i className="svg-icon icon-Table_edit_3 icon-color-primary"/>
                                </Link>


                            </div>
                        }
                        </div>
                    </div>
                    <div style={{marginTop: "10px", color: "darkGrey"}}>Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл</div>
                    {body}
                    <div className="card-footer">
                        <div className="btn-toolbar-preview card-toolbar">

                            {
                                this.state.selectFileTooltipShow &&
                                <MyTooltip target={this.selectFileRef}
                                           text={"Выбрать файл предпросмотра"}
                                           show={this.state.selectFileTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <Dropdown>
                                <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle"
                                                 ref={this.selectFileRef}
                                                 onMouseEnter={() => {
                                                     this.setState({selectFileTooltipShow: true})
                                                 }}
                                                 onMouseLeave={() => {
                                                     this.setState({selectFileTooltipShow: false})
                                                 }}>
                                    <i className="svg-icon icon-Doc_view_12"/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item key={createUniqueIdString(3)}
                                                   className={`dropdown-item ${preview.activeView === "original" ? "active" : ""}`}
                                                   onClick={() => this.selectViewActiveFile("original")}>Оригинал
                                    </Dropdown.Item>
                                    <Dropdown.Item key={createUniqueIdString(3)}
                                                   className={`dropdown-item ${preview.activeView === "preview" ? "active" : ""}`}
                                                   onClick={() => this.selectViewActiveFile("preview")}>Предпросмотр
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {
                                this.state.halfScreenTooltipShow &&
                                <MyTooltip target={this.halfScreenRef}
                                           text={"Увеличенный предпросмотр"}
                                           show={this.state.halfScreenTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-outline-success btn-circle btn-sm"
                                  ref={this.halfScreenRef}
                                  style={buttonActiveStyleHalf}
                                  onMouseEnter={() => {
                                      this.setState({halfScreenTooltipShow: true})
                                  }}
                                  onMouseLeave={() => {
                                      this.setState({halfScreenTooltipShow: false})
                                  }}
                                  onClick={() => {
                                      this.toggleSizePreview("halfScreen")
                                  }}>
                            <i className="svg-icon icon-Doc_view_2" style={arrowButtonActiveStyleHalf}/>
                        </span>

                            {
                                this.state.fullScreenTooltipShow &&
                                <MyTooltip target={this.fullScreenRef}
                                           text={"Полноэкранный предпросмотр"}
                                           show={this.state.fullScreenTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-outline-success btn-circle btn-sm"
                                  ref={this.fullScreenRef}
                                  style={buttonActiveStyleFull}
                                  onMouseEnter={() => {
                                      this.setState({fullScreenTooltipShow: true})
                                  }}
                                  onMouseLeave={() => {
                                      this.setState({fullScreenTooltipShow: false})
                                  }}
                                  onClick={() => {
                                      this.toggleSizePreview("fullScreen")
                                  }}>
                            <i className="svg-icon icon-Doc_view_3" style={arrowButtonActiveStyleFull}/>
                        </span>

                            {
                                this.state.openInNewTabTooltipShow &&
                                <MyTooltip target={this.openInNewTabRef}
                                           text={"Открыть в новой вкладке"}
                                           show={this.state.openInNewTabTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <a href={preview.isReady ? activeLink : "#"}
                               ref={this.openInNewTabRef}
                               onMouseEnter={() => {
                                   this.setState({openInNewTabTooltipShow: true})
                               }}
                               onMouseLeave={() => {
                                   this.setState({openInNewTabTooltipShow: false})
                               }}
                               target="_blank"
                               rel="noreferrer"
                               className="btn btn-icon btn-outline-success btn-circle btn-sm">
                                <i className="svg-icon icon-Doc_view_5"/>
                            </a>
                        </div>
                    </div>
                </>
        );

    }
}


const  mapStateToProps = state => {
    return {
        preview : state.document.preview,
        rootState : state.document.rootState,
        activeRow : state.document.mainTable.activeRow,
        activeNode : state.document.tree.activeNode,


        cards : state.document.cards,
        router : state.router,

        redactorDataIsUpdated : state.document.cards.redactorDataCollected.isUpdatedInAll,
        adminLevel : state.globalState.user.AdminLevel,
        tree_docs: state.document.tree.render.children
    }
}

const  mapDispatchToProps = {
    selectActiveFileForPreview,
    setSizePreview,
    changeDocumentViewMode,
    selectActiveFileForPreview_Card,
    selectActiveDocumentCard,
    createRedactorData,
    createDocumentCards,
    setModalData,
    setStateLeftAsideMenu,
    checkInputDataBeforeUpdate,
    changeVersionDocumentCards,
    updateUserSettings,
    changeViewMode,
    setFilesContent,
    selectViewActiveFileForPreview,
    collectDataForAdditionalSections_Normal,
    setLoaderModalData,

    mainTableDownloadDocCollection,
    combineMainTableAttributes,
    selectActiveRow,
    resetDocumentPreview,
    createPreviewBySelectDocument,
    treeSelectNode

}

export default connect(mapStateToProps,mapDispatchToProps)(DocPreview);
