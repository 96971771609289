import React from "react";
import {connect} from "react-redux";
import {contextMenuTypes, ModalTypes} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData, setModalData,
    setUserModalShow
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    createDocumentCards,
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {
    changeDocumentSearchInputsContextMenu_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
    mainTableSearchDoc_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";

class ContextMenuMainTableTrPackagesDocumentRight extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.name = contextMenuTypes.MainTableTR;


        this.copy = this.copy.bind(this);
        this.addDocCard = this.addDocCard.bind(this);
        this.search = this.search.bind(this);


        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTableTR) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    copy() {
        let copytext = document.createElement('input');
        copytext.value = this.props.contextMenu.data.item.Value;
        document.body.appendChild(copytext);
        copytext.select();
        document.execCommand('copy');
        document.body.removeChild(copytext);

        this.props.setModalData({
            name : ModalTypes.app.alert,
            data : {content: `Содержимое скопировано`, disableButton: true}
        });

        setTimeout(() => {
            this.props.setModalData({});
            // //Новый прелоадер
            // this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTrPackagesDocumentRight69"});
        }, 500);

        this.props.setContextMenuData({});
    }

    addDocCard() { // TODO: ломается на правой кнопку при редактировании
        this.props.createDocumentCards({
            IdDoc : this.props.contextMenu.data.tr.Version.IdDoc,
            IdVersion : this.props.contextMenu.data.tr.Version.Id,
            isRightClick : true
        });
        this.props.setContextMenuData({});
    }
    search(){
        this.props.setUserModalShow({
            isShow : true,
            Type : "treeDocumentsSearch",
            Next:{
                action: () => {
                    setTimeout(()=>{ // TODO: исправить этот кастыль
                        this.props.changeDocumentSearchInputsContextMenu_packagesDocumentRight({
                            Attribute : this.props.contextMenu.data.item,
                            Value : this.props.contextMenu.data.item.Value,
                            isConstant : this.props.contextMenu.data.item.IsConstant,
                            Next : {
                                action: () => {
                                    this.props.mainTableSearchDoc_packagesDocumentRight({
                                        Next: {
                                            action: () => {
                                                this.props.combineMainTableAttributes_packagesDocumentRight({})
                                            },
                                            params: {}
                                        }
                                    });
                                },
                                params : {}
                            }
                        });
                    }, 500);
                },
                params : {}

            }
        })


        this.props.setContextMenuData({});
    }


    render() {

        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x
        };


        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
            onMouseEnter={(event) => {this.setState({isHover : true})}}
            onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <li className="context-menu__item" onClick={this.copy} style={{cursor : "pointer"}}>
                        <span className="context-menu__link">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                <i className="svg-icon svg-icon-sm text-primary icon-Doc_view_14"> </i>
                            </span>
                            Скопировать содержимое
                        </span>
                    </li>
                    <li className="context-menu__item" onClick={this.search} style={{cursor : "pointer"}}>
                        <span className="context-menu__link">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                <i className="svg-icon svg-icon-sm text-primary icon-Search_menu"> </i>
                            </span>
                            Фильтр по значению
                        </span>
                    </li>
                    <li className="context-menu__item" onClick={this.addDocCard} style={{cursor : "pointer"}}>
                        <span className="context-menu__link">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                <i className="svg-icon svg-icon-sm text-primary icon-Table_edit_3"> </i>
                            </span>
                            Карточка документа
                        </span>
                    </li>
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu :  state.globalState.app.contextMenu,
    }
}

const  mapDispatchToProps = {
    changeDocumentSearchInputsContextMenu_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
    createDocumentCards, // TODO: доделать когда общие карты будут
    mainTableSearchDoc_packagesDocumentRight,
    setContextMenuData,
    setLoaderModalData,
    setModalData,
    setUserModalShow,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuMainTableTrPackagesDocumentRight);
