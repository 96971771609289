import Cookies from 'universal-cookie';
import {apiRequest} from "../../../tools/API/apiRequest";
import {AUTH_ACTIONS} from "../globalStateActionsList";
import  {apiUrl} from "../../../tools/API/apiUrl";
import {store} from "../../../index";
import {setLoaderModalData} from "./globalState_AppActionCreator";


// вход в аккаунт с логином и паролем
export const AUTH_login = ({userName, password, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            //Новый прелоадер
            // store.dispatch(setModalData({
            //     name : ModalTypes.app.alert,
            //     data : {content : "Восстановление состояния приложения...", disableButton : true, fullBackground : true, gif : "search"}
            // }));
            store.dispatch(setLoaderModalData({
                data : {content : "Восстановление состояния приложения...", disableButton : true, fullBackground : true, gif : "search", key: "globalState_AuthActionCreator20"}
            }));

            let request = new apiRequest(apiUrl.AUTH.LOGIN, {
                armType : 2,
                userName : userName,
                password : password
            });

            request.execute(function(data) {
                let result = {
                    isAuth : true,
                    userName : userName,
                    token : {
                        value : data.Token,
                        createdAt : data.CreationTime
                    }
                }
                // const cookies = new Cookies();
                // cookies.set("userName", userName,  { path: '/' });
                // cookies.set("Arc-Auth", result.token.value,  { path: '/' });

                dispatch({type : AUTH_ACTIONS.LOGIN, payload : result});
                // store.dispatch(setModalData({}));
                //Новый прелоадер
                store.dispatch(setLoaderModalData({keyDeleted: "globalState_AuthActionCreator42"}));

                if(Next) Next.action(Next.params); // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    if(Error) {Error.action(Error.params);}
                } else {
                    console.log("Ошибка авторизации: ", data);
                    if(Error) {Error.action(Error.params);}
                }

            });
        } catch (exception) {
            console.log("Ошибка авторизации (catch)");
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        } finally {
            // store.dispatch(setModalData({}));
            store.dispatch(setLoaderModalData({keyDeleted: "globalState_AuthActionCreator59"}));
        }
    }
}

// проверка авторизован ли пользователь
export const AUTH_isAuth = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.AUTH.IS_AUTH, {});

            request.execute(function(data) {
                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : true});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type :  AUTH_ACTIONS.IS_AUTH, payload : false});
                    if(Error) {Error.action(Error.params);}
                } else {
                    if(Error) {Error.action(Error.params);}
                    console.log("Ошибка авторизации IS_AUTH (post): ", data);
                }
            });
        } catch (exception) {
            console.log("Ошибка авторизации IS_AUTH (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// выход из аккаунта
export const AUTH_logout = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.AUTH.LOG_UOT, {});

            request.execute(function(data) {
                dispatch({type : AUTH_ACTIONS.LOGUOT, payload : {}});

                const cookies = new Cookies();
                cookies.remove("userName");
                cookies.remove("Arc-Auth");

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

                // window.location.reload(); // перезагрузка для сброса store

            },function(data) {
                console.log("Ошибка (callBack) LOGOUT (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка LOGOUT (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
