import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {ActionQueue} from "../../store/rootReducer";
import {treeSelectNode, toggleIsLoading} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, resetMainTableForUpdate, testPackage
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {RootComponentsStateViewModeTypes, SettingsValueEnum} from "../../tools/StaticTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    createRedactorData,
    resetDocumentPreview,
    selectActiveDocumentCard
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentSearchInputs} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {AUTH_isAuth} from "../../store/globalState/actionCreators/globalState_AuthActionCreator";
import {history} from "../../index";

class TreeDocsDocument extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.selectNode = this.selectNode.bind(this);
        this.setLink = this.setLink.bind(this);
        this.ref = React.createRef();
    }

    componentDidMount() {
        const { activeNode, data, openedFolders, depth } = this.props;

        //TODO SS изменено условие для фокуса, чтобы работало с многоуровневой вложенностью
        if(activeNode && activeNode.treeId === data.treeId && depth - 1 === openedFolders.length
            // && openedFolders.length === 1
        ) {
            this.ref.current.focus();
        }
    }

    setLink(link) {
        if (this.props.isAuth) history.replace(link)
    }

    async selectNode(link) {
        let isContinue = true;
        if (this.props.redactorData) {
            isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
        }

        if (!isContinue) {
            return;
        }

        if (isContinue && this.props.redactorData) {
            window.onbeforeunload = null;
            window.addEventListener("popstate", null);
        }
        this.props.ActionQueue({
            List: [
                {action: this.props.AUTH_isAuth, params: {}, name: "AUTH_isAuth"}, // проверка авторизации
                {action: this.props.toggleIsLoading, params: {}, name: "toggleIsLoading"},
                {action: this.props.resetMainTableForUpdate, params: {}, name: "resetMainTableForUpdate"},
                {action: this.props.treeSelectNode, params: {Node: this.props.data}, name: "treeSelectNode"},
                {
                    action: this.props.mainTableDownloadDocCollection,
                    params: {ActiveNode: this.props.data},
                    name: "mainTableDownloadDocCollection"
                },
                {
                    action: this.props.combineMainTableAttributes,
                    params: {ActiveNode: this.props.data},
                    name: "combineMainTableAttributes"
                },
                {action: this.props.resetDocumentPreview, params: {}, name: "resetDocumentPreview"},
                {
                    action: this.props.changeDocumentViewMode,
                    params: {ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly},
                    name: "changeDocumentViewMode"
                },
                {action: this.props.selectActiveDocumentCard, params: {CardId: null}, name: "selectActiveDocumentCard"},
                {
                    action: this.props.changeDocumentSearchInputs,
                    params: {Delete: true},
                    name: "changeDocumentSearchInputs"
                },
                {action: this.props.createRedactorData, params: {CardId: null}, name: "createRedactorData"},
                {action: this.props.toggleIsLoading, params: {}, name: "toggleIsLoading"},
                // TODO Задача № 23189
                {action: () => this.setLink(link), params: {}, name: "setLink"},
            ],
            debug: false
        });
    }


    render() {
        let {data, activeNode, globalSettings} = this.props;

        let docStyle = "";
        if(activeNode !== null && activeNode.treeId === data.treeId) {
            // console.log("data.treeId2",data.treeId)
            docStyle = "menu-item menu-item-active";
        }
        else {
            docStyle = "menu-item";
        }



        let link = "/documents";
        if(data && data.info) {
            link = `/documents?g=${this.props.groupId}&t=${data.info.Id}`;
        }

        let isEmptyStyle = {};


        let lFlagHighlight = true;

        if (globalSettings.NotHighlightContainsDocs)
            if (globalSettings.NotHighlightContainsDocs === SettingsValueEnum.True)
                lFlagHighlight = false;

        //TODO подсветка типов без документов
        if (lFlagHighlight)
            if(!data.info.ContainsDocs) {
                isEmptyStyle = {
                    color : "#3f42546b"
                };
            }

        return(
            <li className={docStyle} key={createUniqueIdString(6)}>
                <a onClick={() => this.selectNode(link)} className="menu-link">
                    <i className="menu-bullet menu-bullet-dot">
                        <span> </span>
                    </i>
                    <span ref={this.ref} tabIndex={data.info.Id} className="menu-text noneFocus" style={isEmptyStyle}>{data.info.Name}</span>
                </a>
            </li>
        );
    }

}


const  mapStateToProps = state => {
    return {
        activeNode : state.document.tree.activeNode,
        mainTable : state.document.mainTable,
        activeFilial : state.globalState.filial.Active,
        userSettings : state.globalState.userSettings,
        redactorData : state.document.cards.redactorData,
        globalSettings: state.globalState.settings.Content,
        openedFolders: state.document.tree.openedFolders,
        isAuth: state.globalState.auth.isAuth,
    }
}

const  mapDispatchToProps = {
    AUTH_isAuth,
    ActionQueue,
    treeSelectNode,
    mainTableDownloadDocCollection,
    testPackage,
    combineMainTableAttributes,
    resetMainTableForUpdate,
    changeDocumentViewMode,
    resetDocumentPreview,
    selectActiveDocumentCard,
    changeDocumentSearchInputs,
    createRedactorData,
    toggleIsLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeDocsDocument);
