import React from "react";
import {connect} from "react-redux";
import { __ } from "../../tools/HelpFunctions";

class TabItem extends React.Component {
    //aa 08.08.2022 Позиционирование на созданном узле
    //tabRef;
    constructor(props) {
        super(props);

        this.state = {
            iconDeleteOnHover: false,
            refsComplete: []
        };

        this.tabRef = React.createRef();

        this.OnTabClick = this.OnTabClick.bind(this);
        this.OnTabCloseClick = this.OnTabCloseClick.bind(this);
    }


    OnTabClick() {
        if (this.props.OnTabClick) {
            this.props.OnTabClick();
        }
    }

    OnTabCloseClick() {
        if (this.props.OnTabCloseClick) {
            this.props.OnTabCloseClick();
        }
    }

    componentDidMount() {
        if (this.props.setRef) {
            if (!this.state.refsComplete.find(lItem => lItem === this.tabRef)) {
                const lCopy = __.deepCopy(this.state.refsComplete);
                lCopy.push(this.tabRef);
                this.setState({refsComplete: lCopy});
                if (this.tabRef.current) {
                    this.props.setRef(this.tabRef);
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.setRef) {
            if (!this.state.refsComplete.find(lItem => lItem === this.tabRef)) {
                const lCopy = __.deepCopy(this.state.refsComplete);
                lCopy.push(this.tabRef);
                this.setState({refsComplete: lCopy});
                if (this.tabRef.current) {
                    this.props.setRef(this.tabRef);
                }
            }
        }
    }


    render() {
        if(!this.props.info) return null;
        let activeStyle="";
        if(this.props.info.IsActive) activeStyle = "active";

        let icon = <i className={`menu-icon ${this.props.info.Icon}`} style={{marginRight: "5px"}}/>;
        let title = `Переключиться на вкладку «${this.props.info.Name}»`;
        if (this.state.iconDeleteOnHover && !this.props.Unclosable) {
            icon = <i className={`icon-Delete icon-color-red`} style={{marginRight: "5px"}}/>;
            title = "Закрыть вкладку";
        }

        return (
            <li className="nav-item"
                ref={this.tabRef}
                style={{cursor: "pointer", width: "auto"}}
                // , minWidth:`${lMinWidth}`
                title={title}
                onClick={(event) => {
                    if (this.props.OnTabClick && !this.state.iconDeleteOnHover) this.OnTabClick()
                }}
                onContextMenu={(ev) => this.props.onRightClick(ev)}
                tabIndex={Math.random()}
            >
                <span className={`nav-link py-2 ${activeStyle}`}>
                    <span className="svg-icon menu-icon nav-icon active svg-icon-success"
                          onClick={(event) => {

                              if (this.state.iconDeleteOnHover) this.OnTabCloseClick()
                          }}
                          onMouseEnter={() => {
                              this.setState({iconDeleteOnHover: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({iconDeleteOnHover: false})
                          }}>
                        {icon}
                    </span>
                    <span className={`nav-text ${this.props.IsEllipsis ? 'ellipsis' : ''}`}
                          style={{whiteSpace: "nowrap"}}>{this.props.info.Name}</span>
                </span>
            </li>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    }
}

const  mapDispatchToProps = {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(TabItem);
