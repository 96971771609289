import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {
     toggleAdditionalSectionsOpen_Cards
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {attributesSectionsTypes} from "../../../tools/StaticTypes";
import {__} from "../../../tools/HelpFunctions";
import {toggleAdditionalSectionsOpenSearch} from "../../../store/search/actionCreators/search_PreviewActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history} from "../../../index";


class DocumentPackagesSectionSearch extends React.Component {
    constructor(props) {
        super(props);


        this.state = {

        };

        this.sectionName = attributesSectionsTypes.documentPackagesSection;

        this.toggle = this.toggle.bind(this);
    }


    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpenSearch({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }


    render() {
        const {activeTab, searchResults} = this.props;
        let sectionData;
        let isReady;
        const additionalSectionsData = searchResults.find(res => res.Id === activeTab.Id)?.additionalSections;
        if(this.props.viewMode === "normal") {
            sectionData = additionalSectionsData.state[this.sectionName];
            isReady = additionalSectionsData.isReady;
        }
        else if(this.props.viewMode === "card") {
            let card;
            const cards = searchResults.find(res => res.Id === activeTab.Id)?.cards;
            if(this.props.redactorMode && this.props.cards.redactorData) {
                card = cards.redactorData;
            } else {
                card = cards.collection.find(card => card.Id === cards.activeCardId);
            }
            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = cards.collection
                .find(card => card.Id === cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        const activeRow = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.activeRow;

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Пакеты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">

                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-11">Пакет</th>
                                <th className="col-lg-1">Перейти</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.packages.map((item, index) => {

                                    let query = this.props.router.location.query;
                                    let queryLink = "";

                                    let idDoc = activeRow.info.Id;

                                    if(item.Type == 1) {
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : 2, dwp : item.Id, dwd : idDoc},
                                            excludedKeys : ["g", "t", "c", "d", "s", "r"]
                                        });
                                    }
                                    else if(item.Type == 2){
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : item.Id, d : idDoc},
                                            excludedKeys : ["g", "t", "c", "s", "r"]
                                        });
                                    }

                                    let link = `/packages${queryLink}`;

                                    return (
                                        <tr key={item.Name + `-${createUniqueIdString(6)}`}>
                                            <td>
                                                {item.Name}
                                            </td>
                                            <td>
                                                <span onClick={()=>{
                                                    history.push(link);
                                                    history.replace(link);
                                                }} className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                    <i className="svg-icon icon-Doc_view_6"/>
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        router : state.router,
        rootState : state.search.rootState,
        searchResults: state.search.searchState.searchResults,
        activeTab: state.search.rootState.activeTab
    }
}

const  mapDispatchToProps = {
    toggleAdditionalSectionsOpenSearch,
    toggleAdditionalSectionsOpen_Cards,
}



export default connect(mapStateToProps, mapDispatchToProps)(DocumentPackagesSectionSearch);
