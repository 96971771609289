import React from "react";
import {connect} from "react-redux";
import Iframe from "react-iframe";
import AttributesSectionPackagesMini from "../additionalDataSection/packages/mini/AttributesSectionPackagesMini";
import ContentSectionPackagesMini from "../additionalDataSection/packages/mini/ContentSectionPackagesMini";
import VersionSectionPackagesMini from "../additionalDataSection/packages/mini/VersionSectionPackagesMini";
import DocumentPackagesSectionPackagesMini
    from "../additionalDataSection/packages/mini/DocumentPackagesSectionPackagesMini";
import {SelectActivePackageDocument} from "../../store/packages/actionCreators/package_TreeActionCreator";
import {SelectActivePackageDocument_Right} from "../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {API} from "../../tools/API_NEW/API";
import {history, store} from "../../index";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {HelpFunctions} from "../../tools/HelpFunctions";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";
import {
    setModalData,
    setStateLeftAsideMenu,
    setUserModalShow
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {AdminLevelEnum, ModalTypes, TableTypesEnum} from "../../tools/StaticTypes";
import FilesSection from "../additionalDataSection/FilesSection";
import DepartmentsSection from "../additionalDataSection/DepartmentsSection";

class PackagesPreviewMiniDocumentsRight extends React.Component {
    constructor(props) {
        super(props);

        this.lFlagWithDepartments = false;
        let {globalSettings} = this.props;
        if (globalSettings?.WithDepartments === "1" && this.props.adminLevel > AdminLevelEnum.CommonUser)
            this.lFlagWithDepartments = true

        this.state = {
            closePreviewTooltipShow : false,
            goToDocumentsTooltipShow : false,
            addNewCardTooltipShow : false,
        };

        this.closePreviewRef = React.createRef();
        this.goToDocumentsRef = React.createRef();
        this.addNewCardRef = React.createRef();

        this.render_cardBody = this.render_cardBody.bind(this);
        this.closePreview = this.closePreview.bind(this);
    }

    closePreview() {

        //aa 29.09.2022

        // this.props.SelectActivePackageDocument_Right({ActiveRow : null});
        this.props.SelectActivePackageDocument_Right({ActiveRow : this.props.activeDocumentRight, IsVisibleModal: false});
    }

    render_cardBody() {

        let activeDocument = this.props.activeDocumentRight;

        let preview = activeDocument.preview;
        let body = <div className="card-body"/>;

        if(preview.activeView === "preview") {
            body = (
                <div className="card-body beautiful-scroll-10" style={{padding : "0px"}}>
                    <img src={preview.files[preview.activeFileIndex].previewLink} alt="" style={{width : "100%", boxShadow: "0 0 15px -5px grey"}} />
                </div>
            );
        }
        else {
            body = (
                <div className="card-body beautiful-scroll-10" style={{width : "100%", height : "100%", padding : "0px"}}>
                    <Iframe url={preview.files[preview.activeFileIndex].originalLink}
                            width="100%"
                            height="100%"
                            id="test"
                            display="initial"
                            position="relative"/>
                </div>
            );
        }

        return body;
    }

    async goToDocuments(isCard = false) {
        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос

        if (this.props.verifyDocs)
        {
            if (this.props.verifyDocs[this.props.activeDocumentRight.Info.Id]) {
                vFlagVerify = true;
                docRefInfo = this.props.verifyDocs[this.props.activeDocumentRight.Info.Id][0];
            }
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: this.props.activeDocumentRight.Info.Id,
                    IdFilial: this.props.activeFilialId
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: this.props.activeDocumentRight.Info.Id,
                    data: docRefInfo
                }
            });
        }

        if (docRefInfo.errorCode) {
            if (docRefInfo.message) {
                if (docRefInfo.message.Message) {
                    store.dispatch(setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            content: docRefInfo.message.Message,
                            disableButton: false,
                            type: "fail"
                        }
                    }));
                    return;
                }
            }
        } else {
            let link = `/documents`;
            let postfix = "";
            if (docRefInfo.IdDocGroup)
                postfix += `?g=${docRefInfo.IdDocGroup}`;
            if (docRefInfo.IdDocType)
                if (postfix != "")
                    postfix += `&t=${docRefInfo.IdDocType}`
                else
                    postfix += `?t=${docRefInfo.IdDocType}`;
            if (docRefInfo.IdDoc)
                if (postfix != "")
                    postfix += `&d=${docRefInfo.IdDoc}`;
                else
                    postfix += `?d=${docRefInfo.IdDoc}`;
            link += postfix;


            // // let docRefInfo = await API.search().verifyDocClientLinkIds({
            // //     linkIds : {
            // //         IdDoc : this.props.activeDocument.Version.IdDoc,
            // //         IdFilial : this.props.activeFilialId
            // //     }
            // // });
            //
            // let link = `/documents?g=${docRefInfo.IdDocGroup}&t=${docRefInfo.IdDocType}&d=${docRefInfo.IdDoc}`;

            if (isCard) {
                link += `&c=${docRefInfo.IdDoc}`;
            } else {
                this.props.setStateLeftAsideMenu({
                    isClose: false,
                    rootName: "DocumentApp"
                });
            }

            this.props.setUserModalShow({isShow: false})
            history.replace(link);
        }
    }


    render() {

        if(!this.props.activeNode) return null;
        if(!this.props.activeDocumentRight) return null;

        let isVisibleModal;

        isVisibleModal = this.props.isVisibleModalDocument;
         if(!isVisibleModal) return null;

        let activeDocument;
        let IdDoc
        if(this.props.tableRole === TableTypesEnum.Main) {
            activeDocument = this.props.activeDocument;
            IdDoc = this.props.activeDocument.Version.IdDoc
        }
        else if(this.props.tableRole === TableTypesEnum.Additional) {
            activeDocument = this.props.activeDocumentAdditional;
            IdDoc = this.props.activeDocumentAdditional.Version.IdDoc
        }
        else if(this.props.tableRole === TableTypesEnum.Download) {
            activeDocument = this.props.activeDocumentDownload;
            IdDoc = this.props.activeDocumentDownload.Version.IdDoc
        }
        else if(this.props.tableRole === TableTypesEnum.DocumentsRight) {
            activeDocument = this.props.activeDocumentRight;
            IdDoc = this.props.activeDocumentRight.Version.IdDoc
        }

        return (
            <div className="modal modal-sticky modal-sticky-bottom-right modal-packege1 show" style={{zIndex: 999}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="card card-custom" style={{border:"1px solid #ffffff"}}>
                            <div className="card-header align-items-center px-4 py-3 modal-packege2">
                                <div className="text-left flex-grow-1">
                                    <div className="text-dark-75 font-weight-bold">
                                        <span title={`${this.props.activeDocumentRight.Info.Name} ${this.props.activeDocumentRight.Info.ShortDescription?? ""}`}>
                                             {HelpFunctions.cutLongString(`${this.props.activeDocumentRight.Info.Name} ${this.props.activeDocumentRight.Info.ShortDescription ?? ""}`, 20, true)}
                                        </span>
                                        {
                                            this.state.addNewCardTooltipShow &&
                                            <MyTooltip target={this.addNewCardRef}
                                                       text={"Карточка документа"}
                                                       show={this.state.addNewCardTooltipShow}
                                                       placement={"top"} delay={500}
                                            />
                                        }
                                        <span className="btn btn-icon btn-sm"
                                              ref={this.addNewCardRef}
                                              onClick={()=>{this.goToDocuments(true)}}
                                              onMouseEnter={()=>{this.setState({addNewCardTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({addNewCardTooltipShow : false})}}>
                                            <i className="svg-icon icon-Table_edit_3 icon-color-primary"/>
                                        </span>
                                        {
                                            this.state.goToDocumentsTooltipShow &&
                                            <MyTooltip target={this.goToDocumentsRef}
                                                       text={"Перейти в реестр"}
                                                       show={this.state.goToDocumentsTooltipShow}
                                                       placement={"top"} delay={500}
                                            />
                                        }
                                        <span className="btn btn-icon btn-sm"
                                              ref={this.goToDocumentsRef}
                                              onMouseEnter={()=>{this.setState({goToDocumentsTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({goToDocumentsTooltipShow : false})}}
                                              onClick={()=>{this.goToDocuments()}}>
                                            <i className="svg-icon icon-left_window icon-color-primary"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="text-right flex-grow-1">
                                    {
                                        this.state.closePreviewTooltipShow &&
                                        <MyTooltip target={this.closePreviewRef}
                                                   text={"Закрыть предпросмотр"}
                                                   show={this.state.closePreviewTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <div className="text-right flex-grow-1">
                                    <span  onClick={this.closePreview}
                                           ref={this.closePreviewRef}
                                           onMouseEnter={()=>{this.setState({closePreviewTooltipShow : true})}}
                                           onMouseLeave={()=>{this.setState({closePreviewTooltipShow : false})}}
                                           className="btn btn-icon btn-sm">
                                        <i className="svg-icon icon-Delete icon-color-red"/>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body modal-packege4">

                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="symbol symbol-50 symbol-lg-120 modal-packege5 modal-packege6">
                                            {this.render_cardBody()}
                                        </div>
                                    </div>
                                    <div className="col-lg-8" style={{minHeight:"40vh"}}>
                                        <div className={"accordion accordion-solid accordion-toggle-plus beautiful-scroll-10 no-scroll"}>
                                            <AttributesSectionPackagesMini tableRole={this.props.tableRole}/>
                                            <ContentSectionPackagesMini tableRole={this.props.tableRole}/>
                                            {/*<FilesSectionPackagesMini tableRole={this.props.tableRole}/>*/}
                                            <FilesSection
                                                activeDocument={activeDocument}
                                                typeSection = {"packages"}
                                                signatureNotEditable = {true}
                                            />

                                            <VersionSectionPackagesMini tableRole={this.props.tableRole}/>
                                            <DocumentPackagesSectionPackagesMini tableRole={this.props.tableRole}/>
                                            {this.lFlagWithDepartments &&
                                                <DepartmentsSection
                                                    tableRole={this.props.tableRole}
                                                    depMini
                                                    IdDoc={IdDoc}
                                                    viewMode={"normal"}
                                                />}
                                            {/*//TODO аккордеон здесь*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        adminLevel : state.globalState.user.AdminLevel,
        globalSettings: state.globalState.settings.Content,

        activeNode : state.packages.treeForDocuments.activeNode,
        /*activeDocument : state.packages.mainTable.activeDocument,*/

        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,
        activeDocumentRight : state.packages.mainTable.activeDocument,



        isVisibleModalDocument : state.packages.mainTable.IsVisibleModal,

        activeFilialId : state.globalState.filial.Active.Id,

        verifyDocs: state.document.verifyDocs,

    }
}

const  mapDispatchToProps = {
    SelectActivePackageDocument,
    SelectActivePackageDocument_Right,
    setStateLeftAsideMenu,
    setUserModalShow
}


export default connect(mapStateToProps, mapDispatchToProps)(PackagesPreviewMiniDocumentsRight);
