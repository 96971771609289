import React from "react";
import {connect} from "react-redux";
import {AUTH_logout} from "../../store/globalState/actionCreators/globalState_AuthActionCreator";
import {__} from "../../tools/HelpFunctions";
import {TreeCombine} from "../../tools/TreeCombine";
import DocTabs from "../mainTable/tabs/DocTabs";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    resetMainTableForUpdate,
    setCloseOpenedTabsFlag
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {toggleFoldersForTree, treeSelectNode} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {
    createRedactorData, deleteDocumentCard,
    resetDocumentPreview,
    selectActiveDocumentCard
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {ActionQueue} from "../../store/rootReducer";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu,
    setUserModalShow
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {AppRouter, history, store} from "../../index";
import Avatar from "react-avatar";
import {Dropdown} from "react-bootstrap";
import DocTabsItem from "../mainTable/tabs/DocTabsItem";

// Верхнее меню. Отображает breadcrumbs, пользовательские настройки, вкладки документов
class TopMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backToRegistryTooltipShow : false,
            show: false,
            closeAllTabsTooltipShow: false,
            contentOnHover: true,
        };

        this.backToRegistryRef = React.createRef();
        this.closeAllTabsRef = React.createRef();

        this.closeAllTabs = this.closeAllTabs.bind(this)
        this.backToRegistry = this.backToRegistry.bind(this);
        this.selectNode = this.selectNode.bind(this);
        this.tooltipShow = this.tooltipShow.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
    }


    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {
            this.setState({show: false})
            document.removeEventListener('click', this.hideOutSide)
        }
    }

    closeAllTabs() {
        let isContinue = true;
        if (this.props.redactorData) {
            isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
        }

        if (!isContinue) {
            return;
        }


        //TODO ???????? SS зачем была проверка на "packages" и "search"?
        if (this.props.viewMode === "card" || this.props.redactorData) {
            // if (this.props.viewMode === "card" || this.props.redactorData || this.props.router.location.pathname === "/packages" || this.props.router.location.pathname === "/search") {
            // this.props.setModalData({
            //     name : ModalTypes.app.alert,
            //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents"}
            // });
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "TopMenu73"}
            }));


            let cardInfo = this.props.cards.collection.find(card => {
                return card.Id === this.props.cards.activeCardId
            });

            const recursiveSearch = (arr, info) => {
                let isFound = arr.children.find(elem => elem.info.Id === info)
                if (isFound) return isFound
                else {
                    return arr.children.find( item => {
                        if (item.children && item.children.length > 0)
                            return recursiveSearch(item, info)
                        else return false
                    })
                }
            }
            // let foundFolder = this.props.treeRender.children.find(child => child.info.Id === cardInfo.IdDocGroup);
            let foundFolder = recursiveSearch(this.props.treeRender, cardInfo.IdDocGroup)
            let foundDocument = recursiveSearch(foundFolder, cardInfo.TypeId)
            this.selectNode(foundDocument);

            this.props.router.location.pathname === "/documents" && this.props.setStateLeftAsideMenu({
                isClose: false,
                //TODO 20.01.2023 исправлен баг, если из карточки переключиться на реестр, то не открывается левое меню
                rootName: this.props.rootName
            });
            this.props.router.location.pathname !== "/documents" && this.props.setStateLeftAsideMenu({
                isClose: false,
                rootName: 'DocumentApp'
            });

            this.props.toggleFoldersForTree({IdFolder: foundFolder.info.Id, KeepOpen: true})
            this.props.setCloseOpenedTabsFlag({flag: true})


            let link = this.props.router.location.pathname + this.props.router.location.search
            link = link.split("&d")[0];
            link = link.split("&c")[0];
            history.replace(link);
        } else {
            this.props.deleteDocumentCard({})
            const prevLink =  `${this.props.router.location.pathname}${this.props.router.location.search}`

            history.push("/documents");
            history.push(prevLink);
        }

        AppRouter.stored.documents.c = null

        //TODO Задача № 23078 <
        this.props.setStateLeftAsideMenu({
            isClose: false,
            //TODO 20.01.2023 исправлен баг, если из карточки переключиться на реестр, то не открывается левое меню
            rootName: "DocumentApp"
        });
        //TODO Задача № 23078 >
    }

    // обработка клика по хлебным крошкам
    backToRegistry() {
        let isContinue = true;
        if(this.props.redactorData) {
            isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
        }

        if(!isContinue) {
            return;
        }

        if(this.props.viewMode === "card" || this.props.redactorData) {
            let cardInfo = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            let foundFolder = this.props.treeRender.children.find(child => child.info.Id === cardInfo.IdDocGroup);
            let foundDocument = foundFolder.children.find(child => child.info.Id === cardInfo.TypeId);
            this.selectNode(foundDocument);

            this.props.setStateLeftAsideMenu({
                isClose: false,
                //TODO 20.01.2023 исправлен баг, если из карточки переключиться на реестр, то не открывается левое меню
                rootName: this.props.rootName
            });

            let link = this.props.router.location.pathname + this.props.router.location.search.split("&c")[0];
            link = link.split("&d")[0];
            history.push(link);
        }
    }

    // выбрать активным узел дерева
    selectNode(activeNode) {
        this.props.ActionQueue({
            List : [
                {action: this.props.resetMainTableForUpdate, params : {}, name: "resetMainTableForUpdate"},
                {action: this.props.treeSelectNode, params : { Node : activeNode}, name: "treeSelectNode"},
                {action: this.props.mainTableDownloadDocCollection, params : {ActiveNode : activeNode}, name: "mainTableDownloadDocCollection"},
                {action: this.props.combineMainTableAttributes , params : { ActiveNode : activeNode}, name: "combineMainTableAttributes"},
                {action: this.props.resetDocumentPreview , params : {}, name: "resetDocumentPreview"},
                {
                    action: this.props.changeDocumentViewMode ,
                    params : {ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly},
                    name: "changeDocumentViewMode"
                },
                {action: this.props.selectActiveDocumentCard , params : {CardId : null}, name: "selectActiveDocumentCard"},
                {action: this.props.createRedactorData , params : {CardId : null}, name: "createRedactorData"},
                //Новый прелоадер
                {action: this.props.setLoaderModalData , params : {keyDeleted: "TopMenu158"}, name: "setLoaderModalData"},
                {action: () => {
                        if (store.getState().document.mainTable.closeOpenedTabs) {
                            this.props.deleteDocumentCard({})
                            this.props.setCloseOpenedTabsFlag({flag: false})
                        }
                    } , params : {}, name: "deleteDocumentCard"},

            ],
            debug : false
        });
    }

    // вывод подсказок (TODO: пока для одной потом переделать на несколько)
    tooltipShow() {
        if(this.props.viewMode === "card") {
            if(this.state.backToRegistryTooltipShow) {
                return (
                    <MyTooltip target={this.backToRegistryRef}
                               text={"Перейти в раздел реестра"}
                               show={this.state.backToRegistryTooltipShow}
                               placement={"bottom"} delay={500}
                    />
                );
            }
        }

        return null;
    }


    render() {
        let folderName = "";
        let documentName = "";
        let breadcrumbDocumentStyle = {};
        let cards = this.props.cards;

        if(this.props.viewMode === "normal") {
            if(this.props.activeNode) {
                let activeNode = __.deepCopy(this.props.activeNode);
                activeNode.parent = TreeCombine.searchByTreeId({treeId : activeNode.parent});
                documentName = activeNode.info.Name;
                folderName = activeNode.parent?.info.Name ?? "undefined";
            }
        } else if(this.props.viewMode === "card" || this.props.viewMode === "redactor") {
            let cardInfo = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            documentName = cardInfo.TypeName;
            folderName = cardInfo.DocGroupName;
            breadcrumbDocumentStyle = {
                cursor : "pointer",
            };
        }

        let tooltips = this.tooltipShow();

        // при переходе на главную страницу из TreeSearch, чтобы не оставалось в TopMenu название документа,
        // добавляем условие для viewMode
        let isViewModeDocsNone = this.props.viewModeDocs === "DocumentsApp | NONE";

        function headerHtml(title) {
            return (<div className="d-flex align-items-baseline flex-wrap mr-5">
                <h5 className="text-color-primary font-weight-bolder">{title}</h5>
                <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted"> </span>
                    </li>
                </ul>
            </div>)
        }


        return(
            <>
                {tooltips}
                <div className="subheader py-2 py-lg-2 subheader-solid ml-5 mb-3 ">

                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap row " style={{paddingRight : "5px"}}>
                        <div className="d-flex align-items-center col-lg-3 pr-1">
                            {
                                this.props.rootName === "HomeApp" &&
                                <div className="d-flex align-items-baseline flex-wrap mr-5">
                                    <h5 className="text-color-primary font-weight-bolder">Домашняя страница</h5>
                                    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0">
                                        <li className="breadcrumb-item text-muted">
                                            <span className="text-muted"> </span>
                                        </li>
                                    </ul>
                                </div>
                            }
                            {
                                this.props.rootName === "DocumentApp" &&
                                <div className="d-flex align-items-baseline flex-wrap mr-5">
                                    {
                                        (folderName && !isViewModeDocsNone) &&
                                        <>
                                            <h5 className="text-color-primary font-weight-bolder d-flex align-items-center text-dark font-weight-bold my-1 mr-3"
                                                ref={this.backToRegistryRef}
                                                onClick={this.backToRegistry}
                                                onMouseEnter={()=>{this.setState({backToRegistryTooltipShow : true})}}
                                                onMouseLeave={()=>{this.setState({backToRegistryTooltipShow : false})}}
                                                style={breadcrumbDocumentStyle}>
                                                {documentName}
                                            </h5>
                                            <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0">
                                                <li className="breadcrumb-item text-muted">
                                                    <span className="text-muted text-color-primary">{folderName}</span>
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    {
                                        (!folderName || isViewModeDocsNone) &&
                                        <>
                                            <h5 className="text-color-primary font-weight-bolder">Раздел
                                                документов</h5>
                                            <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0">
                                                <li className="breadcrumb-item text-muted">
                                                    <span className="text-muted"> </span>
                                                </li>
                                            </ul>
                                        </>
                                    }
                                </div>
                            }
                            {
                                this.props.rootName === "PackageApp" &&
                                headerHtml("Раздел пакетов")
                            }
                            {
                                this.props.rootName === "SearchApp" &&
                                headerHtml("Раздел поиска")
                            }
                            {
                                this.props.rootName === "ReportsApp" &&
                                headerHtml("Раздел отчетов")
                            }
                            {
                                this.props.rootName === "MasterApp" &&
                                headerHtml("Раздел загрузки документов")
                            }

                            {
                                this.props.rootName === "HelpApp" &&
                                headerHtml("Раздел справки")
                            }
                        </div>
                        { (this.props.rootName !== "HomeApp"
                                && this.props.rootName !== "HelpApp"
                                && this.props.rootName !== "MasterApp"
                                && this.props.rootName !== "ReportsApp") && cards.collection.length !== 0 &&
                            <>
                                {
                                    this.state.closeAllTabsTooltipShow &&
                                    <MyTooltip target={this.closeAllTabsRef}
                                               moreInfo={true}
                                               title={''}
                                               text={`Закрыть все вкладки`}
                                               show={this.state.closeAllTabsTooltipShow}
                                               placement={"left"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-lg"
                                      ref={this.closeAllTabsRef}
                                      onClick={this.closeAllTabs}
                                      onMouseEnter={() => {this.setState({ closeAllTabsTooltipShow : true})}}
                                      onMouseLeave={() => {this.setState({ closeAllTabsTooltipShow : false})}}
                                >
                                   <i className={`svg-icon icon-Delete icon-color-primary${this.state.closeAllTabsTooltipShow ? "" : "-exit"}`}/>
                               </span>
                            </>
                        }
                        <div className="col-lg-5">
                            {
                                (this.props.rootName !== "HomeApp"
                                    && this.props.rootName !== "HelpApp"
                                    && this.props.rootName !== "MasterApp"
                                    && this.props.rootName !== "ReportsApp")
                                &&
                                <DocTabs redactorMode={this.props.redactorMode}/>
                            }
                        </div>
                        {
                            (this.props.rootName !== "HomeApp" && this.props.rootName !== "HelpApp" && this.props.rootName !== "MasterApp" && this.props.rootName !== "ReportsApp" && cards.collection.length>0) &&
                            <div>
                                <Dropdown show={this.state.show}
                                          onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                                          onMouseLeave={() => {this.setState({ contentOnHover : false})}}
                                >
                                    <Dropdown.Toggle
                                        onClick = {() => {
                                            this.setState({show: !this.state.show})
                                            if (!this.state.show) document.addEventListener('click', this.hideOutSide)
                                            else document.removeEventListener('click', this.hideOutSide)
                                        }}
                                        className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Table_grid"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{overflowY: "auto",maxHeight: "212px", minHeight: "42px", borderRadius: "5px"}}
                                                   className="beautiful-scroll-5"
                                    >
                                        {
                                            cards.collection.map((item)=>{
                                                return(
                                                    <Dropdown.Item href="#" className="dropdown-item">
                                                        <DocTabsItem onClick={(isClose) => {
                                                            if (isClose) this.setState({show: !this.state.show})
                                                        }
                                                        } key={item.Id} data={item} active={this.props.cards.activeCardId === item.Id}
                                                                     isDropdown={true}/>
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }

                        <div className="col-lg-3 row" style={{justifyContent : "flex-end"}}>
                            {/*<Dropdown className="d-flex align-items-center flex-wrap">*/}
                            {/*    <Dropdown.Toggle  variant="clean" className="btn-icon w-auto d-inline-flex align-items-center btn-lg px-2 mr-5">*/}
                            {/*        <div className="d-flex flex-column text-right pr-sm-3">*/}
                            {/*            /!*<span className="text-dark opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">{this.props.filial.Name}</span>*!/*/}
                            {/*            <span className="text-dark opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">Цифровые Документы</span>*/}
                            {/*            <span className="text-dark font-weight-bolder font-size-sm d-none d-sm-inline">{this.props.user.Name}</span>*/}
                            {/*        </div>*/}
                            {/*        <span className="symbol symbol-35 symbol-light-success">*/}
                            {/*                <span className="symbol-label font-size-h5 font-weight-bold">*/}
                            {/*                    <i className="svg-icon icon-User"/>*/}
                            {/*                </span>*/}
                            {/*            </span>*/}
                            {/*    </Dropdown.Toggle>*/}
                            {/*    <Dropdown.Menu>*/}
                            {/*        <Dropdown.Item href="#" style={{color : "#b5b5b5"}} className="dropdown-item">*/}
                            {/*            Выбрать филиал*/}
                            {/*        </Dropdown.Item>*/}
                            {/*        <Dropdown.Item href="#" style={{color : "#b5b5b5"}} className="dropdown-item">*/}
                            {/*            Сменить пароль*/}
                            {/*        </Dropdown.Item>*/}
                            {/*        <Dropdown.Item href="#" className="dropdown-item"*/}
                            {/*                       onClick={()=>{this.props.AUTH_logout({})}}>*/}
                            {/*            Выход*/}
                            {/*        </Dropdown.Item>*/}
                            {/*    </Dropdown.Menu>*/}
                            {/*</Dropdown>*/}

                            <div className="d-flex align-items-center flex-wrap justify-content-end"
                                 onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "user" })})}>
                                <div role="button" className="btn-icon w-auto d-inline-flex align-items-center btn-lg px-2 mr-5">
                                    <div className="d-flex flex-column text-right pr-sm-3">
                                        {/*<span className="text-dark opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">{this.props.filial.Name}</span>*/}
                                        <span className="text-dark opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">
                                            {this.props.filial?.Name}
                                        </span>
                                        <span className="text-dark font-weight-bolder font-size-sm d-none d-sm-inline">{this.props.user?.Name}</span>
                                    </div>
                                    <span className="symbol symbol-35 symbol-success">
                                        <span className="symbol-label font-size-h5 font-weight-bold">
                                            <Avatar name={this.props.user?.Name} size="40" color={"var(--originaltwo)"} fgColor={"var(--text-welcome)"} className="rounded"/>
                                            {/*<img style={{borderRadius : "0.42rem"}}*/}
                                            {/*    src={`https://eu.ui-avatars.com/api/?name=${this.props.user.Name.split(" ")[0]}+${this.props.user.Name.split(" ")[1]}&background=204ba000&color=ffffff&size=35`} alt="иконка пользователя"/>*/}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


const  mapStateToProps = state => {
    return {
        user : state.globalState.user,
        filial : state.globalState.filial.Active,
        activeNode : state.document.tree.activeNode,

        cards : state.document.cards,
        treeRender : state.document.tree.render,
        router : state.router,
        redactorData : state.document.cards.redactorData,
        viewModeDocs: state.document.rootState.viewMode
    }
}

const  mapDispatchToProps = {
    AUTH_logout,
    ActionQueue,

    resetMainTableForUpdate,
    treeSelectNode,
    toggleFoldersForTree,
    mainTableDownloadDocCollection,
    combineMainTableAttributes,
    resetDocumentPreview,
    changeDocumentViewMode,
    selectActiveDocumentCard,
    setStateLeftAsideMenu,
    setUserModalShow,
    createRedactorData,
    deleteDocumentCard,
    setCloseOpenedTabsFlag,
    setModalData,
    setLoaderModalData
}


export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
