import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import moment from 'moment';
import {SelectActivePackageDocument} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {setModalData, setLoaderModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {store} from "../../../index";



class VersionSectionPackages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen : true
        };

        this.toggle = this.toggle.bind(this);
        this.selectVersion = this.selectVersion.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }

    // выбрать другую версию документа
    async selectVersion(index, item) {
        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {
        //         content: "Загрузка...",
        //         disableButton: true,
        //         fullBackground: false,
        //         gif: "packages"
        //     }
        // });
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "packages", key : "VersionSectionPackages44" }
        }));

        if (!this.props.activeDocument.Node.IsAuto) {
            await this.props.SelectActivePackageDocument({
                VersionIndex: index,
                TableType: "main",
                Id: this.props.activeDocument.Node.Id
            });
        }

        // this.props.setModalData({});
        //Новый прелоадер
        this.props.setLoaderModalData({keyDeleted: "VersionSectionPackages50"});
    }


    render() {
        if(!this.props.activeDocument) return null;
        let isReady = this.props.activeDocument.additionalSections.isReady;
        let sectionData = this.props.activeDocument.additionalSections.versionSection.versions.Records;
        let activeVersionIndex = this.props.activeDocument.additionalSections.versionSection.activeVersionIndex;

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Версии документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">
                        <table className="table table-bordered table-hover table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-11">Дата</th>
                                <th className="col-lg-1">Номер</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.map((item, index)=>{
                                    let timeMoment = new moment(item.VersionTime);

                                    let trClass = "";
                                    if(index === activeVersionIndex) {
                                        trClass = "tr-active";
                                    }

                                    return(
                                        <tr className={trClass} key={item.Version} onClick={()=> {this.selectVersion(index, item)}}>
                                            <td title={timeMoment.format("LTS")}>
                                               <time>{timeMoment.format("L")}</time>
                                            </td>
                                            <td title={item.VersionComment}>
                                                <span className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                    {item.Version}
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument
    }
}

const  mapDispatchToProps = {
    SelectActivePackageDocument,
    setModalData,
    setLoaderModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionSectionPackages);
