import {API} from "../../API_NEW/API";
import {Sorting} from "../../API_NEW/ApiFilter";
import {FieldSortType} from "../../API_NEW/ServerTypes";


export interface IDocGroupStructureNode {
    Id: number,
    IdParent?: number | null,
    Name: string,
    Description: string | null
}

export class DocGroupStructureNode implements IDocGroupStructureNode {
    Description: string | null;
    Id: number;
    IdParent?: number | null;
    Name: string;

    constructor(params: IDocGroupStructureNode) {
        this.Description = params.Description;
        this.Id = params.Id;
        this.IdParent = params.IdParent ?? null;
        this.Name = params.Name;
    }

    static async getAll(): Promise<IDocGroupStructureNode[]> {
        let docGroupStructureNodesAPI = await API.documents().getDocGroupStructureNodes({
            filter: null,
            sorting: new Sorting()
                .add({PropertyName: "IdParent", Direction: FieldSortType.Asc})
                .add({PropertyName: "Name", Direction: FieldSortType.Asc})
        });
        return docGroupStructureNodesAPI.Records;
    }

}