import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {
    AUTH_ACTIONS,
    USER_ACTIONS
} from "../globalStateActionsList";
import {Sorting} from "../../../tools/Filters";
import {store} from "../../../index";
import {__} from "../../../tools/HelpFunctions";



// получение информации о текущем пользователе
export const getCorrectUser = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.USERS.GET_CORRECT, {});

            request.execute(function(data) {
                dispatch({type : USER_ACTIONS.GET_CURRENT, payload : data});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения данных о пользователе USER_ACTIONS.GET_CURRENT (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения данных о пользователе USER_ACTIONS.GET_CURRENT (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// получение информации о филиалах доступных текущему пользователю
export const getFilial = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.USERS.GET_FILIAL, {
                filers : null,
                sorting :new Sorting().add({Direction: 1,PropertyName : "Name"}).get()
            });

            request.execute(function(data) {
                dispatch({type : USER_ACTIONS.GET_FILIAL, payload : data.Records});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// получение групп филиалов
export const getFilialGroups = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.USERS.GET_FILIAL_GROUPS, {
                filers : null,
                sorting :new Sorting().add({Direction: 1,PropertyName : "Name"}).add({Direction: 1,PropertyName : "IdParent"}).get()
            });

            request.execute(function(data) {
                dispatch({type : USER_ACTIONS.GET_FILIAL_GROUPS, payload : data.Records});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// получение групп филиалов
export const setFilial = ({Filial,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : USER_ACTIONS.SET_FILIAL, payload : Filial});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const renderFilialTree = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let groups = __.deepCopy(store.getState().globalState.filial.Groups)
            let filials = __.deepCopy(store.getState().globalState.filial.All)


            let root = [];
            for (let i = 0; i < groups.length; i++) {
                root.push({...groups[i], type : "group"});

                root[i].children = [];
                for (let j = 0; j < filials.length; j++) {
                    if(root[i].Id === filials[j]?.Group?.Id) {
                        root[i].children.push({...filials[j], type : "filial"});
                    }
                }
            }

            for (let i = 0; i < filials.length; i++) {
                if(!filials[i].Group) {
                    root.push({...filials[i], type : "filial"})
                }
            }





            dispatch({ type : USER_ACTIONS.RENDER_FILIAL_TREE, payload : root});
            if(Next) {Next.action(Next.params);}

        } catch (exception) {
            console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}