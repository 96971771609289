import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import { ResizableBox  } from 'react-resizable';
import {contextMenuTypes} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData,
    setUserModalShow
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    changeDocumentSearchInputs_packagesDocumentRight, combineMainTableAttributes_packagesDocumentRight,
    mainTableSearchDoc_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {changeViewMode} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {store} from "../../../index";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";
import {changeSortDirection} from "../../../store/documents/actionCreators/document_MainTableActionCreator";


class ThMainTableDocumentsRight extends React.Component  {
    constructor(props) {
        super(props);

        this.state = {
            defaultWidth : (this.props.data.Value.length * 13) + 70,
            width : this.props.data.Size ? this.props.data.Size : (this.props.data.Value.length * 13) + 70
        };

        this.onResize = this.onResize.bind(this);
        this.rightClick = this.rightClick.bind(this);
        this.search = this.search.bind(this);
        this.getIdAttributeType = this.getIdAttributeType.bind(this)
    }

    componentDidMount() {
        this.props.setItemSize(this.state.width ?? 0);
        this.props.setWidth(this.state.width ?? 0);
    }

    onResize = (event, {element, size, handle}) => {
        this.setState({width: size.width});
        this.props.setItemSize(size.width);
        this.props.setWidth(size.width);
    };

    rightClick(event, item) {
        this.props.setContextMenuData({
            name : contextMenuTypes.MainTableTH_PackagesDocumentRight,
            data : {
                item : item,
                th : this.props.data
            },
            position : {
                x : event.clientX,
                y : event.clientY}
        });
        event.preventDefault();
    }

    getIdAttributeType(data) {
        if (data.IdAttributeType) return data.IdAttributeType
        return (data.Name === "Name" || data.Name === "RegNumber") ? DataTypesEnum.String : DataTypesEnum.DataTime
    }


    async search(event) {

        // if(this.props.data.IsConstant){
        //
        //     this.props.changeViewMode({
        //         isSearch : true,
        //         Next: {
        //             action: () => {
        //                 this.props.changeDocumentSearchInputs_packagesDocumentRight({
        //                     //Attribute : this.props.data.Attribute,
        //                     Direction: "icon-Filter_up",
        //                     Attribute : this.props.data.Value,
        //                     isConstant : this.props.data.IsConstant,
        //                     isFocus:true,
        //                 })
        //             },
        //             params: {}
        //         }
        //     })
        // }else{
        //     this.props.changeViewMode({
        //         isSearch : true,
        //         Next: {
        //             action: () => {
        //                 this.props.changeDocumentSearchInputs_packagesDocumentRight({
        //                     //Attribute : this.props.data.Attribute,
        //                     Attribute : this.props.data,
        //                     isConstant : this.props.data.IsConstant,
        //                     isFocus:true,
        //                 })
        //             },
        //             params: {}
        //         }
        //     })
        // }

        this.props.changeSortDirection({dir: !this.props.sortDir, sortField: this.props.data})

        let Direction="";

        // TODO aa 17.03.2023 поменял местами, т.к. по умолчанию сортировка по дате 2
        if (this.props.sortDir) {
            Direction = "icon-Filter_down";
        } else {
            Direction = "icon-Filter_up";
        }

        await this.props.mainTableSearchDoc_packagesDocumentRight({
            FieldSorting: this.props.data,
            Direction,
            Next: {
                action: () => {
                    //TODO продолжить здесь
                    // при неоткрытом поле фильтрации нажатие на заголовок колонки кидает ошибку
                    const searchInputs = store.getState().packages.rightTableSearchDoc.searchInputs
                    let param = null
                    if (searchInputs.length !== 0)
                        param = searchInputs.find(item => {
                            const itemValue = item.Attribute?.Value ? item.Attribute?.Value : item.Attribute
                            return itemValue === this.props.data.Value
                        })
                    let IdAttributeType = this.getIdAttributeType(this.props.data)
                    this.props.changeDocumentSearchInputs_packagesDocumentRight({
                        TH: true,
                        Direction,
                        Attribute: this.props.data,
                        Name: this.props.data.Name,
                        Value: this.props.data.IdAttributeType
                            ? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''}
                            : this.props.data.IdAttributeType === 1 || this.props.data.IdAttributeType === 2
                                ? {Equal: '', LessOrEqual: '', MoreOrEqual: ''}
                                : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                        IsApplied: param?.IsApplied,
                        isConstant: this.props.data.IsConstant,
                        IdAttributeType: IdAttributeType,
                        Next: {
                            action: () => {
                                this.props.combineMainTableAttributes_packagesDocumentRight({
                                    Next :{
                                        action: () => {
                                            this.props.setContextMenuData({Next :{
                                                    action: () => {
                                                        // this.props.setModalData({});
                                                        //Новый прелоадер
                                                        this.props.setLoaderModalData({keyDeleted: "ThMainTable134"});
                                                    }
                                                }});
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });

    }


    render() {
        let width = {
            width : this.state.width + "px", // TODO: Подобрать норм размеры изначальные (тут пока на глаз сделал)
            position : "relative",
            paddingLeft: "0px",
        }
        if(this.props.isWrap) {
            width = {
                width : this.state.width + "px", // TODO: Подобрать норм размеры изначальные (тут пока на глаз сделал)
                position : "relative",
                paddingLeft: "0px",

                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace : "nowrap",
            }
        }



        let styleI ={
            verticalAlign: "middle",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor : "pointer",
        }
        let iconClass="icon-Filter fsic"

        let styleSpan ={
            marginRight:"20px",
        }


        if(this.props.isSearch){
            for (let i = 0; i < this.props.iconSorting.length; i++) {
                if(this.props.iconSorting[i].DirectionDown.indexOf("icon-color-primary")!==-1){
                    if(this.props.iconSorting[i].isConstant){
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute){
                            iconClass="icon-Filter_down"
                        }
                    }else{
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute.AttributeName.Name){
                            iconClass="icon-Filter_down"
                        }
                    }

                }else if(this.props.iconSorting[i].DirectionUp.indexOf("icon-color-primary")!==-1){
                    if(this.props.iconSorting[i].isConstant){
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute){
                            iconClass="icon-Filter_up"
                        }
                    }else{
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute.AttributeName.Name){
                            iconClass="icon-Filter_up"
                        }
                    }
                }

            }
        }

        let colorStyle = "icon-color-primary";
        let colorText = "";
        let Name =  this.props.data.Value;
        let thisSearch;



        if(this.props.isSearch && this.props.searchInputs.length > 0) {
            thisSearch = this.props.data.IsConstant
                ? this.props.searchInputs.find(s => s.isConstant && s.Attribute.toLowerCase() === Name.toLowerCase())
                : thisSearch = this.props.searchInputs.find(s => !s.isConstant && s.Attribute.Value.toLowerCase() === Name.toLowerCase())

            if(thisSearch?.IsApplied) {
                colorStyle = "icon-color-secondary";
                colorText = "text-color-secondary";
            }
        }



        if(this.props.data.Name !== "Version.Version" && this.props.data.UserVisible) {
            return (
                <th className="i2color"
                    style={width}
                    onContextMenu={(event) => {this.rightClick(event)}}
                    title={this.props.data.Value}
                >
                    <ResizableBox  style={{display: "inline-block"}}
                                   width={this.state.width}
                                   height={0}
                                   onResize={this.onResize}
                                   minConstraints={[this.state.defaultWidth / 2.5, 0]}
                                   maxConstraints={[this.state.defaultWidth * 3, 0]}
                                   axis={'x'}
                                   handle={(h, ref) =>
                                       <span style={{cursor : "col-resize", position : "absolute", right : 0, width : "24px", background : "#fff", height : "24px"}}
                                                             ref={ref}>
                                       <i className={"svg-icon icon-Doc_view_2"}/>
                                   </span>}
                    >
                        <span style={styleSpan} className={colorText}>
                            <span style={styleI}
                                  onClick={(event) => {this.search(event)}}
                            >
                                <i className={`svg-icon ${colorStyle} svg-icon-sm ${iconClass} `}/>
                                {/*<i className="svg-icon svg-icon-lg icon-color-primary icon-Filter_down"/>*/}
                            </span>&nbsp;&nbsp;
                            <span
                                role="button"
                                onClick = {(event) => this.search(event)}
                            >
                                {this.props.data.Value}
                            </span>
                        </span>
                    </ResizableBox >
                </th>

            );
        } else return null;
    }
}


const  mapStateToProps = state => {
    return {
        adminLevel : state.globalState.user.AdminLevel,
        iconSorting: state.packages.rightTableSearchDoc.searchInputs,
        isSearch: state.packages.rightTableSearchDoc.isSearch,
        searchInputs : state.packages.rightTableSearchDoc.searchInputs,
        isWrap: state.globalState.app.isWrap,
        sortDir:state.document.mainTable.sortDirection,
        sortField:state.document.mainTable.sortField,
    }
}

const  mapDispatchToProps = {
    changeDocumentSearchInputs_packagesDocumentRight,
    changeSortDirection,
    changeViewMode,
    combineMainTableAttributes_packagesDocumentRight,
    mainTableSearchDoc_packagesDocumentRight,
    // TODO: подумать
    setContextMenuData,
    setLoaderModalData,
    setUserModalShow,
}

export default connect(mapStateToProps,mapDispatchToProps)(ThMainTableDocumentsRight);
