import React from "react";
import {connect} from "react-redux";
import {__} from "../../tools/HelpFunctions";
import {apiRequest} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {Sorting} from "../../tools/Filters";
import {store} from "../../index";
import {ActionQueue} from "../../store/rootReducer";
import {
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight, mainTableSearchDoc_packagesDocumentRight
} from "../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {
    setLoaderModalData,
    setModalData,
    setUserModalShow
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import FiltersString from "./filtersByTypes/FiltersString";
import FiltersDate from "./filtersByTypes/FiltersDate";
import FiltersNumber from "./filtersByTypes/FiltersNumber";
import {ModalTypes} from "../../tools/StaticTypes";

class InputSearchDocPackageDocumentsRight extends React.PureComponent  {

    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover : false,
            accordionShow1 : false,
            accordionShow2 : true,
            accordionShow3 : true,
            arrowDopDate : false,

            LoadTypes : [],
            TypeIds:[],
            limitationTooltipShow : false,

            clearHoverSearchText : false,
            clearHoverAttributesText : false,
            clearHoverFullText : false,
            clearHoverDate : false,
            clearHoverNumber : false,
            clearHoverName : false,

            extSearch: false,
            extFilter: false,

            SearchText:"",
            AttributesText:"",
            FullText:"",
            RegDateAsString:"",
            RegNumber:"",
            Name:"",
            searchInputs: null,

            currentFilter: null,

        };
        this.limitation = React.createRef();




        this.search = this.search.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.accordion1 = this.accordion1.bind(this);
        this.accordion2 = this.accordion2.bind(this);
        this.accordion3 = this.accordion3.bind(this);
        this.loadTypes = this.loadTypes.bind(this);
        this.docTypesSelectOnChange = this.docTypesSelectOnChange.bind(this);
        this.dopDate = this.dopDate.bind(this);
        this.inputClear = this.inputClear.bind(this);

        this.inputSearchHandler = this.inputSearchHandler.bind(this);
        this.clearAllFilters = this.clearAllFilters.bind(this);
    }

    // componentDidMount() {
    //     // TODO TP не убирать, при повторном открытии фильтра сбрасываются значения примененного фильтра
    //     //     if(this.props.searchInputs.length > 0) {
    //     //         return;
    //     //     }
    //     //
    //     //     this.props.changeDocumentSearchInputs_packagesDocumentRight({
    //     //         isDelete: true
    //     //     });
    //     if (!this.props.searchInputs.find(item => item.Attribute === "Дата")){
    //         this.props.changeDocumentSearchInputs_packagesDocumentRight({
    //             Attribute : "Дата",
    //             Value : "",
    //             isConstant : true,
    //             IdAttributeType: DataTypesEnum.DataTime,
    //             Name: "RegDate", // PropertyName для фильтров
    //             UserVisible : this.props.allAttribute.find(item => item.Value === "Дата").UserVisible,
    //             IsShowExtFilters: false,
    //         });
    //     }
    //
    //     if (!this.props.searchInputs.find(item => item.Attribute === "Номер"))
    //         this.props.changeDocumentSearchInputs_packagesDocumentRight({
    //             Attribute : "Номер",
    //             Value : "",
    //             isConstant : true,
    //             IdAttributeType: DataTypesEnum.String,
    //             Name: "RegNumber", // PropertyName для фильтров
    //             UserVisible : this.props.allAttribute.find(item => item.Value === "Номер").UserVisible,
    //             IsShowExtFilters: false,
    //         });
    //
    //     if (!this.props.searchInputs.find(item => item.Attribute === "Наименование"))
    //         this.props.changeDocumentSearchInputs_packagesDocumentRight({
    //             Attribute : "Наименование",
    //             Value : "",
    //             isConstant : true,
    //             IdAttributeType: DataTypesEnum.String, //определение типа для точных фильтров
    //             Name: "Name", // PropertyName для фильтров
    //             UserVisible : this.props.allAttribute.find(item => item.Value === "Наименование").UserVisible,
    //             IsShowExtFilters: false,
    //         });
    //
    //         for(let i = 0; i < this.props.allAttribute.length; i++) {
    //             if (!this.props.allAttribute[i].IsConstant) {
    //                 if (!this.props.searchInputs.find(item => item.Attribute?.Value === this.props.allAttribute[i].Value))
    //                     this.props.changeDocumentSearchInputs_packagesDocumentRight({
    //                         Attribute : this.props.allAttribute[i],
    //                         Value : "",
    //                         isConstant : false,
    //                         Name: this.props.allAttribute[i].Name,
    //                         IdAttributeType: this.props.allAttribute[i].IdAttributeType,
    //                         IsShowExtFilters: false,
    //                     });
    //             }
    //         }
    //
    //     if (!this.props.searchInputs.find(item => item.Attribute === "Последнее изменение"))
    //         this.props.changeDocumentSearchInputs_packagesDocumentRight({
    //             Attribute : "Последнее изменение",
    //             Value : "",
    //             isConstant : true,
    //             IdAttributeType: DataTypesEnum.DataTime,
    //             Name: "Version.ModifyTime", // PropertyName для фильтров
    //             UserVisible : this.props.allAttribute.find(item => item.Value === "Последнее изменение").UserVisible,
    //             IsShowExtFilters: false,
    //         });
    //
    //     let fillingFieldsCount = 0;
    //     for (let i = 0; i < this.props.searchInputs.length; i++) {
    //         if (typeof this.props.searchInputs[i].Value === "object") {
    //             fillingFieldsCount = Object.values(this.props.searchInputs[i].Value).filter(Boolean).length;
    //             if (fillingFieldsCount > 0) {
    //                 this.setState({extSearch: true})
    //                 break
    //             }
    //         }
    //     }
    //
    // }

    componentDidUpdate(prevProps, prevState) {
        // для фокуса после удаления данных в поле
        // this.props.searchInputs.forEach(item => {
        //     if(item.isFocus) {
        //         let Name = item.isConstant ? item.Attribute : item.Attribute.Value;
        //         const currentInput = document.getElementById(Name);
        //         if(currentInput) currentInput.focus();
        //     }
        // })
    }

    dopDate(){
        this.setState({
            arrowDopDate : !this.state.arrowDopDate
        });
    }

    accordion1(){

        this.setState({
            accordionShow1 : !this.state.accordionShow1
        });

        this.loadTypes()
    }


    accordion2(){

        this.setState({
            accordionShow2 : !this.state.accordionShow2
        });
    }

    accordion3(){

        this.setState({
            accordionShow3 : !this.state.accordionShow3
        });
    }


    search({test = null, notClose = false, event = null, item = null, openModal = false}) {
        let Direction
        if (test)
            test.forEach(elem => {
                if (elem.DirectionUp.includes('primary')) {
                    Direction = elem.DirectionUp;
                    item = elem
                }
                if (elem.DirectionDown.includes('primary')) {
                    Direction = elem.DirectionDown;
                    item = elem
                };

            })
        if (this.props.searchInputs.every(item => !item.Value) && openModal) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content : `Нужно заполнить хотя бы один атрибут`}
            });
            return
        }

        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {content: "Загрузка...", disableButton: true, fullBackground: false, gif: "documents"}
        // });
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "inputSearchDocPackageDocumentsRight249" }
        }));

        if (!event) {
            this.props.ActionQueue({
                List: [
                    {action: this.props.mainTableSearchDoc_packagesDocumentRight, params: {FieldSorting: item, Direction, PaginationPageNumber: 1}, name: "mainTableSearchDoc_packagesDocumentRight"},
                    {
                        action: this.props.changeDocumentSearchInputs_packagesDocumentRight, params: {
                            Direction,
                            Attribute: item.Attribute,
                            isConstant: item.isConstant,
                            IsApplied: item.IsApplied,
                        }, name: "changeDocumentSearchInputs_packagesDocumentRight"
                    },
                    {action: this.props.combineMainTableAttributes_packagesDocumentRight, params: {}, name: "combineMainTableAttributes_packagesDocumentRight"},
                    // {action: this.props.setModalData, params: {}, name: "setModalData"},
                    //Новый прелоадер
                    {action: this.props.setLoaderModalData, params: {keyDeleted: "inputSearchDocPackageDocumentsRight259"}, name: "setLoaderModalData"},
                ],
                debug: true
            });
        } else {
            let IsApplied = !(item.Value === "" || Object.values(item.Value).every(item => item === ""))
            this.props.ActionQueue({
                List: [
                    {
                        action: this.props.mainTableSearchDoc_packagesDocumentRight,
                        params: {FieldSorting: item, Direction: event.target.className, PaginationPageNumber: 1},
                        name: "mainTableSearchDoc_packagesDocumentRight"
                    },
                    {
                        action: this.props.changeDocumentSearchInputs_packagesDocumentRight, params: {
                            Direction: event.target.className,
                            Attribute: item.Attribute,
                            Value: event.target.value,
                            isConstant: item.isConstant,
                            IsApplied,
                        }, name: "changeDocumentSearchInputs_packagesDocumentRight"
                    },
                    {action: this.props.combineMainTableAttributes_packagesDocumentRight, params: {}, name: "combineMainTableAttributes_packagesDocumentRight"},
                    // {action: this.props.setModalData, params: {}, name: "setModalData"},
                    //Новый прелоадер
                    {action: this.props.setLoaderModalData, params: {keyDeleted: "inputSearchDocPackageDocumentsRight283"}, name: "setLoaderModalData"},
                ],
                debug: true
            });
        }
        if (!notClose) {
            this.props.setUserModalShow({isShow: false})
        }

        setTimeout(() => {
            this.props.searchInputs.forEach(item => {
                // console.log(Object.values(item.Value).every(elem => !elem))
                if (Object.values(item.Value).every(elem => !elem)) {
                    this.props.changeDocumentSearchInputs_packagesDocumentRight({
                        ...item,
                        IsApplied: false,
                    });
                } else {
                    this.props.changeDocumentSearchInputs_packagesDocumentRight({
                        ...item,
                        IsApplied: true,
                    });
                }
            })
        }, 0)

    }

    loadTypes() {
        this.setState(()=>{
            let request_types = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE, {
                filter: {IdFilial : store.getState().globalState.filial.Active.Id},
                sorting: new Sorting().add({Direction: 1, PropertyName: "Name"}).get()
            });

            request_types.execute((data)=>{
                this.setState({
                    LoadTypes : data.Records,
                });
            }, (data)=>{console.log("Ошибка загрузки списка типов документов: ", data)});
        });
    }

    docTypesSelectOnChange(item){
        let tempArr=new Set();
        for (let i = 0; i < item.length;i++){
            //this.setState({TypeIds : [item[i].value]});
            tempArr.add(item[i].value);

        }
        tempArr.delete(null);
        this.setState({TypeIds:Array.from(tempArr)}, ()=>{
        });
    }

    inputHandler(event, item = null) {
        let value = event.target.value;


        if(item == null) {
            let nameInput = event.target.name;
            if(nameInput === "SearchText") {
                this.setState({SearchText : value});
            }
            else if(nameInput === "AttributesText") {
                this.setState({AttributesText : value});
            }
            else if(nameInput === "FullText") {
                this.setState({FullText : value});
            }
            else if(nameInput === "versionsCheckbox") {
                this.setState({IsSearchInVersions : event.target.checked});
            }
            else if(nameInput === "deletedCheckbox") {
                this.setState({IsSearchInMarkedForDelete : event.target.checked});
            }
            else if(nameInput === "attributesCheckbox") {
                this.setState({IsSearchInAttachments : event.target.checked});
            }
            else if(nameInput === "date") {
                this.setState({RegDateAsString : value});
            }
            else if(nameInput === "Number") {
                this.setState({RegNumber : value});
            }
            else if(nameInput === "Name") {
                this.setState({Name : value});
            }
        }
        else {
            let activeAttributes = __.deepCopy(this.state.ActiveAttributes);
            let index = activeAttributes.findIndex(attr => attr.uid === item.uid);
            activeAttributes[index].inputValue = value;
            this.setState({
                ActiveAttributes : activeAttributes
            });
        }
    }

    inputSearchHandler(event, item) {
        this.props.changeDocumentSearchInputs_packagesDocumentRight({
            Attribute : item.Attribute,
            Value : event.target.value,
            isConstant : item.isConstant,
            DirectionUp: item.DirectionUp,
            DirectionDown: item.DirectionDown,
            isFocus: true,
            Next : {
                action : ()=>{
                    if(event.target.value === "") {
                        this.search({notClose: true, item});
                    }
                },
                params : {}
            }
        });
    }

    inputClear(event, item, Name) {
        if(this.state.HoverIconName === Name) {
            this.props.ActionQueue({
                List: [
                    {action: this.props.changeDocumentSearchInputs_packagesDocumentRight, params: {
                        Attribute : item.Attribute,
                            Value : "",
                            isConstant : item.isConstant,
                            isFocus: true,
                            IsApplied: false,
                        }, name: "changeDocumentSearchInputs_packagesDocumentRight"},
                    {action: this.props.mainTableSearchDoc_packagesDocumentRight, params: {}, name: "mainTableSearchDoc_packagesDocumentRight"},
                    {action: this.props.combineMainTableAttributes_packagesDocumentRight, params: {}, name: "combineMainTableAttributes_packagesDocumentRight"},
                ],
                debug: true
            });
        }
    }

    clearAllFilters() {
        if(this.props.searchInputs.length > 0) {
            this.props.searchInputs.forEach(item => {
                this.props.changeDocumentSearchInputs_packagesDocumentRight({
                    Attribute : item.Attribute,
                    Value : "",
                    isConstant : item.isConstant,
                    IsApplied: false,
                    Next : {
                        action : () => {
                            this.props.ActionQueue({
                                List: [
                                    {action: this.props.mainTableSearchDoc_packagesDocumentRight, params: {}, name: "mainTableSearchDoc_packagesDocumentRight"},
                                    {action: this.props.combineMainTableAttributes_packagesDocumentRight, params: {}, name: "combineMainTableAttributes_packagesDocumentRight"},
                                ],
                                debug: true
                            });
                        },
                        params : {}
                    }
                });
            })
        }
    }

    setExtFilters(ev, item) {
        this.setState({ currentFilter: item, extFilter: true});
    }

    setExtSearch(ev) {
        const value = ev.target.checked
        this.setState({extSearch: value})
    }

    render() {

        const { currentFilter } = this.state;

        // let typesOptions = [];
        // typesOptions.push({
        //     value :  null,
        //     label :  "Не выбрано",
        //     isDisabled : true
        // });
        // for(let i = 0; i < this.state.LoadTypes.length; i++) {
        //     typesOptions.push({
        //         value :  this.state.LoadTypes[i].Id,
        //         label :  this.state.LoadTypes[i].Name,
        //         isDisabled : false
        //     });
        // }

        // let accordionShow1 = "";
        // let accordionShowArrow1="";
        // if(this.state.accordionShow1){
        //     accordionShow1 ="show"
        //     accordionShowArrow1 ="active"
        // }

        let accordionShow2 = "";
        let accordionShowArrow2="";
        if(this.state.accordionShow2){
            accordionShow2 ="show"
            accordionShowArrow2 ="active"
        }

        // let accordionShow3 = "";
        // let accordionShowArrow3="";
        // if(this.state.accordionShow3){
        //     accordionShow3 ="show"
        //     accordionShowArrow3 ="active"
        // }
        //
        // let arrowDopDate="right";
        // if(this.state.arrowDopDate){
        //     arrowDopDate="bottom"
        // }
        //
        // let searchIconSearchText = "icon-Search ";
        // if(this.state.clearHoverSearchText) { // для отображения красного крестика
        //     searchIconSearchText = "icon-Delete icon-color-red";
        // }
        // let searchIconAttributesText = "icon-Search ";
        // if(this.state.clearHoverAttributesText) { // для отображения красного крестика
        //     searchIconAttributesText = "icon-Delete icon-color-red";
        // }
        // let searchIconFullText = "icon-Search ";
        // if(this.state.clearHoverFullText) { // для отображения красного крестика
        //     searchIconFullText = "icon-Delete icon-color-red";
        // }
        // let searchIconDate = "icon-Search ";
        // if(this.state.clearHoverDate) { // для отображения красного крестика
        //     searchIconDate = "icon-Delete icon-color-red";
        // }
        // let searchIconNumber = "icon-Search ";
        // if(this.state.clearHoverNumber) { // для отображения красного крестика
        //     searchIconNumber = "icon-Delete icon-color-red";
        // }
        // let searchIconName = "icon-Search ";
        // if(this.state.clearHoverName) { // для отображения красного крестика
        //     searchIconName = "icon-Delete icon-color-red";
        // }

        return (
            <div>
                <div className="accordion accordion-light accordion-toggle-arrow mb-5">
                    <div className="card">
                        <div className="card-header" >
                            <div className={"card-title "+ accordionShowArrow2} onClick={this.accordion2}>
                                Фильтры
                            </div>
                        </div>
                        <div className={"collapse "+ accordionShow2} >
                            <div className="row">
                                <label className="col-9 col-form-label text-color-primary">
                                    Расширенные фильтры
                                </label>
                                <div className="col-3 d-flex align-items-center">
                                       <span className="switch switch-sm">
                                        <label>
                                         <input type="checkbox"
                                                name="extSearch"
                                                checked={this.state.extSearch}
                                                onChange={(e)=>{this.setExtSearch(e)}}
                                         />
                                         <span />
                                        </label>
                                       </span>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    !this.state.extFilter ?
                                        <>
                                            <div className="row">
                                                <div className={'col-lg-9'} onClick={() => {
                                                    this.search({test: this.props.searchInputs})
                                                }}>
                                                    <span className="btn btn-primary btn-lg btn-block my-3">Применить</span>
                                                </div>
                                                {
                                                    <div className="col-lg-3"><span
                                                        className="btn btn-outline-primary btn-icon btn-lg btn-block my-3"
                                                        title={"Очистить текущий фильтр"}
                                                        onClick={this.clearAllFilters}>
                                                        <i id="disabled"
                                                           className="svg-icon icon-Table_edit_8 fsic4-i"/>
                                                    </span>
                                                    </div>
                                                }
                                            </div>
                                            {this.props.searchInputs.map((item, index) => {
                                                let fillingFieldsCount = 0;
                                                if (typeof item.Value === "object") {
                                                    fillingFieldsCount = Object.values(item.Value).filter(Boolean).length;
                                                }
                                                let Name = "";
                                                if (item.isConstant) Name = item.Attribute;
                                                else Name = item.Attribute.Value;
                                                let iconSearchStyle = "svg-icon svg-icon-lg icon-color-primary icon-Search";
                                                if (Name === "Наименование") {
                                                    iconSearchStyle = "svg-icon svg-icon-lg icon-color-primary icon-name_search"
                                                } else if (Name === "Номер") {
                                                    iconSearchStyle = "svg-icon svg-icon-lg icon-color-primary icon-number_search"
                                                } else if (Name === "Дата" || Name === "Последнее изменение") {
                                                    iconSearchStyle = "svg-icon svg-icon-lg icon-color-primary icon-calendar_search"
                                                }
                                                if (this.state.HoverIconName === Name) {
                                                    iconSearchStyle = "svg-icon svg-icon-lg icon-color-red icon-Delete"
                                                }
                                                return (
                                                    <div key={`${Name}_${item.Name}`}>
                                                        <div className="input-group h-40px my-4">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    onMouseEnter={() => {
                                                                        this.setState({HoverIconName: Name})
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        this.setState({HoverIconName: ""})
                                                                    }}
                                                                    onClick={(event) => {
                                                                        this.inputClear(event, item, Name)
                                                                    }}
                                                                    className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg">
                                                                    <i className={iconSearchStyle}/>
                                                                </span>
                                                            </div>
                                                            {/* Убран повторный компонент, т.к. отличается только свойство autoFocus,
                                                                   ему присвоено значение isFocus данного атрибута */}
                                                            <input autoFocus={item.isFocus}
                                                                   id={`${Name}_${item.Name}`}
                                                                   type="text"
                                                                   className="form-control form-control-lg h-40px br-right-5"
                                                                   placeholder={Name}
                                                                   title={Name}
                                                                   onChange={(event) => {
                                                                       this.inputSearchHandler(event, item)
                                                                   }}
                                                                   value={typeof item.Value === "string" ? item.Value : item.Value.ApproximatelyEqual}
                                                                   onKeyDown={(event) => {
                                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                           this.search({notClose: true, test: this.props.searchInputs})
                                                                       }
                                                                   }}
                                                                   name="Name"
                                                                   onFocus={() => {
                                                                       // переключение фокуса, т.к. оставался фокус на поле, в котором было удаление данных
                                                                       // this.props.changeDocumentSearchInputs_packagesDocumentRight({
                                                                       //     ...item,
                                                                       //     Attribute: item.Attribute,
                                                                       //     isFocus: true,
                                                                       // });
                                                                   }}
                                                            />
                                                            <div className="input-group-prepend"><span onClick={(event) => {this.search({notClose: true, event, item})}} className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"><i className={"svg-icon svg-icon-lg " + item.DirectionUp}/></span>
                                                                <span onClick={(event) => {
                                                                    this.search({notClose: true, event, item})
                                                                }}
                                                                      className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"><i className={"svg-icon svg-icon-lg " + item.DirectionDown}/></span>
                                                            </div>
                                                        </div>
                                                        {this.state.extSearch ?
                                                            <div className="d-flex">
                                                                <div className="col-lg-6">
                                                                    <a href="#"
                                                                       className="btn btn-outline-secondary font-weight-bold"
                                                                       style={{
                                                                           color: 'var(--originaltwo)',
                                                                           backgroundColor: 'var(--elem-bg)',
                                                                           borderColor: '#ffffff'
                                                                    }}
                                                                       onClick={(ev) => {
                                                                           this.setExtFilters(ev, item)
                                                                       }}
                                                                    >
                                                                        Настроить
                                                                    </a>
                                                                </div>
                                                                <div className="col-lg-6"
                                                                     style={{
                                                                         display: 'flex',
                                                                         alignItems: 'center',
                                                                         alignContent: 'center',
                                                                         flexWrap: 'wrap',
                                                                         justifyContent: 'center',
                                                                         flexDirection: 'row'
                                                                }}>
                                                                    <p style={{marginBottom: 0}}>
                                                                        Применено: {fillingFieldsCount}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                        {/*{index !== this.props.searchInputs.length - 1 &&*/}
                                                        {/*    <div className="separator separator-solid my-4"/>*/}
                                                        {/*}*/}
                                                    </div>
                                                )
                                            })
                                            }
                                            </>
                                        :
                                        currentFilter &&
                                        <>
                                            {currentFilter.IdAttributeType === DataTypesEnum.String
                                                && <FiltersString filterInfo={currentFilter}
                                                                  onApply={() => this.setState({extFilter: false})}
                                                                  documentSearchRight/>}
                                            {currentFilter.IdAttributeType === DataTypesEnum.DataTime
                                                && <FiltersDate filterInfo={currentFilter}
                                                                onApply={() => this.setState({extFilter: false})}
                                                                searchRight/>}
                                            {(currentFilter.IdAttributeType === DataTypesEnum.Integer || currentFilter.IdAttributeType === DataTypesEnum.Float)
                                                && <FiltersNumber filterInfo={currentFilter}
                                                                  onApply={() => this.setState({extFilter: false})}
                                                                  searchRight/>}
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        allAttribute: state.packages.mainTable.header,
        searchInputs : state.packages.rightTableSearchDoc.searchInputs
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeDocumentSearchInputs_packagesDocumentRight,
    changeDocumentViewMode,
    combineMainTableAttributes_packagesDocumentRight,
    mainTableSearchDoc_packagesDocumentRight,
    setLoaderModalData,
    setModalData,
    setUserModalShow,

}

export default connect(mapStateToProps,mapDispatchToProps)(InputSearchDocPackageDocumentsRight);
