import React from "react";
import {connect} from "react-redux";
import {Routing} from "../../../../tools/routing/Routing";
import {history} from "../../../../index";

class DocumentPackagesSectionPackagesMini extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen : false
        };

        this.toggle = this.toggle.bind(this);
    }


    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }


    render() {
        let activeDocument;
        if(this.props.tableRole === "main") activeDocument = this.props.activeDocument;
        else if(this.props.tableRole === "additional") activeDocument = this.props.activeDocumentAdditional;
        else if(this.props.tableRole === "download") activeDocument = this.props.activeDocumentDownload;
        else if(this.props.tableRole === "documentsRight") activeDocument = this.props.activeDocumentRight;

        if(this.props.tableRole === "documentsRight") {
            activeDocument = this.props.activeDocumentRight
        }

        if(!activeDocument) return null;
        let isReady = activeDocument.additionalSections.isReady;
        let sectionData = activeDocument.additionalSections.documentPackagesSection.packages.Records;

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Пакеты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">

                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-11">Пакет</th>
                                <th className="col-lg-1">Перейти</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.map((item, index) => {

                                    let query = this.props.router.location.query;
                                    let queryLink = "";

                                    let idDoc = activeDocument?.Node?.Id ?? activeDocument?.Info.Id;
                                    if(item.Type == 1) {
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : 2, dwp : item.Id, dwd : idDoc},
                                            excludedKeys : ["g", "t", "c", "d", "s", "r", "d2", "dg2", "dt2", "dd2"]
                                        });
                                    }
                                    else if(item.Type == 2){
                                        queryLink = Routing.CreateRef({
                                            oldQuery : query,
                                            newQuery : {p : item.Id, d : idDoc},
                                            excludedKeys : ["g", "t", "c", "s", "r"]
                                        });
                                    }

                                    let link = `/packages${queryLink}`;


                                    return (
                                        <tr key={item.Id}>
                                            <td>
                                                {item.Name}
                                            </td>
                                            <td>
                                                <span onClick={()=>{
                                                    history.push(link);
                                                    history.replace(link);
                                                }} className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                    <i className="svg-icon icon-Doc_view_6"/>
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,
        activeDocumentRight : state.packages.mainTable.activeDocument,
        router : state.router,
    }
}

const  mapDispatchToProps = {

}



export default connect(mapStateToProps, mapDispatchToProps)(DocumentPackagesSectionPackagesMini);
