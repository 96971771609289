import React from "react";
import {connect} from "react-redux";
import {AdminLevelEnum, ModalTypes} from "../../../tools/StaticTypes";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {HelpFunctions, uploadFilesForDoc} from "../../../tools/HelpFunctions";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {
    addNewFilesRedactorData, collectDataForAdditionalSections_Normal,
    createDocumentCards, deleteDocumentCard, selectActiveDocumentCard, createPreviewBySelectDocument, updateSignature
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {store} from "../../../index";
import {MyTooltip} from "../tooltip/MyTooltip";


// modal.data.IdDoc => обязательно
class DownloadDocModal extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            contentOnHover : false,
            drag : false,
            files : [],
            statusLoad : "collecting",
            deleteFileTooltipShow: false,
        };

        this.modalName = ModalTypes.documents.additionalSection.DownloadDoc;

        this.fileSelector = document.createElement('input');
        this.fileSelector.setAttribute('type', 'file');
        if(this.props.data.type === "attachment") {
            this.fileSelector.setAttribute('multiple', 'multiple');
        }
        this.deleteFileRef = React.createRef();


        this.hideOutSide = this.hideOutSide.bind(this);
        this.hide = this.hide.bind(this);
        this.dragStartHandler = this.dragStartHandler.bind(this);
        this.dragLeaveHandler = this.dragLeaveHandler.bind(this);
        this.dropEndHandler = this.dropEndHandler.bind(this);
        this.dragActiveRender = this.dragActiveRender.bind(this);
        this.dragNotActiveRender = this.dragNotActiveRender.bind(this);
        this.deleteFiles = this.deleteFiles.bind(this);
        this.setDescriptionHandler = this.setDescriptionHandler.bind(this);
        this.onClickFilesSelectHandler = this.onClickFilesSelectHandler.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.setNameHandler = this.setNameHandler.bind(this);
        this.updateSignatures = this.updateSignatures.bind(this);
    }

    // скрывать при клике вне окна
    hideOutSide() {
        if(!this.state.contentOnHover && this.state.statusLoad !== "loading") {

            this.props.setModalData({});
        }
    }

    // скрывать по кнопке
    hide() {

        this.props.setModalData({});
    }

    dragStartHandler(e) {
        e.preventDefault();
        e.stopPropagation();

        if(!this.state.drag) {
            this.setState({drag: true});
        }
    }

    dragLeaveHandler(e){
        e.preventDefault();
        e.stopPropagation();


        if(this.state.drag && e.target.className !== "dragZone") {
            this.setState({drag: false});
        }
    }

    dropEndHandler(e) {
        e.preventDefault();
        e.stopPropagation();

        if(this.state.drag) {
            this.setState({drag: false});
            let files = [...e.dataTransfer.files];
            for(let i = 0; i < files.length; i++) {
                files[i].id = createUniqueIdString(8);
                files[i].description = "";
                files[i].new_name = files[i].name.toString();
               // files[i].status = "waiting";
            }
            this.setState({
               files : [...this.state.files, ...files]
            });
        }
    }

    // удалить файл из выбранных файлов
    deleteFiles(item) {
        let files = this.state.files.filter(file => file.id !== item.id);
        this.fileSelector.value = '';
        this.setState({files : files});
    }

    // заполняем описание для определенного файла
    setDescriptionHandler(item, event) {
        let text = event.target.value;
        let index = this.state.files.findIndex(file => file.id === item.id);
        let files = this.state.files;
        files[index].description = text;
        this.setState({files : files});
    }

    // заполняем new_name для определенного файла
    setNameHandler(item, event) {
        let text = event.target.value;
        let index = this.state.files.findIndex(file => file.id === item.id);
        let files = this.state.files;
        files[index].new_name = text;
        this.setState({files : files});
    }


    // выбора файла по клику
    onClickFilesSelectHandler() {
        this.fileSelector.click();
        this.fileSelector.onchange = () => {
            let files = this.fileSelector.files;
            for(let i = 0; i < files.length; i++) {
                files[i].id = createUniqueIdString(8);
                files[i].description = "";
                files[i].new_name = files[i].name.toString();
             //   files[i].status = "waiting";
            }
            this.setState({
                files : [...this.state.files, ...files]
            });
        }
    }

    async updateSignatures(sectionData, cardId) {
        for (let i in sectionData.files) {
            if (sectionData.files[i].id) {
                let lParam = {idDocFile: sectionData.files[i].id, activeCardId: cardId}
                await this.props.updateSignature({
                    RowInfo: lParam
                });
            }
        }
    }

    async saveChanges() {
        let files = [];
        for(let i = 0; i < this.state.files.length; i++) {
            files.push({
                name : this.state.files[i].new_name,
                description : this.state.files[i].description,
                file : this.state.files[i],
                type : this.props.data.type
            });
        }


        if(this.props.adminLevel === AdminLevelEnum.CommonUser) {
            try {
                const activeCard = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
                this.props.setLoaderModalData({
                    data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "DownloadDocModal174" }
                });
                await uploadFilesForDoc(files, activeCard?.IdDoc).then(async (res) => {
                    await this.props.createDocumentCards({
                        IdDoc: activeCard?.IdDoc,
                        IdVersion: activeCard.IdLastVersion,
                        IsRedactorMode: true,
                        isUpdate: true,
                        Next: {
                            action: async () => {
                                await this.props.createPreviewBySelectDocument({});
                                await this.props.collectDataForAdditionalSections_Normal({
                                    Next: {
                                        action: async () => {
                                            await this.props.selectActiveDocumentCard({
                                                CardId: store.getState().document.cards.collection.at(-1).Id
                                            });

                                            await this.props.deleteDocumentCard({CardId: activeCard.Id});
                                            await this.updateSignatures(
                                                {...store.getState().document.cards.collection.at(-1).additionalSections.filesSection},
                                                store.getState().document.cards.activeCardId);
                                            this.props.setLoaderModalData({keyDeleted: "DownloadDocModal196"});

                                        }
                                    }
                                });

                            }
                        }
                    });
                });

                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "success", content: `Файлы загружены`},
                });
            } catch (e) {
                console.log('e', e)
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Файлы не были загружены`}
                });
            }

        } else {
            this.props.addNewFilesRedactorData({
                type : this.props.data.type,
                newFiles : files,
                Next : {
                    action : () => {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "success", content: `Файлы добавлены для прикрепления`},
                        });
                    },
                    params : {}
                },
                Error : {
                    action : () => {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: `Файлы не были прикреплены`}
                        });
                    },
                    params : {}
                }
            });
        }

    }

    // когда навели файл на область загрузки
    dragActiveRender() {
        return (
            <div className="col-lg-12" id="dragZone"
                 onDragOver={(e) => {this.dragStartHandler(e)}}
                 onDragLeave={(e) => {this.dragLeaveHandler(e)}}
                 onDrop={(e) => {this.dropEndHandler(e)}}>
                <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-light text-secondary"
                     style={{height: "220px", border: "2px dashed #89B2EF", backgroundColor: "#f3f6f961", cursor: "pointer"}}>
                    <div className="card-body d-flex">
                        <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                            <div className="flex-grow-1">
                                <h1 className="text-secondary font-weight-bold">Отпустите <br/>
                                    <b className="text-success">файлы</b></h1>
                                <h4 className="text-dark-75 font-weight-bold mt-3">
                                    <span className="text-success">Выберите </span>
                                    файлы в формате<br/> .doc, .docx, .xls, .pdf<br/>не более 250 Мб
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // когда файл не наведен на область загрузки
    dragNotActiveRender() {
        return (
            <div className="col-lg-12" id="dragZone"
                 onClick={this.onClickFilesSelectHandler}
                 onDragOver={(e) => {this.dragStartHandler(e)}}>
                <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-light text-secondary"
                     style={{height: "220px", border: "2px dashed #89B2EF", backgroundColor: "#f3f6f961", cursor: "pointer"}}>
                    <div className="card-body d-flex">
                        <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                            <div className="flex-grow-1">
                                <h1 className="text-secondary font-weight-bold">Перетащите или выберите  <br/><b className="text-success">файлы с компьютера</b></h1>
                                <h4 className="text-dark-75 font-weight-bold mt-3"><span className="text-success">Выберите </span>файлы в формате<br/> .doc, .docx, .xls, .pdf<br/>не более 250 Мб</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        let body = this.state.drag
            ? this.dragActiveRender()
            : this.dragNotActiveRender();

        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                    <div className="modal-content" style={{borderRadius: "20px"}}
                    onMouseEnter={()=>{this.setState({contentOnHover : true})}}
                    onMouseLeave={()=>{this.setState({contentOnHover : false})}}>
                        <div className="modal-body beautiful-scroll-10" style={{height: "30vw", padding: "1.9rem 2.15rem"}}>
                            <div className="row mt-4">
                                {body}
                            </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped table-borderless table-vertical-center">
                                        <thead className="thead-light">
                                        <tr className="text-uppercase">
                                            <th style={{borderRadius: "1em 0 0 0"}}/>
                                            <th ><p className="text-success m-0 font-weight-normal">Название</p></th>
                                            <th ><p className="text-success m-0 font-weight-normal">Описание</p></th>
                                            <th ><p className="text-success m-0 font-weight-normal">Размер</p></th>
                                            <th  style={{borderRadius: "0 1em  0 0"}}/>
                                        </tr>
                                        </thead>
                                        <tbody style={{fontSize: "1em"}}>
                                        {
                                            this.state.files.length > 0 &&
                                            this.state.files.map((item, index)=>{

                                                if(this.state.statusLoad === "collecting") {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td><i style={{color: "#1fbf3b"}} className="svg-icon text-primary svg-icon-sm icon-Okey fsic2"/></td>
                                                            {/*<td title={item.name}>{HelpFunctions.cutLongString(item.name, 30,true)}</td>*/}
                                                            <td>
                                                                <input type="text" className={"form-control"} onChange={(e)=>{this.setNameHandler(item, e)}}
                                                                       value={item.new_name}/>
                                                            </td>
                                                            <td>
                                                                <input type="text" className={"form-control"} onChange={(e)=>{this.setDescriptionHandler(item, e)}}
                                                                       value={item.description}/>
                                                            </td>
                                                            <td title={item.size}>{HelpFunctions.humanFileSize(item.size, true)}</td>
                                                            <td onClick={()=>{this.deleteFiles(item)}}>{
                                                                this.state.deleteFileTooltipShow &&
                                                                <MyTooltip target={this.deleteFileRef}
                                                                           text={"Удалить файл"}
                                                                           show={this.state.deleteFileTooltipShow}
                                                                           placement={"top"} delay={500}
                                                                />
                                                            }
                                                                <i ref={this.deleteFileRef}
                                                                   onMouseEnter={() => { this.setState({deleteFileTooltipShow: true}) }}
                                                                   onMouseLeave={() => { this.setState({deleteFileTooltipShow: false}) }} className="svg-icon text-danger svg-icon-sm icon-Table_edit_12 icon-color-red fsic2 cursor-pointer"/></td>
                                                        </tr>
                                                    );
                                                }
                                                else if(this.state.statusLoad === "loading") {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td><i style={{color: "#1fbf3b"}} className="svg-icon text-primary svg-icon-sm icon-Okey fsic2"/></td>
                                                            <td title={item.new_name}>{HelpFunctions.cutLongString(item.new_name, 30,true)}</td>
                                                            <td title={item.description}>{HelpFunctions.cutLongString(item.description, 30,true)}</td>
                                                            <td title={item.size}>{HelpFunctions.humanFileSize(item.size, true)}</td>
                                                            <td onClick={()=>{this.deleteFiles(item)}}>
                                                                {
                                                                    this.state.deleteFileTooltipShow &&
                                                                    <MyTooltip target={this.deleteFileRef}
                                                                               text={"Удалить файл"}
                                                                               show={this.state.deleteFileTooltipShow}
                                                                               placement={"top"} delay={500}
                                                                    />
                                                                }
                                                                <i ref={this.deleteFileRef}
                                                                   onMouseEnter={() => { this.setState({deleteFileTooltipShow: true}) }}
                                                                   onMouseLeave={() => { this.setState({deleteFileTooltipShow: false}) }} className="svg-icon text-danger svg-icon-sm icon-Table_edit_12 icon-color-red fsic2 cursor-pointer"/></td>
                                                        </tr>
                                                    );
                                                }
                                                // при this.state.statusLoad === "complete"
                                                else {
                                                    return null;
                                                }
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                        <button type="button" className="btn btn-primary font-weight-bolder"
                                style={{borderRadius:"15em"}}
                                disabled={this.state.files.length <= 0}
                                onClick={this.saveChanges}>
                            Подтвердить
                        </button>
                        <button type="button" className="btn btn-light font-weight-bold"
                                style={{borderRadius:"15em"}}
                                onClick={this.hide}>
                            Отменить
                        </button>

                    </div>
                    </div>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        data: state.globalState.app.modal.data,
        adminLevel: state.globalState.user.AdminLevel,
        cards: state.document.cards,
    }
}

const  mapDispatchToProps = {
    setModalData,
    addNewFilesRedactorData,

    createDocumentCards,
    selectActiveDocumentCard,
    deleteDocumentCard,
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    updateSignature,
    setLoaderModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDocModal);
