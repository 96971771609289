import {store} from "../../index";
import {__} from "../HelpFunctions";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    resetMainTableForUpdate, selectActiveRow
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {toggleFoldersForTree, treeSelectNode} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {
    collectDataForAdditionalSections_Normal, createDocumentCards,
    createPreviewBySelectDocument,
    resetDocumentPreview, selectActiveDocumentCard
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {
    ModalTypes, Operators as FilerOperatorsEnum,
    RootComponentsStateViewModeTypes, SortDirection as SortDirectionEnum, UserSettingsActionsTypes, UserSettingsNames,
} from "../StaticTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {getReportParams, setActiveRow} from "../../store/reports/actionCreators/report_LeftMenu";
import {searchByParams, updateInputsFieldSearch} from "../../store/search/actionCreators/search_Inputs";
import {combineMainTableAttributesSearch} from "../../store/search/actionCreators/search_table";
import {apiRequest, apiRequestNew} from "../API/apiRequest";
import {apiUrl} from "../API/apiUrl";
import {
    breadcrumbsAdd, getDocumentsForTreePackages, getPackageGroupsForTree, GetPackageTypes,
    PackageAddNodesTree, PackageCreateTree, SelectActivePackageDocument,
    SelectActivePackageNode, togglePackagesFolders
} from "../../store/packages/actionCreators/package_TreeActionCreator";
import {
    PackagesPaginationMovePage,
    setViewModePreviewPackages
} from "../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    combineMainTableAttributes_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    SelectActivePackageDocument_Right,
    selectActiveRow_packagesDocumentRight,
    treeSelectNode_packagesDocumentRight
} from "../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";
import {history} from "../../index";
import {API} from "../API_NEW/API";
import {setFilial} from "../../store/globalState/actionCreators/globalState_UserActionCreator";
import {updateUserSettings} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";
import {EntityFilters, Filter, FiltersType, Sorting} from "../Filters";
import {AUTH_ACTIONS} from "../../store/globalState/globalStateActionsList";
import {DOCUMENTS_PREVIEW_NORMAL, SEARCH_PREVIEW_NORMAL} from "../StaticTypesEnum";

const activePage = {
    home : "/",
    documents : "/documents",
    packages : "/packages",
    search : "/search",
    reports : "/report"
};

export class Routing {

    location = "/";
    // раздел документов
    documents = {
        // группа документа
        g : null,
        // тип документа
        t : null,
        // id документа
        d : null,
        // карточка ли это
        c : null,
    };

    // раздел пакетов
    packages = {
        // id пакета
        p : null,
        // id документа
        d : null,
        // id пакета справа
        p2 : null,
        // id документа справа
        d2 : null,
        // id группы документов (документы)
        dg2 : null,
        // id типа документов (документы)
        dt2 : null,
        // id конкретного документа (документы)
        dd2 : null,
        // id конкретного пакета загрузки
        dwp : null,
        // id конкретного документа загрузки
        dwd : null,
    };

    // раздел поиска
    search = {
        // был ли произведен поиск
        s : "",
        // значение строки "Поиск"
        st : "",
        // значение строки "Поиск по атрибутам"
        sat: "",
        //значение строки "Поиск по содержанию"
        ft: "",
        //значение строки "Поиск по дате"
        srd: "",
        //значение переключателя "Поиск в версиях"
        sVer: true,
        //значение переключателя "Поиск в удаленных"
        sDel: true,
        //значение переключателя "Поиск в тексте прикрепленных файлов"
        sAtt: true,
        //значение строки "Фильтр по номеру"
        srn: "",
        //значение строки "Фильтр по наименованию"
        sn: "",
        //значение строки "Фильтр по версии"
        vn: ""
    };

    // раздел отчетов
    reports = {
        // id репорта
      r : null
    };


    filial = {
        // активный филиал
        f : null
    };

    oldState = null;
    key = null;

    isLoaded = {
        home : false,
        documents : false,
        packages : false,
        search : false,
        reports : false
    }

    stored;

    constructor() {
        let routerStore = store.getState().router;
        this.location = routerStore.location.pathname;
        this.key = routerStore.location.key;
        this.oldState = __.deepCopyJSON(this);
        this.stored = __.deepCopyJSON(this);
    }

    async check(newStore, dispatchAction) {
        //TODO костыль
        if (newStore.location.pathname.indexOf("p~") != -1 && newStore.location.pathname.indexOf("#p~") == -1)
        {
            const link = newStore.location.pathname;
            const newLink = link.replace("p~", "#p~");

            history.replace(newLink);
            window.location.reload();
        }

        this.stored[newStore.location.pathname.split("/")[1]] = __.deepCopyJSON(newStore.location.query);
        let condition = ((newStore.action === "POP" || newStore.action === "REPLACE")  && (this.key !== newStore.location.key || dispatchAction === "finish")) || newStore.location.hash.indexOf("#documents") != -1;

        if(dispatchAction !== "finish" && newStore.location.hash.indexOf("#documents") == -1) {
            if(!this.isLoaded[newStore.location.pathname.split("/")[1]]) {
                return;
            }
        }
        else {
            this.isLoaded[newStore.location.pathname.split("/")[1]] = true;
        }

        if(condition) {
            if(newStore.location.hash !== "" && newStore.location.hash.indexOf("#documents") == -1) {
                const pathname = newStore.location.hash.split("#")[1].split(/;|~/)[0];
                switch(pathname) {
                    case "d": this.location = activePage.documents; break;
                    case "w": this.location = activePage.home; break;
                    case "p": this.location = activePage.packages; break;
                    case "s": this.location = activePage.search; break;
                    default: break;
                }

                let hash = "";
                if(newStore.location.hash.includes("~")) {
                    hash = newStore.location.hash.split('~').map((str, i) => {
                        if(i === 1) {
                            return str = "f=" + str;
                        }
                        switch(pathname) {
                            case "d": {
                                if (i === 2) {
                                    return str = "g=" + str;
                                } else if (i === 3) {
                                    return str = "t=" + str;
                                } else if (i === 4) {
                                    return str = "r=" + str;
                                } else {
                                    return str
                                }
                            }
                            case "p": {
                                if(i === 2) {
                                   return str = "pc=" + str;
                                } else if(i === 3) {
                                    return str = "t=" + str;
                                }  else if(i === 4) {
                                      return str = "r=" + str;
                                } else {
                                    return str;
                                }
                            }
                            case "w": return str;
                            case "s": return str;
                            default: break;
                        }
                    }).join(";");
                } else {
                    hash = newStore.location.hash
                }

                this.parseGetHashParams(hash);
            } else {
                //TODO костыль 1C
                if (newStore.location.hash.indexOf("#documents") != -1)
                {
                    let link = `${activePage.documents}${newStore.location.hash.slice(newStore.location.hash.indexOf("#documents")+10, newStore.location.hash.length)}`
                    history.replace(link);
                    window.location.reload();
                } else {
                    this.key = newStore.location.key;
                    this.location = newStore.location.pathname;
                    this.parseGet(newStore.location.query);
                }
            }

            // store.dispatch(getReportParams({
            //     IdReport: r,
            // }));
           let filial= store.getState().globalState.filial.All.find(item => item.Id == this.filial.f)
            if((store.getState().globalState.filial.Active.Id != this.filial.f)&&filial){

                store.dispatch(setFilial({
                    Filial: filial,
                    Next : {
                        action : () => {
                            store.dispatch(updateUserSettings({
                                Name : UserSettingsNames.FILIAL_CONFIG,
                                Action : UserSettingsActionsTypes.Update,
                                // Next : {
                                //     action : () => {
                                //         let query = this.props.router.location.query;
                                //         let queryLink = "";
                                //
                                //         if(this.props.router.location.pathname === "/documents") {
                                //             queryLink = Routing.CreateRef({
                                //                 oldQuery : query,
                                //                 newQuery : {},
                                //                 excludedKeys : ["c", "d"]
                                //             });
                                //         }
                                //         else if(this.props.router.location.pathname === "/packages") {
                                //             queryLink = Routing.CreateRef({
                                //                 oldQuery : query,
                                //                 newQuery : {p : 2},
                                //                 excludedKeys : ["c", "d", "p2", "d2", "dwp", "dwd", "dg2", "dt2", "dd2"]
                                //             });
                                //         }
                                //
                                //         let link = `${this.props.router.location.pathname}${queryLink}`;
                                //
                                //         history.push(link);
                                //         window.location.reload();
                                //     },
                                //     params : {}
                                // }
                                Next : {
                                    action : () => {
                                        window.location.reload();
                                    },
                                    params:{}
                                }
                            }))
                        },
                        params: {}
                    }
                }));
            }

            await this.execute(dispatchAction, newStore);

            // if(newStore.location.pathname === activePage.documents) {
            //     this.oldState.documents = __.deepCopyJSON(this.documents);
            // }
            // else if(newStore.location.pathname === activePage.packages) {
            //     this.oldState.packages = __.deepCopyJSON(this.packages);
            // }
            // else if(newStore.location.pathname === activePage.home) {
            //     this.oldState.home = __.deepCopyJSON(this.home);
            // }
            // else if(newStore.location.pathname === activePage.search) {
            //     this.oldState.search = __.deepCopyJSON(this.search);
            // }
            // else if(newStore.location.pathname === activePage.reports) {
            //     this.oldState.reports = __.deepCopyJSON(this.reports);
            // }


            this.oldState.location = this.location;
            this.oldState.key = this.key;
            this.oldState.filial = this.filial;
        }
    }

    updateOldState(newStore) {
        let pathName = "";
        if(newStore.location.hash !== "") {
            const path = newStore.location.hash.split("#")[1].split(";")[0];
            switch (path) {
                case "d": pathName = activePage.documents;break;
                case "w": pathName = activePage.home;break;
                case "p": pathName = activePage.packages;break;
                case "s": pathName = activePage.search;break;
                default:break;
            }
        } else {
            pathName = newStore.location.pathname;
        }

        if(pathName === activePage.documents) {
            this.oldState.documents = __.deepCopyJSON(this.documents);
        }
        else if(pathName === activePage.packages) {
            this.oldState.packages = __.deepCopyJSON(this.packages);
        }
        else if(pathName === activePage.home) {
            this.oldState.home = __.deepCopyJSON(this.home);
        }
        else if(pathName === activePage.search) {
            this.oldState.search = __.deepCopyJSON(this.search);
        }
        else if(pathName === activePage.reports) {
            this.oldState.reports = __.deepCopyJSON(this.reports);
        }
    }

    parseGet(query) {
        this.filial.f = query.f ?? null;
       // this.filial.f = 1;

        switch (this.location) {
            case activePage.home :
                break;
            case activePage.documents :
                this.documents = {
                    g: query.g ?? null,
                    t: query.t ?? null,
                    d: query.d ?? null,
                    c: query.c ?? null,
                };
                break;
            case activePage.packages :
                this.packages = {
                    p: query.p ?? null,
                    d: query.d ?? null,
                    p2: query.p2 ?? null,
                    d2: query.d2 ?? null,
                    dg2: query.dg2 ?? null,
                    dt2: query.dt2 ?? null,
                    dd2: query.dd2 ?? null,
                    dwp: query.dwp ?? null,
                    dwd: query.dwd ?? null,
                };
                break;
            case activePage.search :
                this.search = {
                    s: query.s ?? "",
                    st: query.st ?? "",
                    sat: query.sat ?? "",
                    ft: query.ft ?? "",
                    sVer: query.sVer ?? true,
                    sDel: query.sDel ?? true,
                    sAtt: query.sAtt ?? true,
                    srd: query.srd ?? "",
                    srn: query.srn ?? "",
                    sn: query.sn ?? "",
                    vn: query.vn ?? ""
                };
                break;
            case activePage.reports :
                this.reports = {
                    r: query.r ?? null
                };
                break;
            default: break;
        }
    }

    parseGetHashParams(hash) {
        const hashString = hash.substr(3);
        const params = hashString.split(';').reduce(function (res, item) {
            const parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});

        this.filial.f = params.f ?? null;

        let link = "";
        switch (this.location) {
            case activePage.home : break;
            case activePage.documents :
                if(params) {
                    // alert("aa 21.03.2023 params" + JSON.stringify(params));
                    this.documents = {
                        // id пакета
                        g :  (params.g == "0" ? null : params.g),
                        // id документа
                        t :  (params.t == "0" ? null : params.t),
                        // id конкретного пакета загрузки
                        r :  (params.r == "0" ? null : params.r),
                    };
                    // link = `${activePage.documents}?${params.g ? `g=${params.g}` : ''}${params.t ? `${params.g ? '&' : ''}t=${params.t}` : ''}${params.r ? `&d=${params.r}` : ''}`;
                    link = `${activePage.documents}?${this.documents.g ? `g=${this.documents.g}` : ''}${this.documents.t ? `${this.documents.g ? '&' : ''}t=${this.documents.t}` : ''}${this.documents.r ? `${(this.documents.g || this.documents.t) ? '&' : ''}d=${this.documents.r}` : ''}`;

                }
                break;
            case activePage.packages :
                const isDownload = params.pt == 1;
                this.packages = {
                    // id пакета
                    p : isDownload ? 2 : (params.pc == "0" ? null : params.pc),
                    // id документа
                    d : isDownload ? null : (params.r == "0" ? null : params.r),
                    // id конкретного пакета загрузки
                    dwp : isDownload ? (params.pc == "0" ? null : params.pc) : null,
                    // id конкретного документа загрузки
                    dwd : isDownload ? (params.r == "0" ? null : params.r) : null,
                };
                if(params) {
                    link = `${activePage.packages}?${this.packages.p ? `p=${this.packages.p}` : ''}${this.packages.d ? `&d=${this.packages.d}` : ''}${this.packages.dwp ? `&dwp=${this.packages.dwp}` : ''}${this.packages.dwd ? `&dwd=${this.packages.dwd}` : ''}`;
                }
                break;
            case activePage.search:
                link = `${activePage.search}`; break;
            default: break;
        }
        history.replace(link);
        window.location.reload();
    }

    async execute(dispatchAction, newStore) {
        try {
            switch (this.location) {
                case activePage.home :
                    await this.homeExecute(dispatchAction, newStore);
                    break;
                case activePage.documents :
                    await this.documentsExecute(dispatchAction, newStore);
                    break;
                case activePage.packages :
                    await this.packagesExecute(dispatchAction, newStore);
                    break;
                case activePage.search :
                    await this.searchExecute(dispatchAction, newStore);
                    break;
                case activePage.reports :
                    await this.reportsExecute(dispatchAction, newStore);
                    break;
                default: break;
            }
        }
        catch (e) {
            //TODO e.responseJSON.Message нет такого, вывел лог, чтоб было понятно, что за ошибка, иначе приложение просто валится
            history.replace(this.location);
            history.push(this.location);

            store.dispatch(setLoaderModalData({keyDeleted : "routing499"}));

            store.dispatch(setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: e.responseJSON ?  e.responseJSON.Message : e}
            }));
            this.oldState.packages = __.deepCopyJSON(this.packages);
        }
        finally {
            //Новый прелоадер

            //TODO перенесено в routing499, т.к. закрывается слишком рано, пока еще не отработал completedFlags
            // store.dispatch(setLoaderModalData({keyDeleted : "routing506"}));
        }
    }

    async homeExecute(dispatchAction, newStore) {
        this.updateOldState(newStore);
    }

    async documentsExecute(dispatchAction, newStore) {
        let {g,t,d,c} = this.documents;
        let oldState = __.deepCopyJSON(this.oldState.documents);
        this.updateOldState(newStore);

        //TODO Иначе создается два раза, а закрывается только один при переходе из лайтовой карточки
        if (!store.getState().globalState.app.loaderModal.data.keys.find(item => item == "routing521"))
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "routing521" }
            }));
        if (!d && !g && !t)
            store.dispatch(setLoaderModalData({
                keyDeleted : "routing530"
            }));
        if((d && !g && !t) || (d && t && !g)) {

            let result = await Routing.CheckDocumentExistOrAccess(d);
            if(!result) {

                let query = store.getState().router.location.query
                let queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : query,
                    excludedKeys : ["d", "d2"]
                });
                history.replace(queryLink)

                return;
            }

            g = result.IdDocGroup;
            t = result.IdDocType;

            let query = store.getState().router.location.query;

           let queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {g: g,t: t,d: d},
                excludedKeys : []
            });
            history.replace(queryLink);
            history.push(queryLink);

            //TODO SS* убрано, чтобы не было чехарды с прелодерами
            // window.location.reload();
            return;
        }

        let selectCard = () => {
            let IdDoc = store.getState().document.mainTable.activeRow.Version.IdDoc;
            let IdVersion = store.getState().document.mainTable.activeRow.Version.Id;

            store.dispatch(createDocumentCards({
                IdDoc:IdDoc,
                IdVersion:IdVersion,
                Next : {
                    action : ()=> {
                        store.dispatch(selectActiveDocumentCard({
                            CardId : store.getState().document.cards.collection.find(card => card.IdDoc == d).Id,
                            Next : {
                                action : ()=> {
                                    sendFlag("c");
                                    store.dispatch(setStateLeftAsideMenu({
                                        rootName: "DocumentApp",
                                        isClose : true
                                    }));
                                },
                                params:{}
                            }
                        }))
                    },
                    params:{}
                }
            }))
        }
        let checkDownloadTableCondition = () => {
            const document = store.getState().document
            let activeRow
            if (d) activeRow = document.mainTable.body.find(tr => tr.Version.IdDoc == d);
            return (!document.search.isSearch
                && (!document.mainTable.body
                    || document.mainTable.body.length === 0
                    || document.cards.collection.length > 0
                    || !activeRow))
                || store.getState().search.mainTable.activeRow
        }
        let selectDocument = async (type_tree) => {
            if(d) {
                let result = await Routing.CheckDocumentExistOrAccess(d);
                if(!result) {
                    store.dispatch(changeDocumentViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
                    }));
                    return;
                }

                // TODO SS чтобы при переключении через боковое меню и обратно сохранялось состояние фильтров и активная строка
                if (checkDownloadTableCondition())
                    store.dispatch(mainTableDownloadDocCollection({
                        id: d ?? null, ActiveNode: type_tree,
                        Next : {
                            action : () => {
                                store.dispatch(combineMainTableAttributes({ActiveNode: type_tree,
                                    Next : {
                                        action : () => {
                                            let activeRow = store.getState().document.mainTable.body.find(tr => tr.Version.IdDoc == d);
                                            store.dispatch(selectActiveRow({
                                                RowInfo: activeRow,
                                                Next: {
                                                    action: () => {
                                                        store.dispatch(resetDocumentPreview({
                                                            Next: {
                                                                action: () => {
                                                                    store.dispatch(createPreviewBySelectDocument({
                                                                        Next: {
                                                                            action: () => {
                                                                                store.dispatch(collectDataForAdditionalSections_Normal({
                                                                                    Next: {
                                                                                        action: () => {
                                                                                            if (c && d) selectCard();
                                                                                            sendFlag("d");
                                                                                        },
                                                                                        params: {}
                                                                                    }
                                                                                }))
                                                                            },
                                                                            params: {}
                                                                        }
                                                                    }))
                                                                },
                                                                params: {}
                                                            }
                                                        }));
                                                    },
                                                    params: {}
                                                }
                                            }));
                                        },
                                        params : {}
                                    }
                                }));
                            },
                            params : {}
                        }
                    }));
                else {
                    let activeRow = store.getState().document.mainTable.body.find(tr => tr.Version.IdDoc == d);
                    store.dispatch(selectActiveRow({
                        RowInfo: activeRow ?? null,
                        Next: {
                            action: () => {
                                store.dispatch(resetDocumentPreview({
                                    Next: {
                                        action: () => {
                                            if (activeRow)
                                                store.dispatch(createPreviewBySelectDocument({
                                                    Document: activeRow,
                                                    Next: {
                                                        action: () => {
                                                            store.dispatch(collectDataForAdditionalSections_Normal({
                                                                Next: {
                                                                    action: () => {
                                                                        if (c && d) selectCard();
                                                                        sendFlag("d");
                                                                    },
                                                                    params: {}
                                                                }
                                                            }))
                                                        },
                                                        params: {}
                                                    }
                                                }))
                                            else {
                                                if (c && d) selectCard();
                                                sendFlag("d");
                                            }
                                        },
                                        params: {}
                                    }
                                }));
                            },
                            params: {}
                        }
                    }));
                }
            }
            else if(c) sendFlag("c");
        }

        const completedFlags = async (CreateFlags = null) => {
            let flags = __.deepCopy(CreateFlags);
            return async (flagName) => {
                flags[flagName] = true;

                let complete = true;
                for (let key in flags) {
                    complete = flags[key];
                    if(!complete) break;
                }

                if(complete) {
                    if(g && t && !d) {
                        store.dispatch(changeDocumentViewMode({
                            ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
                        }));
                    }
                    else if(g && t && d && !c) {
                        if (!DOCUMENTS_PREVIEW_NORMAL.find(item => item == store.getState().document.rootState.viewMode))
                            store.dispatch(changeDocumentViewMode({
                                ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal
                            }));
                    }
                    else if(g && t && d && c) {
                        store.dispatch(changeDocumentViewMode({
                            ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                        }));
                    }
                    else {
                        store.dispatch(changeDocumentViewMode({
                            ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.None
                        }));
                    }
                    // if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
                    //     store.dispatch(setModalData({}));
                    //Новый прелоадер
                    store.dispatch(setLoaderModalData({keyDeleted : "routing741"}));
                }
            }
        }


        let flags = {};
        if(g) flags.g = false;
        if(t) flags.t = false;
        if(d) flags.d  = false;
        if(c) flags.c = false;

        let sendFlag = await completedFlags({...flags});

        let group_tree;
        let type_tree
        if(t
            //TODO есть второй раз пытаешься открыть документ из истории, то он не открывается
            // !== oldState.t
            || g
            // !== oldState.g
        ) {
            if(!g) {
                const allGroups = store.getState().document.tree.group;
                allGroups.forEach(group => {
                    if(group.GroupTypeRefs.length > 0) {
                        if(group.GroupTypeRefs.find(item => item.IdDocType == t)) {
                            g = group.Id
                        }
                    }
                })
            };
            if(!t && !g) return;


            //TODO зачем это повторный прелоадер?????? Он же есть в 520 строке
            // if(dispatchAction === "finish") {
            //     //Новый прелоадер
            // //     store.dispatch(setModalData({
            // //     name : ModalTypes.app.alert,
            // //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents"}
            // // }));
            //
            //     store.dispatch(setLoaderModalData({
            //         // name : ModalTypes.app.alert,
            //         data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "routing742"}
            //     }));
            // }


            // group_tree = store.getState().document.tree.render.children.find(group => group.info.Id == g);

            const tree_render = store.getState().document.tree.render.children;

            let isError = false;

            if (g && t && d) {
                //Задача № 22235
                let vFlagVerify = false;
                let docRefInfo = null;
                //Если документ уже верифицирован, то не делаем запрос
                if (store.getState().document.verifyDocs)
                {

                    if (store.getState().document.verifyDocs[d]) {

                        if (store.getState().document.verifyDocs[d][0].IdDocType == t && store.getState().document.verifyDocs[d][0].IdDocGroup == g) {

                            vFlagVerify = true;
                            docRefInfo = store.getState().document.verifyDocs[d][0];
                        }
                        else
                        {
                            const lFindVerifyDoc = store.getState().document.verifyDocs[d].find(item => item.IdDocType == t  && item.IdDocGroup == g)
                            if (lFindVerifyDoc)
                            {
                                vFlagVerify = true;
                                docRefInfo = lFindVerifyDoc;
                            }
                        }
                    }
                }

                // let res = API.search().verifyDocClientLinkIds({
                //     linkIds: {
                //         IdDoc: d,
                //         IdFilial: store.getState().globalState.filial.Active.Id,
                //         IdDocType: t,
                //         IdDocGroup: g
                //     }
                // })
                if (!vFlagVerify) {
                    const activeFilialId = store.getState().globalState.filial.Active.Id;
                    docRefInfo = API.search().verifyDocClientLinkIds({
                        linkIds: {
                            IdDoc: d,
                            IdFilial: activeFilialId,
                            IdDocType: t,
                            IdDocGroup: g
                        }
                    });
                    await docRefInfo.then(async (promiseRes) => {
                        if (promiseRes?.errorCode) {
                            // TODO TP костыль 23765
                            const isIncorrectFilial = promiseRes.message.Message.includes('некорректный филиал');
                            if(isIncorrectFilial) {
                                const body = {
                                    filter: new Filter(FiltersType.ENTITY)
                                        .add(new EntityFilters().setEntityName("DocExtended")
                                            .add({Name: "Id", Operator: FilerOperatorsEnum.Equal, Value: d})
                                            .get()
                                        )
                                        .addAttribute({Name: "PagingFilter", Value: {PageOffset: 0, PageSize: 25}}),
                                    sorting: new Sorting().add({PropertyName: "Id", Direction: SortDirectionEnum.Desc})
                                }
                                const docInfo = await API.documents().getDocsExtended(body);
                                const filialName = store.getState().globalState.filial.All.find(item => item.Id === docInfo.Records[0].IdFilial)?.Name;
                                store.dispatch(setModalData({
                                    name : ModalTypes.app.info,
                                    data : {
                                        type: "question",
                                        content : `Данный документ был открыт в другом ЛФО: <b>${filialName}</b> <br />Сменить ЛФО и перейти к документу?`,
                                        disableButton : false,
                                        fullBackground : false,
                                        onClickContinue: async () => {await __.buildDocLink(d, docInfo.Records[0].IdFilial)}
                                        }
                                }));
                            } else {
                                store.dispatch(setModalData({
                                    name: ModalTypes.app.info,
                                    data: {
                                        content: promiseRes.message.Message,
                                        disableButton: false,
                                        type: "fail",
                                        onClickSaveHandler: async (IsSave) => {
                                            if (IsSave) {
                                                history.replace('/documents');
                                            }
                                        }
                                    },
                                }))
                            }
                            isError = true

                        } else {
                            // сохраняем в редакс
                            store.dispatch({
                                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                                    id: d,
                                    data: promiseRes
                                }
                            });
                        }
                    })
                }
            }
            if (isError)
            {
                //Новый прелоадер
                store.dispatch(setLoaderModalData({keyDeleted : "routing879"}));
                return;
            }
            store.dispatch(toggleFoldersForTree({isCollapse: true}));
            group_tree = __.findGroup(tree_render, g, false, true);
            if(!group_tree) {
                store.dispatch(setLoaderModalData({keyDeleted : "routing902"}));
                store.dispatch(setModalData({
                    name : ModalTypes.app.info,
                    data : {
                        content : "Недостаточно прав для открытия документа",
                        disableButton : false,
                        type : "fail",
                        //TODO aa 16.03.2023 Задача № 22110
                        onClickSaveHandler: async (IsSave) => {
                            if (IsSave) {
                                history.replace('/documents');
                            }
                        }
                    }
                }))
                return;
            } else {
                if(group_tree.info.StructureNode) {
                    let ids = [];
                    __.findParents(tree_render, group_tree.parent, ids);
                    ids.forEach(item => {store.dispatch(toggleFoldersForTree({IdFolder: item, KeepOpen: true}))});
                }
                store.dispatch(toggleFoldersForTree({IdFolder: group_tree.info.Id, KeepOpen: true}));
            }
            sendFlag("g");
            type_tree = group_tree.children.find(type => type.info.Id == t);
            if(!type_tree) {
                store.dispatch(setLoaderModalData({keyDeleted : "routing930"}));
                store.dispatch(setModalData({
                    name : ModalTypes.app.info,
                    data : {
                        content : "Недостаточно прав для открытия документа",
                        disableButton : false,
                        type : "fail",
                        //TODO aa 16.03.2023 Задача № 22110
                        onClickSaveHandler: async (IsSave) => {
                            if (IsSave) {
                                history.replace('/documents');
                            }
                        }
                    }
                }))
                return;
            }
            sendFlag("t");


            let id = (d && d !== oldState.d) ? d : oldState.d;


            if (d){
                store.dispatch(resetMainTableForUpdate({
                    Next: {
                        action: () => {
                            store.dispatch(treeSelectNode({
                                Node: type_tree, Next: {
                                    action: () => {
                                        if (!store.getState().document.mainTable.body || store.getState().document.mainTable.body.length === 0)
                                            store.dispatch(combineMainTableAttributes({
                                                ActiveNode: type_tree, Next: {
                                                    action: () => {
                                                        store.dispatch(resetDocumentPreview({
                                                            Next: {
                                                                action: () => {
                                                                    sendFlag("t");
                                                                    selectDocument(type_tree);
                                                                    },
                                                                params: {}
                                                            }
                                                        }));
                                                        },
                                                    params: {}
                                                }
                                            }));
                                        else {
                                            sendFlag("t");
                                            selectDocument(type_tree);
                                        }
                                    },
                                    params: {}
                                }
                            }));
                        },
                        params: {}
                    }
                }));
            }
            else {
                store.dispatch(resetMainTableForUpdate({
                    Next: {
                        action: () => {
                            store.dispatch(treeSelectNode({
                                Node: type_tree, Next: {
                                    action: () => {
                                        // TODO SS сделал как в package, чтобы при переключении через боковое меню и возвращении обратно результаты поиска не сбрасывались
                                        if (!store.getState().document.mainTable.body || store.getState().document.mainTable.body.length === 0)
                                            store.dispatch(mainTableDownloadDocCollection({
                                                id: id ?? null, ActiveNode: type_tree, Next: {
                                                    action: () => {
                                                        store.dispatch(combineMainTableAttributes({
                                                            ActiveNode: type_tree, Next: {
                                                                action: () => {
                                                                    store.dispatch(resetDocumentPreview({
                                                                        Next: {
                                                                            action: () => {
                                                                                sendFlag("t");
                                                                                selectDocument(type_tree);
                                                                            },
                                                                            params: {}
                                                                        }
                                                                    }));
                                                                },
                                                                params: {}
                                                            }
                                                        }));
                                                    },
                                                    params: {}
                                                }
                                            }));
                                        else {
                                            sendFlag("t");
                                            selectDocument(type_tree);
                                        }
                                    },
                                    params: {}
                                }
                            }));
                        },
                        params: {}
                    }
                }));
            }
        }
        else {
            // store.dispatch(setModalData({}))
            selectDocument(type_tree);
        }
    }

    async packagesExecute(dispatchAction, newStore) {
        //Новый прелоадер
    //     store.dispatch(setModalData({
    //     name: ModalTypes.app.alert,
    //     data: {
    //         content: "Загрузка пакетов...",
    //         disableButton: true,
    //         fullBackground: true,
    //         gif: "packages"
    //     }
    // }))

        store.dispatch(setLoaderModalData({
            // name: ModalTypes.app.alert,
            data: {
                content: "Загрузка пакетов...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key : "routing944"
            }
        }))
        let {p, d, p2, d2, dg2, dt2, dd2, dwp, dwd} = this.packages;
        let oldState = __.deepCopyJSON(this.oldState.packages);
        if (JSON.stringify(this.oldState.packages) === JSON.stringify(this.packages)
            || (!p && !d && !p2 && !d2 && !dg2 && !dt2 && !dd2 && !dwp && !dwd)
        ) {
            store.dispatch(setLoaderModalData({keyDeleted : "routing988"}));
            return
        }
        this.updateOldState(newStore);

        const completedFlags = async (CreateFlags = null) => {
            let flags = __.deepCopy(CreateFlags);

            return async (flagName) => {
                flags[flagName] = true;

                let complete = true;
                for (let key in flags) {
                    complete = flags[key];
                    if(!complete) break;
                }

                if(complete) {
                    if(p && !p2 && !dt2 && !dwp) {

                        if(d) {
                            const viewModePreview = store.getState().packages.rootState.viewModePreview
                            const isTwoTable = viewModePreview.includes("two_table")
                            store.dispatch(setViewModePreviewPackages( {ViewMode: isTwoTable ? viewModePreview : "normal"}));
                        }
                        else {
                            const viewModePreview = store.getState().packages.rootState.viewModePreview
                            const previewNone = !viewModePreview || viewModePreview === "welcome"
                            store.dispatch(setViewModePreviewPackages({ViewMode: !previewNone ? viewModePreview : "none"}));
                        }
                    }
                    else if(p2 && !dt2 && !dwp) {
                        store.dispatch(setViewModePreviewPackages({ViewMode: "two_table_none"}));
                    }
                    else if(dt2 && !dwp) {
                        store.dispatch(setViewModePreviewPackages({ViewMode: "right_document_table"}));
                    }
                    else if(dwp){
                        store.dispatch(setViewModePreviewPackages({ViewMode: "two_table_download"}));
                    }

                    // TODO добавлено условие для того, чтобы пользователь не видел по шагам как открываются вложенные пакеты в доп таблице
                    //  закрытие модального окна происходит после загрузки пакетов доп таблицы ( функция SelectPackage2 )
                    //TODO sg ?
                    // if(!p2) {
                    //Новый прелоадер
                    // if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
                    //     store.dispatch(setModalData({}));
                    store.dispatch(setLoaderModalData({keyDeleted : "routing1086"}));

                    // }

                }
            }
        }

        let flags = {};
        if(p) flags.p = false;
        if(d) flags.d = false;
        if(p2) flags.p2  = false;
        if(d2) flags.d2 = false;
        if(dt2) flags.dt2 = false;
        if(dd2) flags.dd2 = false;
        if(dwp) flags.dwp = false;
        if(dwd) flags.dwd = false;

        let sendFlag = await completedFlags({...flags});


        if (!p) {
            //Новый прелоадер
            // if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
            //     store.dispatch(setModalData({}))

            store.dispatch(setLoaderModalData({keyDeleted : "routing1058"}));
            return;
        }

        //TODO ???? лишняя модалка
        //
        // if (dispatchAction === "finish") {
        //     //Новый прелоадер
        //     // store.dispatch(setModalData({
        //     //     name: ModalTypes.app.alert,
        //     //     data: {
        //     //         content: "Загрузка пакетов...",
        //     //         disableButton: true,
        //     //         fullBackground: true,
        //     //         gif: "packages"
        //     //     }
        //     // }));
        //     store.dispatch(setLoaderModalData({
        //         // name: ModalTypes.app.alert,
        //         data: {
        //             content: "Загрузка пакетов...",
        //             disableButton: true,
        //             fullBackground: true,
        //             gif: "packages",
        //             key : "routing1028"
        //         }
        //     }));
        // }


        const SelectPackage1 = async () => {
            await store.dispatch(togglePackagesFolders({IsCollapse: true}))
            let request = new apiRequest(apiUrl.PACKAGE.GET_PACKAGE_PATH, {id: p});

                await request.execute(async (data) => {
                    for (let i = 1; i < data.length; i++) {
                        // if (i === 0) continue;

                        // эмулировать клик по пакету

                        //TODO 22.03.2023 aa Оптимизация IsEmulatedClick добавлен, чтобы не делать дополнительный лоад, т.к. ниже есть await selectDocument("main");
                        await store.dispatch(await PackageAddNodesTree({
                            Id: data[i].Id,
                            IdDocOffset: d ? d : null,
                            TableType: "main",
                            IsEmulatedClick: !!d,
                            Next: {
                                action: () => {
                                    store.dispatch(SelectActivePackageNode({
                                        Id: data[i].Id,
                                        TableType: "main",
                                    }));
                                    store.dispatch(togglePackagesFolders({
                                        IdFolder: data[i].Id,
                                        TableType: "main",
                                    }));
                                    store.dispatch(breadcrumbsAdd({
                                        NodeId: data[i].Id,
                                        Name: data[i].Name,
                                        ParentId: data[i].IdParent,
                                        TableType: "main",
                                    }));
                                }
                    ,
                                params: {}
                            }
                        }));
                    }
                    await sendFlag("p");
                    if (d
                        // && d !== oldState.d
                    ) {
                        await selectDocument("main");
                    }


                    if (p2 && !dt2 && !dwp) {
                        await SelectPackage2();
                    } else if (dg2 && dt2 && !dwp) {
                        await SelectDocumentTreeRight();
                    } else if (dwp
                        // && dwp !== oldState.dwp
                    ) {
                        await SelectDownloadPackage();
                    }

                    ///////////// if (!dwp || d) store.dispatch(setModalData({}))
                });
        }

        const SelectPackage2 = async () => {
            let requestP2 = new apiRequest(apiUrl.PACKAGE.GET_PACKAGE_PATH, {id: p2});


            await requestP2.execute(async (data) => {
                for (let i = 0; i < data.length; i++) {
             //       if (i === 0) continue;

                    // эмулировать клик по пакету
                    await store.dispatch(await PackageAddNodesTree({
                        Id: data[i].Id,
                        TableType: "additional",
                        Next: {
                            action: async () => {
                                store.dispatch(SelectActivePackageNode({
                                    Id: data[i].Id,
                                    TableType: "additional",
                                }));
                                store.dispatch(togglePackagesFolders({
                                    IdFolder: data[i].Id,
                                    TableType: "additional",
                                }));

                                store.dispatch(breadcrumbsAdd({
                                    NodeId: data[i].Id,
                                    Name: data[i].Name,
                                    ParentId: data[i].IdParent,
                                    TableType: "additional",
                                }));
                                await sendFlag("p2");
                            },
                            params: {}
                        }
                    }));
                }
                if (d2 && d2 !== oldState.d2) {
                    await selectDocument("additional");
                }
                //Новый прелоадер
                // if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
                //     store.dispatch(setModalData({}));

                // store.dispatch(setLoaderModalData({keyDeleted : "routing1190"}));
            });


        }
        const selectDocument = async (type = "main") => {
            let pageSize
            let documentsOffset
            let foldersTotal
            let lastLoad

            if (type === "main") {
                pageSize = __.deepCopy(store.getState().packages.tree.paginationPageSize);
                documentsOffset = __.deepCopy(store.getState().packages.tree.lastLoad.documentsOffset);
                foldersTotal = __.deepCopy(store.getState().packages.tree.lastLoad.foldersTotal);
                lastLoad = __.deepCopy(store.getState().packages.tree.lastLoad.documents);
            } else if (type = "additional") {
                pageSize = __.deepCopy(store.getState().packages.treeAdditional.paginationPageSize);
                documentsOffset = __.deepCopy(store.getState().packages.treeAdditional.lastLoad.documentsOffset);
                foldersTotal = __.deepCopy(store.getState().packages.treeAdditional.lastLoad.foldersTotal);
                lastLoad = __.deepCopy(store.getState().packages.treeAdditional.lastLoad.documents);
            } else {
                pageSize = __.deepCopy(store.getState().packages.treeDownload.paginationPageSize);
                documentsOffset = __.deepCopy(store.getState().packages.treeDownload.lastLoad.documentsOffset);
                foldersTotal = __.deepCopy(store.getState().packages.treeDownload.lastLoad.foldersTotal);
                lastLoad = __.deepCopy(store.getState().packages.treeDownload.lastLoad.documents);
            }


            let numberDoc = 100;
            let PageNumber = 0;
            if (lastLoad.findIndex(document => document.Id == d) >= 0) {
                numberDoc = lastLoad.findIndex(document => document.Id == d) + 1;
                PageNumber = Math.ceil((numberDoc + documentsOffset + foldersTotal) / pageSize);
            } else {
                if (!d && d != "")
                    PageNumber = 1
                else {
                    let IdFilial = store.getState().globalState.filial.Active.Id;
                    const PageOffset = 0;
                    let response;
                    response = await new apiRequestNew({
                        action : apiUrl.PACKAGE.DOC_IN_PAC,
                        params : {
                            filter : new Filter(FiltersType.ENTITY)
                                .addAttribute({Name : "IdPackage", Value : p})
                                .addAttribute({Name : "PagingFilter", Value : {pageSize, PageOffset}})
                                .add(
                                    new EntityFilters().setEntityName("DocInPackage")
                                        .add({Name: "IdFilial", Value: IdFilial})
                                        .get()
                                ).get(),
                            id : d,
                            sorting : new Sorting()
                                .add({Direction: 2,PropertyName : "RegDate"})
                                .add({Direction: 1,PropertyName : "RegNumber"})
                                .add({Direction: 1,PropertyName : "Id"})
                                .get()
                        },
                        headers : null,
                        endUrl : "",
                    }).execute();

                    let result = await response.json();

                    if(!response.ok) {
                        if(response.status === 401) {
                            store.dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                        }
                        console.log("Ошибка получения документов в пакете (post): ", response, result)
                        return;
                    }

                    numberDoc = 0;
                    if ((result.RecordsOffset || result.RecordsOffset==0) && result.Records)
                    {
                        numberDoc = __.deepCopy(result.Records).findIndex(document => document.Id == d) + 1;
                    }

                    //TODO 23.03.2023 aa округляем вниз, т.к. на pageSize числитель больше
                    // PageNumber = Math.ceil(((result.RecordsOffset ?? documentsOffset) + foldersTotal + pageSize) / pageSize);
                    PageNumber = Math.ceil(((result.RecordsOffset ?? documentsOffset) + foldersTotal + numberDoc) / pageSize);
                    // PageNumber = Math.floor((documentsOffset + pageSize + foldersTotal) / pageSize);
                }
             }

            store.dispatch(PackagesPaginationMovePage({
                PageNumber: PageNumber, TableType: type,
                Next: {
                    action: () => {
                        let foundDocument;
                        if (type === "main") {
                            foundDocument = store.getState().packages.tree.activeNode.Children
                                .filter((doc) => doc.Type === "PackagesDocument")
                                .find((doc) => doc.Node.Id == d);
                        } else {
                            foundDocument =
                                store.getState().packages.treeAdditional.activeNode.Children
                                    .filter((doc) => doc.Type === "PackagesDocument")
                                    .find((doc) => doc.Node.Id == d2);
                        }
                        if (!foundDocument) {
                            store.dispatch(setLoaderModalData({keyDeleted: "Routing1353"}));
                            store.dispatch(setModalData({
                                name: ModalTypes.app.info,
                                // data: {type: "fail", content: `Файл с данным Id не найден: [Id=${d}]`}
                                // todo tp  № 22883
                                data: {type: "fail", content: `Недостаточно прав для выполнения действия`}
                            }));

                            let query = store.getState().router.location.query
                            let queryLink = Routing.CreateRef({
                                                oldQuery : query,
                                                newQuery : query,
                                                // excludedKeys : ["p", "p2", "d", "d2"]
                                                // todo tp  № 22883
                                                excludedKeys : ["d", "d2"]
                                            });
                            history.replace(queryLink)
                            //Новый прелоадер
                            store.dispatch(setLoaderModalData({keyDeleted : "routing1363"}));
                            return;
                        }

                        store.dispatch(SelectActivePackageDocument({
                            Id: foundDocument.Node.Id,
                            TableType: type,
                            Next: {
                                action: async () => {
                                    store.dispatch(collectDataForAdditionalSections_Normal({}))
                                    if (type === "main") {
                                        await sendFlag("d");
                                    } else if (type === "additional") {
                                        await sendFlag("d2");
                                    } else if (type === "download") {
                                        await sendFlag("dwd");
                                    } else { // documents

                                    }
                                },
                                params: {}
                            },
                            Error : {
                                action : ()=> {
                                    store.dispatch(setViewModePreviewPackages({ViewMode: "none"}));
                                },
                                params : {}
                            }
                        }));
                    },
                    params: {}
                }
            }));
        }

        const SelectDocumentTreeRight = async () => {
            let activeDocumentRightGroup = store.getState().packages.treeForDocuments.render.children.find(group => group.info.Id == dg2);
            let activeDocumentRightType = activeDocumentRightGroup.children.find(type => type.info.Id == dt2);


            store.dispatch(treeSelectNode_packagesDocumentRight({
                Node: activeDocumentRightType,
                Next: {
                    action: () => {
                        store.dispatch(mainTableDownloadDocCollection_packagesDocumentRight({
                            Next: {
                                action: () => {
                                    store.dispatch(combineMainTableAttributes_packagesDocumentRight({
                                        Next: {
                                            action: () => {
                                                sendFlag("dt2");
                                                if (dd2 && oldState.dd2 != dd2) {
                                                    store.dispatch(mainTableDownloadDocCollection_packagesDocumentRight({
                                                        id: dd2,
                                                        Next: {
                                                            action: () => {

                                                                let activeRow = store.getState().packages.mainTable.body.find(tr => tr.Version.IdDoc == dd2);
                                                                store.dispatch(selectActiveRow_packagesDocumentRight({
                                                                    ActiveRow: activeRow,
                                                                    Next: {
                                                                        action: () => {
                                                                            store.dispatch(SelectActivePackageDocument_Right({
                                                                                ActiveRow: activeRow,
                                                                                Next : {
                                                                                    action: () => {
                                                                                        sendFlag("dd2");
                                                                                    },
                                                                                    params : {}
                                                                                }
                                                                            }));
                                                                        },
                                                                        params: {}
                                                                    }
                                                                }));
                                                            },
                                                            params: {}
                                                        }
                                                    }));
                                                }
                                            },
                                            params: {}
                                        }
                                    }));
                                },
                                params: {}
                            }
                        }));
                    },
                    params: {}
                }
            }));

        }

        const SelectDownloadPackage = async () => {

            await store.dispatch(getPackageGroupsForTree({
                IdParent : 1,
                IsDownload : true,
                IdPackageType : 1,
                Next : {
                    action: async () => {
                        await store.dispatch(getDocumentsForTreePackages({
                            Id: 1,
                            IdDocOffset: dwd ? dwd : null,
                            IsDownload: true,
                            Next : {
                                action: async () => {
                                    await store.dispatch(GetPackageTypes({
                                        idPackage : 1,
                                        IsDownload : true,
                                        Next : {
                                            action : async () => {
                                                await store.dispatch(PackageCreateTree({
                                                    IsDownload : true,
                                                    Next : {
                                                        action : async () => {
                                                            let request = new apiRequest(apiUrl.PACKAGE.GET_PACKAGE_PATH, {id: dwp});

                                                            await request.execute(async (data) => {
                                                                for (let i = 0; i < data.length; i++) {
                                                                    if (i === 0) continue;

                                                                    // эмулировать клик по пакету
                                                                    await store.dispatch(await PackageAddNodesTree({
                                                                        Id: data[i].Id,
                                                                        IdDocOffset: dwd ? dwd : null,
                                                                        IsDownload : true,
                                                                        Next: {
                                                                            action: () => {
                                                                                store.dispatch(SelectActivePackageNode({
                                                                                    Id: data[i].Id,
                                                                                    IsDownload : true,
                                                                                }));
                                                                                store.dispatch(togglePackagesFolders({
                                                                                    IdFolder: data[i].Id,
                                                                                    IsDownload : true,
                                                                                }));
                                                                                store.dispatch(breadcrumbsAdd({
                                                                                    NodeId: data[i].Id,
                                                                                    Name: data[i].Name,
                                                                                    ParentId: data[i].IdParent,
                                                                                    TableType: "download",
                                                                                    IsDownload : true,
                                                                                }));
                                                                            },
                                                                            params: {}
                                                                        }
                                                                    }));

                                                                }

                                                                sendFlag("dwp");
                                                                if(dwd) {
                                                                    let pageSize = __.deepCopy(store.getState().packages.treeDownload.paginationPageSize);
                                                                    let documentsOffset = __.deepCopy(store.getState().packages.treeDownload.lastLoad.documentsOffset);
                                                                    let foldersTotal = __.deepCopy(store.getState().packages.treeDownload.lastLoad.foldersTotal);


                                                                    let numberDoc = 100;
                                                                    let PageNumber = 0;
                                                                    if (__.deepCopy(store.getState().packages.treeDownload.lastLoad.documents).findIndex(document => document.Id == d) >= 0) {
                                                                        numberDoc = __.deepCopy(store.getState().packages.treeDownload.lastLoad.documents).findIndex(document => document.Id == d) + 1;
                                                                        PageNumber = Math.ceil((numberDoc + documentsOffset + foldersTotal) / pageSize);
                                                                    } else {
                                                                        PageNumber = Math.ceil((documentsOffset + pageSize + foldersTotal) / pageSize);
                                                                    }

                                                                    store.dispatch(PackagesPaginationMovePage({
                                                                        PageNumber: PageNumber,
                                                                        IsDownload : true,
                                                                        Next : {
                                                                            action : async () => {
                                                                                store.dispatch(SelectActivePackageDocument({
                                                                                    Id: dwd,
                                                                                    IsDownload : true,
                                                                                    TableType : "download",
                                                                                    Next: {
                                                                                        action: async () => {
                                                                                            sendFlag("dwd");
                                                                                            if (p2) {
                                                                                                sendFlag("p2");
                                                                                            }
                                                                                        },
                                                                                        params: {}
                                                                                    }
                                                                                }));
                                                                            },
                                                                            params : {}
                                                                        }
                                                                    }));
                                                                }
                                                            });
                                                        },
                                                        params : {}
                                                    }
                                                }));
                                            },
                                            params : {}
                                        }
                                    }));
                                },
                                params : {}
                            }
                        }));
                    },
                    params : {}
                }
            }));
        }

        if (p) {
            await SelectPackage1();
        } else {
            //Новый прелоадер
            // if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
            //     store.dispatch(setModalData({}));
            store.dispatch(setLoaderModalData({keyDeleted : "routing1509"}));
        }
    }

    async searchExecute(dispatchAction, newStore) {
        //Деструктуризация строки поиска на значения
        //sVer, sDel, sAtt - булевы значения по умолчанию true,
        //изменение со строки поиска на них не сделано
        //Это "Поиск в версиях","Поиск в удаленных", "Поиск в тексте прикрепленных файлов"
        let {s, st, sat, ft, sVer, sDel, sAtt, srd, srn, sn, vn} = this.search;
        if (!s && !st && !sat && !ft && !srd && !srn && !sn && !vn) return

        if(dispatchAction === "finish") {
            //Новый прелоадер
            // store.dispatch(setModalData({
            //     name : ModalTypes.app.alert,
            //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "search"}
            // }));

            store.dispatch(setLoaderModalData({
                // name : ModalTypes.app.alert,
                data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "search", key : "routing1466"}
            }));
        }
        this.updateOldState(newStore);

        //Если нет значений поиска, значения подхватываются из истории
        let searchParams
        if (s &&!st && !sat && !ft && !srd && !srn && !sn && !vn) {
            let allUserSettings = store.getState().globalState.userSettings;
            if (allUserSettings.length === 0) {
                // store.dispatch(setModalData({}))
                //Новый прелоадер
                //if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
                //     store.dispatch(setModalData({}));
                store.dispatch(setLoaderModalData({keyDeleted : "routing1544"}));
                return
            }
            searchParams = allUserSettings.find(item => item.Name === "searchConfig").Value.at(-1);
        } else
        {
            //decodeURI если в запросах будут спецсимволы
            searchParams = {
            SearchText : decodeURI(st),
            AttributesText : decodeURI(sat),
            FullText : decodeURI(ft),
            IsSearchInVersions: sVer,
            IsSearchInMarkedForDelete: sDel,
            IsSearchInAttachments: sAtt,
            //TypeIds: searchParams.TypeIds ? (isParseTypeIds ? typeIds : TypeIds) : null,
            // TypeIds: searchParams.TypeIds ? searchParams.TypeIds : null,
            //FilialIds:isParseFilials ? filialIds : FilialIds,
            FilialIds: "",
            RegDateAsString: decodeURI(srd),
            RegPeriodStart: "",
            RegPeriodFinish: "",
            RegNumber: decodeURI(srn),
            Name: decodeURI(sn),
            VersionNumber: decodeURI(vn),
        }
        }

        //
        // let filialIds = [];
        // let FilialIds = searchParams.FilialIds;
        // let isParseFilials = false;
        // if(FilialIds && FilialIds.length > 0 && FilialIds[0]?.value) {
        //     isParseFilials = true;
        //     for (let i = 0; i < FilialIds.length; i++) {
        //         filialIds.push(FilialIds[i].value);
        //     }
        // }
        //
        // let TypeIds = searchParams.TypeIds;
        // let typeIds = [];
        // let isParseTypeIds = false;
        // if(TypeIds && TypeIds.length > 0 && TypeIds[0]?.value) {
        //     isParseTypeIds = true;
        //     for (let i = 0; i < TypeIds.length; i++) {
        //         typeIds.push(TypeIds[i].value);
        //     }
        // }

        if(store.getState().search.searchState.searchInputsList.length === 0) {
            const idSearch = __.createUniqueIdString(6);
            store.dispatch(updateInputsFieldSearch({
                SearchText: searchParams.SearchText, //st
                AttributesText: searchParams.AttributesText, //sat
                FullText: searchParams.FullText,
                IsSearchInVersions: searchParams.IsSearchInVersions, //sVer
                IsSearchInMarkedForDelete: searchParams.IsSearchInMarkedForDelete, //sDel
                IsSearchInAttachments: searchParams.IsSearchInAttachments, //sAtt
                //TypeIds: searchParams.TypeIds ? (isParseTypeIds ? typeIds : TypeIds) : null,
                TypeIds: searchParams.TypeIds ? searchParams.TypeIds : null,
                //FilialIds:isParseFilials ? filialIds : FilialIds,
                FilialIds: searchParams.FilialIds,
                RegDateAsString: searchParams.RegDateAsString, //srd
                RegPeriodStart: searchParams.RegPeriodStart,
                RegPeriodFinish: searchParams.RegPeriodFinish,
                RegNumber: searchParams.RegNumber, //srn
                Name: searchParams.Name, //sn
                VersionNumber: searchParams.VersionNumber, //vn
                Id: idSearch,

                Next: {
                    action: () => {
                        store.dispatch(searchByParams({
                            Id: idSearch,
                            Next: {
                                action: () => {
                                    store.dispatch(combineMainTableAttributesSearch({
                                        Next: {
                                            action: () => {
                                                //Новый прелоадер
                                                // if (store.getState().globalState.app.modal?.data?.content?.indexOf("Загрузка") != -1)
                                                //     store.dispatch(setModalData({}));

                                                store.dispatch(setLoaderModalData({keyDeleted: "routing1624"}));
                                                // store.dispatch(updateUserSettings({
                                                //     Name : UserSettingsNames.SEARCH_CONFIG,
                                                //     Action : UserSettingsActionsTypes.Update,
                                                // }))
                                                //TODO 22703
                                                if (!SEARCH_PREVIEW_NORMAL.find(item => item == store.getState().search.rootState.viewMode))
                                                    store.dispatch(changeSearchViewMode({
                                                        ViewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly
                                                    }));
                                            },
                                            params: {}
                                        }
                                    }))
                                },
                                params: {}
                            }
                        }))
                    },
                    params: {}
                }
            }))
        }
    }

    async reportsExecute(dispatchAction, newStore) {

        let r = this.reports.r
        let oldState = __.deepCopyJSON(this.oldState.reports);
        this.updateOldState(newStore);

        if(!r) return;
        if(r === oldState.r) return;

        store.dispatch(getReportParams({
            IdReport: r,
            Next: {
                action: async () => {
                    store.dispatch(setActiveRow({
                        IdReport: r,
                    }));
                },
                params: {}
            }
        }));
    }

    static CreateRef({oldQuery, newQuery, excludedKeys}) {
        let link = "?";
        for (let key in oldQuery) {
            if (excludedKeys.findIndex(item => item == key) != -1) continue;

            if (key in newQuery) {
                link += `${key}=${newQuery[key]}&`;
            } else {
                link += `${key}=${oldQuery[key]}&`;
            }
        }

        for (let key in newQuery) {
            if(!(key in oldQuery)) {
                link += `${key}=${newQuery[key]}&`;
            }
        }

        return link.substring(0, link.length - 1);
    }


    static async CheckDocumentExistOrAccess(d) {
        if (d) {
            //Задача № 22235
            let vFlagVerify = false;
            let docRefInfo = null;
            //Если документ уже верифицирован, то не делаем запрос
            if (store.getState().document.verifyDocs) {
                if (store.getState().document.verifyDocs[d]) {
                    vFlagVerify = true;
                    docRefInfo = store.getState().document.verifyDocs[d][0];
                }
            }




            // let docRefInfo = API.search().verifyDocClientLinkIds({
            //     linkIds: {
            //         IdDoc: d,
            //         IdFilial: store.getState().globalState.filial.Active.Id,
            //     }
            // })
            if (!vFlagVerify) {
                docRefInfo = await API.search().verifyDocClientLinkIds({
                    linkIds: {
                        IdDoc: d,
                        IdFilial: store.getState().globalState.filial.Active.Id
                    }
                });

                if (docRefInfo?.errorCode) {

                    if (docRefInfo.message.ExceptionName === "DataAccessException") {
                        store.dispatch(setModalData({
                            name: ModalTypes.app.alert,
                            data: {
                                content: "На данный документ недостаточно прав или он расположен в другой Организации",
                                disableButton: false
                            }
                        }));
                    }
                        // else if(docRefInfo.message.ExceptionName === "NotExistException") {
                        //     store.dispatch(setModalData({
                        //         name : ModalTypes.app.alert,
                        //         data : {
                        //             content : "Данный документ не существует",
                        //             disableButton : false
                        //         }
                        //     }));
                    // }
                    else
                        store.dispatch(setModalData({
                            name: ModalTypes.app.info,
                            data: {
                                content: docRefInfo.message.Message,
                                disableButton: false,
                                type: "fail"
                            }
                        }));
                    //Новый прелоадер
                    store.dispatch(setLoaderModalData({keyDeleted : "routing1825"}));
                    return null;
                } else {
                    // сохраняем в редакс
                    store.dispatch({
                        type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                            id: d,
                            data: docRefInfo
                        }
                    });

                }
            }
            return docRefInfo;
        } else
            return null;
    }

}
