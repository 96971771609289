import React from "react";
import {connect} from "react-redux";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";
import HomeWelcome from "../static/HomeWelcome";
import {setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import HistoryHome from "../home/HistoryHome";
import MobileMenu from "../static/MobileMenu";


class HomeApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.setStateLeftAsideMenu({
            isClose : true
        });
    }


    render() {

        let asideMobileStyle = "";
        if(!this.props.asideMenuIsClose) {
            asideMobileStyle = "aside-on";
        }

        return (
            <div>
                <div className="header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu">

                    <MobileMenu rootName={"HomeApp"}/>

                    <div className="d-flex flex-column flex-root">
                        <div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>
                            <LeftMenu rootName="HomeApp"/>
                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper">

                            <div className="content d-flex flex-column flex-column-fluid">
                                <TopMenu rootName="HomeApp"/>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-row-fluid wrapper" style={{}}>
                            <div className="container-new"  style={{}}>
                                <div className="row pt-4" style={{paddingLeft : "15px", paddingRight : "15px", justifyContent : "center"}}>
                                  <HomeWelcome/>
                                  <HistoryHome/>
                                </div>
                            </div>
                        </div>

                        {/*<div style={{height : "100px", width : "300px", backgroundColor : "red"}}>*/}
                        {/*    <ul>*/}
                        {/*        <ReactDnD.Draggable type="fruit" data="banana"><li>Banana</li></ReactDnD.Draggable>*/}
                        {/*        <ReactDnD.Draggable type="fruit" data="apple"><li>Apple</li></ReactDnD.Draggable>*/}
                        {/*        <ReactDnD.Draggable type="metal" data="silver"><li>Silver</li></ReactDnD.Draggable>*/}
                        {/*    </ul>*/}
                        {/*    <ReactDnD.Droppable*/}
                        {/*        types={['fruit']} // <= allowed drop types*/}
                        {/*        onDrop={(data)=>{}}>*/}
                        {/*        <div className="Smoothie">*/}
                        {/*            <p>fmspfesjkfpeskfioesjfoesjfs</p>*/}
                        {/*        </div>*/}
                        {/*    </ReactDnD.Droppable>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        asideMenuIsClose : state.globalState.app.asideMenuIsClose,
    }
}

const  mapDispatchToProps = {
    setStateLeftAsideMenu,
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeApp);
