import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import "moment/locale/ru";
import {AdminLevelEnum, contextMenuTypes} from "../../../tools/StaticTypes";
import moment from "moment";
import {
    setContextMenuData,
    setLoaderModalData
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    addRowToChecked_packagesDocumentRight,
    deleteRowFromChecked_packagesDocumentRight, SelectActivePackageDocument_Right, selectActiveRow_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import * as ReactDnD from "../../tools/MyDragAndDrop/index";
import {history} from "../../../index";
import {Routing} from "../../../tools/routing/Routing";
import {ActionQueue} from "../../../store/rootReducer";

class TrMainTableDocumentsRight extends React.PureComponent  {
    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover : false
        };

        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.checkboxOnHover = this.checkboxOnHover.bind(this);
        this.checkboxOffHover = this.checkboxOffHover.bind(this);
        this.rightClick = this.rightClick.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
    }


    checkboxOnChange() {
        let {data, checkedFields} = this.props;

       if(new Set(checkedFields).has(data.Version.IdDoc)) {
           this.props.deleteRowFromChecked_packagesDocumentRight({
               IdDoc : data.Version.IdDoc
           });
       } else {
           this.props.addRowToChecked_packagesDocumentRight({
               IdDoc : data.Version.IdDoc
           });
       }
    }

    checkboxOnHover() {
        this.setState({
            checkboxOnHover : true
        });
    }

    checkboxOffHover() {
        this.setState({
            checkboxOnHover : false
        });
    }

    // обработка правого клика по строке, ячейке
    rightClick(event, item) {
        this.props.setContextMenuData({
            name : contextMenuTypes.MainTableTR_PackagesDocumentRight,
            data : {
                item : item,
                tr : this.props.data
            },
            position : {
                x : event.clientX,
                y : event.clientY}
        });
        event.preventDefault();
    }

    // загрузка выбранного документа;
    // Обработка onClick по строке;
    selectDocument() {
        this.props.ActionQueue({
            List : [
                {action : this.props.setLoaderModalData, params : {data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "TrMainTableDocumentsRight83" }}, name : "setLoaderModalData"},
                {action : this.props.selectActiveRow_packagesDocumentRight, params : {RowInfo : this.props.data}, name : "selectActiveRow_packagesDocumentRight"},
                {action : this.props.SelectActivePackageDocument_Right, params : {ActiveRow : this.props.data}, name : "SelectActivePackageDocument_Right"},
                {action : () => {
                        let query = this.props.router.location.query;
                        let queryLink = "";

                        queryLink = Routing.CreateRef({
                            oldQuery : query,
                            newQuery : {dd2 : this.props.data.Version.IdDoc},
                            excludedKeys : []
                        });

                        let link = `${this.props.router.location.pathname}${queryLink}`;
                        history.push(link);
                        this.props.setLoaderModalData({keyDeleted: "TrMainTableDocumentsRight98"})
                    }, params : {}, name : "history.push"},
            ],
            debug : false,
        });
    }


    render() {

        let {
            data,
            checkedFields
        } = this.props;

        // поиск того где находится версия
        let Version = data.tr.find(tr => tr.Name === "Version.Version").Value;

        // проверяем есть ли в отмеченных документах
        let isChecked = new Set(checkedFields).has(data.Version.IdDoc);
        let checkboxContent;
        if((isChecked || this.state.checkboxOnHover) && !data.Info.IsMarkedForDelete
        && this.props.adminLevel > AdminLevelEnum.CommonUser) {
            checkboxContent = (
                <td className="py-6" key={createUniqueIdString(6)} onMouseLeave={this.checkboxOffHover}>
                    <label className="checkbox checkbox-lg checkbox-inline mb-1" >
                        <input type="checkbox" checked={isChecked} onChange={this.checkboxOnChange}/>
                        <span className={"row-checked"}> </span>
                    </label>
                </td>
            );
        }
        else {

            let icon = "icon-Documents icolor";
            if(this.props.data.Info.IsMarkedForDelete) {
                icon = "icon-Doc_view_9 icon-color-red";
            }

            checkboxContent = (
                <td className="pl-0" key={createUniqueIdString(6)}  onMouseEnter={this.checkboxOnHover}>
                    <a href="#" className="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative">
                    <span className="svg-icon svg-icon-xxl">
                         <i className={`svg-icon ${icon}`}/>
                    </span>
                        <span className={`label label-sm label-light-success label-rounded font-weight-bolder position-absolute top-0 left-0 mt-1 mr-1 ${Version > 1 && "label-colored"}`}>
                    {Version}</span>
                        {/* Так пока выглядит вывод версии (она стоит первая пока что) */}
                    </a>
                </td>
            );
        }

        let superStyle = {
            overflow : "hidden",
            textOverflow: "ellipsis",
            whiteSpace : "nowrap",
        }

        let trStyle = { // чтобы не дергалось при наддвернее на чекбокс
            lineHeight : "min(55px)"
        };
        let timeStyle = {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        };


        // определяем активная строка или нет
        let trClass = this.props.activeDocument && this.props.activeDocument.Version.IdDoc === this.props.data.Version.IdDoc
            ? "tablez2 tr-active"
            : "tablez2";

        let dropData = checkedFields.length > 0
            ? checkedFields
            : {IdDoc: data.Version.IdDoc, IsMarkedForDelete: data.Info.IsMarkedForDelete};

        let dropType = checkedFields.length > 0
            ? "documents"
            : "document";


        return (
            <ReactDnD.Draggable
                onMouseLeave={this.checkboxOffHover}
                className={trClass}
                style={trStyle}
                tag={"tr"}
                type={dropType}
                data={JSON.stringify(dropData)}>
                {checkboxContent}
                {
                    data.tr.map((item, index) => {
                        if(item.Name === "Version.Version" || !item.UserVisible) {
                            return null;
                        }
                        else if(item.Name === "RegDate" || item.Name === "Version.ModifyTime") {
                            let timeMoment = new moment(item.Value);
                            return(
                                <td key={createUniqueIdString(6)}
                                    onClick={(event) => {this.selectDocument(event)}}
                                    onContextMenu={(event) => {this.rightClick(event, item)}}>
                                    <div className="text-primary font-weight-normal d-block font-size-lg" style={timeStyle}>
                                        <time>{timeMoment.format("L")}</time>
                                    </div>
                                </td>
                            );
                        }
                        else {
                            return(
                                <td key={createUniqueIdString(6)}
                                    onClick={(event) => {this.selectDocument(event)}}
                                    onContextMenu={(event) => {this.rightClick(event, item)}}>
                                    <div style={superStyle} title={item.Value} className=" text-primary font-weight-normal d-block font-size-lg ">
                                        {item.Value}
                                    </div>
                                </td>
                            );
                        }

                    })
                }
            </ReactDnD.Draggable>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeRow : state.packages.mainTable.activeRow,
        activeDocument : state.packages.mainTable.activeDocument,
        adminLevel : state.globalState.user.AdminLevel,
        checkedFields : state.packages.mainTable.checkedFields,
        router : state.router,
        viewMode : state.packages.rootState.viewMode,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    addRowToChecked_packagesDocumentRight,
    deleteRowFromChecked_packagesDocumentRight,
    SelectActivePackageDocument_Right,
    selectActiveRow_packagesDocumentRight,
    setContextMenuData, // TODO: Правая кнопка
    setLoaderModalData,
   // createPreviewBySelectDocument,
   // resetDocumentPreview,
   // collectDataForAdditionalSections_Normal,
}

export default connect(mapStateToProps,mapDispatchToProps)(TrMainTableDocumentsRight);
